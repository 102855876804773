import React, { useCallback, useState } from 'react';
import TextInput from '../../../../../components/Forms/TextInput';
import { useClothingFinishingOptions } from '../../../../../contexts/ClothingFinishingOptionsContext';
import SplitButton from '../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../components/IconsCatalog';
import { toast } from 'react-hot-toast-promise';
import SpanButton from '../../../../../components/Buttons/SpanButton';
import addFinishingOption from './api/addFinishingOption';
import { useHttpRequest } from '../../../../../contexts/HttpRequestContext';
import { useAppTranslation } from '../../../../../contexts/TranslationContext';
import editFinishingOption from './api/editFinishingOption';
import { deleteFromCachedFinishingOptions, updateCachedFinishingOptions, updateCachedFinishingOptionsAndItems } from '../../ViewProject/components/SublistList/components/FinishingOptionsManagerForSublist/services/finishingOptionsServices';
import { type FinishingOption } from '../../../../../types/ClothingFinishingOptions/FinishingOption';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationModal';
import deleteFinishingOption from './api/deleteFinishingOption';
import FinishingOptionItemsManager from './components/FinishingOptionItemsManager';
import { getServerErrorMessageFromResponse } from '../../../../../utils/helper';

type FinishingOptionEditType = {
  id?: number;
  title: string;
}

export default function FinishingOptionsManager() {
  const { finishingOptions, setFinishingOptions } = useClothingFinishingOptions();
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const [isRequesting, setIsRequesting] = useState(false);
  const [newFinishingOption, setNewFinishingOption] = useState<FinishingOptionEditType>({ title: '' });
  const [finishingOptionToDelete, setFinishingOptionToDelete] = useState<FinishingOption | null>(null);
  const [selectedFinishingOptionIdToAddItems, setSelectedFinishingOptionIdToAddItems] = useState<FinishingOption | null>(null);

  const validateFinishingOption = useCallback(() => {
    if (newFinishingOption.title === '') throw new Error(Translate('error.finishing-option-empty-title'));

    const isDuplicated = finishingOptions.find(finishingOption => finishingOption.title.toUpperCase() === newFinishingOption.title.toUpperCase());

    if (isDuplicated) throw new Error(Translate('error.finishing-option-duplicated-title'));
  }, [Translate, finishingOptions, newFinishingOption.title]);

  const handleAddFinishingOption = useCallback(() => {
    try {
      validateFinishingOption();

      const task = addFinishingOption({ httpConnection, title: newFinishingOption.title });

      setIsRequesting(true);

      toast.promise(task, {
        loading: Translate('progress.loading'),
        success: (addedFinishingOption) => {
          setFinishingOptions([addedFinishingOption, ...finishingOptions]);
          setNewFinishingOption({ title: '' });
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOptions, httpConnection, newFinishingOption.title, setFinishingOptions, validateFinishingOption]);

  const handleEditFinishingOption = useCallback(() => {
    try {
      validateFinishingOption();

      if (!newFinishingOption.id) return;

      const { id, title } = newFinishingOption;
      const task = editFinishingOption({ httpConnection, id, title });

      setIsRequesting(true);

      toast.promise(task, {
        loading: Translate('progress.loading'),
        success: (editedFinishingOption) => {
          const updatedFinishingOptions = updateCachedFinishingOptions(finishingOptions, editedFinishingOption);
          setFinishingOptions(updatedFinishingOptions);
          setNewFinishingOption({ title: '' });

          if (selectedFinishingOptionIdToAddItems) setSelectedFinishingOptionIdToAddItems({ ...selectedFinishingOptionIdToAddItems, title: editedFinishingOption.title });
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOptions, httpConnection, newFinishingOption, selectedFinishingOptionIdToAddItems, setFinishingOptions, validateFinishingOption]);

  const handleDeleteFinishingOption = useCallback(() => {
    try {
      if (!finishingOptionToDelete) return;

      const task = deleteFinishingOption({ httpConnection, id: finishingOptionToDelete.id });

      setIsRequesting(true);

      toast.promise(task, {
        loading: Translate('progress.loading'),
        success: () => {
          const updatedFinishingOptions = deleteFromCachedFinishingOptions(finishingOptions, finishingOptionToDelete);
          setFinishingOptions(updatedFinishingOptions);
          setNewFinishingOption({ title: '' });
          setFinishingOptionToDelete(null);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOptionToDelete, finishingOptions, httpConnection, setFinishingOptions]);

  const handleSubmitFinishingOptionForm = useCallback((event?: React.FormEvent) => {
    event?.preventDefault();

    const isEditing = !!newFinishingOption.id;
    if (isEditing) handleEditFinishingOption();
    else handleAddFinishingOption();
  }, [handleAddFinishingOption, handleEditFinishingOption, newFinishingOption.id]);

  const handleUpdateFinishingOption = useCallback((updatedFinishingOption: FinishingOption) => {
    const updatedFinishingOptions = updateCachedFinishingOptionsAndItems(finishingOptions, updatedFinishingOption);
    setFinishingOptions(updatedFinishingOptions);
    setSelectedFinishingOptionIdToAddItems(updatedFinishingOption);
  }, [finishingOptions, setFinishingOptions]);

  return (
    <React.Fragment>

      <ConfirmationModal
        title={Translate('modal.delete-finishing-option')}
        message={Translate('description.delete-finishing-option-confirmation-message')}
        visible={!!finishingOptionToDelete}
        disableButtons={isRequesting}
        style='danger'
        handleClose={() => {
          setFinishingOptionToDelete(null);
        }}
        handleConfirm={() => {
          handleDeleteFinishingOption();
        }}
      />

      {
        !!selectedFinishingOptionIdToAddItems && (
          <FinishingOptionItemsManager
            finishingOption={selectedFinishingOptionIdToAddItems}
            disableClickOutsideToClose
            hideFooter
            visible
            title={Translate('labels.finishing-option-items-variations')}
            message={Translate('description.add-variations-to-selected-finishing-option')}
            style='primary'
            handleClose={() => {
              setSelectedFinishingOptionIdToAddItems(null);
            }}
            handleConfirm={() => null}
            onUpdateFinishingOption={handleUpdateFinishingOption}
          />
        )
      }

      <div className="container-fluir">
        <div className='row mt-2'>
          <div className='col-3'>
            <h5 className='text-primary font-weight-bold'>{Translate('labels.management')}</h5>

            <div className="row">

              <div className="col-8">
                <form onSubmit={handleSubmitFinishingOptionForm}>

                  <TextInput
                    id='finishing-option-input'
                    disabled={isRequesting}
                    label={!newFinishingOption.id ? Translate('actions.add') : Translate('actions.edit')}
                    value={newFinishingOption.title}
                    onChange={({ target }) => {
                      setNewFinishingOption({ ...newFinishingOption, title: target.value });
                    }}
                  />
                </form>

              </div>

              <div className="col px-0">
                {!!newFinishingOption.id && (
                  <SplitButton
                    disabled={isRequesting}
                    simulateLabelMarginTop
                    icon={IconsCatalog.times}
                    color='danger'
                    handleClick={() => {
                      setNewFinishingOption({ title: '' });
                    }} />
                )}

                <SplitButton
                  disabled={isRequesting}
                  marginLeft={!!newFinishingOption.id}
                  simulateLabelMarginTop
                  icon={newFinishingOption.id ? IconsCatalog.pen : IconsCatalog.plus}
                  color='primary'
                  handleClick={() => {
                    handleSubmitFinishingOptionForm();
                  }} />
              </div>
            </div>

            <table className='table table-sm table-bordered'>
              <thead>
                <tr>
                  <th>{Translate('labels.finishing-option-type')}</th>
                  <th colSpan={2} className='text-center'>-</th>
                </tr>
              </thead>

              <tbody>
                {
                  finishingOptions.map((finishingOption, index) => (
                    <tr key={index}>
                      <td className='align-middle'>
                        <SpanButton
                          text={finishingOption.title}
                          handleClick={() => {
                            setSelectedFinishingOptionIdToAddItems(finishingOption);
                          }}
                        />
                      </td>

                      <td className='table-column-fit'>
                        <SplitButton
                          size='sm'
                          color='primary'
                          icon={IconsCatalog.pen}
                          handleClick={() => {
                            setNewFinishingOption(finishingOption);
                          }}
                        />
                      </td>

                      <td className='table-column-fit'>
                        <SplitButton
                          size='sm'
                          color='danger'
                          icon={IconsCatalog.trash}
                          handleClick={() => {
                            setFinishingOptionToDelete(finishingOption);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
