import { type AxiosInstance } from 'axios';
import { type SublistType } from '../../../../../../../../../types/SublistTypes';

type AddSublistFinishingOptionParams = {
  sublist_id: number;
  model_id: number;
  finishing_option_id: number;
  finishing_option_item_id: number;
}

export const addSublistFinishingOption = async (params: AddSublistFinishingOptionParams, httpConnection: AxiosInstance): Promise<SublistType> => {
  return await new Promise<SublistType>((resolve, reject) => {
    httpConnection.post<SublistType>('/list/clothing-projects/sublist/add-finishing-option', params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
