import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Files, FilesContent } from '../../style';
import { Attachment } from './fragments/Attachments';
import { DropZone } from './style';
import _ from 'lodash';

type FilePreviewProps = {
  onHandleSetFiles?: (attachments: File[]) => void;
  column?: number;
};

export function FilePreview({ onHandleSetFiles, column }: FilePreviewProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [dropZone, setShowDropZone] = useState(false);
  const [attachments, setAttachments] = useState<File[]>([]);

  const onHandleFiles = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    if (event.target.files) {
      setAttachments(prevAttachments => [...prevAttachments, ...Array.from(event.target.files ?? [])]);
    }
  }, []);

  const handleDrop = useCallback(
    (event: { preventDefault: () => void; dataTransfer: { files: Iterable<unknown> | ArrayLike<unknown> } }) => {
      event.preventDefault();
      const droppedFiles = Array.from(event.dataTransfer.files) as File[];
      setAttachments(prevAttachments => [...prevAttachments, ...droppedFiles]);
      setIsDragging(false);
      setShowDropZone(false);
    },
    []
  );

  const removeAttachment = useCallback((position: number) => {
    setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== position));
  }, []);

  const columnsCount = useMemo(() => {
    if (column) return column;
    if (attachments.length >= 3) return 4;
    return attachments.length + 1;
  }, [attachments.length, column]);

  useEffect(() => {
    const handleDragOver = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      setShowDropZone(true);
    };

    const handleDrop = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      setShowDropZone(false);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    if (onHandleSetFiles && !_.isEmpty(attachments)) {
      onHandleSetFiles(attachments);
    }
  }, [attachments, onHandleSetFiles]);

  return (
    <Files column={columnsCount}>
      {dropZone ? (
        <DropZone
          onDrop={handleDrop}
          onDragLeave={event => {
            event.preventDefault();
            setIsDragging(false);
          }}
          onDragEnter={event => {
            event.preventDefault();
            setIsDragging(true);
          }}
        >
          {isDragging ? 'Solte os arquivos aqui' : 'Arraste e solte arquivos aqui'}
        </DropZone>
      ) : null}

      {attachments.map((attachment, attachmentPosition) => (
        <Attachment
          key={attachmentPosition}
          attachment={attachment}
          onRemove={() => {
            removeAttachment(attachmentPosition);
          }}
        />
      ))}

      <FilesContent htmlFor='attachments'>
        <i className='fa fa-paperclip'></i>
        <span>Adicionar arquivos</span>
        <input type='file' multiple id='attachments' onChange={onHandleFiles} />
      </FilesContent>
    </Files>
  );
}
