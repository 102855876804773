import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

export type CreateWorkspaceDTO = {
  title: string;
  workingProcess: Array<{
    id: string;
    title: string;
    daily_amount: number;
    position: number;
  }>;
};

export class CreateWorkspace {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: CreateWorkspaceDTO) {
    return await this.http.post('/workspaces', data);
  }
}
