import React, { useCallback } from 'react';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import {
  type OrderReplacementsReportType
} from '../../../../../../types/Report/OrderReplacementsReportType';
import { displayFormattedDate } from '../../../../../../helpers/dateTime';
import { displayAsCurrency } from '../../../../../../utils/helper';
import { useSettings } from '../../../../../../contexts/SettingsContext';

type RenderSellsReportTableType = {
  orderReplacementsReport: OrderReplacementsReportType[];
}

export default function RenderOrderReplacementsReportTable({ orderReplacementsReport }: RenderSellsReportTableType) {
  const { Translate, dateFnsLocale } = useAppTranslation();
  const { preferences } = useSettings();

  const calculateTotalReplacementPriceInRow = useCallback((replacement: OrderReplacementsReportType) => {
    return replacement.price * replacement.order_replacements.length;
  }, []);

  return (
    <table className='table table-bordered table-striped table-hover table-sm' width='100%'>
      <thead>
        <tr>
          <th>{Translate('labels.replacement')}</th>
          <th className={'text-center'}>{Translate('labels.cost')}</th>
          <th className={'text-center'}>{Translate('labels.to-model')}</th>
          <th className={'text-center'}>{Translate('labels.requested-by')}</th>
          <th className={'text-center'}>{Translate('labels.at-day')}</th>
          <th className={'text-center'}>{Translate('labels.modified-at')}</th>
        </tr>
      </thead>

      <tbody>
        {
          orderReplacementsReport.map((replacement, index) => (
            replacement.order_replacements.length > 0 && (
              <React.Fragment key={index}>
                <tr>
                  <td rowSpan={replacement.order_replacements.length}>{replacement.name} ({replacement.order_replacements.length})</td>
                  <td rowSpan={replacement.order_replacements.length} className={'text-center'}>
                    {displayAsCurrency(calculateTotalReplacementPriceInRow(replacement), preferences.currency)}
                  </td>
                  <td className={'text-center'}>{replacement.order_replacements[0].model.name}</td>
                  <td className={'text-center'}>{replacement.order_replacements[0].user.name}</td>
                  <td className={'text-center'}>{displayFormattedDate(replacement.order_replacements[0].created_at, dateFnsLocale)}</td>
                  <td className={'text-center'}>{displayFormattedDate(replacement.order_replacements[0].updated_at, dateFnsLocale)}</td>
                </tr>

                {
                  replacement.order_replacements.slice(1).map((replacement, replacementIndex) => (
                    <tr key={replacementIndex}>
                      <td className={'text-center'}>{replacement.model.name}</td>
                      <td className={'text-center'}>{replacement.user.name}</td>
                      <td className={'text-center'}>{displayFormattedDate(replacement.created_at, dateFnsLocale)}</td>
                      <td className={'text-center'}>{displayFormattedDate(replacement.updated_at, dateFnsLocale)}</td>
                    </tr>
                  ))
                }
              </React.Fragment>
            )
          ))
        }

        {orderReplacementsReport.length === 0 && (
          <tr>
          <td colSpan={999} className="text-center">{Translate('status.nothing-to-show-for-now')}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
