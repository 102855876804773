import React, { useMemo } from 'react';
import { type IconButtonType } from '../types/IconButtonType';

type CircleButtonType = Omit<IconButtonType, 'title'>;

export default function CircleButton({ testId, icon, color, size, handleClick, marginLeft = false }: CircleButtonType) {
  const buttonSize = useMemo(() => (size ? `btn-${size}` : ''), [size]);

  const useMarginLeft = useMemo(() => (marginLeft ? 'ml-2' : ''), [marginLeft]);

  return (
    <React.Fragment>
      <button
        className={`btn btn-${color} btn-circle ${buttonSize} ${useMarginLeft}`}
        data-testid={testId}
        onClick={handleClick}
      >
        <i className={icon} data-testid='circle-button-icon' />
      </button>
    </React.Fragment>
  );
}
