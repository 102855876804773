import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

type ItemProps = {
  selectedItem?: boolean;
};

export const Item = styled.div<ItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(220, 220, 220, 0.1);

  ${props =>
    props.selectedItem &&
    css`
      background-color: rgba(220, 220, 220, 0.1);
    `}

  &:hover {
    background-color: rgba(220, 220, 220, 0.1);
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 2px 4px 5px rgba(0, 0, 0, 0.3);
  padding: 8px;
  z-index: 101;
  width: 220px;

  ${Item}:first-child {
    border-bottom: none;
  }
`;

export const WrapperModalContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const ItemLabel = styled.span`
  font-size: 14px;
  color: var(--gray-400);
  font-weight: 500;
  margin-right: 8px;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemOption = styled.div`
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(220, 220, 220, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Selected = styled.div`
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--success);
  pointer-events: none;
`;

export const Footer = styled.div`
  button {
    width: 100% !important;
  }
`;
