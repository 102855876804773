import styled from 'styled-components';

type ContainerProps = {
  formControl?: boolean;
  disabled: boolean;
}

export const Container = styled.div.withConfig({ shouldForwardProp: prop => !['formControl', 'disabled'].includes(prop) }) <ContainerProps>`
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  transition: all 0.5s ease-in-out;

  ${props => props.formControl && `
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    border-radius: 0.35rem;
  `}
`;
