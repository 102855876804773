import { createContext, useEffect, useContext, useCallback } from 'react';
import { type UserType } from '../types/UserType';
import { type LoginFieldsType } from '../types/LoginFieldsType';
import { type LoginResponseType } from '../types/LoginResponseType';
import { useHttpRequest } from './HttpRequestContext';
import { useUser } from './UserContext';

type AuthProviderProps = {
  children: JSX.Element;
};

type AuthContextType = {
  signed: boolean;
  doLogin: (credentials: LoginFieldsType) => Promise<UserType | null>;
  doLogout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { bearerToken, setBearerToken, httpConnection } = useHttpRequest();
  const { setUser } = useUser();

  const handleSuccess = useCallback(({ token, user }: LoginResponseType) => {
    setBearerToken(token);
    setUser(user);
  }, [setBearerToken, setUser]);

  const doLogin = useCallback(async (credentials: LoginFieldsType): Promise<UserType | null> => {
    return await new Promise((resolve, reject) => {
      const { email, password } = credentials;
      httpConnection
        .post<LoginResponseType>('/auth/login/list', { email, password })
        .then(response => {
          handleSuccess(response.data);
          resolve(response.data.user);
        })
        .catch(reject);
    });
  }, [handleSuccess, httpConnection]);

  const doLogout = useCallback(async (): Promise<void> => {
    await new Promise((resolve, reject) => {
      httpConnection
        .get('/auth/logout')
        .then(() => {
          setBearerToken('');
          setUser(null);
        })
        .catch(err => {
          reject(new Error(err));
        });
    });
  }, [httpConnection, setBearerToken, setUser]);

  useEffect(() => {
    if (bearerToken === '') setUser(null);
  }, [bearerToken, setUser]);

  return (
    <AuthContext.Provider
      value={{
        signed: !!bearerToken,
        doLogin,
        doLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
