import { useCallback, useState } from 'react';

import { useKanbanWorkspaces } from '../../contexts/KanbanWorkspacesContext';
import _ from 'lodash';
import { CreateCard } from './fragments/CreateCard';
import { UpdateCard } from './fragments/UpdateCard';

export const CardModal = () => {
  const { showCreateCard, kanbanCard } = useKanbanWorkspaces();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  if (!_.isEmpty(showCreateCard)) {
    return <CreateCard isExpanded={isExpanded} handleExpand={handleExpand} />;
  }

  if (!_.isEmpty(kanbanCard)) {
    return <UpdateCard isExpanded={isExpanded} handleExpand={handleExpand} />;
  }

  return null;
};
