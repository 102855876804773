import styled from 'styled-components';

export const Container = styled.div`
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px 5px #66666688;
  animation: fadeIn .4s ease-in-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
