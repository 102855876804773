import { type AxiosInstance } from 'axios';
import { type SellsReportType } from '../../../../../types/Report/SellsReportType';

type SellsReportParams = {
  startDate: string;
  endDate: string;
  selectedUserId: number;
}

export default async function (httpConnection: AxiosInstance, params: SellsReportParams): Promise<SellsReportType[]> {
  return await new Promise<SellsReportType[]>((resolve, reject) => {
    const { startDate, endDate, selectedUserId } = params;

    httpConnection.get<SellsReportType[]>(`list/clothing-projects/sells-report/range?start_date=${startDate}&end_date=${endDate}&user_id=${selectedUserId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
