import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import { useSettings } from '../../contexts/SettingsContext';
import Footer from '../../components/Footer';

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { backendResourcesReady } = useSettings();

  useEffect(() => {
    if (!backendResourcesReady) {
      navigate('/loading', { state: { destinationRoute: location.pathname + location.search } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div id='wrapper'>
        <Sidebar />

        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <Navbar />

            <div className='container-fluid'>
              <Outlet />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}
