import { type AxiosInstance } from 'axios';
import { type OrderReplacementsReportType } from '../../../../../types/Report/OrderReplacementsReportType';

type SellsReportParams = {
  startDate: string;
  endDate: string;
  selectedUserId: number;
}

export default async function (httpConnection: AxiosInstance, params: SellsReportParams): Promise<OrderReplacementsReportType[]> {
  return await new Promise<OrderReplacementsReportType[]>((resolve, reject) => {
    const { startDate, endDate, selectedUserId } = params;

    httpConnection.get<OrderReplacementsReportType[]>(`list/clothing-projects/order-replacements-report?start_date=${startDate}&end_date=${endDate}&user_id=${selectedUserId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
