import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';

type CreateListFormModalType = Omit<ModalBaseType, 'message' | 'style'>;

export default function CreateListFormModal(params: CreateListFormModalType) {
  return (
    <ModalBase {...params} style='primary' message=''>
      {params.children}
    </ModalBase>
  );
};
