type Colors = Record<number, string>;

export const COLORS: Colors = {
  2: 'var(--danger) !important',
  1: 'var(--orange) !important',
  0: 'var(--light-v2) !important'
};

export const BACKGROUND_COLORS: Colors = {
  2: 'var(--danger) !important',
  1: 'var(--orange) !important',
  0: 'var(--background-card) !important'
};

export const isHexColor = (code: string) => {
  const hexColorRegex = /^#([A-Fa-f0-9]{3}){1,2}([A-Fa-f0-9]{2})?$/;
  return hexColorRegex.test(code);
};

/**
 * Calculate luminance to determine text color
 * @param bgColor in hex code
 */
type getTextColorBasedOnBackgroundColorParams = {
  darkTextColor?: string;
  lightTextColor?: string;
}

export const getTextColorBasedOnBackgroundColor = (bgColor: string, textColorOptions?: getTextColorBasedOnBackgroundColorParams) => {
  const hex = bgColor.replace('#', '');
  const r = parseInt(hex.length === 3 ? hex[0] + hex[0] : hex.slice(0, 2), 16);
  const g = parseInt(hex.length === 3 ? hex[1] + hex[1] : hex.slice(2, 4), 16);
  const b = parseInt(hex.length === 3 ? hex[2] + hex[2] : hex.slice(4, 6), 16);

  // Calculate luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance > 128
    ? textColorOptions?.darkTextColor ?? '#000' // Light background -> dark text
    : textColorOptions?.lightTextColor ?? '#fff'; // Dark background -> light text
};
