import styled from 'styled-components';

export const ItemCard = styled.div`
  display: flex;
  padding: 1rem;
  gap: 10px;
  align-items: center;
`;

export const ItemAuthor = styled.div`
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--light);
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;

  span {
    font-size: 14px;
    color: var(--dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
`;

export const ItemDate = styled.span`
  font-size: 12px;
`;
