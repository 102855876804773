import * as Yup from 'yup';

export const validationOfficeHoursSchema = Yup.object().shape({
  officeHours: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      day: Yup.number().required('Dia da semana é obrigatório'),
      hours: Yup.array().min(1, 'É necessário ao menos um horário')
    })
  )
});

export const validationHolidaysSchema = Yup.object().shape({
  holidays: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Id do feriado é obrigatório'),
      date: Yup.string().required('Data do feriado é obrigatória'),
      title: Yup.string().required('Nome do feriado é obrigatório')
    })
  )
});
