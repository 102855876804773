import styled, { css } from 'styled-components';

type SublistExpandableContainerParams = {
  useCompactView: boolean;
}

export const SublistExpandableContainer = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'useCompactView' }) <SublistExpandableContainerParams>`
  ${({ useCompactView }) => useCompactView && css`
    max-height: 200px;
    overflow: auto;
    padding-right: 15px;
  `}
`;

export const SublistsFooterCompactToggleButton = styled.button`
  padding: 0px;
  margin: 0px;
  width: 150px;

  & > i {
    font-size: 14px;
  }
`;
