import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';
import { type BulkDeleteReportType } from '../../../../types/BulkDeleteReportType';
import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';

export class DeleteWorkspaces {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(selectedWorkspaces: KanbanWorkspace[]): Promise<BulkDeleteReportType> {
    const selectedIds = selectedWorkspaces.map(workspace => workspace.id);
    return await this.http.post<BulkDeleteReportType>('kanban/workspaces/bulk-delete', {
      selected_ids: selectedIds
    });
  }
}
