import styled from 'styled-components';

type ContainerProps = {
  isGlowing: boolean;
  alert?: boolean;
  backgroundColor: string;
};

export const Container = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'isGlowing' })<ContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;
  border-radius: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  gap: 10px;
`;

export const WrapperNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const CardNumber = styled.span`
  color: var(--info);
  font-size: 14px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: block;
    font-size: 12px;
  }
`;

export const Lack = styled.span`
  font-size: 16px !important;
  font-weight: bold;
`;

export const QtdPiece = styled.span`
  display: block;
  font-size: 14px !important;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: var(--dark);
`;

export const CardBody = styled.div`
  overflow: hidden;
`;

export const WrapperCardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
`;

export const CardImage = styled.img`
  display: block;
  width: auto;
  height: 220px;
`;

export const WithoutAttachment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 24px;
  height: 220px;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  span {
    display: block;
    font-size: 12px;
    white-space: nowrap;
  }
`;

export const ProgressBar = styled.div`
  border-radius: 8px;
  height: 13px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Bar = styled.div`
  height: 13px;
  background-color: var(--success);
`;

export const PercentageLabel = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-25%);
  top: 0;
  font-size: 10px !important;
  font-weight: bold;
`;
