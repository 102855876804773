import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';

export type GetCardCommentResponse = {
  id: number;
  comment: string;
  priority: number;
  user: {
    id: number;
    name: string;
  };
};

export class GetCardComment {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(cardId: number): Promise<GetCardCommentResponse[]> {
    return await this.http.get(`/kanban/card/comments/${cardId}`);
  }
}
