import { type TFunction } from 'i18next';
import type ClothingPresetType from '../../../../../types/ClothingPresetType';
import { type ComboBoxDataType } from '../../../../../types/ComboBoxDataType';
import { type ProjectType } from '../../../../../types/ProjectType';
import { emptySublistData } from '../../ViewProject/services/orderFormService';

export const validateProject = (project: ProjectType, Translate: TFunction): boolean => {
  if (project.name.trim() === '') throw new Error(Translate('error.project-missing-name'));
  if (project.preset_id === 0) throw new Error(Translate('error.project-missing-preset'));
  return true;
};

export const generatePresetOptions = (presets: ClothingPresetType[], Translate: TFunction): ComboBoxDataType[] => {
  const options = presets.map<ComboBoxDataType>(preset => ({ label: preset.name, value: preset.id }));
  const defaultOption: ComboBoxDataType = { label: Translate('labels.select'), value: 0 };

  return [defaultOption, ...options];
};

export const generateEmptyProjectData = (firstSublistTitle?: string): ProjectType => ({
  name: '',
  preset_id: 0,
  sublists: firstSublistTitle ? emptySublistData(firstSublistTitle) : [],
  payments: [],
  qrcode_content: '',
  delivery_date: null,
  client_name: null,
  client_informations: null,
  client_whatsapp: null,
  token: null,
  final_client_hide_molds_prices: false,
  final_client_readonly: false,
  final_client_link_offline: false,
  created_at: null,
  updated_at: null,
  approved_at: null,
  approved_by: '',
  workspace: '',
  author: { name: '' },
  pendings: '',
  shipping: null,
  users_changes_history: []
});
