import i18next from 'i18next';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { type Locale } from 'date-fns';
import { ptBR, enUS, es } from 'date-fns/locale';
import { type SupportedLanguages } from '../types/SupportedLanguages';

type TranslationContextType = {
  Translate: TFunction;
  selectedLanguage: SupportedLanguages;
  dateFnsLocale: Locale;
  changeLanguage: (language: SupportedLanguages) => void;
};

type TranslationProviderProps = {
  children: JSX.Element;
};

const TranslationContext = createContext<TranslationContextType>({} as TranslationContextType);

export const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const { t: Translate, i18n } = useTranslation();

  const currentLanguageCodeISO = useMemo(() => i18n.language.substring(0, 2), [i18n.language]);

  const parseLanguageCodeToDateFnsLocale = useCallback((language: SupportedLanguages): Locale => {
    if (language === 'pt') return ptBR;
    if (language === 'en') return enUS;
    if (language === 'es') return es;

    return enUS;
  }, []);

  const changeLanguage = useCallback(
    (language: SupportedLanguages) => {
      i18next.changeLanguage(language);
      const updatedLocale = parseLanguageCodeToDateFnsLocale(language as SupportedLanguages);
      setDateFnsLocale(updatedLocale);
    },
    [parseLanguageCodeToDateFnsLocale]
  );

  const [dateFnsLocale, setDateFnsLocale] = useState<Locale>(() => {
    return parseLanguageCodeToDateFnsLocale(currentLanguageCodeISO as SupportedLanguages);
  });

  const selectedLanguage = useMemo(() => {
    return currentLanguageCodeISO as SupportedLanguages;
  }, [currentLanguageCodeISO]);

  useEffect(() => {
    localStorage.setItem('i18nextLng', selectedLanguage);
  }, [selectedLanguage]);

  const contextValues = useMemo(
    () => ({ Translate, selectedLanguage, changeLanguage, dateFnsLocale }),
    [Translate, selectedLanguage, changeLanguage, dateFnsLocale]
  );

  return <TranslationContext.Provider value={contextValues}>{children}</TranslationContext.Provider>;
};

export const useAppTranslation = (): TranslationContextType => {
  const context = useContext(TranslationContext);

  if (!context) {
    throw new Error('useAppTranslation must be used within a TranslationProvider');
  }

  return context;
};
