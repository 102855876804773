import _ from 'lodash';
import { useMemo } from 'react';
import { AddComment } from './fragments/AddComment';
import { useComments } from './hooks/useComments';
import {
  Author,
  Avatar,
  Comment,
  Container,
  Content,
  QtdComments,
  WithoutComments,
  WrapperAvatar,
  WrapperButtons,
  WrapperList
} from './style';
import { RemoveButton } from '../CreateTasks/style';
import { useUser } from '../../../../contexts/UserContext';
import { type GetCardCommentResponse } from './api/GetCardComment';
import { COLORS } from '../../../../helpers/colors';

export function Comments() {
  const { user, isCompanyAccount } = useUser();
  const { comments, deleteCommentMutation } = useComments();

  const allComments = useMemo(() => {
    if (comments.isLoading) {
      return (
        <WithoutComments>
          <p>Carregando comentários...</p>
        </WithoutComments>
      );
    }

    if (_.isEmpty(comments?.data)) {
      return (
        <WithoutComments>
          <p>Nenhum comentário</p>
        </WithoutComments>
      );
    }

    return comments?.data?.map(comment => {
      const userAvatar = comment.user.name
        .split(' ')
        .map(name => name[0])
        .join('');

      const showButtons = ({ comment }: { comment: GetCardCommentResponse }) => {
        if (deleteCommentMutation.isLoading) {
          return (
            <WrapperButtons>
              <i className='fa fa-spinner fa-spin' />
            </WrapperButtons>
          );
        }

        return (
          <WrapperButtons>
            <RemoveButton
              type='button'
              onClick={() => {
                deleteCommentMutation.mutate(comment.id);
              }}
            >
              <i className='fa fa-trash' />
            </RemoveButton>
          </WrapperButtons>
        );
      };

      return (
        <Content key={comment.id} backgroundColor={COLORS[comment.priority]}>
          <WrapperAvatar>
            <Avatar>{userAvatar}</Avatar>
          </WrapperAvatar>

          <Comment>
            <Author>{comment.user.name}</Author>
            <p>{comment.comment}</p>
          </Comment>

          {_.isEqual(user?.id, comment.user.id) || isCompanyAccount ? showButtons({ comment }) : null}
        </Content>
      );
    });
  }, [comments?.data, comments.isLoading, deleteCommentMutation, isCompanyAccount, user?.id]);

  return (
    <Container>
      <h3>
        comentários <QtdComments>({comments?.data?.length ?? 0})</QtdComments>
      </h3>

      <WrapperList>{allComments}</WrapperList>

      <AddComment />
    </Container>
  );
}
