import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { type WEEK_DAYS } from './constants';
import { Hours } from './Hours';
import { useUser } from '../../../../../contexts/UserContext';
import { getOfficeHoursService, createOfficeHoursService } from './services';
import { validationOfficeHoursSchema } from '../validations';

import { DayContent, DaysContainer, Label, LoadingDays, LoadingDaysLabel } from './style';

export function OfficeHours() {
  const query = useQueryClient();
  const { user } = useUser();
  const queryKey = `officeHours-${user?.id}`;
  const { data: officeHours, isFetching } = useQuery(queryKey, async () => await getOfficeHoursService.execute(), {
    enabled: !!user,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchIntervalInBackground: false,
    refetchInterval: false
  });

  const createOfficeHoursMutation = useMutation(
    async data => {
      await createOfficeHoursService.execute(data as any);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(queryKey);
      }
    }
  );

  const methods = useForm({
    defaultValues: {
      officeHours
    },
    resolver: yupResolver(validationOfficeHoursSchema)
  });
  const weekDays = (methods.watch('officeHours') ?? []) as typeof WEEK_DAYS;

  const loading = isFetching || createOfficeHoursMutation.isLoading;

  useEffect(() => {
    methods.reset({
      officeHours
    });
  }, [methods, officeHours]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(data => {
          createOfficeHoursMutation.mutate(data as any);
        })}
      >
        <DaysContainer>
          {loading ? (
            <LoadingDays>
              <LoadingDaysLabel>Carregando horários...</LoadingDaysLabel>
            </LoadingDays>
          ) : null}

          {weekDays.map(day => (
            <DayContent key={day.id}>
              <Label>{day.title}</Label>

              <div className='d-flex flex-column' style={{ gap: 10 }}>
                {day.hours?.map((hour, index, hoursArray) => (
                  <Hours
                    key={hour.id}
                    hour={hour}
                    remove={hoursArray.length === index + 1 && hoursArray.length > 1}
                    add={hoursArray.length - 1 === index}
                    dayId={day.id}
                  />
                ))}
              </div>
            </DayContent>
          ))}
        </DaysContainer>

        <button className='btn btn-primary' disabled={loading}>
          {createOfficeHoursMutation.isLoading ? 'Atualizando...' : 'Atualizar horários'}
        </button>
      </form>
    </FormProvider>
  );
}
