import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useKanbanWorkspaces } from '../../../../contexts/KanbanWorkspacesContext';
import { useKanbanView } from '../../../../pages/Authenticated/KanbanView/hooks/useKanbanView';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaCardModalForm } from '../../validations';
import { useCallback, useEffect, useMemo } from 'react';
import { CreateTasks, type task } from '../CreateTasks';
import {
  CancelButton,
  Container,
  Content,
  HeaderModal,
  Input,
  ModalBody,
  ModalBodyContent,
  ModalButton,
  ModalFooter,
  ModalTitle,
  Select,
  SubmitButton,
  SubTitle,
  TextContent,
  WrapperContent,
  WrapperLabels,
  WrapperPermissions
} from '../../style';
import { FilePreview } from '../FilePreview';
import { CenterCardModal } from '../CenterCardModal';

type onSubmitProps = {
  title: string;
  quantity_piece?: number;
  description?: string;
  taskListGroupTitle?: string;
  taskList?: task[];
  attachments?: File[];
  priority: number;
};

type CreateCardProps = { isExpanded: boolean; handleExpand: () => void };

export function CreateCard({ isExpanded, handleExpand }: CreateCardProps) {
  const { showCreateCard, handleShowCreateModal } = useKanbanWorkspaces();
  const { createCardMutation } = useKanbanView();

  const methods = useForm({
    resolver: yupResolver(schemaCardModalForm)
  });
  const watch = useWatch({ control: methods.control });

  const onSubmit = useCallback(
    (data: onSubmitProps) => {
      createCardMutation.mutate({
        title: data.title,
        column_id: showCreateCard?.id as number,
        position_index: 0,
        description: data?.description,
        taskList: data?.taskList,
        attachments: data.attachments,
        quantity_piece: data.quantity_piece as number,
        priority: data.priority
      });
    },
    [createCardMutation, showCreateCard?.id]
  );

  const enableSubmit = useMemo(() => {
    try {
      schemaCardModalForm.validateSync(watch);
      return false;
    } catch {
      return true;
    }
  }, [watch]);

  const handleAttachFiles = useCallback(
    (attachments: File[]) => {
      methods.setValue('attachments', attachments);
    },
    [methods]
  );

  useEffect(() => {
    methods.reset({
      title: '',
      description: '',
      taskList: [],
      attachments: [],
      quantity_piece: 0,
      priority: 0
    });
  }, [methods]);

  return (
    <FormProvider {...methods}>
      <Container
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={() => {
          methods.reset();
          handleShowCreateModal();
        }}
      >
        <CenterCardModal isExpanded={isExpanded}>
          <HeaderModal>
            <ModalTitle
              placeholder='# título da tarefa'
              {...methods.register('title')}
              error={methods.formState.errors?.title?.message}
            />

            <ModalButton
              type='button'
              onClick={() => {
                handleExpand();
              }}
            >
              <i className={`fa ${isExpanded ? 'fa-expand' : 'fa-expand-alt'}`}></i>
            </ModalButton>

            <ModalButton
              type='button'
              disabled={createCardMutation.isLoading}
              onClick={() => {
                handleShowCreateModal();
              }}
            >
              &times;
            </ModalButton>
          </HeaderModal>

          <Content>
            <ModalBody>
              <ModalBodyContent>
                <WrapperContent>
                  <FilePreview onHandleSetFiles={handleAttachFiles} />

                  <TextContent placeholder='# descrição da tarefa' {...methods.register('description')} />
                </WrapperContent>

                <WrapperPermissions>
                  <WrapperLabels>
                    <SubTitle>Prioridade:</SubTitle>
                    <Select {...methods.register('priority')}>
                      <option value={0}>Baixa</option>
                      <option value={1}>Média</option>
                      <option value={2}>Alta</option>
                    </Select>
                  </WrapperLabels>

                  <WrapperLabels>
                    <SubTitle>Quantidade de peças:</SubTitle>
                    <Input placeholder='Quantidade de peças' type='number' {...methods.register('quantity_piece')} />
                  </WrapperLabels>

                  <WrapperLabels>
                    <SubTitle>Tarefas:</SubTitle>
                    <CreateTasks />
                  </WrapperLabels>
                </WrapperPermissions>
              </ModalBodyContent>
            </ModalBody>
          </Content>

          <ModalFooter>
            <CancelButton type='reset' disabled={createCardMutation.isLoading}>
              Cancelar
            </CancelButton>

            <SubmitButton disabled={enableSubmit}>
              {createCardMutation.isLoading ? <i className='fa fa-spinner fa-spin' /> : 'Criar Cartão'}
            </SubmitButton>
          </ModalFooter>
        </CenterCardModal>
      </Container>
    </FormProvider>
  );
}
