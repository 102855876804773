import { type KanbanColumn } from '../../../../types/Kanban/KanbanColumn';
import { type KanbanCard } from '../../../../types/Kanban/KanbanCard';
import { type CreateCardParams } from '../../../../types/Kanban/CreateCardParams';
import { type DraggableLocation } from '@hello-pangea/dnd';
import { type KanbanCardMovedResponse } from '../../../../types/Kanban/KanbanCardMovedResponse';
import { type KanbanCardIdentifier } from '../../../../types/Kanban/KanbanCardIdentifier';
import { type CreateCardResponse } from '../api/createCard';
import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';

export const addCardToColumn = (createdCard: CreateCardResponse, workspace: KanbanWorkspace): KanbanWorkspace => {
  const { id, column_id, position_index, title } = createdCard;

  const newCard: KanbanCard = {
    id,
    title,
    column_id,
    position_index,
    description: '',
    check_groups: [],
    images: [],
    priority: 0
  };

  const updatedWorkspace: KanbanWorkspace = { ...workspace };

  updatedWorkspace.columns.map(column => {
    if (column.id === column_id) {
      column.cards = [...column.cards, newCard];
    }

    return column;
  });

  return updatedWorkspace;
};

export const generateEmptyCardData = (): CreateCardParams => ({
  title: '',
  column_id: 0,
  position_index: 0,
  taskList: [],
  priority: 0
});

export type MoveCardToColumnParams = {
  source: DraggableLocation;
  destination: DraggableLocation | null;
  kanbanColumns: KanbanColumn[];
};

type MoveCardInSameListParams = Pick<MoveCardToColumnParams, 'kanbanColumns'> & {
  sourceColumn: KanbanColumn;
  movedItem: KanbanCard;
  destination: DraggableLocation;
};

const moveCardInSameList = ({
  sourceColumn,
  destination,
  movedItem,
  kanbanColumns
}: MoveCardInSameListParams): KanbanColumn[] => {
  const sourceListCardRemoved = sourceColumn.cards.filter(card => card.id !== movedItem.id);

  const updatedCards = [
    ...sourceListCardRemoved.slice(0, destination.index),
    movedItem,
    ...sourceListCardRemoved.slice(destination.index)
  ];

  const upcatedCardsIndexes = updatedCards.map((card, index) => ({ ...card, position_index: index }));

  const updatedColumn: KanbanColumn = { ...sourceColumn, cards: upcatedCardsIndexes };

  const updatedKanbanColumns = kanbanColumns.map(column => {
    if (column.id === sourceColumn.id) return updatedColumn;
    return column;
  });

  return updatedKanbanColumns;
};

type MoveCardToDifferentColumnParams = Pick<
MoveCardInSameListParams,
'sourceColumn' | 'movedItem' | 'destination' | 'kanbanColumns'
> & {
  destinationColumn: KanbanColumn;
};

const moveCardToDifferentColumn = ({
  sourceColumn,
  destinationColumn,
  movedItem,
  destination,
  kanbanColumns
}: MoveCardToDifferentColumnParams): KanbanColumn[] => {
  const filteredSourceColumnCards = sourceColumn.cards.filter(card => card.id !== movedItem.id);

  const updatedDestinationColumnCards = [
    ...destinationColumn.cards.slice(0, destination.index),
    { ...movedItem, column_id: destinationColumn.id },
    ...destinationColumn.cards.slice(destination.index)
  ];

  const updatedSourceColumn: KanbanColumn = { ...sourceColumn, cards: filteredSourceColumnCards };
  const updatedDestinationColumn: KanbanColumn = { ...destinationColumn, cards: updatedDestinationColumnCards };

  const updatedKanbanColumns = kanbanColumns.map(column => {
    if (column.id === updatedSourceColumn.id) return updatedSourceColumn;
    if (column.id === updatedDestinationColumn.id) return updatedDestinationColumn;
    return column;
  });

  const updatedKanbanColumnsWithUpdatedCardIndexes = updatedKanbanColumns.map(column => {
    if (column.id === sourceColumn.id || column.id === destinationColumn.id) {
      column.cards = column.cards.map((card, index) => ({
        ...card,
        position_index: index
      }));
    }

    return column;
  });

  return updatedKanbanColumnsWithUpdatedCardIndexes;
};

export const moveCardToColumn = ({
  source,
  destination,
  kanbanColumns
}: MoveCardToColumnParams): KanbanCardMovedResponse | undefined => {
  if (!destination) return;

  const sourceColumn = kanbanColumns.find(column => column.id.toString() === source.droppableId);

  const destinationColumn = kanbanColumns.find(column => column.id.toString() === destination.droppableId);

  if (!sourceColumn) throw new Error('A coluna de origem do cartão, não foi encontrada.');

  if (!destinationColumn) throw new Error('A coluna de destino do cartão, não foi encontrada.');

  const movedItem = sourceColumn.cards.find((_, index) => index === source.index);

  if (!movedItem) throw new Error('O item movido não foi encontrado.');

  const updatedColumnCards =
    source.droppableId === destination.droppableId
      ? moveCardInSameList({ sourceColumn, destination, movedItem, kanbanColumns })
      : moveCardToDifferentColumn({ sourceColumn, destinationColumn, movedItem, destination, kanbanColumns });

  const affectedCards = collectBasicCardInformationsFromSelectedColunsById(updatedColumnCards, [
    sourceColumn.id,
    destinationColumn.id
  ]);

  const moveResult: KanbanCardMovedResponse = { updatedColumnCards, affectedCards };

  return moveResult;
};

export const editCard = (kanbanLists: KanbanColumn[], editedCard: KanbanCard): KanbanColumn[] => {
  return kanbanLists.map(list => {
    list.cards = list.cards.map(card => {
      if (card.id === editedCard.id) return editedCard;
      return card;
    });
    return list;
  });
};

export const collectBasicCardInformationsFromSelectedColunsById = (
  columns: KanbanColumn[],
  selectedColumnsId: number[]
): KanbanCardIdentifier[] => {
  const selectedCards: KanbanCard[] = [];

  columns.forEach(column => {
    if (selectedColumnsId.includes(column.id)) {
      selectedCards.push(...column.cards);
    }
  });

  const filteredCardFields: KanbanCardIdentifier[] = selectedCards.map(card => ({
    id: card.id,
    column_id: card.column_id,
    position_index: card.position_index
  }));

  return filteredCardFields;
};
