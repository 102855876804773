import React, { useCallback, useState } from 'react';
import BasicCard from '../../../../../../components/Cards/BasicCard';
import Tabs from '../../../../../../components/Tabs';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { generateSublistOptions } from '../../services/orderFormService';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import RenderFastCreationModels from './components/RenderFastCreationModels';
import { type ClothingProductionData } from '../../../../../../types/OrderTypes';
import { generateDefaultInputValues } from './components/RenderFastCreationModels/services/productionDataService';
import { generateOrdersFromClothingProductionData } from './components/RenderFastCreationModels/services/fastCreationOrderService';
import { addOrders } from '../../../services/orderService';
import { toast } from 'react-hot-toast-promise';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { addOrdersToSublist } from '../../../api/addOrders';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';
import { getServerErrorMessageFromResponse, isDev } from '../../../../../../utils/helper';
import ReactGA from 'react-ga4';

type FastCreationEditorType = {
  projectId: number;
  sublists: SublistType[];
  importedModels: ClothingModelType[];
  onUpdateSublists: (updatedSublists: SublistType[]) => void;
};

export default function FastCreationEditor({ projectId, sublists, importedModels, onUpdateSublists }: FastCreationEditorType) {
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const [targetSublistIndex, setTargetSublistIndex] = useState<number>(0);
  const [isGeneratingMultipleOrders, setIsGeneratingMultipleOrders] = useState<boolean>(false);

  const [modelsProductionDataForMale, setModelsProductionDataForMale] = useState<ClothingProductionData[][]>(
    generateDefaultInputValues({ importedModels, gender: 'male' })
  );

  const [modelsProductionDataForFemale, setModelsProductionDataForFemale] = useState<ClothingProductionData[][]>(
    generateDefaultInputValues({ importedModels, gender: 'female' })
  );

  const [modelsProductionDataForChildish, setModelsProductionDataForChildish] = useState<ClothingProductionData[][]>(
    generateDefaultInputValues({ importedModels, gender: 'childish' })
  );

  const resetDefaultInputValuesForAllGenders = useCallback(() => {
    setModelsProductionDataForMale(generateDefaultInputValues({ importedModels, gender: 'male' }));
    setModelsProductionDataForFemale(generateDefaultInputValues({ importedModels, gender: 'female' }));
    setModelsProductionDataForChildish(generateDefaultInputValues({ importedModels, gender: 'childish' }));
  }, [importedModels]);

  const handleGenerateOrders = useCallback(() => {
    const sublist = sublists[targetSublistIndex];

    if (!sublist) {
      toast.error(Translate('error.sublist-not-found'));
      return;
    }

    if (!isDev()) {
      ReactGA.event({
        category: 'create',
        action: 'create-list-multiple-sizes'
      });
    }

    const generatedOrders = generateOrdersFromClothingProductionData({
      importedModels,
      productionData: [
        { gender: 'male', modelsProductionData: modelsProductionDataForMale },
        { gender: 'female', modelsProductionData: modelsProductionDataForFemale },
        { gender: 'childish', modelsProductionData: modelsProductionDataForChildish }
      ]
    });

    if (generatedOrders.length === 0) {
      toast.error(Translate('error.no-data-to-process'));
      return;
    }

    const createMultipleOrdersPromise = addOrdersToSublist({
      httpConnection,
      projectId,
      orders: generatedOrders,
      sublistId: sublist.id!
    });

    setIsGeneratingMultipleOrders(true);

    toast.promise(createMultipleOrdersPromise, {
      loading: Translate('progress.loading'),
      success: (orders) => {
        const updatedSublists = addOrders(sublists, sublist.id!, orders);

        onUpdateSublists(updatedSublists);
        resetDefaultInputValuesForAllGenders();

        return Translate('toast.multiple-sizes-generated');
      },
      finally: () => {
        setIsGeneratingMultipleOrders(false);
      },
      error: getServerErrorMessageFromResponse
    });
  }, [sublists, targetSublistIndex, importedModels, modelsProductionDataForMale, modelsProductionDataForFemale, modelsProductionDataForChildish, httpConnection, projectId, Translate, onUpdateSublists, resetDefaultInputValuesForAllGenders]);

  return (
    <BasicCard>
      <div className='row'>
        <div className='col'>
          <Tabs
            data={[
              {
                id: 'male',
                label: Translate('labels.male'),
                active: true,
                content: (
                  <RenderFastCreationModels
                    disabled={isGeneratingMultipleOrders}
                    importedModels={importedModels}
                    gender='male'
                    modelsProductionData={modelsProductionDataForMale}
                    updateModelsProductionData={setModelsProductionDataForMale}
                  />
                )
              },
              {
                id: 'female',
                label: Translate('labels.female'),
                content: (
                  <RenderFastCreationModels
                    importedModels={importedModels}
                    gender='female'
                    modelsProductionData={modelsProductionDataForFemale}
                    updateModelsProductionData={setModelsProductionDataForFemale}
                  />
                )
              },
              {
                id: 'childish',
                label: Translate('labels.childish'),
                content: (
                  <RenderFastCreationModels
                    importedModels={importedModels}
                    gender='childish'
                    modelsProductionData={modelsProductionDataForChildish}
                    updateModelsProductionData={setModelsProductionDataForChildish}
                  />
                )
              }
            ]}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-3'>
          <ComboBox
            id='fast-creation-sublist-picker'
            disabled={isGeneratingMultipleOrders}
            data={generateSublistOptions(sublists)}
            header={Translate('labels.sublist')}
            value={targetSublistIndex.toString()}
            handleChange={({ target }) => {
              setTargetSublistIndex(parseInt(target.value));
            }}
          />
        </div>

        <div className='col-auto'>
          <SplitButton
            simulateLabelMarginTop
            disabled={isGeneratingMultipleOrders}
            color='success'
            icon={IconsCatalog.play}
            title={Translate('actions.generate-list')}
            handleClick={handleGenerateOrders}
          />
        </div>
      </div>
    </BasicCard>
  );
}
