import { type AxiosResponse, type AxiosInstance } from 'axios';
import { type FinishingOptionItem } from '../../../../../../../../types/ClothingFinishingOptions/FinishingOptionItem';

type AddFinishingOptionItemParams = Pick<FinishingOptionItem, 'item' | 'price'> & {
  finishingOptionId: number;
  httpConnection: AxiosInstance;
}

export default async function ({ httpConnection, item, price, finishingOptionId }: AddFinishingOptionItemParams): Promise<FinishingOptionItem> {
  return await new Promise<FinishingOptionItem>((resolve, reject) => {
    httpConnection.post<FinishingOptionItem>('list/finishing-option-items', { item, price, finishing_option_id: finishingOptionId })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        const error = err as AxiosResponse;
        reject(error);
      });
  });
}
