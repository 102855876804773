import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router';

export default function Redirector() {
  const { signed } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!signed) {
      navigate('/login');
    } else {
      navigate('/dashboard');
    }
  }, [navigate, signed]);

  return <p>Redirecionando...</p>;
}
