import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import type { ProjectBasicType } from '../../../../../../types/ProjectBasicType';
import { type ProjectStatusType } from '../../../../../../types/ProjectStatusType';
import changeProjectStatus from './api/changeProjectStatus';
import { toast } from 'react-hot-toast-promise';
import { updateCachedProject } from '../../services/projectService';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';
import { useProjects } from '../../../../../../contexts/ProjectsContext';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';
import { useProjectsStatuses } from '../../../../../../contexts/ProjectStatusContext';

type ProjectStatusChangerModalType = Omit<ModalBaseType, 'visible' | 'children' | 'handleConfirm'> & {
  project: ProjectBasicType;
  onUpdateProjectStatus?: (projectStatus: ProjectStatusType | undefined) => void;
}

/**
 * Component to change the project status or remove it. The function also updates cached projects `ProjectBasicType` in projects list
 * that is shown in MyProjects page.
 *
 * @onUpdateProjectStatus Returns the updated `ProjectStatusType` to update the current opened project when used in this screnario.
 */
export default function ProjectStatusChangerModal({
  style,
  title,
  project,
  handleClose,
  onUpdateProjectStatus
}: ProjectStatusChangerModalType) {
  const { projects, setProjects } = useProjects();
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();
  const { projectStatusComboboxOptions } = useProjectsStatuses();

  const [selectedProjectStatusOption, setSelectedProjectStatusOption] = useState<string>(() => project?.status?.id?.toString() ?? '');
  const [isUpdatingProjectStatus, setIsUpdatingProjectStatus] = useState<boolean>(false);

  const handleChangeProjectStatus = useCallback(() => {
    const parsedId = parseInt(selectedProjectStatusOption);
    const statusId = !isNaN(parsedId) ? parsedId : null;

    const changeProjectStatusPromise = changeProjectStatus({
      httpConnection,
      projectId: project.id!,
      statusId
    });

    setIsUpdatingProjectStatus(true);

    toast.promise(changeProjectStatusPromise, {
      loading: Translate('progress.loading'),
      success: (updatedStatus) => {
        const updatedProjectStatus: ProjectStatusType | undefined = typeof updatedStatus === 'object' ? updatedStatus : undefined;
        const projectWithUpdatedStatus: ProjectBasicType = { ...project, status: updatedProjectStatus };
        const updatedCachedProjects = updateCachedProject(projectWithUpdatedStatus, projects);

        setProjects(updatedCachedProjects);
        if (onUpdateProjectStatus) onUpdateProjectStatus(updatedProjectStatus);
        handleClose();
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsUpdatingProjectStatus(false);
      }
    });
  }, [selectedProjectStatusOption, httpConnection, project, Translate, projects, setProjects, onUpdateProjectStatus, handleClose]);

  if (!project) return <></>;

  return (
    <ModalBase
      visible={true}
      style={style}
      title={title}
      handleConfirm={handleChangeProjectStatus}
      handleClose={handleClose}
      disableButtons={isUpdatingProjectStatus}
    >
      <ComboBox
        id={'combobox-new-project-status'}
        value={selectedProjectStatusOption}
        header={`${Translate('labels.new-status-to-project')}: ${project.name}.`}
        data={projectStatusComboboxOptions}
        handleChange={({ target }) => {
          setSelectedProjectStatusOption(target.value);
        }}
      />
    </ModalBase>
  );
}
