import { type SublistType } from '../../../../../types/SublistTypes';

export const countPendingReplacementsInProjectBySublists = (sublists: SublistType[]): number => {
  let pendingReplacements = 0;

  sublists.forEach(sublist => {
    sublist.orders.forEach(order => {
      order.clothing_replacements.flatMap(replacements => replacements).forEach(replacement => {
        if (!replacement.completed) pendingReplacements++;
      });
    });
  });

  return pendingReplacements;
};
