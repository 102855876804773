import { ButtonDelete, ButtonWrapper, Container } from './styles';
import { IconsCatalog } from '../IconsCatalog';

type CatalogSquareBlockType = {
  imagePath: string;
  handleClick?: () => void;
  handleDelete?: () => void;
  deleteButtonDisabled?: boolean;
};

export default function CatalogSquareBlock({
  imagePath,
  handleClick,
  handleDelete,
  deleteButtonDisabled = false
}: CatalogSquareBlockType) {
  return handleClick === undefined ? (
    <Container>
      <ButtonDelete onClick={handleDelete} deleteButtonDisabled={deleteButtonDisabled}>
        <span className={`${IconsCatalog.times} fa-sm`} />
      </ButtonDelete>
      {imagePath ? <img src={imagePath} width={45} height={45} /> : <span className={`${IconsCatalog.image} fa-sm`} />}
    </Container>
  ) : (
    <ButtonWrapper onClick={handleClick}>
      <img src={imagePath} width={45} height={45} />
    </ButtonWrapper>
  );
}
