import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';
import { type KanbanCheckItem } from '../../../../../types/Kanban/KanbanCheckbox/KanbanCheckItem';

type CheckGroups = {
  card_id: number;
  title: string;
  check_items: KanbanCheckItem[];
  id: number;
  default: boolean;
};

export class GetCheckGroups {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(): Promise<CheckGroups[]> {
    return await this.http.get('/kanban/checkgroups');
  }
}
