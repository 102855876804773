import styled from 'styled-components';

export const LayoutSection = styled.section`
  page-break-inside: avoid;
`;

type LayoutContainerProps = {
  scaleFactor: number;
}

export const LayoutContainer = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'scaleFactor' }) <LayoutContainerProps>`
  height: ${props => `calc(645px * ${props.scaleFactor})`};
  text-align: center;
`;

export const LayoutImage = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
`;
