import styled, { css } from 'styled-components';

export const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const HeaderModal = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding: 10px;
  flex: 1;
`;

type ModalTitleProps = {
  error?: string;
};

export const ModalTitle = styled.input<ModalTitleProps>`
  background-color: var(--light-v2);
  border: 1px solid var(--light);
  color: var(--black);
  outline: none;
  border-radius: 4px;
  padding: 10px 20px;
  flex: 1;

  &:focus {
    border-color: rgba(120, 120, 120);
  }

  ${({ error }) =>
    error &&
    css`
      border-color: var(--danger);
    `}
`;

type InputProps = {
  width?: string;
};

export const Input = styled(ModalTitle)<InputProps>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  &:disabled {
    background-color: var(--dark);
    color: #333;
    cursor: not-allowed;
  }
`;

type SelectProps = {
  width?: number;
};

export const Select = styled.select<SelectProps>`
  background-color: var(--light-v2);
  border: 1px solid var(--light);
  color: var(--black);
  outline: none;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  flex: 1;

  &:focus {
    border-color: rgba(120, 120, 120);
  }

  ${({ width }) =>
    width &&
    css`
      max-width: ${width + 'px'};
    `}
`;

export const ModalButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--dark);
  font-size: 26px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: var(--dark);
    font-size: 16px;
    pointer-events: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ModalBody = styled.div`
  flex: 1;
`;

export const ModalBodyContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 340px;
  flex: 1;
  gap: 10px;
`;

export const BodyContent = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  position: relative;
`;

export const TextContent = styled.textarea`
  background-color: var(--light-v2);
  border: 1px solid var(--light);
  color: var(--black);
  outline: none;
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
  min-height: 340px;
  max-height: 600px;

  &:focus {
    border-color: rgba(120, 120, 120);
    max-height: inherit;
  }
`;

export const WrapperPermissions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
  max-width: 300px;
`;

type FilesProps = {
  column?: number;
};

export const Files = styled.div<FilesProps>`
  border: 1px dashed var(--light-v2);
  border-radius: 4px;
  gap: 10px;
  min-height: 120px;
  padding: 10px;
  width: 100%;
  position: relative;

  display: grid;
  ${({ column = 1 }) => `grid-template-columns: repeat(${column}, minmax(120px, 1fr));`}
  grid-template-rows: repeat(1, minmax(120px, 1fr));
`;

export const WrapperAttachment = styled.div`
  height: 195px;
  position: relative;
`;

export const RemoveAttachment = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--danger);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &:hover {
    opacity: 0.8;
  }

  span {
    font-size: 12px;
  }
`;

export const ImageFile = styled.img`
  width: 100%;
  height: 195px;
  object-fit: cover;
  border-radius: 16px;
`;

export const FilesContent = styled.label`
  background-color: var(--light-v2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 195px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0;

  &:hover {
    background-color: var(--light);
  }

  input {
    display: none;
  }

  span {
    text-align: center;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CancelButton = styled.button`
  background-color: var(--danger);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: bold;

  &:hover {
    background-color: var(--teal);
  }
`;

export const SubmitButton = styled.button`
  background-color: var(--success);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: bold;

  &:hover {
    background-color: var(--teal);
  }

  &:disabled {
    background-color: #f1f1f1;
    color: #333;
    cursor: not-allowed;
  }
`;

export const WrapperLabels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px dashed rgba(220, 220, 220);
  padding: 10px;
  border-radius: 4px;
`;

export const SubTitle = styled.h3`
  font-size: 14px;
  color: var(--black);
  font-weight: bold;
  margin: 0;
`;

export const ClothingList = styled.ul`
  padding: 0 15px;
  margin: 0;
`;

export const ClothingLink = styled.li`
  font-size: 14px;
  font-weight: bold;
  color: var(--primary);
  border-radius: 4px;
  cursor: pointer;
`;

export const WrapperButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const ModalContentTabs = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const TabsButton = styled.button``;

export const OperationNumber = styled.span`
  font-size: 14px;
  margin-right: 1rem;
  white-space: nowrap;
`;
