import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 20px;
`;

type gridColumns = {
  row?: number;
};

export const GridColumns = styled.div<gridColumns>`
  display: grid;
  grid-template-columns: ${({ row }) => (row ? `repeat(${row}, 1fr)` : 'repeat(5, 1fr)')};
  gap: 1rem;

  @media (max-width: 996px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 468px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--background-card);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
`;

export const ContentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
`;

export const Value = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  height: 80px;
  white-space: nowrap;
`;
