import { type ProjectStatusType } from '../../../../../../types/ProjectStatusType';

type UpdateCachedStatusListParams = {
  projectStatus: ProjectStatusType;
  projectStatusList: ProjectStatusType[];
}

export const updateCachedStatusList = ({ projectStatus, projectStatusList }: UpdateCachedStatusListParams): ProjectStatusType[] => {
  return projectStatusList.map(currentProjectStatus => {
    if (currentProjectStatus.id === projectStatus.id) return projectStatus;
    return currentProjectStatus;
  });
};
