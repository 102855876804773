import { type WEEK_DAYS } from '../constants';
import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

type CreateOfficeHoursServiceParams = {
  officeHours: typeof WEEK_DAYS;
};

export class CreateOfficeHours {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: CreateOfficeHoursServiceParams): Promise<void> {
    await this.http.post('/office-hours', data);
  }
}
