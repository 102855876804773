import React, { useCallback } from 'react';
import { useSettings } from '../../../contexts/SettingsContext';
import SplitButton from '../SplitButton';
import { IconsCatalog } from '../../IconsCatalog';
import { type BasicButtonType } from '../types/BasicButtonType';
import { toast } from 'react-hot-toast-promise';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { useUser } from '../../../contexts/UserContext';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

type SavePreferencesButtonType = Pick<BasicButtonType, 'simulateLabelMarginTop'>;

export default function SavePreferencesButton({ simulateLabelMarginTop }: SavePreferencesButtonType) {
  const { Translate } = useAppTranslation();
  const { savePreferences, isPreferencesSynched, isPreferencesSynching } = useSettings();
  const { isEmployeeAccount } = useUser();

  const handleSavePreferences = useCallback(() => {
    toast.promise(savePreferences(), {
      loading: Translate('progress.saving-wait'),
      success: () => {
        return Translate('toast.preferences-saved');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, savePreferences]);

  if (isEmployeeAccount) return <></>;

  return (
    <React.Fragment>
      {!isPreferencesSynched && (
        <SplitButton
          simulateLabelMarginTop={simulateLabelMarginTop}
          disabled={isPreferencesSynching}
          color='primary'
          size='sm'
          title={Translate('actions.save-preferences')}
          icon={IconsCatalog.check}
          handleClick={handleSavePreferences}
        />
      )}
    </React.Fragment>
  );
}
