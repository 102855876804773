import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

type Holidays = {
  id: string;
  date: string;
  title: string;
};

export class UpdateHolidays {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: Holidays[]): Promise<void> {
    await this.http.put('/holidays', data);
  }
}
