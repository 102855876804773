import React, { useCallback, useMemo, useRef, useState } from 'react';
import BadgeButton from '../../../../../../../../components/Buttons/BadgeButton';
import { type ProjectPaymentRegistryType } from '../../../../../../../../types/ProjectPaymentRegistry';
import { displayCommonDateWithTime } from '../../../../../../../../helpers/dateTime';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import FilePicker from '../../../../../../../../components/Forms/FilePicker';
import { acceptedFileExtensions, acceptedMimeTypes } from '../..';
import { toast } from 'react-hot-toast-promise';
import { updatePaymentRegistry } from '../../api/updatePaymentRegistry';
import { useHttpRequest } from '../../../../../../../../contexts/HttpRequestContext';
import { displayAsCurrency, getServerErrorMessageFromResponse } from '../../../../../../../../utils/helper';
import { calculateTotalAmmountPaymentsReceived } from '../../services/paymentCalculatorService';
import { type UserPreferences } from '../../../../../../../../types/UserPreferences';

type PaymentsReceiptsListType = {
  payments: ProjectPaymentRegistryType[];
  preferences: UserPreferences;
  onAttachPaymentReceipt: (updatedPayment: ProjectPaymentRegistryType) => void;
}

export default function PaymentsReceiptsList({ payments, preferences, onAttachPaymentReceipt }: PaymentsReceiptsListType) {
  const [paymentIdToAttachReceipt, setPaymentIdToAttachReceipt] = useState<number | null>(null);
  const filePickerRef = useRef<HTMLInputElement>(null);

  const { dateFnsLocale, Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const handleUploadPaymentReceipt = useCallback((file: File | null) => {
    if (!file || !paymentIdToAttachReceipt) return;

    const promise = updatePaymentRegistry({ paymentRegistryId: paymentIdToAttachReceipt, file }, httpConnection);

    toast.promise(promise, {
      loading: Translate('progress.sending-receipt'),
      success: (updatedPaymentRegistry) => {
        onAttachPaymentReceipt(updatedPaymentRegistry);
        return Translate('toast.receipt-sent');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setPaymentIdToAttachReceipt(null);
      }
    });
  }, [Translate, httpConnection, onAttachPaymentReceipt, paymentIdToAttachReceipt]);

  const openFilePicker = useCallback((paymentId: number) => {
    setPaymentIdToAttachReceipt(paymentId);
    filePickerRef.current?.click();
  }, []);

  const totalAmountReceived = useMemo(() => {
    return calculateTotalAmmountPaymentsReceived(payments);
  }, [payments]);

  return (
    <React.Fragment>
      <FilePicker
        id='attatch-payment-receipt-file'
        inputRef={filePickerRef}
        label=''
        filename={''}
        visible={false}
        acceptedFileExtensions={acceptedFileExtensions}
        acceptedMimeTypes={acceptedMimeTypes}
        onPick={handleUploadPaymentReceipt}
      />

      <div className="row">
        <div className="col">
          <h5 className='mt-3'>{Translate('labels.history')}</h5>
        </div>
        <div className="col text-right">
          <h5 className='mt-3'><strong>{Translate('labels.received')}: {displayAsCurrency(totalAmountReceived, preferences.currency)}</strong></h5>
        </div>
      </div>

      <table className='table table-bordered table-sm' width='100%'>
        <thead>
          <tr>
            <th>{Translate('labels.registered-by')}</th>
            <th className='text-center'>{Translate('labels.date')}</th>
            <th className='text-center'>{Translate('labels.amount-received')}</th>
            <th className='text-center'>{Translate('labels.receipt')}</th>
            <th>{Translate('labels.details')}</th>
          </tr>
        </thead>
        <tbody>
          {
            payments.map((payment, index) => (
              <tr key={index}>
                <td className='align-middle'>{payment.user.name}</td>
                <td className='text-center align-middle'>
                  {displayCommonDateWithTime(new Date(payment.created_at), dateFnsLocale)}
                </td>
                <td className='text-center align-middle'>{displayAsCurrency(payment.amount_received, preferences.currency)}</td>
                <td className='text-center align-middle'>
                  <BadgeButton
                    title={payment.payment_receipt ? Translate('actions.open') : Translate('actions.attach')}
                    color={payment.payment_receipt ? 'success' : 'primary'}
                    handleClick={() => {
                      if (payment.payment_receipt) {
                        open(payment.payment_receipt, '_blank');
                        return;
                      }

                      openFilePicker(payment.id);
                    }}
                  />
                </td>
                <td>{payment.description ?? '-'}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </React.Fragment>
  );
};
