import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uniqueId } from 'uuid';

import { validationSchema } from '../AddWorkspace/validationSchema';
import { FormWorkspace } from '../components/FormWorkspace';
import { useQuery } from 'react-query';
import { showWorkspace } from '../ShowWorkspace/services';

export function EditWorkspace() {
  const params = useParams<{ slug?: string }>();
  const { data: workspace } = useQuery(
    `workspace-params-${params.slug}-edit`,
    async () => {
      return await showWorkspace.execute({ slug: params.slug });
    },
    {
      enabled: !!params.slug
    }
  );

  const methods = useForm({
    defaultValues: {
      title: '',
      workingProcess: [
        {
          id: uniqueId(),
          title: '',
          daily_amount: 0,
          position: 0
        }
      ] as Array<{ id: string; title: string; daily_amount: number; position: number }>
    },
    resolver: yupResolver(validationSchema)
  });

  const disableButton = useMemo(() => {
    return !methods.formState.isValid;
  }, [methods.formState.isValid]);

  useEffect(() => {
    if (workspace) {
      methods.reset({
        title: workspace.title,
        workingProcess: workspace.columns.map(column => ({
          ...column,
          id: column.id.toString()
        }))
      });
    }
  }, [methods, workspace]);

  return (
    <FormWorkspace
      disableButton={disableButton}
      methods={methods}
      onSubmit={methods.handleSubmit(() => {})}
      headerTitle={`Atualizar workspace: "${workspace?.title}"`}
      headerSubtitle='Atualizar a área de trabalho pode modificar as posições dos card'
    >
      Editar workspace
    </FormWorkspace>
  );
}
