import { ChangeWorkspace } from '../../../ChangeWorkspace';
import { HeaderModal, Input, ModalButton, ModalTitle, OperationNumber } from '../../../../style';
import { useKanbanWorkspaces } from '../../../../../../contexts/KanbanWorkspacesContext';
import { useKanbanView } from '../../../../../../pages/Authenticated/KanbanView/hooks/useKanbanView';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { useUser } from '../../../../../../contexts/UserContext';
import { useMemo } from 'react';

type HeaderProps = {
  handleExpand: () => void;
  isExpanded: boolean;
};

export function Header({ handleExpand, isExpanded }: HeaderProps) {
  const form = useFormContext();
  const { isEmployeeAccount } = useUser();
  const { kanbanCard, handleShowUpdateModal } = useKanbanWorkspaces();
  const { updateCardMutation, deleteCardMutation } = useKanbanView();

  const operationNumber = useMemo(() => {
    const number = kanbanCard?.sublist?.project?.order_number ?? kanbanCard?.clothing_project?.order_number;

    if (!number) return null;

    return <OperationNumber>OP - {number}</OperationNumber>;
  }, [kanbanCard?.clothing_project?.order_number, kanbanCard?.sublist?.project?.order_number]);

  return (
    <HeaderModal>
      <ChangeWorkspace cardId={kanbanCard?.id} />

      {operationNumber}

      <ModalTitle placeholder='# título da tarefa' {...form.register('title')} />

      <Input
        placeholder='Quantidade de peças'
        type='number'
        {...form.register('quantity_piece')}
        disabled={
          (_.isNumber(kanbanCard?.id) && isEmployeeAccount) ||
          !_.isEmpty(kanbanCard?.clothing_project) ||
          !_.isEmpty(kanbanCard?.sublist)
        }
        width='120px'
      />

      <Input
        placeholder='Data de Início'
        type='date'
        {...form.register('date.start')}
        disabled={!_.isEmpty(kanbanCard?.start_date) && isEmployeeAccount}
      />

      <Input placeholder='Data de Entrega' type='date' {...form.register('date.end')} />

      <ModalButton
        type='button'
        onClick={() => {
          handleExpand();
        }}
      >
        <i className={`fa ${isExpanded ? 'fa-expand' : 'fa-expand-alt'}`}></i>
      </ModalButton>

      <ModalButton
        type='button'
        onClick={() => {
          handleShowUpdateModal();
        }}
        disabled={updateCardMutation.isLoading || deleteCardMutation.isLoading}
      >
        &times;
      </ModalButton>
    </HeaderModal>
  );
}
