import styled from 'styled-components';

export const ButtonContainer = styled.button`
  &:disabled {
    cursor: not-allowed !important;
    opacity: .3;

    span {
      text-decoration: line-through;
    }
  }
`;
