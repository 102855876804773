import styled from 'styled-components';

type WrapperDropdownType = {
  active: boolean;
};

export const WrapperDropdown = styled.div<WrapperDropdownType>`
  position: relative;

  i {
    ${({ active }) => active && 'color: var(--secondary)'}
  }

  i:hover {
    color: var(--input-placeholder-color);
    cursor: pointer;
  }
`;

export const Dropdown = styled.div`
  background: var(--background);
  border-radius: 4px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 30px;
  right: 0;
  width: 200px;
`;

export const DropdownItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;

  input {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

export const ResetButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: var(--primary);

  &:disabled {
    color: var(--secondary);
    cursor: not-allowed;
  }
`;

export const DropdownButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: var(--text);
  display: flex;
  gap: 10px;
  text-align: left;

  &:hover {
    color: var(--secondary);
  }
`;
