import { useMutation, useQuery, useQueryClient } from 'react-query';
import { listWorkspace, createWorkspace, deleteWorkspaces, updateWorkspaceTitle } from '../api';
import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';
import { toast } from 'react-toastify';
import { useAppTranslation } from '../../../../contexts/TranslationContext';

export function useWorkspaces() {
  const queryClient = useQueryClient();
  const { Translate } = useAppTranslation();

  const workspaces = useQuery<KanbanWorkspace[]>('workspaces', async () => await listWorkspace.execute(), {
    refetchOnWindowFocus: false
  });

  const createWorkspaceMutation = useMutation(async (title: string) => await createWorkspace.execute(title), {
    onSuccess: () => {
      toast.success(Translate('toast.done'));
      queryClient.invalidateQueries('workspaces');
    },
    onError: (error: { message: string }) => {
      toast.error(error.message);
    }
  });

  const renameWorkspaceMutation = useMutation(
    async (renamedWorkspace: KanbanWorkspace) => {
      queryClient.setQueryData('workspaces', (oldWorkspaces: KanbanWorkspace[] | undefined) => {
        if (!oldWorkspaces) return [];
        return oldWorkspaces.map(workspace => {
          if (workspace.id === renamedWorkspace.id) {
            return {
              ...workspace,
              title: renamedWorkspace.title
            };
          }
          return workspace;
        });
      });
      await updateWorkspaceTitle.execute(renamedWorkspace);
    },
    {
      onSuccess: () => {
        toast.success(Translate('progress.loading'));
        queryClient.invalidateQueries('workspaces');
      },
      onError: (error: { message: string }) => {
        toast.error(error.message);
      }
    }
  );

  const deleteWorkspaceMutation = useMutation(
    async (selectedWorkspaces: KanbanWorkspace[]) => await deleteWorkspaces.execute(selectedWorkspaces),
    {
      onSuccess: () => {
        toast.success('Espaços de trabalho excluídos.');
        queryClient.invalidateQueries('workspaces');
      },
      onError: (error: { message: string }) => {
        toast.error(error.message);
      }
    }
  );

  return { workspaces, createWorkspaceMutation, renameWorkspaceMutation, deleteWorkspaceMutation };
}
