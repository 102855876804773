import React, { useEffect, useState } from 'react';
import SpanButton from '../../../../../components/Buttons/SpanButton';
import EmployeePermissionManagerModal from './components/EmployeePermissionManagerModal';
import { type EmployeePermissionType, type PermissionItem } from '../../../../../types/EmployeePermissionType';
import loadCompanyEmployees from './api/loadCompanyEmployees';
import { useHttpRequest } from '../../../../../contexts/HttpRequestContext';
import { toast } from 'react-hot-toast-promise';
import { getServerErrorMessageFromResponse } from '../../../../../utils/helper';
import Spinner from '../../../../../components/Spinner';
import { useAppTranslation } from '../../../../../contexts/TranslationContext';

export default function EmployeePermissionsManager() {
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const [employees, setEmployees] = useState<EmployeePermissionType[]>([]);
  const [loadingEmployees, setLoadingEmployees] = useState<boolean>(true);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeePermissionType | null>(null);
  const [availablePermissions, setAvailablePermissions] = useState<PermissionItem[]>([]);

  useEffect(() => {
    loadCompanyEmployees({ httpConnection })
      .then((response) => {
        setEmployees(response.employees);
        setAvailablePermissions(response.permissions);
      })
      .catch(err => {
        toast.error(getServerErrorMessageFromResponse(err));
      })
      .finally(() => {
        setLoadingEmployees(false);
      });
  }, [httpConnection]);

  return (
    <React.Fragment>
      {
        !!selectedEmployee && (
          <EmployeePermissionManagerModal
            employee={selectedEmployee}
            availablePermissions={availablePermissions}
            handleClose={() => {
              setSelectedEmployee(null);
            }}
            handleConfirm={() => {
              setSelectedEmployee(null);
            }}
            onReceiveUpdatedPermissions={(updatedPermissions) => {
              const updatedEmployeesList = employees.map(employee => {
                if (employee.id === selectedEmployee.id) {
                  return { ...employee, permissions: updatedPermissions };
                }
                return employee;
              });

              setEmployees(updatedEmployeesList);
              setSelectedEmployee({ ...selectedEmployee, permissions: updatedPermissions });
            }}
          />
        )
      }

      <div className="row">
        <div className="col-auto">
          {loadingEmployees && (
            <Spinner />
          )}

          {!loadingEmployees && (
            <table className={'table table-sm table-stripped table-bordered'}>
              <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
              </tr>
              </thead>
              <tbody>
              {
                employees.length > 0 && employees.map((employee, index) => (
                  <tr key={index}>
                    <td>
                      <SpanButton text={employee.name} handleClick={() => {
                        setSelectedEmployee(employee);
                      }} />
                    </td>
                    <td>
                      <SpanButton text={employee.email} handleClick={() => {
                        setSelectedEmployee(employee);
                      }} />
                    </td>
                  </tr>
                ))
              }

              {
                employees.length === 0 && (
                  <tr>
                    <td colSpan={999}>
                      {Translate('status.nothing-to-show-for-now')}
                    </td>
                  </tr>
                )
              }

              </tbody>
            </table>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
