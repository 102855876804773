import { type AxiosResponse, type AxiosInstance } from 'axios';
import { type FinishingOptionItem } from '../../../../../../../../types/ClothingFinishingOptions/FinishingOptionItem';

type EditFinishingOptionItemParams = Pick<FinishingOptionItem, 'item' | 'price'> & {
  finishingOptionId: number;
  finishingOptionItemId: number;
  httpConnection: AxiosInstance;
}

export default async function ({ httpConnection, item, price, finishingOptionId, finishingOptionItemId }: EditFinishingOptionItemParams): Promise<FinishingOptionItem> {
  return await new Promise<FinishingOptionItem>((resolve, reject) => {
    httpConnection.put<FinishingOptionItem>(`list/finishing-option-items/${finishingOptionItemId}`, { item, price, finishing_option_id: finishingOptionId })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        const error = err as AxiosResponse;
        reject(error);
      });
  });
}
