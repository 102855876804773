import { useCallback } from 'react';
// import { useNavigate } from 'react-router';
import { useFormContext } from 'react-hook-form';

// import { type ClothingProject } from '../../../../../../types/Kanban/KanbanCard';s
import { BodyContent, ModalBodyContent, TextContent, WrapperContent, WrapperLabels } from '../../../../style';
import { FilePreview } from '../../../FilePreview';
import { Comments } from '../../../Comments';
import { useKanbanWorkspaces } from '../../../../../../contexts/KanbanWorkspacesContext';
import { CreateTasks } from '../../../CreateTasks';
import { format } from 'date-fns';

type UpdateModalContentProps = {
  history: React.ReactNode;
};

export function UpdateModalContent({ history }: UpdateModalContentProps) {
  // const navigate = useNavigate();
  const form = useFormContext();
  const { kanbanCard } = useKanbanWorkspaces();

  // const gotoKanbanView = useCallback(
  //   (clothingProject: ClothingProject) => {
  //     navigate('/orderlist/projects/view', {
  //       state: { projectId: clothingProject.id }
  //     });
  //   },
  //   [navigate]
  // );

  const handleAttachFiles = useCallback(
    (attachments: File[]) => {
      form.setValue('attachments', attachments);
    },
    [form]
  );

  return (
    <ModalBodyContent>
      <BodyContent>
        <WrapperContent>
          {kanbanCard?.clothing_project ? (
            <WrapperLabels>
              <span>Projeto: {kanbanCard?.clothing_project?.name}</span>
              {kanbanCard?.clothing_project?.approved_at ? (
                <span>Data de aprovação: {format(new Date(kanbanCard?.clothing_project?.approved_at), 'd-MM-yyyy')}</span>
              ) : null}
            </WrapperLabels>
          ) : null}

          <FilePreview onHandleSetFiles={handleAttachFiles} />

          <TextContent placeholder='# descrição da tarefa' {...form.register('description')} />

          <CreateTasks />

          <Comments />
        </WrapperContent>
      </BodyContent>

      {history}
      {/*
        <WrapperLabels>
          <SubTitle>Prioridade:</SubTitle>
          <Select {...form.register('priority')}>
            <option value={0}>Baixa</option>
            <option value={1}>Média</option>
            <option value={2}>Alta</option>
          </Select>
        </WrapperLabels> */}
    </ModalBodyContent>
  );
}
