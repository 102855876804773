import { type ClothingModelType } from '../../../../../../../types/ClothingModelType';
import { type Order, type ClothingProductionData } from '../../../../../../../types/OrderTypes';
import { type SequentialListInputs } from '../../../../../../../types/SequentialListInputs';
import { generateArrayOfNumbersInRange } from '../../../../../../../utils/helper';
import { type TFunction } from 'i18next';

export const generateDefaultListSequenceSettings = (importedModels: ClothingModelType[]): SequentialListInputs => ({
  startValue: 1,
  endValue: 2,
  gender: 'male',
  targetSublistIndex: 0,
  clothingProductionData: [
    ...importedModels.map<ClothingProductionData>(model => ({ modelId: model.id, quantity: 0, sizeIndex: -1 }))
  ]
});

type UpdateClothingProductionDataInSequenceSettingsParams = {
  sequence: SequentialListInputs;
  modelId: number;
  sizeIndex: number;
  clotheIndex: number;
};

export const updateClothingProductionDataInSequenceSettings = ({
  sequence,
  modelId,
  sizeIndex,
  clotheIndex
}: UpdateClothingProductionDataInSequenceSettingsParams): SequentialListInputs => {
  const updatedClothes = sequence.clothingProductionData.map<ClothingProductionData>((currentClothe, currentIndex) => {
    if (currentIndex === clotheIndex) {
      return {
        modelId,
        quantity: sizeIndex === -1 ? 0 : 1,
        sizeIndex
      };
    }
    return currentClothe;
  });

  return { ...sequence, clothingProductionData: updatedClothes };
};

export const validateSequenceSettings = (sequence: SequentialListInputs, Translate: TFunction): void => {
  if (sequence.startValue < 0) throw new Error(Translate('error.sequential-list-start-cant-be-negavite'));
  if (sequence.endValue < 0) throw new Error(Translate('error.sequential-list-end-cant-be-negavite'));
  if (sequence.startValue > sequence.endValue) throw new Error(Translate('error.sequential-list-start-cant-be-greater-then-end'));
  if (sequence.startValue === sequence.endValue) throw new Error(Translate('error.sequential-list-start-end-must-be-different'));

  const countTotalPieces = sequence.clothingProductionData.reduce((accumulator, currentClothe) => {
    return accumulator + currentClothe.quantity;
  }, 0);

  if (countTotalPieces === 0) throw new Error(Translate('error.sequential-list-missing-clothing-size'));
};

export const createOrdersFromSequence = (sequence: SequentialListInputs, sublist_id: number): Order[] => {
  const createdOrders = [...generateArrayOfNumbersInRange(sequence.startValue, sequence.endValue)].map<Order>(
    sequenceNumber => {
      const order: Order = {
        sublist_id,
        // id: uuidv4(),
        name: '',
        nickname: '',
        bloodType: '',
        gender: sequence.gender,
        number: sequenceNumber.toString(),
        clothes: sequence.clothingProductionData,
        totalValue: 0,
        clothing_replacements: []
      };

      return order;
    }
  );

  return createdOrders;
};
