import _ from 'lodash';
import { useCallback } from 'react';
import { useManagerTask } from '../../useManagerTask';
import { toast } from 'react-toastify';

import { ModalTitle } from '../../../../style';
import { AddedButton, Wrapper } from '../../style';
import { type task } from '../..';

export function CreateTitleTask() {
  const { form } = useManagerTask();

  const handleCreateAndUpdate = useCallback(() => {
    const { task_name, taskList } = form.getValues();

    if (form.watch('edit_task')) {
      form.setValue(
        'taskList',
        taskList.map((task: task) => {
          if (task.title === form.watch('edit_task').title) {
            return { title: task_name, checked: task.checked };
          }

          return task;
        })
      );

      form.setValue('edit_task', '');
      form.setValue('task_name', '');

      return;
    }

    const existTask = taskList.find((task: task) => task.title === task_name);

    if (existTask) {
      toast.error('Já existe tarefa com o mesmo nome');
      return;
    }

    form.setValue('taskList', [...taskList, { title: task_name, checked: false }]);
    form.setValue('task_name', '');
  }, [form]);

  return (
    <Wrapper>
      <ModalTitle
        placeholder='nome da tarefa'
        error={form.formState.errors?.taskList?.message as string}
        {...form.register('task_name')}
      />

      <AddedButton type='button' disabled={_.isEmpty(form.watch('task_name'))} onClick={handleCreateAndUpdate}>
        {_.isEmpty(form.watch('edit_task')) ? <i className='fa fa-plus' /> : <i className='fa fa-check' />}
      </AddedButton>
    </Wrapper>
  );
}
