import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createCheckGroups, deleteCheckGroups, getCheckGroups } from './service';
import { type CreateCheckGroupsParams } from './service/CreateCheckGroups';
import { useFormContext } from 'react-hook-form';

export function useManagerTask() {
  const form = useFormContext();
  const query = useQueryClient();

  const taskGroupList = useQuery(
    'getCheckGroups',
    async () => {
      const response = await getCheckGroups.execute();
      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );

  const createTaskGroup = useMutation(
    async (data: CreateCheckGroupsParams) => {
      await createCheckGroups.execute(data);
    },
    {
      onSuccess: () => {
        form.setValue('group_list_select', '');
        form.setValue('group_title', '');
        form.setValue('taskList', []);
        query.invalidateQueries('getCheckGroups');
      }
    }
  );

  const deleteTaskGroup = useMutation(
    async (id: number) => {
      await deleteCheckGroups.execute(id);
    },
    {
      onSuccess: () => {
        form.setValue('taskListGroupTitle', '');
        form.setValue('taskList', []);
        query.invalidateQueries('getCheckGroups');
      }
    }
  );

  return { form, taskGroupList, createTaskGroup, deleteTaskGroup };
}
