import { useCallback, useEffect, useState } from 'react';
import TextAnimation from './TextAnimation';
import { LoginContainer } from './styles';
import { type LoginFieldsType } from '../../types/LoginFieldsType';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router';
import { toast } from 'react-hot-toast-promise';
import Spinner from '../../components/Spinner';
import { useUser } from '../../contexts/UserContext';
import { useAppTranslation } from '../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../utils/helper';
import packageJson from '../../../package.json';

export default function Login() {
  const navigate = useNavigate();
  const { signed, doLogin } = useAuth();
  const { setUser } = useUser();
  const { Translate } = useAppTranslation();

  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState<LoginFieldsType>({ email: '', password: '' });

  const handleLogin = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
        setLoading(true);
        const user = await doLogin(credentials);
        setUser(user);
        window.location.href = '/dashboard';
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
        setLoading(false);
      }
    },
    [credentials, doLogin, setUser]
  );

  useEffect(() => {
    if (signed) navigate('/dashboard');
  }, [navigate, signed]);

  return (
    <LoginContainer className='bg-gradient-primary'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-10 col-lg-12 col-md-9'>
            <div className='card o-hidden border-0 shadow-lg my-5'>
              <div className='card-body p-0'>
                <div className='row'>
                  <div className='col-lg-6 d-none d-lg-block' style={{ position: 'relative' }}>
                    <TextAnimation />
                  </div>
                  <div className='col-lg-6'>
                    <div className='px-5 pt-5 pb-4'>
                      <div className='text-center'>
                        <h1 className='h4 text-gray-900 mb-4' data-testid='login-welcome-message'>
                          {Translate('labels.good-have-you-here')}
                        </h1>
                      </div>

                      <form className='user' onSubmit={handleLogin}>
                        <div className='form-group'>
                          <input
                            disabled={loading}
                            type='email'
                            className='form-control form-control-user'
                            id='email'
                            placeholder='E-mail'
                            value={credentials.email}
                            onChange={({ target }) => {
                              setCredentials({ ...credentials, email: target.value.trim() });
                            }}
                          />
                        </div>

                        <div className='form-group'>
                          <input
                            disabled={loading}
                            type='password'
                            className='form-control form-control-user'
                            id='password'
                            placeholder={Translate('labels.password')}
                            value={credentials.password}
                            onChange={({ target }) => {
                              setCredentials({ ...credentials, password: target.value.trim() });
                            }}
                          />
                        </div>

                        {loading && <Spinner />}

                        {!loading && (
                          <button className='btn btn-primary btn-user btn-block' data-testid='do-login-button'>
                            {Translate('actions.connect')}
                          </button>
                        )}

                        <hr />
                      </form>
                      <div className='text-center'>
                        <a
                          className='small'
                          target='_blank'
                          href='https://dashboard.aistenlab.com/password/recovery'
                          rel='noreferrer'
                        >
                          {Translate('labels.forgor-password')}
                        </a>
                      </div>
                      <div className='text-center'>
                        <a
                          className='small'
                          target='_blank'
                          href='https://dashboard.aistenlab.com/register'
                          rel='noreferrer'
                        >
                          {Translate('labels.create-your-account')}
                        </a>
                      </div>

                      <footer className='mt-3'>
                        <div className='text-center'>
                          <span className='d-block' style={{ fontSize: '12px' }}>Copyright &copy; Aistenlab Technology</span>
                          <span style={{ fontSize: '12px' }}>v{packageJson.version}</span>
                        </div>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
}
