import { useMemo } from 'react';

type SpinnerType = {
  alignLeft?: boolean;
  small?: boolean;
  useWhite?: boolean;
};

export default function Spinner({ alignLeft = false, small = false, useWhite = false }: SpinnerType) {
  const alignLeftStyle = useMemo(() => (!alignLeft ? 'text-center' : 'align-left'), [alignLeft]);

  const sizeStyle = useMemo(() => (small ? 'spinner-border-sm' : ''), [small]);

  return (
    <div className={`${alignLeftStyle}`}>
      <div
        className={`spinner-border ${!useWhite ? 'text-primary' : ''} ${sizeStyle}`}
        style={{ verticalAlign: 'none', display: 'inherit', color: useWhite ? '#fff' : '' }}
      />
    </div>
  );
}
