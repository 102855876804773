import * as Style from './style';

import { Header } from './fragments/Header';
import { Content } from './fragments/Content';
import { type WorkspaceColumn } from '../services/ShowWorkspace';

export function Column({ column }: { column: WorkspaceColumn }) {
  return (
    <Style.Container>
      <Header title={column.title} />

      <Content />
    </Style.Container>
  );
}
