import { type WEEK_DAYS } from '../constants';
import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

export class GetOfficeHours {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(): Promise<typeof WEEK_DAYS> {
    return await this.http.get('/office-hours');
  }
}
