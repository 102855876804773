import { type PropsWithChildren } from 'react';
import { FormProvider } from 'react-hook-form';

import { Header } from '../Header';
import { WorkingProcess } from '../../AddWorkspace/WorkingProcess';
import { FormContainer, Label } from '../../AddWorkspace/style';
import { Title } from '../../AddWorkspace/fragments/Title';

type FormWorkspaceProps = {
  methods: any;
  onSubmit: any;
  disableButton: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  workingProgressTitle?: string;
} & PropsWithChildren;

export function FormWorkspace({
  children,
  methods,
  onSubmit,
  disableButton,
  headerTitle = 'Adicionar uma nova workspace',
  headerSubtitle = 'Criar uma nova área de planejamento e controle da produção',
  workingProgressTitle = 'Adicione sua linha de produção, dando nome e quantidade diária'
}: FormWorkspaceProps) {
  return (
    <div className='d-flex flex-column pb-5'>
      <Header title={headerTitle} subtitle={headerSubtitle} />

      <FormProvider {...methods}>
        <FormContainer onSubmit={onSubmit}>
          <div className='form-group'>
            <Label htmlFor='title'>Nome da workspace</Label>
            <input type='text' placeholder='nome da workspace' className='form-control' {...methods.register('title')} />
          </div>

          <Title title='Processo de produção' subtitle={workingProgressTitle} />

          <WorkingProcess />

          <div className='d-flex justify-content-end'>
            <button type='submit' className='btn btn-primary' disabled={disableButton}>
              {children}
            </button>
          </div>
        </FormContainer>
      </FormProvider>
    </div>
  );
}
