import styled from 'styled-components';

export const FormContainer = styled.form`
  padding: 20px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
`;
