import { type SublistBatchToken } from '../../../../../types/SublistBatchToken';
import { generateSublistJsonFullUrl } from '../../services/sublistService';

export const generateContentToCopyToClipboard = (generatedSublistLinks: SublistBatchToken[]) => {
  let textToClipboard = '';

  generatedSublistLinks.forEach(({ title, token, id }) => {
    const jsonURL = generateSublistJsonFullUrl(id, token);
    textToClipboard += `${title}\nJSON: ${jsonURL}\n\n`;
  });

  return textToClipboard.trim();
};
