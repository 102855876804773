import BasicMenu from './BasicMenu';
import CollapsableItem, { type CollapsableItemType } from './CollapsableMenu';
import SidebarDivider from './SidebarDivider';
import SidebarBrand from './SidebarBrand';
import { IconsCatalog } from '../IconsCatalog';
import { useSettings } from '../../contexts/SettingsContext';
import React, { useMemo } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useAppTranslation } from '../../contexts/TranslationContext';
import { Container } from './styles';
import { useSidebarCollapse } from '../../contexts/SidebarCollapseContext';
import { isDev } from '../../utils/helper';

export default function Sidebar() {
  const { backendResourcesReady } = useSettings();
  const { isEmployeeAccount } = useUser();
  const { Translate } = useAppTranslation();
  const { isCollapsed } = useSidebarCollapse();

  const sideMenuOrderListOptions = useMemo<CollapsableItemType[]>(() => {
    const options: CollapsableItemType[] = [
      { title: Translate('labels.my-projects'), route: 'projects' },
      { title: 'Avaliações', route: 'survey', hidden: !isDev(), showDevelopmentTag: true },
      { title: Translate('labels.settings'), route: 'settings', hidden: isEmployeeAccount }
    ];

    return options;
  }, [isEmployeeAccount, Translate]);

  return (
    <Container
      className='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-print-none'
      isCollapsed={isCollapsed}
      id='accordionSidebar'
    >
      <SidebarBrand />

      <SidebarDivider noMargins />

      {backendResourcesReady && (
        <React.Fragment>
          <BasicMenu
            options={[
              { title: Translate('labels.calendar'), route: 'dashboard', icon: IconsCatalog.calendarDays },
              { title: 'Kanban', route: 'kanban/workspaces', icon: IconsCatalog.tasks }
            ]}
          />

          {
            isDev() && (
              <CollapsableItem
                controlId='sidemenu-pcp'
                title='Processo de Produção'
                icon={IconsCatalog.tasks}
                header='Processo de Produção'
                routeSection='pcp'
                items={[
                  { title: 'Workspaces', route: 'workspaces', hidden: isEmployeeAccount && isDev(), showDevelopmentTag: true },
                  { title: 'Configurações', route: 'settings', hidden: isEmployeeAccount && isDev(), showDevelopmentTag: true }
                ]}
              />
            )
          }

          <CollapsableItem
            controlId='sidemenu-orderlist'
            title={Translate('labels.orderlist')}
            icon={IconsCatalog.list}
            header={Translate('labels.production')}
            routeSection='orderlist'
            items={sideMenuOrderListOptions}
          />

          <CollapsableItem
            controlId='sidemenu-clothing'
            title={Translate('labels.clothes')}
            icon={IconsCatalog.tshirt}
            header={Translate('labels.catalog')}
            routeSection='clothes'
            items={[
              { title: Translate('labels.icons-gallery'), route: 'clothing-icons' },
              { title: Translate('labels.my-models'), route: 'clothing-models' },
              { title: Translate('labels.presets'), route: 'clothing-presets' }
            ]}
          />

          <CollapsableItem
            controlId='sidemenu-financial'
            title='Controles'
            icon={IconsCatalog.dollar}
            header='Dashboard'
            routeSection='financial'
            items={[
              { title: Translate('labels.sells-report'), route: 'sells-report', hidden: isEmployeeAccount },
              {
                title: Translate('labels.clothing-replacements-report'),
                route: 'order-replacements-report',
                hidden: isEmployeeAccount
              },
              { title: 'Cadastros', route: 'entities', hidden: isEmployeeAccount },
              { title: 'Estoque', route: 'register', hidden: isEmployeeAccount && isDev() },
              { title: 'Costureiras', route: 'seamstress', hidden: isEmployeeAccount && isDev() }
            ]}
          />
        </React.Fragment>
      )}
    </Container>
  );
}
