import React from 'react';
import { type BasicTextInputProps } from '../BasicTextInput';
import { NumericFormat } from 'react-number-format';

type TextInputPrependedTextType = BasicTextInputProps & {
  prependedText: string;
  onChangeQuantity: (value: number) => void;
};

export default function NumericInputPrependedText({
  id,
  tabIndex,
  value,
  onChangeQuantity,
  prependedText,
  disabled
}: TextInputPrependedTextType) {
  return (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text' id={id}>
          {prependedText}
        </span>
      </div>

      <NumericFormat
        disabled={disabled}
        tabIndex={tabIndex}
        value={value}
        className="form-control"
        decimalScale={2}
        allowedDecimalSeparators={['.', ',']}
        fixedDecimalScale={false}
        onFocus={event => {
          event.target.select();
        }}
        onValueChange={values => {
          onChangeQuantity(values.floatValue ?? 0);
        }}
      />
    </div>
  );
}
