import React from 'react';
import TextInput from '../../../../../components/Forms/TextInput';
import { useSettings } from '../../../../../contexts/SettingsContext';
import { generateExampleSizesHelpText } from './services/helpTextPrefixSufixService';
import SavePreferencesButton from '../../../../../components/Buttons/SavePreferencesButton';
import { useUser } from '../../../../../contexts/UserContext';
import { useAppTranslation } from '../../../../../contexts/TranslationContext';

export default function GenderPrefix() {
  const { preferences, setPreferences } = useSettings();
  const { isEmployeeAccount } = useUser();
  const { Translate } = useAppTranslation();

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-4'>
          <TextInput
            id='preferences-female-prefix'
            label={Translate('labels.female-prefix')}
            disabled={isEmployeeAccount}
            value={preferences.gender_female_prefix}
            onChange={({ target }) => {
              setPreferences({ ...preferences, gender_female_prefix: target.value.trim() });
            }}
            helpText={generateExampleSizesHelpText({ prefix: preferences.gender_female_prefix, Translate })}
          />
        </div>

        <div className='col-4'>
          <TextInput
            id='preferences-childish-sufix'
            label={Translate('labels.childish-suffix')}
            disabled={isEmployeeAccount}
            value={preferences.gender_childish_sufix}
            onChange={({ target }) => {
              setPreferences({ ...preferences, gender_childish_sufix: target.value.trim() });
            }}
            helpText={generateExampleSizesHelpText({ sufix: preferences.gender_childish_sufix, Translate })}
          />
        </div>

        <div className='col-4'>
          <TextInput
            id='preferences-male-no-prefix-suffix'
            label={Translate('labels.male')}
            disabled={true}
            value={Translate('status.non-used')}
            onChange={({ target }) => {
              setPreferences({ ...preferences, gender_female_prefix: target.value.trim() });
            }}
            helpText={Translate('description.male-no-need-prefix-suffix')}
          />
        </div>

        <div className='col-auto'>
          <SavePreferencesButton />
        </div>
      </div>
    </React.Fragment>
  );
}
