import { type AxiosInstance } from 'axios';
import { type Order } from '../../../../types/OrderTypes';

export type AddOrderToSublistParams = {
  projectId: number;
  order: Order;
  httpConnection: AxiosInstance;
  finalClientAccessToken?: string;
}

export const addOrderToSublist = async ({ httpConnection, projectId, order, finalClientAccessToken }: AddOrderToSublistParams): Promise<Order> => {
  return await new Promise((resolve, reject) => {
    const url = !finalClientAccessToken
      ? `list/clothing-projects/sublist/orders?project_id=${projectId}`
      : `/list/clothing-projects/final-client/orders?project_id=${projectId}&access_token=${finalClientAccessToken}`;

    httpConnection.post<Order>(url, order)
      .then((response) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

type AddOrdersToSublistParams = {
  projectId: number;
  sublistId: number;
  orders: Order[];
  sort?: { field: string; type: string };
  httpConnection: AxiosInstance;
}

export const addOrdersToSublist = async ({ httpConnection, projectId, sublistId, orders, sort }: AddOrdersToSublistParams): Promise<Order[]> => {
  const putData = { orders, sort };

  return await new Promise((resolve, reject) => {
    httpConnection.post<Order[]>(`list/clothing-projects/sublist/orders/create-multiple?project_id=${projectId}&sublist_id=${sublistId}`, putData)
      .then((response) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
