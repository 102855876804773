import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';
import esES from 'date-fns/locale/es';

import { type BasicTextInputProps } from '../BasicTextInput';
import { useAppTranslation } from '../../../contexts/TranslationContext';

registerLocale('pt', ptBR);
registerLocale('en', enUS);
registerLocale('es', esES);

type DatePickerInputType = Pick<BasicTextInputProps, 'id' | 'tabIndex' | 'disabled'> & {
  label: string;
  startDate: Date | undefined;
  handleDateChange: (selectedDate: Date) => void;
};

export default function DatePickerInput({
  id,
  label,
  tabIndex,
  disabled = false,
  startDate,
  handleDateChange
}: DatePickerInputType) {
  const { dateFnsLocale } = useAppTranslation();

  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <DatePicker
        id={id}
        tabIndex={tabIndex}
        disabled={disabled}
        className='form-control'
        locale={dateFnsLocale}
        dateFormat={'dd/MM/yyy'}
        selected={startDate}
        onChange={date => {
          if (date) handleDateChange(date);
        }}
      />
    </div>
  );
}
