import styled from 'styled-components';

export const WrapperContainer = styled.div`
  height: calc(100vh - 250px);
  overflow-x: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding-right: 8px;
`;
