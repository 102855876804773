import { format } from 'date-fns';
import { ItemAuthor, ItemCard, ItemContent, ItemDate } from './style';
import { useMemo } from 'react';

type ItemProps = {
  title?: string;
  created_at?: string;
  author?: string;
};

export function Item({ title, created_at, author }: ItemProps) {
  const authorInitialLetters = useMemo(() => {
    if (author?.split(' ')?.length ?? 0 > 1) {
      const [firstName, lastName] = (author ?? '').split(' ');

      return [firstName[0], lastName[0]].join('').toUpperCase();
    }

    return author?.slice(0, 2).toUpperCase();
  }, [author]);

  return (
    <ItemCard>
      <ItemAuthor>{authorInitialLetters}</ItemAuthor>

      <div>
        <ItemContent>
          <span>{title}</span>
        </ItemContent>
        <ItemDate>{created_at ? format(new Date(created_at), 'dd/MM/yyyy') : ''}</ItemDate>
      </div>
    </ItemCard>
  );
}
