import styled from 'styled-components';

export const Container = styled.div`
  > li {

    > hr {
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      margin: 0px 16px 0px 16px;
    }
  }

  > li:last-child {

    > hr {
      display: none;
    }
  }
`;
