import { useEffect, useState } from 'react';

type useHeightProps = {
  className: string;
  deps?: any;
};

export function useHeight({ className, deps }: useHeightProps) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const height = (document.querySelector(className) as HTMLElement)?.clientHeight;
      setHeight(height);
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [className, deps]);

  return { height };
}
