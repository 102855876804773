import { createContext, useContext, useMemo, useState } from 'react';
import { type ClothingReplacementOptionType } from '../types/ClothingReplacementOption';
import type { ComboBoxDataType } from '../types/ComboBoxDataType';

type ClothingReplacementOptionsContextType = {
  clothingReplacementOptions: ClothingReplacementOptionType[];
  setClothingReplacementOptions: React.Dispatch<React.SetStateAction<ClothingReplacementOptionType[]>>;
  clothingReplacementOptionsComboboxOptions: ComboBoxDataType[];
};

const ClothingReplacementOptionsContext = createContext<ClothingReplacementOptionsContextType>({} as ClothingReplacementOptionsContextType);

type ClothingReplacementOptionsType = {
  children: JSX.Element;
}

export const ClothingReplacementOptionsProvider = ({ children }: ClothingReplacementOptionsType) => {
  const [clothingReplacementOptions, setClothingReplacementOptions] = useState<ClothingReplacementOptionType[]>([]);

  const clothingReplacementOptionsComboboxOptions = useMemo<ComboBoxDataType[]>(() => {
    if (clothingReplacementOptions.length === 0) return [];

    return clothingReplacementOptions.map<ComboBoxDataType>(status => ({
      label: status.name,
      value: status.id!.toString()
    }));
  }, [clothingReplacementOptions]);

  const contextValues = useMemo(
    () => ({
      clothingReplacementOptions,
      setClothingReplacementOptions,
      clothingReplacementOptionsComboboxOptions
    }),
    [
      clothingReplacementOptions,
      setClothingReplacementOptions,
      clothingReplacementOptionsComboboxOptions
    ]
  );

  return (
    <ClothingReplacementOptionsContext.Provider value={contextValues}>{children}</ClothingReplacementOptionsContext.Provider>
  );
};

export const useClothingReplacementOptions = (): ClothingReplacementOptionsContextType => {
  const context = useContext(ClothingReplacementOptionsContext);

  if (!context) {
    throw new Error('useClothingReplacementOptions must be used within a ClothingReplacementOptionsProvider');
  }

  return context;
};
