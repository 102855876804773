import { type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type Order } from '../../../../../types/OrderTypes';
import { generateClothingProductionDataFromModels } from '../services/orderFormService';

export const generateInitialOrder = (models?: ClothingModelType[]): Order => ({
  name: '',
  number: '',
  bloodType: '',
  nickname: '',
  gender: 'male',
  clothes: models !== undefined ? generateClothingProductionDataFromModels(models) : [],
  totalValue: 0,
  clothing_replacements: []
});
