import React, { useCallback, useMemo } from 'react';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type ProjectType } from '../../../../../../types/ProjectType';
import { toast } from 'react-hot-toast-promise';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import ProjectSharedLinkInfo from './ProjectSharedLinkInfo';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import MiniCard from '../../../../../../components/Cards/MiniCard';
import { displayAsCurrency } from '../../../../../../utils/helper';
import { type ProjectReportType } from '../../../../../../types/ProjectReportType';
import { calculateTotalAmmountPaymentsReceived } from '../PaymentReceipts/services/paymentCalculatorService';
import { generatePathRegisterFile } from '../../../../../../helpers/general';
import { Profile } from './styles';
import BadgeButton from '../../../../../../components/Buttons/BadgeButton';
import { Tooltip } from 'react-tooltip';

export type ClothingReplacementsMiniReportType = {
  lostPieces: number;
  totalSpentWithClothingReplacements: number;
}

type ProjectHeaderInfoType = {
  project: ProjectType;
  preset: string;
  presetCount: number;
  hideButtons: boolean;
  toolsDropdownButton: JSX.Element;
  sellerApproveProjectButton: JSX.Element;
  projectReport: ProjectReportType;
  currencySymbol: string;
  onClickEditButton: () => void;
  onClickSharedLinkButton: () => void;
  onClickPaymentReceiptsButton: () => void;
  onRequestChangeProjectStatus: () => void;
  onClickShowProjectClientInformations: () => void;
  buttonOpenOrderClothingReplacements?: JSX.Element | false;
  clothingReplacementsMiniReport: ClothingReplacementsMiniReportType;
};

export default function ProjectHeaderInfo({
  project,
  preset,
  presetCount,
  hideButtons,
  toolsDropdownButton,
  sellerApproveProjectButton,
  projectReport,
  currencySymbol,
  onClickEditButton,
  onClickSharedLinkButton,
  onClickPaymentReceiptsButton,
  onClickShowProjectClientInformations,
  onRequestChangeProjectStatus,
  clothingReplacementsMiniReport
}: ProjectHeaderInfoType) {
  const { Translate } = useAppTranslation();

  const startWhatsAppChat = useCallback(() => {
    if (project.client_whatsapp) window.open(`https://wa.me/${project.client_whatsapp}`);
    else toast.error(Translate('error.project-missing-client-whatsapp'));
  }, [Translate, project.client_whatsapp]);

  const totalAmmountPaymentsReceived = useMemo(() => {
    return calculateTotalAmmountPaymentsReceived(project.payments);
  }, [project.payments]);

  return (
    <React.Fragment>
      <div className="row">
        {project?.entity ? (
          <div className="col-md-1">
            <Profile src={generatePathRegisterFile({ filename: project.entity?.photo })} alt="" />
          </div>
        ) : null}

        <div className="col-xs-10 col-auto">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-column">

                <div className="d-flex mb-2">
                  <span className="badge badge-large badge-secondary align-self-start">
                    {Translate('labels.production-order-abbrev')} {project.order_number}
                  </span>

                  <span className="badge badge-large badge-primary align-self-start ml-1">
                    {displayAsCurrency(projectReport.totalProjectPriceWithDiscount, currencySymbol)}
                  </span>

                  {
                    clothingReplacementsMiniReport.lostPieces > 0 && (
                      <React.Fragment>
                        <Tooltip id='project-order-replacements-value' opacity={1}>
                          {Translate('labels.lost-pieces')}: {clothingReplacementsMiniReport.lostPieces}
                        </Tooltip>

                        <span
                          className="badge badge-large badge-danger align-self-start ml-1"
                          data-tooltip-id='project-order-replacements-value'
                          data-tooltip-delay-hide={250}
                          data-tooltip-variant='dark'
                        >
                          {`-${displayAsCurrency(clothingReplacementsMiniReport.totalSpentWithClothingReplacements, currencySymbol)}`}
                        </span>
                      </React.Fragment>
                    )
                  }

                  {
                    project.status && (
                      <BadgeButton
                        marginLeft
                        title={project.status.name}
                        color={project.status.color}
                        handleClick={onRequestChangeProjectStatus}
                      />
                    )
                  }
                </div>

                <h1 className="h3 mb-2 text-gray-800 mr-2" data-testid="project-header-main-title">
                  {project.name}
                </h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col d-flex">
              {!hideButtons && (
                <section>
                  <SplitButton
                    color="primary"
                    icon={IconsCatalog.pen}
                    handleClick={onClickEditButton}
                    size="sm"
                    testId="project-edit-name-header-button"
                  />

                  <SplitButton
                    testId="project-header-button-shared-link"
                    color="primary"
                    icon={IconsCatalog.link}
                    handleClick={onClickSharedLinkButton}
                    size="sm"
                    marginLeft
                  />

                  {project.client_whatsapp && (
                    <SplitButton
                      testId="project-header-button-open-whatsapp"
                      color="success"
                      icon={IconsCatalog.whatsapp}
                      handleClick={startWhatsAppChat}
                      size="sm"
                      marginLeft
                    />
                  )}
                </section>
              )}

              <section>
                <SplitButton
                  color="primary"
                  icon={IconsCatalog.dollar}
                  handleClick={onClickPaymentReceiptsButton}
                  size="sm"
                  marginLeft
                />

                <SplitButton
                  color="primary"
                  icon={IconsCatalog.quoteLeft}
                  handleClick={onClickShowProjectClientInformations}
                  size="sm"
                  marginLeft
                />
              </section>

              {toolsDropdownButton}

              {sellerApproveProjectButton}
            </div>
          </div>

          <p className="mb-0 mt-2">
            <span>
              {Translate('labels.preset-used-is')}
              &nbsp;
              <b>{preset}</b> {Translate('labels.and-contains')} {presetCount}&nbsp;
            </span>
            <span className="text-lowercase">{Translate('labels.models')}</span>.
          </p>

          {project?.entity ? (
            <p className="my-0">
              <span>
                Projeto vinculado ao cliente <b>{project?.entity?.name}</b>
              </span>
            </p>
          ) : null}

          <ProjectSharedLinkInfo project={project} />
        </div>

        <div className={project?.entity ? 'col-xs-12 col-md-5' : 'col-xs-12 col'}>
          <div className="row justify-content-end">

            {project.shipping && (
              <MiniCard
                useCompactView
                icon={IconsCatalog.truck}
                color="secondary"
                title={project.shipping.carrier}
                text={displayAsCurrency(project.shipping.price, currencySymbol)}
              />
            )}

            <MiniCard
              useCompactView
              icon={IconsCatalog.dollar}
              color="success"
              title={Translate('labels.received')}
              text={displayAsCurrency(totalAmmountPaymentsReceived, currencySymbol)}
            />
            <MiniCard
              useCompactView
              icon={IconsCatalog.dollar}
              color="warning"
              title={Translate('labels.to-receive')}
              text={displayAsCurrency(
                projectReport.totalProjectPriceWithDiscount - totalAmmountPaymentsReceived,
                currencySymbol
              )}
            />
            <MiniCard
              useCompactView
              icon={IconsCatalog.dollar}
              color="primary"
              title={Translate('labels.full-total-pieces-count')}
              text={projectReport.totalProjectClothes.toString()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
