import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHttpRequest } from '../../../../contexts/HttpRequestContext';
import { createColumn } from '../api/createColumn';
import { validateDuplicatedColumnTitle } from '../service/kanbanColumnService';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import { type KanbanColumn } from '../../../../types/Kanban/KanbanColumn';
import { deleteColumn } from '../api/deleteColumn';
import { renameColumn } from '../api/renameColumn';
import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';
import { createCard } from '../api/createCard';
import { type CreateCardParams } from '../../../../types/Kanban/CreateCardParams';
import { useLocation } from 'react-router';
import { updateCardsAffectedByMove } from '../api/updateCards';
import { type KanbanCardIdentifier } from '../../../../types/Kanban/KanbanCardIdentifier';
import { addImageCard, deleteCard, listColumnsWorkspace, updateCard, updateColumnBackgroundColor } from '../api';
import { useKanbanWorkspaces } from '../../../../contexts/KanbanWorkspacesContext';
import { schemaUpdateCardModalForm } from '../../../../components/CardModal/validations';
import { type UpdateCardMutationData } from './contracts';
// import { socketConnect } from '../../../../helpers/socketConnect';

type CreateColumnParams = {
  title: string;
  workspace: KanbanWorkspace;
  columns?: KanbanColumn[];
};

type MoveCardMutationParams = {
  affectedCards: KanbanCardIdentifier[];
  updatedColumnCards?: KanbanColumn[];
};

type DeleteCardParams = {
  cardId: number;
};

type UpdateCardColorBackgroundParams = {
  column_id: number;
  color: string;
};

export function useKanbanView() {
  const location = useLocation();
  const query = useQueryClient();
  const { httpConnection } = useHttpRequest();
  const { handleShowUpdateModal, handleShowCreateModal, kanbanCard } = useKanbanWorkspaces();
  const methods = useForm({
    resolver: yupResolver(schemaUpdateCardModalForm),
    defaultValues: {
      title: kanbanCard?.title,
      description: kanbanCard?.description,
      taskList: kanbanCard?.check_groups[0]?.check_items ?? [],
      attachments: kanbanCard?.images,
      quantity_piece: kanbanCard?.quantity_piece,
      date: {
        start: _.defaultTo(kanbanCard?.start_date, format(new Date(), 'yyyy-MM-dd')),
        end: kanbanCard?.end_date
      },
      priority: kanbanCard?.priority
    }
  });

  const workspaceId = location.state?.workspace?.id;
  const workspaceKey = `kanban-workspace-${workspaceId}-columns`;

  const workspaceColumns = useQuery<KanbanColumn[]>(
    workspaceKey,
    async () => await listColumnsWorkspace.execute(workspaceId),
    {
      enabled: _.isNumber(workspaceId)
    }
  );

  const createColumnMutation = useMutation(
    async ({ title, workspace, columns }: CreateColumnParams) => {
      validateDuplicatedColumnTitle(title, columns as KanbanColumn[]);
      const column = await createColumn({ title, workspace_id: workspace.id }, httpConnection);
      return column;
    },
    {
      onSuccess: () => {
        query.invalidateQueries(workspaceKey);
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  const renameColumnMutation = useMutation(
    async ({ columnId, title }: { columnId: number; title: string }) =>
      await renameColumn(
        {
          id: columnId,
          title,
          workspace_id: workspaceId
        },
        httpConnection
      ),
    {
      onSuccess: () => {
        query.invalidateQueries(workspaceKey);
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  const deleteColumnMutation = useMutation(
    async (id: number) => {
      await deleteColumn({ id }, httpConnection);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(workspaceKey);
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  const updateColumnBackgroundColorMutation = useMutation(
    async ({ column_id, color }: UpdateCardColorBackgroundParams) => {
      await updateColumnBackgroundColor.execute({ columnId: column_id, color });
    },
    {
      onSuccess: () => {
        query.invalidateQueries(workspaceKey);
      }
    }
  );

  const createCardMutation = useMutation(
    async ({
      column_id,
      position_index,
      title,
      description,
      taskList,
      attachments,
      quantity_piece,
      priority
    }: CreateCardParams) => {
      const createdCard = await createCard(
        {
          column_id,
          position_index,
          title,
          description,
          taskList,
          attachments,
          quantity_piece,
          priority
        },
        httpConnection
      );

      if (_.isEmpty(attachments)) return;

      await addImageCard.execute({ attachments: attachments ?? [], cardId: createdCard.id.toString() });
    },
    {
      onSuccess: () => {
        query.invalidateQueries(workspaceKey).finally(() => {
          handleShowCreateModal();
        });
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  const updateCardMutation = useMutation(
    async ({
      cardId,
      title,
      description,
      taskList,
      attachments,
      quantity_piece,
      date,
      priority
    }: UpdateCardMutationData) => {
      const response = await updateCard.execute({
        cardId,
        title,
        description,
        taskList,
        attachments,
        quantity_piece,
        date,
        priority
      });

      await addImageCard.execute({ attachments: attachments ?? [], cardId: cardId.toString() });

      return response;
    },
    {
      onSuccess: data => {
        handleShowUpdateModal(data);
        query.invalidateQueries(workspaceKey);
        query.invalidateQueries(`cardHistory-${kanbanCard!.id}`);
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  const moveCardMutation = useMutation(
    async ({ affectedCards, updatedColumnCards }: MoveCardMutationParams) => {
      query.setQueryData(workspaceKey, updatedColumnCards);
      await updateCardsAffectedByMove(affectedCards, httpConnection);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(workspaceKey);
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  const deleteCardMutation = useMutation(
    async ({ cardId }: DeleteCardParams) => {
      await deleteCard.execute({
        cardId
      });
    },
    {
      onSuccess: () => {
        handleShowUpdateModal();
        query.invalidateQueries(workspaceKey);
      },

      onError: (error: Error) => {
        toast.error(error.message);
      }
    }
  );

  return {
    methods,
    workspaceColumns,
    createColumnMutation,
    renameColumnMutation,
    deleteColumnMutation,
    updateColumnBackgroundColorMutation,
    createCardMutation,
    moveCardMutation,
    updateCardMutation,
    deleteCardMutation
  };
}
