import { useCallback, useState } from 'react';

type CollapsableCardType = {
  id: string;
  header: string | JSX.Element;
  children: React.ReactNode;
  onCollapseChange?: (collapsed: boolean) => void;
  startWithClosedPanel?: boolean;
};

export default function CollapsableCard({ id, header, onCollapseChange, children, startWithClosedPanel }: CollapsableCardType) {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
    if (onCollapseChange) onCollapseChange(!collapsed);
  }, [collapsed, onCollapseChange]);

  return (
    <div className='card shadow mb-4'>
      <a href={`#${id}`} className='d-block card-header py-3' data-toggle='collapse' role='button' onClick={handleCollapse}>
        <h5 className='m-0 font-weight-bold text-primary'>{header}</h5>
      </a>
      <div className={`collapse ${!startWithClosedPanel ? 'show' : ''}`} id={id}>
        <div className='card-body'>{children}</div>
      </div>
    </div>
  );
}
