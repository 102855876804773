import { type AxiosInstance } from 'axios';
import { type SublistType } from '../../../../../types/SublistTypes';

type DuplicateSublistParams = {
  httpConnection: AxiosInstance;
  projectId: number;
  sublistId: number;
}

export default async function ({
  httpConnection,
  projectId,
  sublistId
}: DuplicateSublistParams): Promise<SublistType> {
  return await new Promise<SublistType>((resolve, reject) => {
    const postData = {
      project_id: projectId,
      sublist_id: sublistId
    };

    httpConnection
      .post<SublistType>('list/clothing-projects/sublist/duplicate', postData)
      .then(({ data }) => {
        resolve(data);
      });
  });
}
