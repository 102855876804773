import { type AxiosInstance } from 'axios';
import { type SublistBatchToken } from '../../../../../types/SublistBatchToken';

type GenerateSublistlinksBatchResponse = {
  data: SublistBatchToken[];
}

export const generateSublistlinksBatch = async (projectId: number, httpConnection: AxiosInstance): Promise<SublistBatchToken[]> => {
  return await new Promise((resolve, reject) => {
    httpConnection.get<GenerateSublistlinksBatchResponse>(`list/clothing-projects/sublists/generate-batch-token/${projectId}`)
      .then((response) => {
        const { data } = response.data;
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
