import styled from 'styled-components';

export const AnimatedText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #efefef;
  background-color: #000;
  text-shadow: 5px 2px 5px #64439e9e;
  font-size: 3.8em;
  font-weight: bold;

  background: linear-gradient(45deg, #B429F9, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
