import React from 'react';

export type BasicTextInputProps = {
  value: string;
  id?: string;
  smallText?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  tabIndex?: number;
  autofocus?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  testId?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export default function BasicTextInput({ value, onChange, onKeyDown, handleBlur, smallText, autofocus, disabled }: BasicTextInputProps) {
  return (
    <input
      className='form-control form-control-sm text-center'
      style={smallText ? { fontSize: 12 } : {}}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      autoFocus={autofocus}
      onKeyDown={onKeyDown}
    />
  );
}
