import { type AxiosInstance } from 'axios';
import { type ProjectInvoiceData } from '../../../../types/ProjectInvoiceData';

type GetInvoiceDataParams = {
  httpConnection: AxiosInstance;
  projectUUID: string;
}

export default async function ({ httpConnection, projectUUID }: GetInvoiceDataParams): Promise<ProjectInvoiceData> {
  return await new Promise<ProjectInvoiceData>((resolve, reject) => {
    httpConnection.get<ProjectInvoiceData>(`list/clothing-projects/order/invoice/${projectUUID}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
