import { type ComboBoxDataType } from '../../../../../../../../../types/ComboBoxDataType';
import { type ClothingModelType } from '../../../../../../../../../types/ClothingModelType';

type GenerateInitialOrderCothingReplacementStateParams = {
  clothingReplacementOptionsComboboxOptions: ComboBoxDataType[];
  importedModels: ClothingModelType[];
  orderId: number;
}

export default function({ clothingReplacementOptionsComboboxOptions, importedModels, orderId }: GenerateInitialOrderCothingReplacementStateParams) {
  return ({
    clothing_replacement_option_id: clothingReplacementOptionsComboboxOptions.length > 0 ? parseInt(clothingReplacementOptionsComboboxOptions[0].value as string) : -1,
    model_id: importedModels.length > 0 ? parseInt(importedModels[0].id.toString()) : -1,
    order_id: orderId,
    notes: ''
  });
}
