import React from 'react';
import { useAppTranslation } from '../../../contexts/TranslationContext';

export default function LanguagePicker() {
  const { changeLanguage } = useAppTranslation();

  return (
    <li className='nav-item dropdown no-arrow mx-1'>
      <a
        className='nav-link dropdown-toggle'
        href='#'
        id='dropdown-languages'
        role='button'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <i className='fas fa-language fa-fw'></i>
      </a>
      <div className='dropdown-menu dropdown-menu-right shadow animated--grow-in'>
        <button
          className='dropdown-item'
          onClick={() => {
            changeLanguage('pt');
          }}
        >
          <i className='fas fa-globe fa-sm fa-fw mr-2 text-gray-400' />
          Português
        </button>
        <button
          className='dropdown-item'
          onClick={() => {
            changeLanguage('en');
          }}
        >
          <i className='fas fa-globe fa-sm fa-fw mr-2 text-gray-400' />
          English
        </button>
        <button
          className='dropdown-item'
          onClick={() => {
            changeLanguage('es');
          }}
        >
          <i className='fas fa-globe fa-sm fa-fw mr-2 text-gray-400' />
          Spañol
        </button>
      </div>
    </li>
  );
}
