import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function RequireAuth() {
  const { signed } = useAuth();
  const queryClient = new QueryClient();

  return signed ? (
    <QueryClientProvider client={queryClient}>
      <Outlet />
    </QueryClientProvider>
  ) : (
    <Navigate to={'login'} />
  );
}
