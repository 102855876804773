import React, { useCallback, useMemo, useState } from 'react';

import { type ClothingProductionData, type Order } from '../../../../../../../../types/OrderTypes';
import {
  type ListClothingProjectOrderReplacementType
} from '../../../../../../../../types/ClothingReplacementOption/ListClothingProjectOrderReplacementType';
import Checkbox from '../../../../../../../../components/Forms/Checkbox';
import changeOrderClothingReplacementCompletionStatus
  from './api/changeOrderClothingReplacementCompletionStatus';
import { getServerErrorMessageFromResponse } from '../../../../../../../../utils/helper';
import { toast } from 'react-hot-toast-promise';
import { useHttpRequest } from '../../../../../../../../contexts/HttpRequestContext';
import { type OrderClothingReplacentsManagerFilterType } from '../../index';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import { type ClothingGenderType, type ClothingModelType } from '../../../../../../../../types/ClothingModelType';

type OrderClothingReplacementRowType = {
  orderIndex: number;
  sublistIndex: number;
  order: Order;
  onUpdateOrder: (updatedOrder: Order) => void;
  checkboxesDisabled: boolean;
  setCheckboxesDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  filterBy: OrderClothingReplacentsManagerFilterType;
  importedModels: ClothingModelType[];
}

type GetModelSizeToReplaceParams = {
  clothesInOrder: ClothingProductionData[];
  clothingReplacementItem: ListClothingProjectOrderReplacementType;
  orderGender: keyof ClothingGenderType;
}

export default function OrderClothingReplacementRow({
  order,
  sublistIndex,
  orderIndex,
  onUpdateOrder,
  checkboxesDisabled,
  setCheckboxesDisabled,
  filterBy,
  importedModels
}: OrderClothingReplacementRowType) {
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const [replacementIdUpdating, setReplacementIdUpdating] = useState<number>(-1);

  const orderClothingReplacementsFlatArray = useMemo(() => {
    return order.clothing_replacements
      .flatMap(replacementItem => replacementItem)
      .filter(replacementItem => {
        if (filterBy === 'completed') return replacementItem.completed;
        if (filterBy === 'pending') return !replacementItem.completed;
        return true; // show everything
      });
  }, [filterBy, order.clothing_replacements]);

  const handleChangeOrderClothingReplacementStatus = useCallback(async (checked: boolean, replacementItem: ListClothingProjectOrderReplacementType) => {
    try {
      setCheckboxesDisabled(true);
      setReplacementIdUpdating(replacementItem.id);

      const updatedOrderClothingReplacements = await changeOrderClothingReplacementCompletionStatus({
        httpConnection,
        orderReplacementStatusId: replacementItem.id,
        completed: checked
      });

      onUpdateOrder({ ...order, clothing_replacements: updatedOrderClothingReplacements });
    } catch (err) {
      const message = getServerErrorMessageFromResponse(err);
      toast.error(message);
    } finally {
      setCheckboxesDisabled(false);
      setReplacementIdUpdating(-1);
    }
  }, [httpConnection, onUpdateOrder, order, setCheckboxesDisabled]);

  const renderCheckBoxForClothingPieceReplacement = useCallback((replacementItem: ListClothingProjectOrderReplacementType) => (
    <Checkbox
      disabled={checkboxesDisabled}
      isLoading={replacementIdUpdating === replacementItem.id}
      id={`checkbox-order-clothing-replacement-row-${sublistIndex}-${orderIndex}`}
      name={`checkbox-order-clothing-replacement-row-${sublistIndex}-${orderIndex}`}
      label={`${replacementItem.model.name} - ${replacementItem.clothing_piece.name}`}
      checked={replacementItem.completed}
      handleChange={async (checked) => {
        setReplacementIdUpdating(replacementItem.id);
        await handleChangeOrderClothingReplacementStatus(checked, replacementItem);
        setReplacementIdUpdating(-1);
      }}
    />

  ), [checkboxesDisabled, handleChangeOrderClothingReplacementStatus, orderIndex, replacementIdUpdating, sublistIndex]);

  const getModelSizeToReplace = useCallback(({ clothingReplacementItem, clothesInOrder, orderGender }: GetModelSizeToReplaceParams) => {
    const targetModelDataInClothes = clothesInOrder.find(clotheData => clotheData.modelId === clothingReplacementItem.model_id);

    if (!targetModelDataInClothes) return Translate('status.undefined');

    const model = importedModels.find(currentModel => currentModel.id === targetModelDataInClothes.modelId);

    if (!model) return Translate('status.undefined');

    const pricesForGender = model.informations[orderGender];

    const sizeInformations = pricesForGender[targetModelDataInClothes.sizeIndex];

    if (!sizeInformations) return Translate('status.undefined');

    return sizeInformations.size;
  }, [Translate, importedModels]);

  return (
    <React.Fragment key={orderIndex}>
      {
        orderClothingReplacementsFlatArray.length >= 1 && (
          <React.Fragment>
            <tr>
              <td rowSpan={orderClothingReplacementsFlatArray.length} className={'align-middle'}>{order.name}</td>
              <td rowSpan={orderClothingReplacementsFlatArray.length} className={'text-center align-middle'}>{order.number}</td>
              <td rowSpan={orderClothingReplacementsFlatArray.length} className={'align-middle text-center'}>{order.nickname}</td>
              <td className={'text-center'}>
                {
                  getModelSizeToReplace({
                    clothesInOrder: order.clothes,
                    orderGender: order.gender,
                    clothingReplacementItem: orderClothingReplacementsFlatArray[0]
                  })
                }
              </td>
              <td className={'text-center'}>{orderClothingReplacementsFlatArray[0].user.name}</td>
              <td className={'align-middle'}>{orderClothingReplacementsFlatArray[0].notes}</td>
              <td>{renderCheckBoxForClothingPieceReplacement(orderClothingReplacementsFlatArray[0])}
              </td>
            </tr>

            {orderClothingReplacementsFlatArray.slice(1).map((replacementItem, replacementItemIndex) => (
              <tr key={replacementItemIndex}>
                <td className={'text-center'}>
                  {
                    getModelSizeToReplace({
                      clothesInOrder: order.clothes,
                      orderGender: order.gender,
                      clothingReplacementItem: replacementItem
                    })
                  }
                </td>
                <td className={'align-middle text-center'}>{replacementItem.user.name}</td>
                <td className={'align-middle'}>{replacementItem.notes}</td>
                <td>{renderCheckBoxForClothingPieceReplacement(replacementItem)}</td>
              </tr>
            ))}
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
}
