import { useCallback } from 'react';
import { toast } from 'react-hot-toast-promise';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import TextAreaInput from '../../Forms/TextAreaInput';
import SwitchInput from '../../Forms/SwitchInput';
import { useSettings } from '../../../contexts/SettingsContext';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { useUser } from '../../../contexts/UserContext';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

type ScreenshotSettingsModalType = Pick<ModalBaseType, 'handleClose' | 'visible'> & {
  handleConfirm: () => void;
};

export default function ScreenshotSettingsModal({ visible, handleClose, handleConfirm }: ScreenshotSettingsModalType) {
  const { preferences, setPreferences, savePreferences } = useSettings();
  const { Translate } = useAppTranslation();
  const { isCompanyAccount } = useUser();

  const finishAndClose = useCallback(() => {
    handleConfirm();
    handleClose();
  }, [handleClose, handleConfirm]);

  const handleBeforeConfirm = useCallback(() => {
    if (!isCompanyAccount) {
      handleConfirm();
      return;
    }

    toast.promise(savePreferences(), {
      loading: Translate('progress.saving-wait'),
      success: () => {
        return Translate('toast.preferences-saved');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        finishAndClose();
      }
    });
  }, [isCompanyAccount, savePreferences, Translate, handleConfirm, finishAndClose]);

  return (
    <ModalBase
      title={Translate('labels.screenshot')}
      message={Translate('description.screenshot-description')}
      style='primary'
      visible={visible}
      handleClose={handleClose}
      handleConfirm={handleBeforeConfirm}
    >
      <>
        <div className='row'>
          <div className='col'>
            <SwitchInput
              id='screenshot-hide-project-prices'
              label={Translate('actions.hide-prices')}
              checked={preferences.screenshot_hide_prices}
              handleChange={checked => {
                setPreferences({ ...preferences, screenshot_hide_prices: checked });
              }}
            />

            <SwitchInput
              id='screenshot-show-gender-colors'
              label={Translate('actions.show-gender-colors')}
              checked={preferences.screenshot_show_gender_colors}
              handleChange={checked => {
                setPreferences({ ...preferences, screenshot_show_gender_colors: checked });
              }}
            />

            <section className='mt-3'>
              <TextAreaInput
                maxLength={150}
                id='printscreen-description'
                label={Translate('description.screenshot-annotations')}
                value={preferences.screenshot_description ?? ''}
                handleChange={({ target }) => {
                  setPreferences({ ...preferences, screenshot_description: target.value });
                }}
              />
            </section>
          </div>
        </div>
      </>
    </ModalBase>
  );
}
