import { type TFunction } from 'i18next';
import { type ProjectType } from '../../../../../../types/ProjectType';

type EditedBySectionType = Pick<ProjectType, 'users_changes_history'> & {
  Translate: TFunction;
}

export default function EditedBySection({ users_changes_history, Translate }: EditedBySectionType) {
  if (users_changes_history.length === 0) {
    return (
      <span>{Translate('status.no-editings')}</span>
    );
  }

  return (
    <span>{users_changes_history[0].user.name}</span>
  );
};
