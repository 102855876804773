import { useFormContext } from 'react-hook-form';
import {
  BadgeColumnPosition,
  CardSkeleton,
  CardSkeletonAdd,
  Column,
  Container,
  LabelProcessInput,
  WrapperColumn
} from './style';
import { useCallback } from 'react';
import { v4 as uniqueId } from 'uuid';

export function WorkingProcess() {
  const { watch, register, setValue, getValues } = useFormContext();

  const workingProcess = (watch('workingProcess') ?? []) as Array<{
    id: number;
    title: string;
    daily_amount: number;
    position: number;
  }>;

  const addWorkingProcess = useCallback(() => {
    setValue('workingProcess', [
      ...(getValues('workingProcess') ?? []),
      {
        id: uniqueId(),
        title: '',
        daily_amount: 0,
        position: workingProcess.length + 1
      }
    ]);
  }, [getValues, setValue, workingProcess.length]);

  return (
    <Container>
      <WrapperColumn>
        {workingProcess.map((working, index, workingArray) => (
          <Column key={working.id}>
            {index > 0 && (
              <BadgeColumnPosition
                type='button'
                onClick={() => {
                  setValue(
                    'workingProcess',
                    workingArray.filter(workingFilter => workingFilter.id !== working.id)
                  );
                }}
              >
                <i className='fas fa-trash' />
              </BadgeColumnPosition>
            )}

            <div>
              <LabelProcessInput htmlFor='working_name'>Nome do processo</LabelProcessInput>
              <input
                type='text'
                placeholder='Nome do processo'
                className='form-control'
                {...register(`workingProcess.${index}.title`)}
              />
            </div>

            <div>
              <LabelProcessInput htmlFor='working_day'>Qtd diária</LabelProcessInput>
              <input
                type='number'
                placeholder='Quantidade diária'
                className='form-control'
                min={0}
                {...register(`workingProcess.${index}.daily_amount`)}
              />
            </div>

            <CardSkeleton />
            <CardSkeleton />
          </Column>
        ))}
      </WrapperColumn>

      <Column>
        <CardSkeletonAdd onClick={addWorkingProcess}>
          <i className='fas fa-plus' />
          <span>Adicionar processo</span>
        </CardSkeletonAdd>

        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </Column>
    </Container>
  );
}
