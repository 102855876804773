import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div`
  background-color: var(--white);
  border: 1px solid var(--light-v2);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-10%);
    border: 6px solid transparent;
    border-top-color: var(--dark);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    border-color: var(--primary);

    &::after {
      border-top-color: var(--primary);
    }
  }
`;

type WrapperContainerProps = {
  openModal: boolean;
};

export const WrapperContainer = styled.div<WrapperContainerProps>`
  display: ${({ openModal }: { openModal: boolean }) => (openModal ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const WrapperOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px 20px;

  &:hover {
    opacity: 0.75;
  }
`;

type OptionProps = {
  active?: boolean;
};

export const Option = styled.div<OptionProps>`
  display: flex;
  border-radius: 20px;
  border: 1px solid var(--light-v2);
  height: 18px;
  width: 18px;
  position: relative;

  &::after {
    content: '';
    display: ${({ active }: { active?: boolean }) => (active ? 'block' : 'none')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--primary);
    height: 10px;
    width: 10px;
    transition: all 0.3s ease-in-out;
  }
`;

export const Label = styled.span`
  white-space: nowrap;
`;
