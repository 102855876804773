import styled from 'styled-components';

export const Container = styled.div`
  margin: 2rem 0 1rem;
  display: flex;
  align-items: center;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.h3`
  margin: 0;
  font-size: 22px;
  font-weight: 600;
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 14px;
  color: var(--gray);
`;
