import React, { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';

import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type ListContentParsedResultData } from '../../../../../../types/ListContentParsedResultData';
import { type SublistType } from '../../../../../../types/SublistTypes';

import BasicCard from '../../../../../../components/Cards/BasicCard';
import TextAreaInput from '../../../../../../components/Forms/TextAreaInput';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import { extractSelectionIndexNumber, processParsedContent } from '../../services/orderBatchService';
import { generateSublistOptions } from '../../services/orderFormService';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { toast } from 'react-hot-toast-promise';
import { addOrders } from '../../../services/orderService';
import { findFirstShirtIndexInModels } from '../../services/modelService';
import { useSettings } from '../../../../../../contexts/SettingsContext';
import GenderPrefix from '../../../OrderSettings/GenderPrefix';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import ListContentTableView from '../../components/ListContentTableView';
import { getServerErrorMessageFromResponse, isDev } from '../../../../../../utils/helper';
import { addOrdersToSublist } from '../../../api/addOrders';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';

type PastedListEditorType = {
  projectId: number;
  sublists: SublistType[];
  onUpdateSublists: (updatedSublists: SublistType[]) => void;
  importedModels: ClothingModelType[];
};

export default function PastedListEditor({
  projectId,
  sublists,
  onUpdateSublists,
  importedModels
}: PastedListEditorType) {
  const [readyToEdit, setReadyToEdit] = useState<boolean>(false);
  const [rawListPasted, setRawListPasted] = useState('');
  const [listContentParsingErrors, setListContentParsingErrors] = useState<string[][]>([]);
  const [listContentParsed, setListContentParsed] = useState<ListContentParsedResultData[]>([]);
  const [selectedClothesToGenerate, setSelectedClothesToGenerate] = useState<ClothingModelType[]>([]);
  const [targetSublistIndex, setTargetSublistIndex] = useState(0);
  const [sortingType, setSortingType] = useState<string>('');

  const { preferences } = useSettings();
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const handleCheckClothesToGenerate = useCallback(
    (targetClothe: ClothingModelType) => {
      if (!selectedClothesToGenerate.includes(targetClothe)) {
        setSelectedClothesToGenerate([...selectedClothesToGenerate, targetClothe]);
        return;
      }

      const filtered = selectedClothesToGenerate.filter(currentClothe => currentClothe.id !== targetClothe.id);
      setSelectedClothesToGenerate(filtered);
    },
    [selectedClothesToGenerate]
  );

  const cleanUp = useCallback(() => {
    setRawListPasted('');
    setReadyToEdit(false);
    setListContentParsed([]);
    setListContentParsingErrors([]);
  }, []);

  const handleAddBatchOrders = useCallback(() => {
    if (!isDev()) {
      ReactGA.event({
        category: 'create',
        action: 'create-list-from-whatsapp-text'
      });
    }

    const targetSublist: SublistType = sublists[targetSublistIndex];

    if (!targetSublist.id) return;

    const { hasError, errorsFound, generatedOrders } = processParsedContent({
      sublist_id: targetSublist.id,
      listContentParsed,
      selectedClothesToGenerate,
      importedModels,
      preferences,
      Translate
    });

    setListContentParsingErrors(errorsFound);

    if (hasError) {
      toast.error(Translate('error.add-batch-orders-contains-error'));
      return;
    }

    const clothingIndexToSortBy = findFirstShirtIndexInModels({ models: importedModels });

    const sort = sortingType
      ? { type: sortingType, field: `clothe-${clothingIndexToSortBy}` }
      : undefined;

    const addOrdersToSublistPromise = addOrdersToSublist({
      httpConnection,
      orders: generatedOrders,
      sublistId: targetSublist.id,
      projectId,
      sort
    });

    toast.promise(addOrdersToSublistPromise, {
      loading: Translate('progress.loading'),
      success: (orders) => {
        const updatedSublists = addOrders(sublists, targetSublist.id!, orders);

        onUpdateSublists(updatedSublists);
        cleanUp();

        return Translate('toast.list-generated');
      },
      finally: () => {},
      error: getServerErrorMessageFromResponse
    });
  }, [sublists, targetSublistIndex, listContentParsed, selectedClothesToGenerate, importedModels, preferences, Translate, httpConnection, projectId, sortingType, onUpdateSublists, cleanUp]);

  return (
    <BasicCard>
      <GenderPrefix />
      <div className='row'>
        <div className='col-12'>
          {!readyToEdit && (
            <React.Fragment>
              <TextAreaInput
                id='raw-list'
                label={Translate('labels.data-input')}
                value={rawListPasted}
                placeholder=''
                rows={10}
                tabIndex={1}
                maxLength={30000}
                handleChange={({ target }) => {
                  setRawListPasted(target.value);
                }}
              />

              <SplitButton
                color='primary'
                icon={IconsCatalog.forms}
                title={Translate('actions.prepare-inserted-data')}
                size='sm'
                handleClick={() => {
                  setReadyToEdit(true);
                }}
              />
            </React.Fragment>
          )}

          {
            readyToEdit && (
              <React.Fragment>
                <ListContentTableView
                  data={rawListPasted.trim()}
                  importedModels={importedModels}
                  listContentParsingErrors={listContentParsingErrors}
                  handleChange={(parsedList) => {
                    setListContentParsed(parsedList);
                  }}
                />

                <SplitButton
                  color='danger'
                  icon={IconsCatalog.trash}
                  title={Translate('actions.discard-data')}
                  size='sm'
                  handleClick={cleanUp}
                />
              </React.Fragment>
            )
          }

        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-auto'>
          <label>{Translate('labels.which-clothes')}</label>

          {importedModels.map((clothe, index) => {
            const selectionIndex = extractSelectionIndexNumber(selectedClothesToGenerate, clothe);
            return (
              <Checkbox
                key={index}
                tabIndex={2}
                id={`imported-model-${index}`}
                name='selected-clothes'
                checked={selectedClothesToGenerate.includes(clothe)}
                label={`${selectionIndex} ${clothe.name}`}
                handleChange={() => {
                  handleCheckClothesToGenerate(clothe);
                }}
              />
            );
          })}
        </div>

        <div className='col-auto'>
          <ComboBox
            tabIndex={3}
            id='target-sublist'
            value={targetSublistIndex.toString()}
            header={Translate('labels.sublists')}
            handleChange={({ target }) => {
              setTargetSublistIndex(parseInt(target.value));
            }}
            data={generateSublistOptions(sublists)}
          />
        </div>

        <div className='col-auto'>
          <ComboBox
            tabIndex={4}
            id='sorting-type'
            value={sortingType}
            header={Translate('labels.sorting')}
            handleChange={({ target }) => {
              setSortingType(target.value.trim());
            }}
            data={[
              {
                label: Translate('labels.sorting-none'),
                value: ''
              },
              {
                label: Translate('labels.sorting-ltg'),
                value: 'asc'
              },
              {
                label: Translate('labels.sorting-gtl'),
                value: 'desc'
              }
            ]}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <SplitButton
            color='success'
            icon={IconsCatalog.play}
            title={Translate('actions.process')}
            tabIndex={5}
            disabled={selectedClothesToGenerate.length === 0 || listContentParsed.length === 0}
            handleClick={handleAddBatchOrders}
          />
        </div>
      </div>
    </BasicCard>
  );
}
