import styled from 'styled-components';

export const TimeInputContainer = styled.input`
  &::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
  }

  text-align: center;
`;
