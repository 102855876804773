import { type ProjectStatusType } from '../../../../../../types/ProjectStatusType';
import { type AxiosInstance } from 'axios';

type AddProjectStatusParams = {
  httpConnection: AxiosInstance;
  statusName: string;
  statusColor: string;
}

export default async function ({ httpConnection, statusName, statusColor }: AddProjectStatusParams): Promise<ProjectStatusType> {
  return await new Promise<ProjectStatusType>((resolve, reject) => {
    httpConnection.post<ProjectStatusType>('list/clothing-projects/status', { name: statusName, color: statusColor })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
