import React, { useCallback, useRef, useState } from 'react';
import TextInput from '../../../../../components/Forms/TextInput';
import SplitButton from '../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../contexts/TranslationContext';
import { useClothingReplacementOptions } from '../../../../../contexts/ClothingReplacementOptionsContext';
import { displayAsCurrency, getServerErrorMessageFromResponse } from '../../../../../utils/helper';
import { type UserPreferences } from '../../../../../types/UserPreferences';
import { type ClothingReplacementOptionType } from '../../../../../types/ClothingReplacementOption';
import CurrencyInput from '../../../../../components/Forms/CurrencyInput';
import addClothingReplacementOption from './api/addClothingReplacementOption';
import { toast } from 'react-hot-toast-promise';
import { useHttpRequest } from '../../../../../contexts/HttpRequestContext';
import editClothingReplacementOption from './api/editClothingReplacementOption';
import updateCachedReplacementOption from './services/updateCachedReplacementOption';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationModal';
import deleteClothingReplacementOption from './api/deleteClothingReplacementOption';

type ClothingReplacementOptionsManagerType = {
  preferences: UserPreferences;
}

export default function ClothingReplacementOptionsManager({ preferences }: ClothingReplacementOptionsManagerType) {
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();
  const { clothingReplacementOptions, setClothingReplacementOptions } = useClothingReplacementOptions();

  const replacementOptionInputRef = useRef<HTMLInputElement | null>(null);

  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [replacementOption, setReplacementOption] = useState<ClothingReplacementOptionType | null>(null);
  const [replacementOptionToDelete, setReplacementOptionToDelete] = useState<ClothingReplacementOptionType | null>(null);

  const focusReplacementOptionInput = useCallback(() => {
    setTimeout(() => {
      replacementOptionInputRef?.current?.focus();
    }, 150);
  }, []);

  const handleAdd = useCallback(() => {
    try {
      if (!replacementOption) return;
      const addClothingReplacementOptionPromise = addClothingReplacementOption({ httpConnection, replacementOption });

      setIsRequesting(true);

      toast.promise(addClothingReplacementOptionPromise, {
        loading: Translate('progress.loading'),
        success: (addedClothingReplacementOption) => {
          setClothingReplacementOptions([...clothingReplacementOptions, addedClothingReplacementOption]);
          setReplacementOption(null);
          focusReplacementOptionInput();
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  }, [Translate, clothingReplacementOptions, focusReplacementOptionInput, httpConnection, replacementOption, setClothingReplacementOptions]);

  const handleEdit = useCallback(() => {
    try {
      if (!replacementOption) return;
      const addClothingReplacementOptionPromise = editClothingReplacementOption({ httpConnection, replacementOption });

      setIsRequesting(true);

      toast.promise(addClothingReplacementOptionPromise, {
        loading: Translate('progress.loading'),
        success: () => {
          const updatedReplacementOptions = updateCachedReplacementOption({ updatedReplacementOption: replacementOption, clothingReplacementOptions });
          setClothingReplacementOptions(updatedReplacementOptions);
          setReplacementOption(null);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  }, [Translate, clothingReplacementOptions, httpConnection, replacementOption, setClothingReplacementOptions]);

  const handleDeleteReplacementOption = useCallback(() => {
    if (!replacementOptionToDelete?.id) return;

    const deleteClothingReplacementOptionPromise = deleteClothingReplacementOption({ httpConnection, replacementOptionId: replacementOptionToDelete.id });

    setIsRequesting(true);

    toast.promise(deleteClothingReplacementOptionPromise, {
      loading: Translate('progress.loading'),
      success: () => {
        const filteredReplacementOptions = clothingReplacementOptions.filter(option => option.id !== replacementOptionToDelete.id);
        setClothingReplacementOptions(filteredReplacementOptions);
        setReplacementOptionToDelete(null);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, clothingReplacementOptions, httpConnection, replacementOptionToDelete?.id, setClothingReplacementOptions]);

  const handleSubmit = useCallback((event?: React.FormEvent) => {
    event?.preventDefault();

    if (replacementOption?.id) handleEdit();
    else handleAdd();
  }, [handleAdd, handleEdit, replacementOption?.id]);

  return (
    <React.Fragment>
      {
        !!replacementOptionToDelete && (
          <ConfirmationModal
            visible
            disableButtons={isRequesting}
            title={Translate('actions.delete')}
            message={Translate('modal.confirm-delete-replacement-option')}
            style={'danger'}
            handleClose={() => {
              setReplacementOptionToDelete(null);
            }}
            handleConfirm={handleDeleteReplacementOption}
          />
        )
      }

      <div className="row mt-2">
        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
          <form onSubmit={handleSubmit}>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-4">
                <TextInput
                  inputRef={replacementOptionInputRef}
                  id="replacement-option-name"
                  disabled={isRequesting}
                  label={replacementOption?.id ? Translate('actions.edit') : Translate('actions.add')}
                  value={replacementOption?.name ?? ''}
                  onChange={({ target }) => {
                    setReplacementOption({ ...replacementOption!, name: target.value });
                  }}
                />
              </div>

              <div className='col-6 col-md-3 col-lg-3 pl-md-0'>
                <CurrencyInput
                  id={'replacement-option-price'}
                  label={Translate('labels.price')}
                  value={replacementOption?.price ?? 0}
                  onChange={(value) => {
                    setReplacementOption({ ...replacementOption!, price: value });
                  }}
                />
              </div>

              <div className='col-6 col-md-4 col-lg-3 pl-md-0'>
                <SplitButton
                  disabled={isRequesting || !replacementOption?.name}
                  simulateLabelMarginTop
                  title={replacementOption?.id ? Translate('actions.edit') : Translate('actions.add')}
                  icon={replacementOption?.id ? IconsCatalog.check : IconsCatalog.plus}
                  color={replacementOption?.id ? 'success' : 'primary'}
                  handleClick={handleSubmit} />

                {
                  replacementOption?.id && (
                    <SplitButton
                      disabled={isRequesting}
                      marginLeft
                      simulateLabelMarginTop
                      icon={IconsCatalog.times}
                      color="danger"
                      handleClick={() => {
                        setReplacementOption(null);
                      }} />
                  )
                }
              </div>
            </div>
          </form>

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-6">
          <table className="table table-sm table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th>{Translate('labels.title')}</th>
              <th className={'text-center'}>{Translate('labels.price')}</th>
              <th colSpan={2} className="text-center">-</th>
            </tr>
            </thead>

            <tbody>
            {
              clothingReplacementOptions.map((replacementOption, index) => (
                <tr key={index}>
                  <td className="align-middle">
                    {replacementOption.name}
                  </td>

                  <td className="align-middle text-center">
                    {displayAsCurrency(replacementOption.price, preferences.currency)}
                  </td>

                  <td className="table-column-fit">
                    <SplitButton
                      size="sm"
                      color="primary"
                      icon={IconsCatalog.pen}
                      handleClick={() => {
                        setReplacementOption(replacementOption);
                      }}
                    />
                  </td>

                  <td className="table-column-fit">
                    <SplitButton
                      size="sm"
                      color="danger"
                      icon={IconsCatalog.trash}
                      handleClick={() => {
                        setReplacementOptionToDelete(replacementOption);
                      }}
                    />
                  </td>
                </tr>
              ))
            }

            {
              clothingReplacementOptions.length === 0 && (
                <tr className={'text-center'}>
                  <td colSpan={999}>{Translate('status.nothing-to-show-for-now')}</td>
                </tr>
              )
            }
            </tbody>

          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
