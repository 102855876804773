import React, { useMemo } from 'react';

export type BasicCardProps = {
  title?: string;
  description?: string;
  children: React.ReactNode;
  useBlueHeader?: boolean;
  hideOnPrint?: boolean;
};

export default function BasicCard({ title, description, children, useBlueHeader, hideOnPrint }: BasicCardProps) {
  const blueHeaderBackgroundColor = useMemo(() => {
    return useBlueHeader ? 'bg-primary' : '';
  }, [useBlueHeader]);

  const blueHeaderTextColor = useMemo(() => {
    return useBlueHeader ? 'text-white-forced' : 'text-primary';
  }, [useBlueHeader]);

  const isHidden = useMemo(() => hideOnPrint ? 'd-print-none' : '', [hideOnPrint]);

  return (
    <div className={`card shadow mb-4 ${isHidden}`} data-testid='basic-card-container'>
      {!!title && (
        <div className={`card-header py-3 ${blueHeaderBackgroundColor}`}>
          <h5 className={`m-0 font-weight-bold ${blueHeaderTextColor}`} data-testid='basic-card-title'>
            {title}
          </h5>
        </div>
      )}

      <div className='card-body'>
        {!!description && (
          <React.Fragment>
            <div className='my-2'></div>
            <p data-testid='basic-card-description'>{description}</p>
          </React.Fragment>
        )}

        <div data-testid='basic-card-content'>{children}</div>
      </div>
    </div>
  );
}
