import { useQuery } from 'react-query';
import { format } from 'date-fns';
import {
  AddWorkspace,
  Container,
  WrapperContent,
  Row,
  WorkspaceLabel,
  WorkspaceName,
  WrapperName,
  WorkspaceCreate,
  WorkspaceCardsLength
} from './style';
import { getAllWorkspaces } from './services';
import { useUser } from '../../../../../../contexts/UserContext';
import { WorkspaceOptions } from './fragments/WorkspaceOptions';

export function Content() {
  const { user } = useUser();
  const workspaces = useQuery(
    `workspaces-${user?.id}`,
    async () => {
      return await getAllWorkspaces.execute();
    },
    {
      enabled: !!user,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false
    }
  );

  return (
    <Container>
      <AddWorkspace to='add'>
        <i className='fas fa-plus'></i>
        <WorkspaceLabel>Adicionar Workspace</WorkspaceLabel>
      </AddWorkspace>

      {workspaces?.data?.map(workspace => (
        <WrapperContent key={workspace.id} title={workspace.title}>
          <Row>
            <WrapperName to={workspace.slug}>
              <WorkspaceName>{workspace.title}</WorkspaceName>
            </WrapperName>

            <WorkspaceOptions slug={workspace.slug} />
          </Row>

          <Row>
            <WorkspaceCreate>{format(new Date(workspace.created_at), 'dd/MM/yyyy')}</WorkspaceCreate>
            <WorkspaceCardsLength>3 cards</WorkspaceCardsLength>
          </Row>
        </WrapperContent>
      ))}
    </Container>
  );
}
