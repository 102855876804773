import _ from 'lodash';
import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';
import FormData from 'form-data';

type FormDataEntries = Record<string, string | Blob> & {
  products?: Array<{
    id?: number;
    photo: FileList;
    title: string;
    description: string;
    meter: string;
    value: string;
    width: string;
    product_units: any;
  }>;
  factions?: Array<{ id: number; price: number; list_clothing_model_id?: number }>;
};

export class UpdateEntity {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: FormDataEntries, id: number): Promise<void> {
    const formData = new FormData();

    Object.keys(data).forEach(value => {
      if (value === 'photo' && typeof data[value] !== 'string') {
        const photoValue = data[value] as unknown as FileList;
        formData.append('photo', _.first(photoValue) as unknown as File);
        return;
      }

      if (
        _.isEmpty(data[value]) ||
        (value === 'photo' && typeof value === 'string') ||
        ['list_clothing_projects', 'factions'].includes(value)
      ) {
        return;
      }

      formData.append(value, data[value]);
    });

    if (data.products) {
      data.products.forEach((rawMaterial, index) => {
        Object.keys(rawMaterial).forEach(key => {
          const value = key as keyof typeof rawMaterial;

          if (value === 'photo' && typeof rawMaterial[value] !== 'string' && !_.isEmpty(rawMaterial[value])) {
            const photoValue = rawMaterial[value] as unknown as FileList;

            formData.append(`products[${index}][${value}]`, _.first(photoValue) as unknown as File);
            return;
          }

          if (value === 'product_units') {
            formData.append(`products[${index}][${value}]`, JSON.stringify(rawMaterial[value]));
            return;
          }

          formData.append(`products[${index}][${value}]`, rawMaterial[value]);
        });
      });
    }

    if (data.factions) {
      data?.factions
        ?.filter(faction => faction?.price !== 0)
        .forEach((model, index) => {
          if (!_.isEmpty(model)) {
            const factionId = model?.list_clothing_model_id ? model?.list_clothing_model_id : model?.id?.toString();

            formData.append(`factions[${index}][id]`, factionId);
            formData.append(`factions[${index}][price]`, model?.price?.toString());
          }
        });
    }

    await this.http.post(`/entity/${id}`, formData);
  }
}
