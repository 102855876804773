import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Droppable, type DroppableStateSnapshot } from '@hello-pangea/dnd';
import { CardBody, Container, ListCards } from './styles';
import { type KanbanColumn } from '../../../../../types/Kanban/KanbanColumn';
import Card from '../Card';
import { ColumnHeader } from './fragments/ColumnHeader';
import { CreateCardButton } from './fragments/CreateCardButton';
import { useKanbanView } from '../../hooks/useKanbanView';

type ListType = {
  column: KanbanColumn;
  columnIndex: number;
};

export default function Column({ column, columnIndex }: ListType) {
  const { updateColumnBackgroundColorMutation } = useKanbanView();
  const [color, setColor] = useState(column?.color ?? '#d2d2d21a');

  const getBackgroundColor = useCallback((snapshot: DroppableStateSnapshot): string => {
    if (snapshot.isDraggingOver) {
      return 'bg-success';
    }
    if (snapshot.draggingFromThisWith) {
      return 'bg-primary-v2';
    }
    return '';
  }, []);

  const handleSetColumnColor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | string) => {
      if (typeof event === 'string') {
        setColor(event);
        updateColumnBackgroundColorMutation.mutate({ column_id: column.id, color: event });
        return;
      }
      setColor(event.target.value);
    },
    [column.id, updateColumnBackgroundColorMutation]
  );

  return (
    <>
      <Droppable droppableId={column.id.toString()} key={columnIndex}>
        {(providedDrop, snapshot) => {
          return (
            <Container className='card' color={color} ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
              <ColumnHeader
                title={column.title}
                column={column}
                first={columnIndex !== 0}
                color={color}
                handleSetColumnColor={handleSetColumnColor}
              />

              <CreateCardButton column={column} />

              <CardBody className={`card-body p-2 ${getBackgroundColor(snapshot)}`}>
                <ListCards>
                  {_.sortBy(column?.cards, 'position_index').map((card, cardIndex) => (
                    <Card cardData={card} cardIndex={cardIndex} key={cardIndex} />
                  ))}

                  {providedDrop.placeholder}
                </ListCards>
              </CardBody>
            </Container>
          );
        }}
      </Droppable>
    </>
  );
}
