import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';

type DeleteCardParams = {
  cardId: number;
};

export class DeleteCard {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ cardId }: DeleteCardParams) {
    return await this.http.delete(`/kanban/card/${cardId}`);
  }
}
