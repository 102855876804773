import { type AxiosInstance } from 'axios';

type DeleteProjectStatusParams = {
  httpConnection: AxiosInstance;
  statusId: number;
}

export default async function ({ httpConnection, statusId }: DeleteProjectStatusParams): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    httpConnection.delete(`list/clothing-projects/status/${statusId}`)
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}
