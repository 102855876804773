import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

type WrapperEvalutateProps = {
  lastItem: boolean;
  active?: boolean;
};

export const WrapperEvalutate = styled.div<WrapperEvalutateProps>`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  position: relative;
  z-index: 5;

  &::after {
    content: '';
    display: ${({ lastItem }) => (lastItem ? 'none' : 'block')};
    width: 5px;
    height: 100%;
    background-color: ${({ active }) => (active ? 'var(--success)' : 'var(--primary)')};
    position: absolute;
    top: 20px;
    left: 48px;
    z-index: -1;
  }
`;

type EvaluateInfoProps = {
  active?: boolean;
};

export const EvaluateInfo = styled.div<EvaluateInfoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? 'var(--success)' : 'var(--primary)')};
  border-radius: 30px;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  font-size: 28px;
  color: var(--white);
`;

export const Label = styled.h1`
  font-size: 24px;
  padding: 0;
  margin: 0;
`;
