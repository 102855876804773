import { useCallback, useState } from 'react';

import * as Style from './style';
import { useMutation, useQueryClient } from 'react-query';
import { deleteWorkspace } from '../../services';
import { useUser } from '../../../../../../../../contexts/UserContext';
import { useNavigate } from 'react-router';

type WorkspaceOptionsProps = {
  slug: string;
};

export function WorkspaceOptions({ slug }: WorkspaceOptionsProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useUser();
  const workspaceKey = `workspaces-${user?.id}`;
  const deleteWorkspaceMutation = useMutation(
    async (data: string) => {
      await deleteWorkspace.execute(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceKey);
      }
    }
  );
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleOptions = useCallback(() => {
    setIsOptionsOpen(prev => !prev);
  }, []);

  return (
    <>
      {deleteWorkspaceMutation.isLoading ? (
        <Style.Wrapper>
          <Style.Label>Removendo workspace</Style.Label>
        </Style.Wrapper>
      ) : null}

      <Style.Container onClick={handleOptions}>
        <i className='fas fa-ellipsis-v' />

        {isOptionsOpen ? (
          <Style.Options>
            <Style.Option
              onClick={() => {
                navigate(`/pcp/workspaces/${slug}/edit`);
              }}
            >
              <Style.OptionIcon className='fas fa-edit' />
              <Style.OptionText>editar</Style.OptionText>
            </Style.Option>

            <Style.Option
              onClick={() => {
                deleteWorkspaceMutation.mutate(slug);
              }}
            >
              <Style.OptionIcon className='fas fa-trash' />
              <Style.OptionText>remover</Style.OptionText>
            </Style.Option>
          </Style.Options>
        ) : null}
      </Style.Container>
    </>
  );
}
