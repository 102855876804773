import FilePicker from '../../../../../../../../components/Forms/FilePicker';
import TextInput from '../../../../../../../../components/Forms/TextInput';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';
import SplitButton from '../../../../../../../../components/Buttons/SplitButton';
import { type FormEvent, useCallback, useState, useRef, useMemo } from 'react';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import { type FormAddPaymentReceiptData } from './types/FormAddPaymentReceiptData';
import { emptyPaymentReceiptData } from './services/formPaymentReceiptService';
import CurrencyInput from '../../../../../../../../components/Forms/CurrencyInput';
import { acceptedFileExtensions, acceptedMimeTypes } from '../..';

type FormAddPaymentReceiptType = {
  onSubmit: (paymentReceiptData: FormAddPaymentReceiptData) => void;
}

export default function FormAddPaymentReceipt({ onSubmit }: FormAddPaymentReceiptType) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<FormAddPaymentReceiptData>(emptyPaymentReceiptData);

  const { Translate } = useAppTranslation();

  const isValidFormData = useMemo((): boolean => {
    const { amount } = formData;
    return amount > 0;
  }, [formData]);

  const handleSubmit = useCallback(() => {
    if (!isValidFormData) return;
    onSubmit(formData);
    setFormData(emptyPaymentReceiptData);
  }, [formData, isValidFormData, onSubmit]);

  const handleSubmitEvent = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit();
  }, [handleSubmit]);

  return (
    <form onSubmit={handleSubmitEvent} ref={formRef}>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <FilePicker
            id='pick-payment-receipt-file'
            label={Translate('labels.receipt')}
            filename={formData.file?.name}
            acceptedFileExtensions={acceptedFileExtensions}
            acceptedMimeTypes={acceptedMimeTypes}
            onPick={(file) => {
              setFormData({ ...formData, file });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <CurrencyInput
            id='amount-received'
            label={Translate('labels.value')}
            value={formData.amount}
            onChange={(value) => {
              setFormData({ ...formData, amount: value });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <TextInput
            id='payment-receipt-description'
            label={Translate('labels.short-description')}
            value={formData.description}
            maxLength={50}
            helpText={`${50 - formData.description.length} ${Translate('labels.characters-left')}.`}
            onChange={({ target }) => {
              setFormData({ ...formData, description: target.value });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-6">
          <SplitButton
            simulateLabelMarginTop
            disabled={!isValidFormData}
            color='primary'
            icon={IconsCatalog.plus}
            title={Translate('actions.add')}
            handleClick={handleSubmit} />
        </div>
      </div>
    </form>
  );
};
