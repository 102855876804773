import { useManagerTask } from '../../useManagerTask';

import { Container } from './style';
import { Option, Select, TextButton, WrapperButtons, WrapperSelect } from '../../style';

import { type task } from '../..';

export function TasksLists() {
  const { form } = useManagerTask();

  return (
    <Container>
      {(form.watch('taskList') as task[])?.map((task, index: number) => {
        const taskList = form.getValues('taskList') as task[];

        const handleCheckTask = () => {
          form.setValue(
            'taskList',
            taskList.map((taskValue: task) => {
              if (taskValue.title === task.title) {
                return { ...taskValue, checked: !taskValue.checked };
              }

              return taskValue;
            })
          );
        };

        const handleEditTask = () => {
          form.setValue('edit_task', task);
          form.setValue('task_name', task.title);
        };

        const handleRemoveTask = () => {
          form.setValue(
            'taskList',
            taskList.filter(taskValue => taskValue.title !== task.title)
          );
        };

        return (
          <WrapperSelect key={index} active={task.checked}>
            <Select active={task.checked} onClick={handleCheckTask}>
              <i className='fa fa-check' />
            </Select>

            <Option onClick={handleCheckTask}>{task.title}</Option>

            <WrapperButtons>
              <TextButton type='button' onClick={handleEditTask}>
                <i className='fa fa-edit' />
              </TextButton>

              <TextButton type='button' onClick={handleRemoveTask}>
                <i className='fa fa-trash' />
              </TextButton>
            </WrapperButtons>
          </WrapperSelect>
        );
      })}
    </Container>
  );
}
