import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().required('Nome da workspace é obrigatório'),
  workingProcess: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      title: yup.string().required('Nome do processo é obrigatório'),
      daily_amount: yup.number().required('Quantidade diária é obrigatória'),
      position: yup.number()
    })
  )
});
