export const getFilenameFromUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url); // Create a URL object
    const pathname = parsedUrl.pathname; // Get the pathname from the URL
    return pathname.substring(pathname.lastIndexOf('/') + 1); // Extract the filename
  } catch (error) {
    console.log(error);
    return null; // Return null if the URL is invalid
  }
};
