import React, { useMemo } from 'react';
import { getTextColorBasedOnBackgroundColor, isHexColor } from '../../../helpers/colors';

type BadgeType = {
  color: string;
  content: string;
}

export default function Badge({ color, content }: BadgeType) {
  const customStyle = useMemo(() => {
    return isHexColor(color)
      ? ({ backgroundColor: color, color: getTextColorBasedOnBackgroundColor(color) })
      : ({});
  }, [color]);

  return (
    <i className={'badge'} style={customStyle}>
      {content}
    </i>
  );
}
