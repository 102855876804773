import React, { useCallback, useState } from 'react';
import BasicButton from '../../../../../../components/Buttons/BasicButton';
import Spinner from '../../../../../../components/Spinner';
import approveProjectBySeller, { type ProjectApprovalResponse } from '../../api/approveProjectBySeller';
import { toast } from 'react-hot-toast-promise';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';
import { type AxiosInstance } from 'axios';
import { type TFunction } from 'i18next';
import { type ProjectBasicType } from '../../../../../../types/ProjectBasicType';

export type OnApproveParams = {
  response: ProjectApprovalResponse;
  project: ProjectBasicType;
}

type SellerApproveProjectButtonType = {
  project: ProjectBasicType;
  httpConnection: AxiosInstance;
  Translate: TFunction;
  onApprove: (params: OnApproveParams) => void;
  marginLeft?: boolean;
}

export default function SellerApproveProjectButton({ onApprove, project, marginLeft, httpConnection, Translate }: SellerApproveProjectButtonType) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleApproveProjectAsSeller = useCallback(() => {
    const promise = approveProjectBySeller(httpConnection, { projectId: project.id! });

    setLoading(true);

    toast.promise(promise, {
      loading: Translate('progress.loading'),
      success: (response) => {
        onApprove({ project, response });
        return Translate('toast.done');
      },
      error: err => {
        return getServerErrorMessageFromResponse(err);
      },
      finally: () => {
        setLoading(false);
      }
    });
  }, [Translate, httpConnection, onApprove, project]);

  return (
    <React.Fragment>
      {!loading && (
        <BasicButton
          marginLeft={marginLeft}
          title={Translate('actions.approve-now')}
          color='primary'
          size='sm'
          handleClick={handleApproveProjectAsSeller}
        />
      )}

      {loading && (
        <div className={`d-flex justify-content-center ${marginLeft ? 'ml-2' : ''}`}>
          <Spinner small />
        </div>
      )}
    </React.Fragment>
  );
};
