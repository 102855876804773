import { type task } from '..';
import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';

export type CreateCheckGroupsParams = {
  checkGroupTitle: string;
  checkGroupCardItems: task[];
};

export class CreateCheckGroups {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ checkGroupTitle, checkGroupCardItems }: CreateCheckGroupsParams) {
    await this.http.post('/kanban/checkgroups', { checkGroupTitle, checkGroupCardItems });
  }
}
