import { Header } from '../components/Header';
import { OfficeHours } from './OfficeHours';
import { Title } from '../AddWorkspace/fragments/Title';
import { Holiday } from './Holiday';
import { FormContainer } from './style';
import { GlobalWorkspace } from '../ShowWorkspace/style';
import Tabs from '../../../../components/Tabs';

export function Settings() {
  return (
    <>
      <GlobalWorkspace />
      <Header
        title='Configurações'
        subtitle='Configure o horário de funcionamento e feriados do ano'
        defaultPath='/pcp/settings'
      />

      <FormContainer>
        <Tabs
          data={[
            {
              id: 'officeHours',
              label: 'Horários de Funcionamento',
              active: true,
              content: (
                <>
                  <Title title='Horários de Funcionamento' subtitle='Defina os horários de funcionamento da workspace' />
                  <OfficeHours />
                </>
              )
            },
            {
              id: 'holidays',
              label: 'Feriados',
              content: (
                <>
                  <Title title='Feriados' subtitle='Defina os feriados' />
                  <Holiday />
                </>
              )
            }
          ]}
        />
      </FormContainer>
    </>
  );
}
