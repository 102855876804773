import { type RawAxiosRequestHeaders, type AxiosInstance } from 'axios';
import { type SublistLayoutDetailsType } from '../../../../../../../../../types/SublistLayoutDetailsType';
import { convertFileToBlob } from '../../../../../../../../../utils/helper';

type UploadLayoutDetailsFileParams = {
  httpConnection: AxiosInstance;
  sublistId: number;
  file: File;
}

export default async function ({ httpConnection, sublistId, file }: UploadLayoutDetailsFileParams): Promise<SublistLayoutDetailsType> {
  const formData = new FormData();

  formData.append('file', convertFileToBlob(file), file.name);
  formData.append('sublist_id', sublistId.toString());

  const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;

  return await new Promise((resolve, reject) => {
    httpConnection.post('list/clothing-projects/sublist/layout-details', formData, {
      headers: {
        ...defaultHeaders,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
