import { type AxiosInstance } from 'axios';
import { type KanbanColumn } from '../../../../types/Kanban/KanbanColumn';

type DeleteColumnParams = Pick<KanbanColumn, 'id'>;

export const deleteColumn = async ({ id }: DeleteColumnParams, httpConnection: AxiosInstance): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    httpConnection.delete(`kanban/workspaces/columns/${id}`)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
