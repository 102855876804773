import type {
  OrderClothingReplacementNewRegistryType
} from '../../../../../../../../../types/ClothingReplacementOption/OrderClothingReplacementNewRegistryType';
import { type TFunction } from 'i18next';

export default function(orderClothingReplacement: OrderClothingReplacementNewRegistryType, Translate: TFunction) {
  const { clothing_replacement_option_id, model_id } = orderClothingReplacement;

  if (clothing_replacement_option_id === -1) throw new Error(Translate('error.clothe-to-replace-is-not-valid'));

  if (model_id === -1) throw new Error(Translate('error.model-is-not-valid'));
}
