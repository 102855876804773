import { type ProjectScheduleType } from '../../../../../../types/ProjectScheduleType';
import { displayFormattedDate } from '../../../../../../helpers/dateTime';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { type Locale } from 'date-fns';

export type RenderProjectsScheduleTableType = {
  projectsSchedule: ProjectScheduleType[];
  dateFnsLocale: Locale;
}

export default function RenderProjectsScheduleTable({ projectsSchedule, dateFnsLocale }: RenderProjectsScheduleTableType) {
  const { Translate } = useAppTranslation();

  return (
    <table className='table table-bordered table-hover table-sm' width='100%'>
      <thead>
        <tr>
          <th>{Translate('labels.project')}</th>
          <th>{Translate('labels.client')}</th>
          <th>{Translate('labels.seller')}</th>
          <th className='text-center'>{Translate('labels.delivery-date')}</th>
          <th className='text-center'>{Translate('labels.pieces-quantity')}</th>
          <th className='text-center'>{Translate('labels.order-number-abbrev')}</th>
        </tr>
      </thead>

      <tbody>
        {
          projectsSchedule.map((project, index) => (
            <tr key={index}>
              <td>{project.project_name}</td>
              <td>{project.client_name}</td>
              <td>{project.seller_name}</td>
              <td className='text-center'>{displayFormattedDate(project.delivery_date, dateFnsLocale)}</td>
              <td className='text-center'>{project.total_project_clothing_items}</td>
              <td className='text-center'>{project.order_number}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};
