import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px #ccc;
  padding: 10px;
`;

export const TrackingIcon = styled.i`
  font-size: 24px;
  background-color: var(--primary);
  color: #fff;
  padding: 20px;
  border-radius: 5px;
`;

export const TrackingData = styled.div`
`;
