import { type TFunction } from 'i18next';
import type ClothingPresetType from '../../../../../types/ClothingPresetType';

export const generateDefaultPresetData = (): ClothingPresetType => ({
  id: -1,
  name: '',
  imported_clothes_id: [],
  details: ''
});

export const validatePreset = (preset: ClothingPresetType, Translate: TFunction): void => {
  const { name, imported_clothes_id } = preset;

  if (!name) throw new Error(Translate('error.preset-missing-name-error'));

  if (imported_clothes_id.length === 0) throw new Error(Translate('error.preset-missing-models-error'));
};
