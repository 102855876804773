import { type AxiosInstance } from 'axios';
import { type Order } from '../../../../types/OrderTypes';

type DeleteOrdersFromSublistParams = {
  projectId: number;
  selectedOrdersToDelete: Order[];
  httpConnection: AxiosInstance;
  finalClientAccessToken?: string;
}

type DeleteOrdersFromSublistResponse = {
  deleted_ids: number[];
}

export const deleteOrdersFromSublist = async ({ httpConnection, projectId, selectedOrdersToDelete, finalClientAccessToken }: DeleteOrdersFromSublistParams): Promise<number[]> => {
  const postData = {
    orders_to_delete: selectedOrdersToDelete.map(order => ({ order_id: order.id, sublist_id: order.sublist_id }))
  };

  const url = !finalClientAccessToken
    ? `list/clothing-projects/sublist/orders/bulk-delete?project_id=${projectId}`
    : `/list/clothing-projects/final-client/orders/bulk-delete?project_id=${projectId}&access_token=${finalClientAccessToken}`;

  return await new Promise<number[]>((resolve, reject) => {
    httpConnection
      .post<DeleteOrdersFromSublistResponse>(url, postData)
      .then(({ data }) => {
        resolve(data.deleted_ids);
      })
      .catch(err => {
        reject(err);
      });
  });
};
