import React from 'react';
import { Link } from 'react-router-dom';

export default function SidebarBrand() {
  return (
    <Link className='sidebar-brand d-flex align-items-center justify-content-center' to='dashboard'>
      <div className='sidebar-brand-icon rotate-n-15'>
        <i className='fas fa-list-alt'></i>
      </div>
      <div className='sidebar-brand-text mx-3'>
        LIST <sup>v3</sup>
      </div>
    </Link>
  );
}
