import * as Style from './style';

export function Header({ title }: { title: string }) {
  return (
    <Style.Container>
      <Style.WrapperLabel>
        <Style.Label>{title}</Style.Label>
      </Style.WrapperLabel>

      <Style.Options>
        <Style.Option type='button'>
          <span className='fas fa-ellipsis-h' />
        </Style.Option>
      </Style.Options>
    </Style.Container>
  );
}
