import React, { useCallback, useMemo, useState } from 'react';
import StepperHeaderButtons from './components/StepperHeaderButtons';
import StepperFooterButtons from './components/StepperFooterButtons';
import TextInput from '../../../../../../../../components/Forms/TextInput';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import CollapsableCard from '../../../../../../../../components/Cards/CollapsableCard';
import { type DefaultListEditorType } from '../../../../../../../../types/FinalClientEditor/DefaultListEditorType';
import ComboBox from '../../../../../../../../components/Forms/ComboBox';
import { generateBloodTypeOptions, generateComboBoxOptionsForClothingInput, generateSublistOptions } from '../../../../services/orderFormService';
import { type Order } from '../../../../../../../../types/OrderTypes';
import { type ClothingModelType } from '../../../../../../../../types/ClothingModelType';
import ClothingInputData from '../../../../../../../../components/Forms/ClothingInputData';
import SplitButton from '../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';

export type StepperListEditorItemType = {
  title: string;
  componentToRender: JSX.Element;
}

type FinalClientStepperListEditorType = Omit<DefaultListEditorType, 'isCompanyEditor' | 'dynamicLargeClass'> & {
  stretched?: boolean;
}

type FormAction = 'addOrder' | 'editOrder' | 'cancelEdit' | 'clearForm';

export default function FinalClientStepperListEditor({
  order,
  setOrder,
  targetSublistIndex,
  setTargetSublistIndex,
  handleChangeGender,
  handleFindSublistIndex,
  sublists,
  importedModels,
  handleChangeClothingData,
  handleClearClothingData,
  handleAddOrder,
  handleEditOrder,
  handleClearForm,
  handleCancelEdit,
  stretched = false
}: FinalClientStepperListEditorType) {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const { Translate } = useAppTranslation();

  const handleFormActionWithStepReset = useCallback((action: FormAction) => {
    setCurrentStep(0);

    switch (action) {
      case 'addOrder':
        handleAddOrder();
        break;
      case 'editOrder':
        handleEditOrder();
        break;
      case 'cancelEdit':
        handleCancelEdit();
        break;
      case 'clearForm':
        handleClearForm();
        break;
    }
  }, [handleAddOrder, handleCancelEdit, handleClearForm, handleEditOrder]);

  const steps = useMemo<StepperListEditorItemType[]>(() => ([
    {
      title: Translate('labels.data'),
      componentToRender: (
        <React.Fragment>
          <div className="row justify-content-center">
            <div className={!stretched ? 'col-sm-6 col-md-3' : 'col-6'}>
              <TextInput
                label={Translate('labels.name')}
                value={order.name ?? ''}
                id='txtName'
                autofocus
                onChange={({ target }) => {
                  setOrder({ ...order, name: target.value });
                }}
              />
            </div>

            <div className={!stretched ? 'col-sm-6 col-md-3' : 'col-6'}>
              <TextInput
                label={Translate('labels.number')}
                value={order.number ?? ''}
                id='txtNumber'
                onChange={({ target }) => {
                  setOrder({ ...order, number: target.value });
                }}
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className={!stretched ? 'col-sm-12 col-md-6' : 'col-12'}>
              <CollapsableCard id='extra-data' header={Translate('labels.extra-data')} startWithClosedPanel>
                <div className="row">
                  <div className="col-sm-6">
                    <TextInput
                      label={Translate('labels.nickname')}
                      value={order.nickname ?? ''}
                      id='txtNickname'
                      onChange={({ target }) => {
                        setOrder({ ...order, nickname: target.value });
                      }}
                    />
                  </div>

                  <div className="col-sm-6">
                    <ComboBox
                      id='blood-type'
                      value={order.bloodType ?? ''}
                      header={Translate('labels.blood-type')}
                      handleChange={({ target }) => {
                        setOrder({ ...order, bloodType: target.value });
                      }}
                      data={generateBloodTypeOptions(Translate('status.no-info'))}
                    />
                  </div>
                </div>

              </CollapsableCard>
            </div>
          </div>
        </React.Fragment>
      )
    },
    {
      title: Translate('labels.clothes'),
      componentToRender: (
        <React.Fragment>
          <div className="row justify-content-center">
            <div className="mt-2">

              <ComboBox
                id='modeling'
                value={order.gender}
                header={Translate('labels.model')}
                handleChange={({ target }) => {
                  handleChangeGender(target.value as Order['gender']);
                }}
                data={[
                  { label: Translate('labels.male'), value: 'male' },
                  { label: Translate('labels.female'), value: 'female' },
                  { label: Translate('labels.childish'), value: 'childish' }
                ]}
              />

              <p className='pb-2'>{Translate('description.select-sizes-and-pieces-quantity')}</p>

              {importedModels.map((model: ClothingModelType, index) => {
                return (
                  <div key={index}>
                    <ClothingInputData
                      sizeIndex={order.clothes[index].sizeIndex}
                      quantity={order.clothes[index].quantity}
                      clothingName={model.name}
                      clotheIcon={model.icon.url}
                      onChangeSize={sizeIndex => {
                        handleChangeClothingData({ value: sizeIndex, field: 'sizeIndex', clothingIndex: index });
                      }}
                      onChangeQuantity={price => {
                        handleChangeClothingData({ value: price, field: 'quantity', clothingIndex: index });
                      }}
                      onClearAll={() => {
                        handleClearClothingData(index);
                      }}
                      data={generateComboBoxOptionsForClothingInput(model, order.gender)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )
    },
    {
      title: Translate('labels.sublist'),
      componentToRender: (
        <React.Fragment>
          <div className="row justify-content-center">
            <div className={!stretched ? 'col-xs-12 col-sm-6 col-md-3' : 'col-6'}>
              <ComboBox
                id='target-sublist'
                value={!order.id ? targetSublistIndex.toString() : handleFindSublistIndex(order.sublist_id).toString()}
                header={Translate('labels.which-sublist-want-add-to')}
                tabIndex={6}
                disabled={!!order.id}
                handleChange={({ target }) => {
                  setTargetSublistIndex(parseInt(target.value));
                }}
                data={generateSublistOptions(sublists)}
              />
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='text-center my-2'>
              <SplitButton
                color='light'
                icon={!order.id ? IconsCatalog.trash : IconsCatalog.times}
                title={!order.id ? Translate('actions.clear') : Translate('actions.cancel')}
                handleClick={() => {
                  !order.id ? handleFormActionWithStepReset('clearForm') : handleFormActionWithStepReset('cancelEdit');
                }}
              />

              <SplitButton
                marginLeft
                color='success'
                icon={!order.id ? IconsCatalog.save : IconsCatalog.pen}
                title={!order.id ? Translate('actions.add') : Translate('actions.save')}
                handleClick={() => {
                  !order.id ? handleFormActionWithStepReset('addOrder') : handleFormActionWithStepReset('editOrder');
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )
    }
  ]), [Translate, handleChangeClothingData, handleChangeGender, handleClearClothingData, handleFindSublistIndex, handleFormActionWithStepReset, importedModels, order, setOrder, setTargetSublistIndex, stretched, sublists, targetSublistIndex]);

  return (
    <div className="container-fluid px-0">

      <StepperHeaderButtons currentStep={currentStep} setCurrentStep={setCurrentStep} steps={steps} streched={stretched}/>

      {steps.map((step, stepIndex) => stepIndex === currentStep && <React.Fragment key={stepIndex}>{step.componentToRender}</React.Fragment>)}

      <StepperFooterButtons currentStep={currentStep} setCurrentStep={setCurrentStep} stepsCount={steps.length} streched={stretched} />

    </div>
  );
};
