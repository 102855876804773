import { type RawAxiosRequestHeaders, type AxiosInstance } from 'axios';
import { type FormAddPaymentReceiptData } from '../components/FormAddPaymentReceipt/types/FormAddPaymentReceiptData';
import { convertFileToBlob } from '../../../../../../../utils/helper';
import { type ProjectPaymentRegistryType } from '../../../../../../../types/ProjectPaymentRegistry';

type CreatePaymentRegistryParams = {
  projectId: number;
  data: FormAddPaymentReceiptData;
}

export const createPaymentRegistry = async (params: CreatePaymentRegistryParams, httpConnection: AxiosInstance): Promise<ProjectPaymentRegistryType> => {
  return await new Promise<ProjectPaymentRegistryType>((resolve, reject) => {
    const { file, amount, description } = params.data;
    const formData = new FormData();

    if (file) formData.append('receipt', convertFileToBlob(file), file.name);
    formData.append('amount_received', amount.toString());
    formData.append('project_id', params.projectId.toString());
    formData.append('description', description);

    const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;

    httpConnection.post<ProjectPaymentRegistryType>('list/clothing-projects/payment-receipts', formData, {
      headers: {
        ...defaultHeaders,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
