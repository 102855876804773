import React from 'react';
import ModalBase from '../ModalBase';
import type ClothingPresetType from '../../../types/ClothingPresetType';
import { type ClothingModelType } from '../../../types/ClothingModelType';
import ClothingIconViewer from '../../ClothingIconViewer';
import { useAppTranslation } from '../../../contexts/TranslationContext';

type ClothingModelViewerModalType = {
  visible: boolean;
  preset: ClothingPresetType;
  models: ClothingModelType[];
  handleClose: () => void;
};

export default function ClothingModelViewerModal({ visible, preset, models, handleClose }: ClothingModelViewerModalType) {
  const { Translate } = useAppTranslation();

  return (
    <ModalBase
      title={preset.name}
      message={Translate('description.models-used-in-this-preset')}
      style='primary'
      visible={visible}
      handleClose={handleClose}
      hideFooter
      handleConfirm={() => { }}
    >
      <>
        <div className='list-group mt-3'>
          {models.map((model, key) => (
            <React.Fragment key={key}>
              <div className='mb-2'>
                <ClothingIconViewer icon={model.icon.url} label={model.name} useInlineIcon />
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className='mt-2'>
          <pre style={{ fontFamily: 'inherit', color: 'var(--dark)' }}>
            {preset.details}
          </pre>
        </div>
      </>
    </ModalBase>
  );
}
