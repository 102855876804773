import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast-promise';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import FormAddPaymentReceipt from './components/FormAddPaymentReceipt';
import PaymentsReceiptsList from './components/PaymentReceiptsList';
import { createPaymentRegistry } from './api/createPaymentRegistry';
import { type FormAddPaymentReceiptData } from './components/FormAddPaymentReceipt/types/FormAddPaymentReceiptData';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';
import { type ProjectPaymentRegistryType } from '../../../../../../types/ProjectPaymentRegistry';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { type UserPreferences } from '../../../../../../types/UserPreferences';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';

type PaymentRegistriesViewType = Pick<ModalBaseType, 'visible' | 'handleClose' | 'handleConfirm'> & {
  projectId: number;
  payments: ProjectPaymentRegistryType[];
  preferences: UserPreferences;
  onCreatePaymentRegistry: (paymentRegistry: ProjectPaymentRegistryType) => void;
  onUpdatePaymentRegistry: (paymentRegistry: ProjectPaymentRegistryType) => void;
}

export const acceptedFileExtensions = '.jpg, .jpeg, .png, .pdf';
export const acceptedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];

export default function PaymentRegistriesView({ projectId, payments, preferences, visible, handleClose, handleConfirm, onCreatePaymentRegistry, onUpdatePaymentRegistry }: PaymentRegistriesViewType) {
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const handleCreatePaymentRegistry = useCallback((data: FormAddPaymentReceiptData) => {
    const promise = createPaymentRegistry({ projectId, data }, httpConnection);

    toast.promise(promise, {
      loading: Translate('progress.registering-payment'),
      success: (createdPaymentRegistry) => {
        onCreatePaymentRegistry(createdPaymentRegistry);
        return Translate('toast.payment-registered');
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate, httpConnection, onCreatePaymentRegistry, projectId]);

  return (
    <ModalBase
      hideFooter
      visible={visible}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      style='primary'
      size={'lg'}
      title={Translate('modal.payment-history')}
      message={Translate('description.payment-history-description')}
    >
      <div className="row">
        <div className="col">
          <FormAddPaymentReceipt onSubmit={handleCreatePaymentRegistry} />
          <PaymentsReceiptsList payments={payments} onAttachPaymentReceipt={onUpdatePaymentRegistry} preferences={preferences} />
        </div>
      </div>
    </ModalBase>
  );
};
