import _ from 'lodash';
import Angry from '../../../../../NoAuth/ViewProjectClient/fragments/SurveyEvaluation/fragments/angry.svg';
import Calm from '../../../../../NoAuth/ViewProjectClient/fragments/SurveyEvaluation/fragments/calm.svg';
import Confused from '../../../../../NoAuth/ViewProjectClient/fragments/SurveyEvaluation/fragments/confused.svg';
import Neutral from '../../../../../NoAuth/ViewProjectClient/fragments/SurveyEvaluation/fragments/neutral.svg';
import Ok from '../../../../../NoAuth/ViewProjectClient/fragments/SurveyEvaluation/fragments/ok.svg';
import { type HeaderSurveyType } from '../../contractors/SurveyResponse';

import { Card, Container } from './style';

type HeaderProps = {
  data?: HeaderSurveyType;
};

export function Header({ data }: HeaderProps) {
  return (
    <Container>
      <Card>
        <h1 className='text-danger'>{_.defaultTo(data?.[1], 0)}</h1>
        <p>
          <img src={Angry} alt='' /> Péssimo
        </p>
      </Card>

      <Card>
        <h1 className='text-warning'>{_.defaultTo(data?.[2], 0)}</h1>
        <p>
          <img src={Confused} alt='' /> Ruim
        </p>
      </Card>

      <Card>
        <h1 className='text-info'>{_.defaultTo(data?.[3], 0)}</h1>
        <p>
          <img src={Neutral} alt='' /> Bom
        </p>
      </Card>

      <Card>
        <h1 className='text-primary'>{_.defaultTo(data?.[4], 0)}</h1>
        <p>
          <img src={Calm} alt='' /> Muito bom
        </p>
      </Card>

      <Card>
        <h1 className='text-success'>{_.defaultTo(data?.[5], 0)}</h1>
        <p>
          <img src={Ok} alt='' /> Perfeito
        </p>
      </Card>
    </Container>
  );
}
