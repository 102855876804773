import React, { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast-promise';
import { type FinishingOption } from '../../../../../../../../../../types/ClothingFinishingOptions/FinishingOption';
import ComboBox from '../../../../../../../../../../components/Forms/ComboBox';
import SplitButton from '../../../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../../../../../../contexts/TranslationContext';
import { generateComboBoxFinishingOptions, generateComboBoxFinishingOptionsItems, generateInitialSelectedFinishingOption, generateInitialSelectedFinishingOptionItem } from '../../services/finishingOptionsServices';
import { type FinishingOptionItem } from '../../../../../../../../../../types/ClothingFinishingOptions/FinishingOptionItem';
import { type UserPreferences } from '../../../../../../../../../../types/UserPreferences';
import { getServerErrorMessageFromResponse } from '../../../../../../../../../../utils/helper';

export type AddFinishinOptionToSublistParams = {
  selectedFinishingOption: FinishingOption;
  selectedFinishingOptionItem: FinishingOptionItem;
  model_id: number;
}

type FinishingOptionsPickerType = {
  model_id: number;
  finishingOptions: FinishingOption[];
  preferences: UserPreferences;
  disabled: boolean;
  onAddFinishinOptionToSublist: (params: AddFinishinOptionToSublistParams) => void;
}

export default function FinishingOptionsPicker({ model_id, finishingOptions, preferences, disabled, onAddFinishinOptionToSublist }: FinishingOptionsPickerType) {
  const { Translate } = useAppTranslation();

  const [selectedFinishingOption, setSelectedFinishingOption] = useState<FinishingOption>(generateInitialSelectedFinishingOption(finishingOptions));
  const [selectedFinishingOptionItem, setSelectedFinishingOptionItem] = useState<FinishingOptionItem>(generateInitialSelectedFinishingOptionItem(selectedFinishingOption));

  /**
   * Need to retrieve the entire object to update the sate
   * and ComboBox only provides the id
   * so it's needed to find the object by id and put to the state
   */
  const handleUpdateSelectedFinishingOption = useCallback((selectedFinishingOptionId: number) => {
    try {
      const newSelectedFinishingOption = finishingOptions.find(finishingOption => finishingOption.id === selectedFinishingOptionId);
      if (!newSelectedFinishingOption) throw new Error(Translate('error.failed-to-update-finishing-option'));
      setSelectedFinishingOption(newSelectedFinishingOption);

      const { option_items } = newSelectedFinishingOption;

      if (option_items.length === 0) return;
      setSelectedFinishingOptionItem(newSelectedFinishingOption.option_items[0]);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, finishingOptions]);

  const handleUpdateSelectedFinishingOptionItem = useCallback((selectedFinishingOptionItemId: number) => {
    try {
      const newSelectedFinishingOptionItem = selectedFinishingOption.option_items.find(finishingOptionItem => finishingOptionItem.id === selectedFinishingOptionItemId);
      if (!newSelectedFinishingOptionItem) throw new Error(Translate('error.failed-to-update-finishing-option-item'));
      setSelectedFinishingOptionItem(newSelectedFinishingOptionItem);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, selectedFinishingOption.option_items]);

  if (finishingOptions.length === 0) return <span>{Translate('description.no-finishing-options-added-yet')}</span>;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <ComboBox
            id='finishing-options'
            disabled={disabled}
            data={generateComboBoxFinishingOptions(finishingOptions)}
            value={selectedFinishingOption.id.toString()}
            header={`${Translate('labels.type')}:`}
            handleChange={({ target }) => {
              handleUpdateSelectedFinishingOption(parseInt(target.value));
            }}
          />
        </div>
        <div className="col">
          <ComboBox
            id='finishing-option-items'
            noMarginBottom
            disabled={disabled}
            data={generateComboBoxFinishingOptionsItems(selectedFinishingOption.option_items, preferences.currency)}
            value={selectedFinishingOptionItem.id.toString()}
            header={`${Translate('labels.option')}:`}
            handleChange={({ target }) => {
              handleUpdateSelectedFinishingOptionItem(parseInt(target.value));
            }}
          />
          <small className="form-text text-muted">{selectedFinishingOption.option_items.length === 0 && Translate('status.no-finishing-option-items')}</small>
        </div>
        <div className="col-auto pl-0">
          <SplitButton
            simulateLabelMarginTop
            disabled={disabled || selectedFinishingOption.option_items.length === 0}
            color='primary'
            icon={IconsCatalog.plus}
            handleClick={() => {
              onAddFinishinOptionToSublist({ selectedFinishingOption, selectedFinishingOptionItem, model_id });
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
