import { Card } from '../Card';
import * as Style from './style';

export function Content() {
  return (
    <Style.WrapperContainer>
      <Style.Container>
        {Array.from({ length: 20 }).map((_, index) => (
          <Card key={index} />
        ))}
      </Style.Container>
    </Style.WrapperContainer>
  );
}
