import { useCallback, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FilePreview } from '../../../../../components/CardModal/fragments/FilePreview';

import Angry from './fragments/angry.svg';
import Calm from './fragments/calm.svg';
import Ok from './fragments/ok.svg';
import Confused from './fragments/confused.svg';
import Neutral from './fragments/neutral.svg';

import { Container, Content, Options, Title, WrapperNote, WrapperVotes } from './style';
import { type ProjectAccessInfo } from '../../../../../types/FinalClientEditor/ProjectAccessInfo';
import { useMutation } from 'react-query';
import { type SatisfactionSurveySaveServiceProps } from './services/SatisfactionSurveySaveService';
import { satisfactionSurveySaveService } from './services';
import _ from 'lodash';
import { type SatisfactionSurveyType } from '../../../../../types/FinalClientEditor/ProjectDataFinalClientEditor';

const Votes = [
  { id: 0, icon: Angry, value: 1, valueName: 'Péssimo' },
  { id: 1, icon: Confused, value: 2, valueName: 'Ruim' },
  { id: 2, icon: Neutral, value: 3, valueName: 'Bom' },
  { id: 3, icon: Calm, value: 4, valueName: 'Muito bom' },
  { id: 4, icon: Ok, value: 5, valueName: 'Perfeito' }
];

type SurveyEvaluationProps = {
  name: string;
  userId: number;
  projectAccessInfo: ProjectAccessInfo;
  satisfactionSurvey: SatisfactionSurveyType;
};

const schema = yup
  .object({
    votes: yup.string().required(),
    recommend: yup.string().required(),
    comment: yup.string().required(),
    files: yup.array(),
    agree: yup.boolean().oneOf([true], 'You must agree to the terms of use').required()
  })
  .required();

export function SurveyEvaluation({ name, userId, projectAccessInfo, satisfactionSurvey }: SurveyEvaluationProps) {
  const form = useForm({
    resolver: yupResolver(schema)
  });

  const watch = useWatch({ control: form.control });

  const satisfactionSurveySaveMutation = useMutation(
    async (data: SatisfactionSurveySaveServiceProps) => {
      return await satisfactionSurveySaveService.execute({ ...data, files: form.getValues('files') ?? [] });
    },
    {
      onSuccess: () => {
        toast.success('Pesquisa enviada com sucesso!');
        form.reset();
        window.location.reload();
      },
      onError: () => {
        toast.error('Erro ao enviar pesquisa!');
      }
    }
  );

  const disabledButton = useMemo(() => {
    try {
      schema.validateSync(watch);
      return false;
    } catch {
      return true;
    }
  }, [watch]);

  const votes = useMemo(() => {
    return Votes.map(vote => (
      <label key={vote.id} htmlFor={`votes-${vote.id}`} data-tooltip-id={vote.id.toString()}>
        <Tooltip id={vote.id.toString()}>
          <span>{vote.valueName}</span>
        </Tooltip>

        <input type='radio' id={`votes-${vote.id}`} value={vote.value} {...form.register('votes')} />
        <img src={vote.icon} alt='' />
      </label>
    ));
  }, [form]);

  const handleSetFiles = useCallback(
    (files: File[]) => {
      form.setValue('files', files);
    },
    [form]
  );

  const onSubmit = useCallback(
    (data: any) => {
      const { votes, recommend, comment } = data;
      satisfactionSurveySaveMutation.mutate({
        user_id: userId,
        accessToken: projectAccessInfo.accessToken,
        project_id: projectAccessInfo.projectId,
        comment,
        is_recommend: recommend,
        note: _.parseInt(votes)
      });
    },
    [projectAccessInfo.accessToken, projectAccessInfo.projectId, satisfactionSurveySaveMutation, userId]
  );

  if (satisfactionSurvey) {
    return (
      <Container>
        <h1>Pesquisa já foi respondida</h1>
        <p>Agradecemos o seu feedback</p>
      </Container>
    );
  }

  return (
    <FormProvider {...form}>
      <Container onSubmit={form.handleSubmit(onSubmit)}>
        <h1>Pesquisa de satisfação</h1>

        <p>Avalie e nos ajude a melhorar.</p>

        <WrapperVotes>
          <Title>Escolha uma nota</Title>

          <Content>{votes}</Content>
        </WrapperVotes>

        <WrapperNote>
          <Title>Recomendaria nossos produtos para mais alguém?.</Title>

          <Options>
            <label htmlFor='sim'>
              <input type='radio' {...form.register('recommend')} id='sim' value='sim' />
              <span>Sim</span>
            </label>

            <label htmlFor='nao'>
              <input type='radio' {...form.register('recommend')} id='nao' value='nao' />
              <span>Não</span>
            </label>
          </Options>
        </WrapperNote>

        <WrapperNote>
          <Title>Deixe um comentário sobre sua Experiência conosco.</Title>

          <textarea placeholder='Deixe um comentário' {...form.register('comment')} />
        </WrapperNote>

        <WrapperNote>
          <Title>Pode compartilhar fotos conosco usando nossos produtos?</Title>

          <FilePreview column={2} onHandleSetFiles={handleSetFiles} />
        </WrapperNote>

        <p className='mt-3'>
          Autorização de Uso: Você concede à {name} o direito não exclusivo, irrevogável, e mundial de usar, reproduzir,
          exibir, distribuir, e criar trabalhos derivados das fotos enviadas em suas plataformas digitais, incluindo, mas não
          se limitando a, redes sociais, websites, e materiais de marketing.
        </p>

        <label htmlFor='concordo'>
          <input type='checkbox' id='concordo' {...form.register('agree')} />
          <span className='ml-2'>Concordo com os termos de uso</span>
        </label>

        <button
          type='submit'
          className='btn btn-primary mt-3'
          title='Enviar'
          disabled={satisfactionSurveySaveMutation.isLoading || disabledButton}
        >
          Enviar
        </button>
      </Container>
    </FormProvider>
  );
}
