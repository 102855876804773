import React, { useMemo } from 'react';
import { type BasicButtonType } from '../types/BasicButtonType';
import { BadgeLargeText } from './styles';
import { getTextColorBasedOnBackgroundColor, isHexColor } from '../../../helpers/colors';

type BadgeButtonType = Pick<BasicButtonType, 'title' | 'color' | 'handleClick'> & {
  useLarge?: boolean;
  marginLeft?: boolean;
}

export default function BadgeButton({ title, color, handleClick, useLarge, marginLeft }: BadgeButtonType) {
  const customStyle = useMemo(() => {
    return isHexColor(color)
      ? ({ backgroundColor: color, color: getTextColorBasedOnBackgroundColor(color) })
      : ({});
  }, [color]);

  const marginLeftClass = useMemo(() => marginLeft ? 'ml-1' : '', [marginLeft]);

  return (
    <span className={`badge badge-${color} badge-large ${marginLeftClass}`} role='button' onClick={handleClick} style={customStyle}>
      {!useLarge && title}
      {useLarge && <BadgeLargeText>{title}</BadgeLargeText>}
    </span>
  );
};
