import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

import { Container, ImageFile } from '../../../style';
import { WrapperFile, RowMaterial, WrapperRawMaterial, Content, RemoveButton } from '../styles';
import { generatePathRegisterFile } from '../../../../../../../../../helpers/general';

type Errors = {
  photo?: { message: string };
  title?: { message: string };
  description?: { message: string };
  type_meter?: { message: string };
  color?: { message: string };
};

type ProductsError = {
  products?: Record<number, Errors>;
};

type RawMaterialContentProps = {
  handleInventory: (rawMaterial: any) => void;
};

export function RawMaterialContent({ handleInventory }: RawMaterialContentProps) {
  const methods = useFormContext();

  const key = 'products';
  const rawMaterialValue = methods.watch(key) ?? [];
  const errors = methods.formState.errors as ProductsError;

  return (
    <Container>
      <WrapperRawMaterial>
        {rawMaterialValue?.map((rawMaterial: any, index: number) => (
          <RowMaterial key={index}>
            <Content>
              <WrapperFile>
                {!_.isEmpty(rawMaterial.photo) ? (
                  <ImageFile
                    src={
                      typeof rawMaterial.photo === 'string'
                        ? generatePathRegisterFile({ filename: rawMaterial.photo })
                        : URL.createObjectURL(rawMaterial.photo[0])
                    }
                    alt='Imagem do cliente'
                  />
                ) : (
                  <span>Adicionar foto</span>
                )}

                <input
                  type='file'
                  onChange={e => {
                    const file = e.target.files?.[0];
                    if (file) {
                      methods.setValue(`${key}.${index}.photo`, [file]);
                    }
                  }}
                />
              </WrapperFile>

              {errors.products?.[index]?.photo && (
                <div className='alert alert-danger mt-2'>{errors?.products?.[index]?.photo?.message}</div>
              )}

              <div>
                <label className='form-label'>Nome</label>
                <input
                  className={`form-control ${errors.products?.[index]?.title ? 'is-invalid' : ''}`}
                  type='text'
                  placeholder='Nome da matéria-prima'
                  value={rawMaterial.title}
                  onChange={e => {
                    methods.setValue(`${key}.${index}.title`, e.target.value);
                  }}
                />
              </div>

              <div>
                <label className='form-label'>Descrição</label>
                <input
                  className={`form-control ${errors.products?.[index]?.description ? 'is-invalid' : ''}`}
                  type='text'
                  placeholder='Descrição da matéria-prima'
                  value={rawMaterial.description}
                  onChange={e => {
                    methods.setValue(`${key}.${index}.description`, e.target.value);
                  }}
                />
              </div>

              <div>
                <label className='form-label'>Cor</label>
                <input
                  className={`form-control ${errors.products?.[index]?.color ? 'is-invalid' : ''}`}
                  type='text'
                  placeholder='Cor da matéria-prima'
                  value={rawMaterial.color}
                  onChange={e => {
                    methods.setValue(`${key}.${index}.color`, e.target.value);
                  }}
                />
              </div>

              <button
                type='button'
                className='btn btn-primary w-100'
                onClick={() => {
                  handleInventory({ ...rawMaterial, index });
                }}
              >
                Adicionar estoque
              </button>

              {_.isEmpty(rawMaterial.list_clothing_model_and_product) ? (
                <RemoveButton
                  className='btn btn-danger btn-circle d-flex justify-content-center align-items-center'
                  type='button'
                  onClick={() => {
                    methods.setValue(
                      key,
                      rawMaterialValue.filter((_: any, i: number) => i !== index)
                    );
                  }}
                >
                  <i className='fa fa-trash' />
                </RemoveButton>
              ) : null}
            </Content>
          </RowMaterial>
        ))}
      </WrapperRawMaterial>

      <button
        className='btn btn-warning mt-3'
        type='button'
        onClick={() => {
          methods.setValue(key, [...rawMaterialValue, { photo: null }]);
        }}
      >
        <i className='fa fa-plus mr-2'></i>
        Adicionar matéria-prima
      </button>
    </Container>
  );
}
