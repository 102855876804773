import { type Locale } from 'date-fns';
import { useMemo } from 'react';
import SpanButton from '../../../../../../components/Buttons/SpanButton';
import { displayCommonDateWithTime } from '../../../../../../helpers/dateTime';

type TimePassedSinceLastEditingType = {
  updated_at: string;
  dateFnsLocale: Locale;
  hasUserChangesHistoryToShow: boolean;
  onRequestShowUserChangesHistory: () => void;
}

export default function TimePassedSinceLastEditing({ dateFnsLocale, hasUserChangesHistoryToShow, updated_at, onRequestShowUserChangesHistory }: TimePassedSinceLastEditingType) {
  const timePassesSinceDate = useMemo(() => {
    return displayCommonDateWithTime(new Date(updated_at), dateFnsLocale);
  }, [dateFnsLocale, updated_at]);

  if (!hasUserChangesHistoryToShow) <span>{timePassesSinceDate}</span>;

  return <SpanButton text={timePassesSinceDate} handleClick={onRequestShowUserChangesHistory} />;
};
