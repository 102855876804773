import styled from 'styled-components';

export const WrapperFilePreview = styled.div`
  overflow-y: auto;
  width: 100%;
`;

export const AddedFile = styled.label`
  background-color: var(--success);
  border: none;
  color: white;
  position: absolute;
  right: 20px;
  top: 410px;
  border-radius: 25px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  img {
    width: auto;
    height: 480px;
    display: inline-block;
  }
`;

export const DropZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--light);
  color: var(--dark);
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  border-radius: 4px;
  z-index: 100;
`;
