import { type Sections } from '../fragments/SectionsOptions';

export function getLabels(section: Sections) {
  switch (section) {
    case 'customer':
      return {
        title: 'Clientes',
        icon: 'fas fa-user mr-2'
      };
    case 'seamstress':
      return {
        title: 'Costureiras',
        icon: 'fas fa-user-tie mr-2'
      };
    case 'supplier':
      return {
        title: 'Fornecedores',
        icon: 'fas fa-user-friends mr-2'
      };
  }
}
