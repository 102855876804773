import React, { useCallback } from 'react';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import { type SublistReportType, type ProjectReportType } from '../../../../../../../../types/ProjectReportType';
import { type UserPreferences } from '../../../../../../../../types/UserPreferences';
import { displayAsCurrency } from '../../../../../../../../utils/helper';
import { type SublistFinishingOption } from '../../../../../../../../types/ClothingFinishingOptions/SublistFinishingOption';
import SelectedFinishingOptionsTooltip from './components/SelectedFinishingOptionsTooltip';

type SublistCollapsableCardHeaderType = Pick<SublistType, 'title'> & {
  projectReport: ProjectReportType | undefined;
  sublistIndex: number;
  sublistFinishingOptions: SublistFinishingOption[];
  preferences: UserPreferences | undefined;
  hidePrices: boolean;
}

export default function SublistCollapsableCardHeader({ projectReport, sublistIndex, title, preferences, sublistFinishingOptions, hidePrices }: SublistCollapsableCardHeaderType) {
  const { Translate } = useAppTranslation();

  const sublistTotalValueInTitle = useCallback((sublistReport: SublistReportType) => {
    if (!preferences || !sublistReport) return '';

    return displayAsCurrency(sublistReport.totalSublistPriceWithDiscount, preferences?.currency ?? '$');
  }, [preferences]);

  return (
    <div className='d-flex'>
      <span>{title}</span>
      {
        !!projectReport && (
          <React.Fragment>
            <span className='badge badge-secondary badge-large align-self-center ml-2' title={Translate('labels.pieces-quantity')} data-testid={`sublist-container-${sublistIndex}-header-clothes-count`}>
              {projectReport.sublists[sublistIndex]?.totalSublistClothes ?? 0}
            </span>

            {!hidePrices && (
              <span className='badge badge-success badge-large align-self-center ml-2'
                    title={Translate('labels.total-price')}
                    data-testid={`sublist-container-${sublistIndex}-header-total-price`}>
              {sublistTotalValueInTitle(projectReport.sublists[sublistIndex])}
            </span>
            )}
          </React.Fragment>
        )
      }

      {
        sublistFinishingOptions.length > 0 && (
          <SelectedFinishingOptionsTooltip sublistIndex={sublistIndex}
                                           sublistFinishingOptions={sublistFinishingOptions} />
        )
      }
    </div>
  );
};
