import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';
import { type KanbanCardImage } from '../../../../types/Kanban/KanbanCardImage';

export class AddImageCard {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ attachments, cardId }: { attachments: File[] | KanbanCardImage[]; cardId: string }) {
    const data = new FormData();

    attachments.forEach(file => {
      if (!(file as KanbanCardImage).id) {
        data.append('attachments[]', file as File);
        return;
      }
      data.append('images[]', JSON.stringify(file));
    });
    return await this.http.post(`/kanban/card/image/${cardId}`, data);
  }
}
