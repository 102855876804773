import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-right: 8px;
`;

export const WrapperLabel = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  font-size: 22px;
  font-weight: 600;
  color: var(--black);
`;

export const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Options = styled.div``;

export const Option = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--black);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 0;
  height: 30px;
  width: 30px;
`;
