import { type ClothingModelType } from '../../../../../../../../../types/ClothingModelType';
import { type Order, type ClothingProductionData } from '../../../../../../../../../types/OrderTypes';

type GenerateDefaultInputValuesParams = {
  importedModels: ClothingModelType[];
  gender: Order['gender'];
}

export const generateDefaultInputValues = ({ importedModels, gender }: GenerateDefaultInputValuesParams): ClothingProductionData[][] => {
  return importedModels.map(model => {
    return model.informations[gender].map<ClothingProductionData>(() => ({
      modelId: model.id,
      sizeIndex: -1,
      quantity: 0
    }));
  });
};

export type HandleUpdateQuantityParams = {
  modelIndex: number;
  sizeIndex: number;
  quantity: number;
};

export const updateProductionDataQuantity = (modelsProductionData: ClothingProductionData[][], {
  modelIndex,
  sizeIndex,
  quantity
}: HandleUpdateQuantityParams): ClothingProductionData[][] => {
  return modelsProductionData.map((currentProductionData, pModelIndex) => {
    if (pModelIndex === modelIndex) {
      return currentProductionData.map<ClothingProductionData>((currentSize, pSizeIndex) => {
        if (pSizeIndex === sizeIndex) {
          return {
            modelId: currentSize.modelId,
            sizeIndex: pSizeIndex,
            quantity: quantity < 0 ? 0 : quantity
          };
        }

        return currentSize;
      });
    }
    return currentProductionData;
  });
};
