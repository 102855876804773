import _ from 'lodash';
import { generateClothingIcons } from '../../../../../../../../helpers/general';
import { type SublistType } from '../../../../../../../../types/SublistTypes';

type SeamstressTableProps = {
  sublist: SublistType;
};

export function SeamstressTable({ sublist }: SeamstressTableProps) {
  return (
    <table className='table'>
      <thead>
        <tr>
          <th>Costureira</th>
          <th>Preço</th>
          <th>Quantidade</th>
          <th>Total</th>
        </tr>
      </thead>

      <tbody>
        {sublist?.list_clothing_project_sublist_and_factions?.map(sublist => {
          const price = _.isEqual(Number(sublist.price), 0) ? Number(sublist.faction?.price) : Number(sublist.price);

          return (
            <tr key={sublist.id}>
              <td>
                <div className='d-flex'>
                  <img
                    src={generateClothingIcons({ filename: sublist.faction?.list_clothing_model.icon.image_name as string })}
                    alt=''
                    srcSet=''
                  />
                  <div className='d-flex flex-column ml-3'>
                    <span>{sublist.faction?.entity?.name}</span>
                    <span>{sublist.faction?.list_clothing_model.name}</span>
                  </div>
                </div>
              </td>
              <td>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(price)}
              </td>
              <td>{sublist.quantity}</td>
              <td>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(price * Number(sublist.quantity))}
              </td>
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={3}>Total</td>
          <td>
            <b>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(
                _.sumBy(sublist?.list_clothing_project_sublist_and_factions, sublist => {
                  const price = _.isEqual(Number(sublist.price), 0) ? Number(sublist.faction?.price) : Number(sublist.price);
                  return price * Number(sublist.quantity);
                })
              )}
            </b>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
