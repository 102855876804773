import { type AxiosInstance } from 'axios';
import { type ProjectStatusType } from '../../../../../../../types/ProjectStatusType';

type ChangeProjectStatusParams = {
  httpConnection: AxiosInstance;
  projectId: number;
  statusId: number | null;
}
export default async function changeProjectStatus({ httpConnection, projectId, statusId }: ChangeProjectStatusParams): Promise<ProjectStatusType | boolean> {
  return await new Promise<ProjectStatusType | boolean>((resolve, reject) => {
    const putData = { project_id: projectId, status_id: statusId };

    httpConnection.put<ProjectStatusType | boolean>('list/clothing-projects/change-status', putData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
