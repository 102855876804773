import styled from 'styled-components';

export const WrapperFile = styled.label`
  background-color: var(white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 2px dashed #c4c4c4;
  border-radius: 20px;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    pointer-events: none;
  }

  input {
    display: none;
  }
`;

export const WrapperRawMaterial = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: 560px;
  overflow-y: auto;
`;

export const Content = styled.div`
  padding: 1rem;
  background-color: #f1f1f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
`;

export const RowMaterial = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

export const WrapperInput = styled.div`
  padding: 0;
  input {
    border: none;
    border-radius: 0.35rem;
    outline: none;
    padding: 0.375rem 0.75rem;
  }
`;
