import React, { useMemo, useState } from 'react';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { LayoutContainer, LayoutImage, LayoutSection } from './styles';
import InputRange from '../../../../../../components/Forms/InputRange';
import SublistFinishingOptionsViewer from '../SublistFinishingOptionsViewer';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';

type LayoutGalleryType = {
  importedModels: ClothingModelType[];
  sublists: SublistType[];
};

export default function LayoutGallery({ sublists, importedModels }: LayoutGalleryType) {
  const [layoutScaleFactor, setLayoutScaleFactor] = useState(1);

  const layoutScalePercent = useMemo(() => Math.round(layoutScaleFactor * 100), [layoutScaleFactor]);

  const { Translate } = useAppTranslation();

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col p-0 pt-2' style={{ borderTop: '5px solid #999' }}>
          <h5>{Translate('labels.layouts-gallery')}</h5>

          <section className='d-print-none'>
            <hr />

            <InputRange
              min={50} max={100} initialValue={100}
              label={`${Translate('labels.images-scale')}: ${layoutScalePercent}%`}
              onChangeValue={(updatedValue => {
                setLayoutScaleFactor(updatedValue / 100);
              })} />

            <hr />
          </section>
        </div>
      </div>

      <div className='row'>
        {sublists.map((sublist, index) => (
          <React.Fragment key={index}>
            <div className='d-flex justify-content-between align-items-start'>
              <LayoutSection className='mt-4 w-100 mr-2'>

                {
                  (!!sublist.details || sublist.layout?.file) && (
                    <strong className='d-block text-center'>{sublist.title}</strong>
                  )
                }

                {sublist.layout?.file && (
                  <LayoutContainer scaleFactor={layoutScaleFactor}>
                    <LayoutImage src={sublist.layout.file} className='img-fluid shadow-sm rounded mb-1' alt={sublist.title} />
                  </LayoutContainer>
                )}

                {
                  !!sublist.details && (
                    <h5 className='m-0 text-wrap text-center mt-3' style={{ fontFamily: 'inherit' }}>
                      {sublist.details}
                    </h5>
                  )
                }
              </LayoutSection>

              <SublistFinishingOptionsViewer sublist={sublist} importedModels={importedModels} />
            </div>
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
}
