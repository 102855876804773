import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';

type CreatePresetModalType = Omit<ModalBaseType, 'message' | 'style'>;

export default function CreatePresetModal(params: CreatePresetModalType) {
  return (
    <ModalBase {...params} style='primary' message=''>
      {params.children}
    </ModalBase>
  );
};
