import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Button,
  HeaderContainer,
  Icon,
  Search,
  Subtitle,
  Title,
  WrapperIcon,
  WrapperInfo,
  WrapperLeft,
  Form,
  RollbackButton
} from './style';

type HeaderProps = {
  title?: string;
  subtitle?: string;
  showForm?: boolean;
  defaultPath?: string;
};

export function Header({
  title = 'Workspaces',
  subtitle = 'Planejamento e controle da produção',
  showForm,
  defaultPath = '/pcp/workspaces'
}: HeaderProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleRollback = useCallback(() => {
    navigate(defaultPath);
  }, [defaultPath, navigate]);

  const rollback = useMemo(() => {
    if (_.isEqual(pathname, defaultPath)) {
      return (
        <WrapperIcon>
          <Icon className='fas fa-list' />
        </WrapperIcon>
      );
    }

    return (
      <RollbackButton type='button' onClick={handleRollback}>
        <Icon className='fas fa-arrow-left' />
      </RollbackButton>
    );
  }, [defaultPath, handleRollback, pathname]);

  return (
    <HeaderContainer>
      <WrapperLeft>
        {rollback}

        <WrapperInfo>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </WrapperInfo>
      </WrapperLeft>

      {showForm ? (
        <Form onSubmit={() => {}}>
          <Search type='text' placeholder='Buscar workspace' className='form-control' />

          <Button className='btn btn-primary'>
            <Icon className='fas fa-search' />
          </Button>
        </Form>
      ) : null}
    </HeaderContainer>
  );
}
