import { type AxiosInstance } from 'axios';
import {
  type ListClothingProjectOrderReplacementType
} from '../../../../../../../../../types/ClothingReplacementOption/ListClothingProjectOrderReplacementType';

type AddOrderClothingReplacementType = {
  httpConnection: AxiosInstance;
  orderClothingReplacementRegistryId: number;
}

export default async function({ httpConnection, orderClothingReplacementRegistryId }: AddOrderClothingReplacementType): Promise<ListClothingProjectOrderReplacementType[][]> {
  return await new Promise<ListClothingProjectOrderReplacementType[][]>((resolve, reject) => {
    httpConnection.delete<ListClothingProjectOrderReplacementType[][]>(`list/clothing-projects/order-clothe-replacement/${orderClothingReplacementRegistryId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
