import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import BasicButton from '../../../../../../../../components/Buttons/BasicButton';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';

type PDFViewerType = {
  sublist: SublistType;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

export default function PDFViewer({ sublist }: PDFViewerType) {
  const { Translate } = useAppTranslation();

  const [numPages, setNumPages] = useState<number>();

  if (!sublist.layout_details) return <></>;

  return (
    <React.Fragment>
      <div className="row my-3">
        <div className="col">
          <BasicButton title={Translate('actions.open-new-tab')} color={'primary'} size={'sm'} handleClick={() => {
            window.open(sublist.layout_details?.file, '_blank');
          }} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Document file={sublist.layout_details?.file ?? ''} onLoadSuccess={({ numPages }: { numPages: number }) => {
            setNumPages(numPages);
          }}>
            {Array.from(new Array(numPages), (_el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
    </React.Fragment>
  );
}
