import { useCallback, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Style from './style';
import { validationHolidaysSchema } from '../validations';
import { getHolidays, updateHolidays } from './services';
import { useUser } from '../../../../../contexts/UserContext';

export type HolidayProps = Array<{
  id: string;
  date: string;
  title: string;
}>;

export function Holiday() {
  const query = useQueryClient();
  const { user } = useUser();
  const keyHolidays = `holidays-${user?.id}`;
  const methods = useForm({
    defaultValues: {
      holidays: []
    },
    resolver: yupResolver(validationHolidaysSchema)
  });

  const holidays = useWatch({ control: methods.control, name: 'holidays', defaultValue: [] }) as HolidayProps;

  const { data: holidaysDate, isLoading } = useQuery(
    keyHolidays,
    async () => {
      return await getHolidays.execute();
    },
    {
      enabled: !!user,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchIntervalInBackground: false,
      refetchInterval: false
    }
  );
  const holidaysMutation = useMutation(
    async (data: HolidayProps) => {
      await updateHolidays.execute(data);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(keyHolidays);
      }
    }
  );

  const handleInputChange = useCallback(
    (id: string, value: string, key: 'date' | 'title') => {
      methods.setValue(
        'holidays',
        holidays?.map(holiday => (holiday.id === id ? { ...holiday, [key]: value } : holiday))
      );
    },
    [holidays, methods]
  );

  const disableButton = useMemo(() => {
    return !methods.formState.isValid;
  }, [methods.formState.isValid]);

  useEffect(() => {
    if (holidaysDate) {
      methods.reset({ holidays: holidaysDate });
    }
  }, [holidaysDate, methods]);

  return (
    <FormProvider {...methods}>
      <Style.WrapperContainer
        onSubmit={methods.handleSubmit(({ holidays }) => {
          if (!holidays) return;

          holidaysMutation.mutate(holidays);
        })}
      >
        {isLoading || holidaysMutation.isLoading ? (
          <Style.Loading>
            <Style.LoadingLabel>
              <span>Atualizando feriados...</span>
            </Style.LoadingLabel>
          </Style.Loading>
        ) : null}

        <Style.Container>
          {holidays?.map(holiday => (
            <Style.WrapperHoliday key={holiday.id}>
              <Style.RemoveButton
                type='button'
                onClick={() => {
                  methods.setValue(
                    'holidays',
                    holidays.filter(h => h.id !== holiday.id)
                  );
                }}
              >
                <span>×</span>
              </Style.RemoveButton>

              <input
                type='date'
                defaultValue={holiday.date}
                className='form-control'
                onChange={e => {
                  handleInputChange(holiday.id, e.target.value, 'date');
                }}
              />
              <input
                type='text'
                defaultValue={holiday.title}
                className='form-control'
                onChange={e => {
                  handleInputChange(holiday.id, e.target.value, 'title');
                }}
              />
            </Style.WrapperHoliday>
          ))}

          <Style.WrapperHoliday>
            <Style.AddButton
              type='button'
              onClick={() => {
                methods.setValue('holidays', [
                  ...holidays,
                  {
                    id: v4(),
                    date: '',
                    title: ''
                  }
                ]);
              }}
            >
              Adicionar feriado
            </Style.AddButton>
          </Style.WrapperHoliday>
        </Style.Container>

        <button className='btn btn-primary mt-3' disabled={disableButton}>
          Salvar feriados
        </button>
      </Style.WrapperContainer>
    </FormProvider>
  );
}
