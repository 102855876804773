import React from 'react';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { type BulkDeleteReportType } from '../../../types/BulkDeleteReportType';
import ModalBase, { type ModalBaseType } from '../ModalBase';

type BulkDeleteMiniReportModalType = Pick<ModalBaseType, 'message' | 'handleClose'> & {
  reportSubItemsLabel: string;
  report: BulkDeleteReportType['report'];
  reportItemKey?: 'preset_name' | 'model_name';
  reportSubItemsKey: 'project_names' | 'preset_names' | 'model_names';
};

export default function BulkDeleteMiniReportModal({
  message,
  handleClose,
  report,
  reportItemKey,
  reportSubItemsKey,
  reportSubItemsLabel
}: BulkDeleteMiniReportModalType) {
  const { Translate } = useAppTranslation();

  return (
    <ModalBase
      okOnly
      visible={true}
      title={Translate('modal.report-failed-to-delete')}
      message={message}
      style='primary'
      handleClose={handleClose}
      handleConfirm={handleClose}
    >
      <React.Fragment>
        {!!reportItemKey && (
          <section>
            {report.map((reportItem: any, reportIndex: number) => (
              <ul key={reportIndex}>
                <li>
                  <strong>{reportItem[reportItemKey]}</strong>
                </li>

                <i className='d-block mb-1'>{reportSubItemsLabel}</i>
                <ul>
                  {reportItem[reportSubItemsKey].map((subItem: string, subIndex: number) => (
                    <li key={subIndex}>{subItem}</li>
                  ))}
                </ul>
              </ul>
            ))}
          </section>
        )}

        {!Array.isArray(report) && (
          <section>
            <i className='d-block mb-1'>{reportSubItemsLabel}</i>
            <ul>
              {!reportItemKey &&
                report[reportSubItemsKey].map((subItem: string, index: number) => <li key={index}>{subItem}</li>)}
            </ul>
          </section>
        )}
      </React.Fragment>
    </ModalBase>
  );
}
