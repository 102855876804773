import { type AxiosInstance } from 'axios';

type EditProjectStatusParams = {
  httpConnection: AxiosInstance;
  statusId: number;
  statusName: string;
  statusColor: string;
}

export default async function ({ httpConnection, statusName, statusColor, statusId }: EditProjectStatusParams): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    httpConnection.put(`list/clothing-projects/status/${statusId}`, { name: statusName, color: statusColor })
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}
