import React, { type FormEvent, useCallback, useEffect, useState, useRef } from 'react';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import TextInput from '../../Forms/TextInput';
import CurrencyInput from '../../Forms/CurrencyInput';

type TextInputModalType = Omit<ModalBaseType, 'children' | 'handleConfirm'> & {
  label: string;
  currentValue: string;
  renderAsCurrencyInput?: boolean;
  handleConfirm: (response: string) => Promise<void> | void;
};

export default function TextInputModal({
  title,
  message,
  label,
  currentValue,
  renderAsCurrencyInput,
  style,
  visible,
  handleClose,
  handleConfirm,
  zIndexIncrement
}: TextInputModalType) {
  const [inputValue, setInputValue] = useState<string>('');
  const projectNameInputRef = useRef<HTMLInputElement>(null);

  const handleBeforeConfirm = useCallback(() => {
    handleConfirm(inputValue);
    handleClose();
  }, [handleClose, handleConfirm, inputValue]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleBeforeConfirm();
    },
    [handleBeforeConfirm]
  );

  useEffect(() => {
    setInputValue(currentValue);
    if (visible) projectNameInputRef.current?.focus();
  }, [currentValue, visible]);

  return (
    <ModalBase
      visible={visible}
      style={style}
      title={title}
      message={message}
      handleConfirm={handleBeforeConfirm}
      handleClose={handleClose}
      zIndexIncrement={zIndexIncrement}
    >
      <React.Fragment>
        <div className='row'>
          <div className='col'>
            <form onSubmit={handleSubmit}>
              {!renderAsCurrencyInput && (
                <TextInput
                  autofocus
                  inputRef={projectNameInputRef}
                  testId='project-header-name-input'
                  id='text-input-modal'
                  label={label}
                  value={inputValue}
                  onChange={({ target }) => {
                    setInputValue(target.value);
                  }}
                />
              )}

              {renderAsCurrencyInput && (
                <CurrencyInput
                  id='text-input-modal-currency'
                  label={label}
                  value={parseFloat(inputValue)}
                  onChange={(value) => {
                    setInputValue(value.toString());
                  }}
                />
              )}
            </form>
          </div>
        </div>
      </React.Fragment>
    </ModalBase>
  );
}
