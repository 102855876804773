import { useCallback, useMemo, useState } from 'react';
import { CountCard, ListCardHeader, Title } from '../../styles';
import { type KanbanColumn } from '../../../../../../../types/Kanban/KanbanColumn';
import { useKanbanView } from '../../../../hooks/useKanbanView';
import ConfirmationModal from '../../../../../../../components/Modals/ConfirmationModal';
import { WrapperTitle } from './styles';
import TextInputModal from '../../../../../../../components/Modals/TextInputModal';
import _ from 'lodash';
import { useUser } from '../../../../../../../contexts/UserContext';
import { DropdownMenu } from './fragments/DropdownMenu';

type ColumnHeaderType = {
  title: string;
  column: KanbanColumn;
  first?: boolean;
  color: string;
  handleSetColumnColor: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
};

export const ColumnHeader = ({ title, column, first, color, handleSetColumnColor }: ColumnHeaderType) => {
  const { isCompanyAccount } = useUser();
  const { deleteColumnMutation, renameColumnMutation } = useKanbanView();
  const [columnToDelete, setColumnToDelete] = useState<KanbanColumn | null>(null);
  const [columnToEdit, setColumnToEdit] = useState<KanbanColumn | null>(null);

  const handleEditColumnName = useCallback(
    async (updatedTitle: string) => {
      if (!columnToEdit) return;

      if (updatedTitle === columnToEdit.title) return;

      renameColumnMutation.mutate({ columnId: columnToEdit.id, title: updatedTitle });
    },
    [columnToEdit, renameColumnMutation]
  );

  const handleDeleteColumn = useCallback(() => {
    if (!columnToDelete) return;

    const { id } = columnToDelete;
    deleteColumnMutation.mutate(id);
    setColumnToDelete(null);
  }, [columnToDelete, deleteColumnMutation]);

  const qtdColumnPiece = useMemo(() => {
    const columnsPieceLength = column?.cards?.reduce((acc, card) => {
      if (!_.isEmpty(card.sublist)) {
        return card.sublist.orders.reduce((acc, order) => {
          return order.clothes.reduce((acc, cloth) => {
            return acc + (cloth.quantity ?? 0);
          }, acc);
        }, acc);
      }

      if (!_.isEmpty(card.clothing_project)) {
        return card.clothing_project.sublists.reduce((acc, sublist) => {
          if (sublist.card) {
            return acc + 0;
          }

          return sublist.orders.reduce((acc, order) => {
            return order.clothes.reduce((acc, cloth) => {
              return acc + (cloth.quantity ?? 0);
            }, acc);
          }, acc);
        }, acc);
      }

      return acc + (card?.quantity_piece ?? 0);
    }, 0);

    return _.isEqual(columnsPieceLength, 0) ? 'nenhuma peça' : `${columnsPieceLength} peças`;
  }, [column?.cards]);

  const handleSetDeleteColumn = useCallback(() => {
    setColumnToDelete(column);
  }, [column]);

  return (
    <>
      <TextInputModal
        visible={!!columnToEdit}
        title='Editar Coluna'
        message='Com as colunas você pode adicionar cartões de tarefas e gerenciar as etapas dos seus projetos.'
        label='Digite o novo nome da coluna:'
        style='primary'
        currentValue={columnToEdit?.title ?? ''}
        handleClose={() => {
          setColumnToEdit(null);
        }}
        handleConfirm={handleEditColumnName}
      />

      <ConfirmationModal
        visible={!!columnToDelete}
        title='Excluir Coluna'
        message='Tem certeza que deseja remover a coluna?'
        style='danger'
        handleClose={() => {
          setColumnToDelete(null);
        }}
        handleConfirm={() => {
          handleDeleteColumn();
        }}
      />

      <ListCardHeader>
        <WrapperTitle>
          <CountCard>{column?.cards?.length}</CountCard>

          <Title
            title={title}
            onClick={() => {
              isCompanyAccount && setColumnToEdit(column);
            }}
          >
            <span>{title}</span>
          </Title>

          <span>{qtdColumnPiece}</span>
        </WrapperTitle>

        {isCompanyAccount ? (
          <DropdownMenu
            first={first}
            handleSetDeleteColumn={handleSetDeleteColumn}
            color={color}
            handleSetColumnColor={handleSetColumnColor}
            column={column}
          />
        ) : null}
      </ListCardHeader>
    </>
  );
};
