import { Container } from './styles';

type BadgeToggleType = {
  textOn: string;
  textOff: string;
  label?: string;
  checked: boolean;
  formControl?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export default function BadgeToggle({
  checked,
  label = '',
  textOn,
  textOff,
  formControl = false,
  onClick,
  disabled = false
}: BadgeToggleType) {
  return label !== '' ? (
    <div className='form-group'>
      <label>{label}</label>
      <Container
        disabled={disabled}
        className={`badge badge-${checked ? 'success' : 'danger'}`}
        formControl={formControl}
        onClick={!disabled ? onClick : () => {}}
      >
        {checked ? textOn : textOff}
      </Container>
    </div>
  ) : (
    <Container
      disabled={disabled}
      className={`badge badge-${checked ? 'success' : 'danger'}`}
      formControl={formControl}
      onClick={!disabled ? onClick : () => {}}
    >
      {checked ? textOn : textOff}
    </Container>
  );
}
