/* eslint-disable @typescript-eslint/indent */
import { type TextInputProps } from '../TextInput';

type TextInputGroupOneButtonRightProps = Omit<TextInputProps, 'helpText'> & {
  buttonText: string;
  buttonDisabled?: boolean;
  handleButtonClick: () => void;
};

export default function TextInputGroupOneButtonRight({
  id,
  label,
  value,
  buttonText,
  buttonDisabled = false,
  placeholder = '',
  onChange,
  readonly = false,
  handleBlur,
  handleButtonClick
}: TextInputGroupOneButtonRightProps) {
  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <div className='input-group mb-3'>
        <input
          id={id}
          type='text'
          className='form-control'
          value={value}
          readOnly={readonly}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={handleBlur}
        />
        <div className='input-group-append'>
          <button className='btn btn-primary' type='button' onClick={handleButtonClick} disabled={buttonDisabled}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}
