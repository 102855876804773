import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { FormProvider } from 'react-hook-form';

import { useKanbanWorkspaces } from '../../../../contexts/KanbanWorkspacesContext';
import { useUser } from '../../../../contexts/UserContext';
import { useKanbanView } from '../../../../pages/Authenticated/KanbanView/hooks/useKanbanView';
import { CancelButton, Container, ModalFooter, SubmitButton, WrapperButtons } from '../../style';

import { UpdateModalContent } from './fragments/UpdateModalContent';
import { History } from './fragments/History';
import { getCardHistory } from './fragments/History/services';
import { Header } from './fragments/Header';
import { CenterCardModal } from '../CenterCardModal';

type UpdateCardProps = {
  isExpanded: boolean;
  handleExpand: () => void;
};

export function UpdateCard({ isExpanded, handleExpand }: UpdateCardProps) {
  const { isCompanyAccount } = useUser();
  const { kanbanCard, handleShowUpdateModal } = useKanbanWorkspaces();
  const { updateCardMutation, deleteCardMutation, methods } = useKanbanView();

  const { data: CardHistoryData, isLoading } = useQuery(
    [`cardHistory-${kanbanCard!.id}`],
    async () => {
      const response = await getCardHistory.execute({ cardId: kanbanCard!.id });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false
    }
  );

  const onUpdate = useCallback(
    (data: any) => {
      updateCardMutation.mutate({
        cardId: kanbanCard!.id,
        title: data.title,
        description: data?.description,
        taskList: data.taskList,
        attachments: data.attachments,
        quantity_piece: data.quantity_piece,
        date: {
          start: data.date.start,
          end: data.date.end
        },
        priority: data.priority
      });
    },
    [kanbanCard, updateCardMutation]
  );

  const renderSubmitButton = useMemo(() => {
    return (
      <WrapperButtons>
        {isCompanyAccount ? (
          <CancelButton
            type='button'
            disabled={deleteCardMutation.isLoading}
            onClick={() => {
              deleteCardMutation.mutate({ cardId: kanbanCard!.id });
            }}
          >
            {deleteCardMutation.isLoading ? <i className='fa fa-spinner fa-spin' /> : 'Deletar Card'}
          </CancelButton>
        ) : null}

        <SubmitButton disabled={updateCardMutation.isLoading}>
          {updateCardMutation.isLoading ? 'Atualizando...' : 'Atualizar'}
        </SubmitButton>
      </WrapperButtons>
    );
  }, [deleteCardMutation, isCompanyAccount, kanbanCard, updateCardMutation.isLoading]);

  return (
    <FormProvider {...methods}>
      <Container
        onSubmit={methods.handleSubmit(onUpdate)}
        onReset={() => {
          methods.reset();
          handleShowUpdateModal();
        }}
      >
        <CenterCardModal isExpanded={isExpanded}>
          <Header handleExpand={handleExpand} isExpanded={isExpanded} />

          <UpdateModalContent
            history={<History isExpanded={isExpanded} cardHistoryData={CardHistoryData ?? []} cardLoading={isLoading} />}
          />

          <ModalFooter>
            <CancelButton type='reset'>Cancelar</CancelButton>
            {renderSubmitButton}
          </ModalFooter>
        </CenterCardModal>
      </Container>
    </FormProvider>
  );
}
