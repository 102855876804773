import { format } from 'date-fns';
import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';
import _ from 'lodash';

type GetAllInventoriesParams = {
  startDate: Date;
  endDate: Date;
  supplier: string;
};

export class GetAllInventories {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ startDate, endDate, supplier }: GetAllInventoriesParams) {
    const params = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd')
    };

    if (!_.isEmpty(supplier)) {
      Object.assign(params, { supplier });
    }

    return await this.http.get(`/inventories?${new URLSearchParams(params).toString()}`);
  }
}
