import { type AxiosInstance } from 'axios';
import { type ProjectsDeliveryCalendarType } from '../../../../types/ProjectsDeliveryCalendarType';
import { type SupportedLanguages } from '../../../../types/SupportedLanguages';
import pt from '@fullcalendar/core/locales/pt-br';
import en from '@fullcalendar/core/locales/en-au';
import es from '@fullcalendar/core/locales/es-us';
import { type LocaleInput } from '@fullcalendar/core';

export const getUpdatedProjectsDeliveryCalendar = async (httpConnection: AxiosInstance): Promise<ProjectsDeliveryCalendarType[]> => {
  return await new Promise<ProjectsDeliveryCalendarType[]>((resolve, reject) => {
    httpConnection.get<ProjectsDeliveryCalendarType[]>('list/projects-delivery-calendar')
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const parseLanguageCodeToFullCalendarLocale = (language: SupportedLanguages): LocaleInput => {
  if (language === 'pt') return pt;
  if (language === 'en') return en;
  if (language === 'es') return es;

  return en;
};
