import { type TFunction } from 'i18next';
import { type ProjectType } from '../../../../../../../types/ProjectType';

export const getBadgeStatusColorForProject = (project: ProjectType): string => {
  if (project.final_client_link_offline) return 'danger';
  if (project.final_client_readonly) return 'warning';
  if (!project.final_client_readonly && !project.final_client_link_offline) return 'success';

  return 'light';
};

export const getBadgeStatusTextForProject = (project: ProjectType, Translate: TFunction): string => {
  if (project.final_client_link_offline) return Translate('status.offline').toUpperCase();
  if (project.final_client_readonly) return Translate('status.read-only').toUpperCase();
  if (!project.final_client_readonly && !project.final_client_link_offline) return Translate('status.online').toUpperCase();

  return '*';
};
