import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import { ptBR, enUS, esES } from './translation';
import { isDev } from './utils/helper';

// setup available languages
const resources = {
  pt: { translation: ptBR },
  en: { translation: enUS },
  es: { translation: esES }
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  detection: {
    order: ['localStorage', 'cookie', 'navigator', 'htmlTag', 'path'],
    caches: ['cookie']
  },
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  debug: isDev() // show missing translation keys in console
});

export default i18n;
