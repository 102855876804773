import { createContext, type PropsWithChildren, useCallback, useContext, useState } from 'react';
import { type KanbanColumn } from '../types/Kanban/KanbanColumn';
import { type KanbanCard } from '../types/Kanban/KanbanCard';

type KanbanWorkspaceContextProps = {
  handleShowCreateModal: (column?: KanbanColumn) => void;
  handleShowUpdateModal: (card?: KanbanCard) => void;
  kanbanCard?: KanbanCard;
  showCreateCard?: KanbanColumn;
};

const KanbanWorkspaceContext = createContext<KanbanWorkspaceContextProps | undefined>({} as KanbanWorkspaceContextProps);

export const KanbanWorkspacesProvider = ({ children }: PropsWithChildren) => {
  const [showCreateCard, setShowCreateCard] = useState<KanbanColumn | undefined>();
  const [kanbanCard, setKanbanCard] = useState<KanbanCard | undefined>();

  const handleShowCreateModal = useCallback((column?: KanbanColumn) => {
    setShowCreateCard(column);
  }, []);

  const handleShowUpdateModal = useCallback((card?: KanbanCard) => {
    setKanbanCard(card);
  }, []);

  return (
    <KanbanWorkspaceContext.Provider value={{ handleShowCreateModal, showCreateCard, kanbanCard, handleShowUpdateModal }}>
      {children}
    </KanbanWorkspaceContext.Provider>
  );
};

export const useKanbanWorkspaces = () => {
  const context = useContext(KanbanWorkspaceContext);

  if (!context) {
    throw new Error('useKanbanWorkspaces must be used within a KanbanWorkspacesProvider');
  }

  return context;
};
