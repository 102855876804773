import { ContentCard } from '../../../Register/style';

export type Sections = 'customer' | 'supplier' | 'seamstress';

type SectionsOptionsProps = {
  section: string;
  handleSection: (section: Sections) => void;
};

export function SectionsOptions({ section, handleSection }: SectionsOptionsProps) {
  return (
    <ContentCard>
      <div className='d-flex justify-content-around flex-lg-column' style={{ gap: 20 }}>
        <button
          className={`btn btn-outline-primary flex-grow-1 ${section === 'customer' ? 'active' : ''}`}
          type='button'
          onClick={() => {
            handleSection('customer');
          }}
        >
          Clientes
        </button>

        <button
          className={`btn btn-outline-primary flex-grow-1 ${section === 'seamstress' ? 'active' : ''}`}
          type='button'
          onClick={() => {
            handleSection('seamstress');
          }}
        >
          Costureiras
        </button>

        <button
          className={`btn btn-outline-primary flex-grow-1 ${section === 'supplier' ? 'active' : ''}`}
          type='button'
          onClick={() => {
            handleSection('supplier');
          }}
        >
          Fornecedor
        </button>
      </div>
    </ContentCard>
  );
}
