import * as Style from './style';

import { Header } from '../components/Header';
import { Columns } from './Columns';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import { showWorkspace } from './services';
import _ from 'lodash';
import { Column } from './Column';

export function ShowWorkspace() {
  const navigate = useNavigate();
  const params = useParams<{ slug?: string }>();
  const showWorkspaceKey = `workspace-params-${params.slug}`;

  const { data: workspace } = useQuery(
    showWorkspaceKey,
    async () => {
      return await showWorkspace.execute({ slug: params.slug });
    },
    {
      enabled: !!params.slug
    }
  );

  if (_.isEmpty(workspace)) {
    return (
      <div>
        <div>Workspace não existe</div>
        <button
          onClick={() => {
            navigate('/pcp/workspaces');
          }}
        >
          Voltar
        </button>
      </div>
    );
  }

  return (
    <Style.Container>
      <Header title={workspace.title} subtitle='Esse será apenas um teste do workspace' showForm />

      <Style.WrapperContent className='workspace-content'>
        <Columns>
          {workspace?.columns?.map(column => (
            <Column key={column.id} column={column} />
          ))}
        </Columns>
      </Style.WrapperContent>

      <Style.GlobalWorkspace />
    </Style.Container>
  );
}
