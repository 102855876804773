import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';
import { type KanbanWorkspaceUpdatedResponse } from '../../../../types/Kanban/KanbanWorkspaceUpdateResonse';
import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';

export class UpdateWorkspaceTitle {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(workspace: KanbanWorkspace): Promise<KanbanWorkspaceUpdatedResponse> {
    return await this.http.put<KanbanWorkspaceUpdatedResponse>(`kanban/workspaces/title/${workspace.id}`, {
      title: workspace.title
    });
  }
}
