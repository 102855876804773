import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type RouteHelpersType = {
  isActiveFirstRouteSection: (routeSection: string) => string;
  isActivePage: (routeSection: string, pageRoute: string) => string;
  isExpandedSideMenuOption: (routeSection: string, trueStr: string, falseStr: string) => string;
};

const useRouteHelpers = (): RouteHelpersType => {
  const location = useLocation();

  const isActiveFirstRouteSection = useCallback(
    (routeSection: string): string => {
      const pathSegment = location.pathname.replace('/', '').split('/')[0];
      return routeSection === pathSegment ? 'active' : '';
    },
    [location]
  );

  const isActivePage = useCallback(
    (routeSection: string, pageRoute: string) => {
      const path = location.pathname.replace('/', '');
      return path === `${routeSection}/${pageRoute}` ? 'active' : '';
    },
    [location]
  );

  const isExpandedSideMenuOption = useCallback(
    (routeSection: string, trueStr: string, falseStr: string) => {
      const pathSegment = location.pathname.replace('/', '').split('/')[0];
      return routeSection === pathSegment ? trueStr : falseStr;
    },
    [location]
  );

  return { isActiveFirstRouteSection, isActivePage, isExpandedSideMenuOption };
};

export default useRouteHelpers;
