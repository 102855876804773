import { useCallback, useMemo } from 'react';
import { v4 as uniqueId } from 'uuid';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import { validationSchema } from './validationSchema';
import { createWorkspace } from './WorkingProcess/services';
import { type CreateWorkspaceDTO } from './WorkingProcess/services/CreateWorkspace';

import { FormWorkspace } from '../components/FormWorkspace';

export function AddWorkspace() {
  const navigate = useNavigate();
  const createWorkingMutation = useMutation(
    async (data: CreateWorkspaceDTO) => {
      await createWorkspace.execute(data);
    },
    {
      onSuccess: () => {
        navigate('/pcp/workspaces');
      }
    }
  );

  const methods = useForm({
    defaultValues: {
      title: '',
      workingProcess: [
        {
          id: uniqueId(),
          title: '',
          daily_amount: 0,
          position: 0
        }
      ] as Array<{ id: string; title: string; daily_amount: number; position: number }>
    },
    resolver: yupResolver(validationSchema)
  });

  const disableButton = useMemo(() => {
    return !methods.formState.isValid || createWorkingMutation.isLoading;
  }, [createWorkingMutation.isLoading, methods.formState.isValid]);

  const onSubmit = useCallback(
    (data: any) => {
      createWorkingMutation.mutate(data);
    },
    [createWorkingMutation]
  );

  return (
    <FormWorkspace disableButton={disableButton} methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      {createWorkingMutation.isLoading ? 'Criando workspace...' : 'Criar workspace'}
    </FormWorkspace>
  );
}
