import styled from 'styled-components';

type ContainerType = {
  disabled: boolean;
}

export const Container = styled.span.withConfig({ shouldForwardProp: prop => prop !== 'disabled' }) <ContainerType>`
  cursor: ${props => !props.disabled ? 'pointer' : 'auto'};

  &:hover {
    text-decoration: ${props => !props.disabled ? 'underline' : 'none'};
  }
`;
