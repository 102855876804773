import React, { useCallback, useRef, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import SwitchInput from '../../Forms/SwitchInput';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import TextInput from '../../Forms/TextInput';
import SignaturePad from 'react-signature-pad-wrapper';
import { type UserPreferences } from '../../../types/UserPreferences';
import SplitButton from '../../Buttons/SplitButton';
import { IconsCatalog } from '../../IconsCatalog';
import { toast } from 'react-hot-toast-promise';
import { processUserSignatureOnAcceptToProduction } from './services/signatureCheckerService';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

export type SignatureResponseType = {
  clientNameAccepting: string;
  signatureImageData: string;
}

type ConfirmSaveFinalClientChangesModalType = Pick<ModalBaseType, 'visible' | 'handleClose'> & {
  preferences: UserPreferences;
  handleConfirm: (data: SignatureResponseType) => void;
}

export default function ConfirmSaveFinalClientChangesModal({
  visible,
  handleClose,
  handleConfirm,
  preferences
}: ConfirmSaveFinalClientChangesModalType) {
  const [userAcceptedSave, setUserAcceptedSave] = useState(false);
  const [userAcceptedDataResponsibility, setUserAcceptedDataResponsibility] = useState(false);
  const [clientNameAccepting, setClientNameAccepting] = useState('');

  const { Translate } = useAppTranslation();

  const signaturePadRef = useRef<SignaturePad>(null);

  const resetUserChecks = useCallback(() => {
    setUserAcceptedSave(false);
    setUserAcceptedDataResponsibility(false);
  }, []);

  const handleBeforeConfirm = useCallback(async () => {
    try {
      if (!signaturePadRef.current) return;

      const signatureImageData = await processUserSignatureOnAcceptToProduction(signaturePadRef, Translate);

      handleConfirm({ clientNameAccepting, signatureImageData });
      handleClose();
      resetUserChecks();
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, clientNameAccepting, handleClose, handleConfirm, resetUserChecks]);

  const handleBeforeClose = useCallback(() => {
    handleClose();
    resetUserChecks();
  }, [handleClose, resetUserChecks]);

  return (
    <ModalBase
      visible={visible}
      size={'lg'}
      handleClose={handleBeforeClose}
      handleConfirm={handleBeforeConfirm}
      confirmationButtonDisabled={!userAcceptedSave || !userAcceptedDataResponsibility}
      disableClickOutsideToClose
      style='primary'
      title={Translate('modal.confirm-order')}
      message={Translate('description.final-client-confirm-changes-message')}
    >
      <>
        <SwitchInput
          checked={userAcceptedSave}
          id='accept-save'
          label={Translate('description.final-client-aware-of-changes')}
          handleChange={checked => {
            setUserAcceptedSave(checked);
          }}
        />

        <SwitchInput
          checked={userAcceptedDataResponsibility}
          id='accept-data-responsibility'
          label={Translate('description.final-client-data-responsibility')}
          handleChange={checked => {
            setUserAcceptedDataResponsibility(checked);
          }}
        />

        {!!preferences.acceptance_message && (
          <div className='alert alert-primary mt-3'>
            <h6 className='text-primary font-weight-bold'>{Translate('labels.company-terms')}</h6>
            <p className='font-weight-bold mb-0'>{preferences.acceptance_message}</p>
          </div>
        )}

        <TextInput
          id='user-signature-accept'
          label={Translate('labels.responsable')}
          placeholder={Translate('labels.full-name')}
          value={clientNameAccepting}
          onChange={({ target }) => {
            setClientNameAccepting(target.value);
          }}
        />

        <h6 className='text-primary font-weight-bold mt-2'>{Translate('labels.digital-signature')}</h6>
        <div className='border border-primary'>
          <SignaturePad ref={signaturePadRef} options={{ minWidth: 1, maxWidth: 5, penColor: '#000', backgroundColor: '#fff' }} />
        </div>

        <div className='mt-2'>
          <SplitButton
            title={Translate('actions.clear-signature')}
            color='secondary'
            icon={IconsCatalog.sync}
            size='sm'
            handleClick={() => {
              signaturePadRef?.current?.clear();
            }}
          />
        </div>

      </>
    </ModalBase>
  );
}
