import { Outlet } from 'react-router';
import { PCPContext } from '../../../contexts/PCPContext';

export function PCP() {
  return (
    <PCPContext>
      <Outlet />
    </PCPContext>
  );
}
