import { type AxiosResponse, type AxiosInstance } from 'axios';
import { type FinishingOption } from '../../../../../../types/ClothingFinishingOptions/FinishingOption';

type AddFinishingOptionParams = {
  httpConnection: AxiosInstance;
  title: string;
}

export default async function ({ httpConnection, title }: AddFinishingOptionParams): Promise<FinishingOption> {
  return await new Promise<FinishingOption>((resolve, reject) => {
    httpConnection.post<FinishingOption>('list/finishing-options', { title })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        const error = err as AxiosResponse;
        reject(error);
      });
  });
}
