import React, { useCallback, useState } from 'react';
import { LogoViewer } from './styles';
import SplitButton from '../Buttons/SplitButton';
import { IconsCatalog } from '../IconsCatalog';
import CompanyLogoPickerModal from '../Modals/CompanyLogoPickerModal';
import { useUser } from '../../contexts/UserContext';
import { toast } from 'react-hot-toast-promise';
import Spinner from '../Spinner';
import { useAppTranslation } from '../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../utils/helper';

export default function CompanyLogo() {
  const [showLogoPickerModal, setShowLogoPickerModal] = useState(false);
  const [isRemovingLogo, setIsRemovingLogo] = useState(false);

  const { Translate } = useAppTranslation();
  const { user, isEmployeeAccount, removeLogoFromUser } = useUser();

  const handleRemoveLogoFromUser = useCallback(async () => {
    try {
      setIsRemovingLogo(true);
      await removeLogoFromUser();
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsRemovingLogo(false);
    }
  }, [removeLogoFromUser]);

  return (
    <React.Fragment>
      {showLogoPickerModal && (
        <CompanyLogoPickerModal
          handleClose={() => {
            setShowLogoPickerModal(false);
          }}
        />
      )}

      <div className='d-flex align-items-center'>
        {!!user?.logo && <LogoViewer src={user.logo} className='img-fluid mb-3' />}

        {!user?.logo && <i className={`${IconsCatalog.camera} fa-fw mb-3`} />}
      </div>

      {!isEmployeeAccount && (
        <div className='d-flex'>
          {!user?.logo && (
            <SplitButton
              color='primary'
              size='sm'
              icon={IconsCatalog.camera}
              title={Translate('actions.send-image')}
              handleClick={() => {
                setShowLogoPickerModal(true);
              }}
            />
          )}

          {user?.logo && !isRemovingLogo && (
            <SplitButton
              color='danger'
              size='sm'
              icon={IconsCatalog.times}
              title={Translate('actions.remove')}
              handleClick={handleRemoveLogoFromUser}
            />
          )}

          {isRemovingLogo && <Spinner />}
        </div>
      )}
    </React.Fragment>
  );
}
