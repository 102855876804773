import { type TableCellUpdateType } from '../../../../../../../types/ListContentTable/TableCellUpdateType';
import { moveElementInSameLine } from './extractedContentTableService';

export const fillExtractedContentWithEmptyItems = (extractedContent: string[], expectedLength: number) => {
  if (extractedContent.length === expectedLength) return extractedContent;

  const missingFields = expectedLength - extractedContent.length;

  const emptyItemsToPush: string[] = Array(missingFields).fill('');

  return [...extractedContent, ...emptyItemsToPush];
};

type MoveCellInRowParams = {
  row: string[];
  sourceIndex: number;
  destinationIndex: number;
}

export const moveCellInRow = ({ row, sourceIndex, destinationIndex }: MoveCellInRowParams) => {
  const updatedRow = moveElementInSameLine(row, sourceIndex, destinationIndex);
  return updatedRow;
};

export const updateCellInExtractedContentTable = ({ coords, updatedText }: TableCellUpdateType, extractedContent: string[][]) => {
  return extractedContent.map((row, rowIndex) => {
    if (rowIndex === coords.row) {
      return row.map((col, colIndex) => {
        if (colIndex === coords.col) return updatedText;
        return col;
      });
    }
    return row;
  });
};

export const updateExtractedContent = (extractedContent: string[][], updatedRow: string[], updatedRowIndex: number) => {
  return extractedContent.map((row, index) => {
    if (updatedRowIndex === index) return updatedRow;
    return row;
  });
};
