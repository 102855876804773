import { type AxiosInstance } from 'axios';
import { type KanbanColumn } from '../../../../types/Kanban/KanbanColumn';

export type CreateColumnParams = Pick<KanbanColumn, 'workspace_id' | 'title'>;

export const createColumn = async (data: CreateColumnParams, httpConnection: AxiosInstance): Promise<KanbanColumn> => {
  return await new Promise<KanbanColumn>((resolve, reject) => {
    httpConnection
      .post<KanbanColumn>('kanban/workspaces/columns', data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
