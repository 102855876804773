import { type ProjectStatusType } from '../../../../../../types/ProjectStatusType';
import { type TFunction } from 'i18next';

type IsDuplicatedProjectStatusParams = {
  projectStatus: ProjectStatusType;
  projectStatusList: ProjectStatusType[];
  Translate: TFunction;
}

export const isDuplicatedProjectStatus = ({ projectStatus, projectStatusList, Translate }: IsDuplicatedProjectStatusParams) => {
  const duplicatedStatus = projectStatusList.find(currentProjectStatus => currentProjectStatus.name.toUpperCase() === projectStatus.name.toUpperCase());
  const isEditing = !!projectStatus.id ?? false;

  if (!duplicatedStatus) return false;

  const hasNoChanges = isEditing &&
    duplicatedStatus &&
    duplicatedStatus.id === projectStatus.id &&
    duplicatedStatus.color === projectStatus.color;

  if (hasNoChanges) throw new Error('Sem alterações.');

  const isDuplicatedOnAdd = !isEditing && !!duplicatedStatus;
  const isDuplicatedOnEdit = isEditing && duplicatedStatus && duplicatedStatus.id !== projectStatus.id;

  if (isDuplicatedOnAdd || isDuplicatedOnEdit) throw new Error(Translate('error.project-status-same-name-already-exists'));

  return false;
};
