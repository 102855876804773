import axios, { type AxiosResponse, type AxiosInstance, type AxiosError } from 'axios';
import { STORAGE_AUTH_TOKEN } from '../../utils/storageItems';
import type BackendErrorMessageType from '../../types/BackendErrorMessage';
import { OfflineError } from '../../Errors/OfflineError';
import { type TFunction } from 'i18next';
import { type SupportedLanguages } from '../../types/SupportedLanguages';

export const loadAuthToken = (): string => {
  const stored = localStorage.getItem(STORAGE_AUTH_TOKEN);

  if (stored) return stored;
  else return '';
};

type CreateConfiguredAxiosInstanceType = {
  bearerToken: string;
  handleResponseError: (error: AxiosResponse) => void;
  selectedLanguage: SupportedLanguages;
  Translate: TFunction;
}

export const createConfiguredAxiosInstance = ({ bearerToken, handleResponseError, selectedLanguage, Translate }: CreateConfiguredAxiosInstanceType): AxiosInstance => {
  const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASEURL,
    timeout: 20 * 1000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: bearerToken ? 'Bearer ' + bearerToken : '',
      'X-LOCALE': selectedLanguage.toString()
    }
  });

  axiosConfig.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError<BackendErrorMessageType>) => {
      // Handle response errors globally here
      if (error.response) {
        handleResponseError(error.response);
        return await Promise.reject(error.response);
      }

      if (error.code === 'ECONNABORTED') {
        return await Promise.reject(new Error(Translate('error.axios-timeout-error'))); // TIMEOUT
      }

      if (error.request) {
        return await Promise.reject(new OfflineError(Translate('error.axios-offline-error')));
      }

      return await Promise.reject(new Error(Translate('error.axios-unkown-error') + ' ' + error.message));
    }
  );

  return axiosConfig;
};
