import { type TFunction } from 'i18next';
import { type ClothingGenderType, type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type Order } from '../../../../../types/OrderTypes';
import { type ComboBoxDataType } from '../../../../../types/ComboBoxDataType';

type getSizeNameFromModelIdParams = {
  gender: Order['gender'];
  models: ClothingModelType[];
  modelId: number;
  sizeIndex: number;
  Translate: TFunction;
}

export const getSizeNameFromModelId = ({ gender, models, modelId, sizeIndex, Translate }: getSizeNameFromModelIdParams): string => {
  if (sizeIndex === -1) return '';

  const model = models.find(currentModel => currentModel.id === modelId);

  if (!model) throw new Error(Translate('error.model-not-found-with-id') + modelId);

  return model.informations[gender][sizeIndex].size;
};

type FindFirstShirtIndexInModelsParams = {
  models: ClothingModelType[];
}

/**
 * @param models list of models to search in. The code will search for CAM1
 * @returns shirt index or 0 by default it no shirts where found
 */
export const findFirstShirtIndexInModels = ({ models }: FindFirstShirtIndexInModelsParams): number => {
  const shirtCodes: Array<ClothingModelType['SBCode']> = ['CAM1', 'CAM2', 'REG1', 'COL1'];

  const shirtIndex = models.findIndex(currentClothe => shirtCodes.includes(currentClothe.SBCode));

  return shirtIndex > -1 ? shirtIndex : 0;
};

export const checkAllModelsHaveEmptyTableSizes = (importedModels: ClothingModelType[]): boolean => {
  for (let importedModelIndex = 0; importedModelIndex < importedModels.length; importedModelIndex++) {
    const genders = ['male', 'female', 'childish'];

    for (let genderKeyIndex = 0; genderKeyIndex < genders.length; genderKeyIndex++) {
      const genderKey = genders[genderKeyIndex] as keyof ClothingGenderType;
      const genderInformations = importedModels[importedModelIndex].informations[genderKey];

      for (let i = 0; i < genderInformations.length; i++) {
        if (genderInformations[i]?.dimensions) {
          return false;
        }
      }
    }
  }

  return true;
};

export const generateComboboxOptionsFromImportedModels = (importedModels: ClothingModelType[]): ComboBoxDataType[] => {
  return importedModels.map((importedModel => ({ label: importedModel.name, value: importedModel.id })));
};

export const findCachedModelById = (importedModels: ClothingModelType[], modelId: number) => {
  return importedModels.find(importedModel => importedModel.id === modelId) ?? null;
};
