import React from 'react';
import { type ProjectShippingType } from '../../../../../types/ProjectShippingType';
import { Container, TrackingIcon } from './styles';
import { IconsCatalog } from '../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../contexts/TranslationContext';

type TrackingNumberType = {
  shipping: ProjectShippingType | null;
}

export default function TrackingNumber({ shipping }: TrackingNumberType) {
  const { Translate } = useAppTranslation();

  if (!shipping) {
    return <h3>{Translate('status.no-tracking-data')}</h3>;
  }

  return (
    <div className="row my-3">
      <div className="col text-center">
        <Container>
          <TrackingIcon className={IconsCatalog.truck} />
          <div className='ml-2'>
            <h3 className='m-0 p-0 text-left font-weight-bold'>{shipping.code}</h3>
            <h5 className='m-0 p-0 text-left'>{shipping.carrier}</h5>
          </div>
        </Container>
      </div>
    </div>
  );
}
