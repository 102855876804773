import React, { useMemo } from 'react';
import { displayAsCurrency, displayFriendlyWeight } from '../../../../../../../../utils/helper';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import { type ProjectReportType } from '../../../../../../../../types/ProjectReportType';
import { type UserPreferences } from '../../../../../../../../types/UserPreferences';

type SublistFooterType = {
  projectReport: ProjectReportType | undefined;
  sublistIndex: number;
  preferences: UserPreferences;
}

export default function SublistFooter({ projectReport, sublistIndex, preferences }: SublistFooterType) {
  const { Translate } = useAppTranslation();

  const currentSublist = useMemo(() => projectReport?.sublists[sublistIndex], [projectReport, sublistIndex]);

  if (!projectReport || !currentSublist) return <></>;

  return (
    <tfoot>
      <tr>
        <td colSpan={999}>
          <div className='row'>
            <div className='col'>
              <strong>{Translate('labels.estimated-weight')}: </strong>
              <span>
                {displayFriendlyWeight(currentSublist?.totalSublistWeight ?? 0)}
              </span>
            </div>

            <div className='col-auto text-right'>
              <strong>{Translate('labels.total-price')}: </strong>
              <span>{displayAsCurrency(currentSublist?.totalSublistPrice ?? 0, preferences.currency)}</span>
            </div>

            {
              currentSublist?.sublistDiscount > 0 && (
                <div className='col-auto text-right border-left'>
                  <strong>{Translate('labels.total-price-with-discount')}: </strong>
                  <span>{displayAsCurrency(currentSublist?.totalSublistPriceWithDiscount ?? 0, preferences.currency)}</span>
                  <span className='badge badge-default'>(-{currentSublist?.sublistDiscount}%)</span>
                </div>
              )
            }
          </div>
        </td>
      </tr>
    </tfoot>
  );
};
