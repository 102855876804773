import styled from 'styled-components';

type CustomInputRangeProps = {
  value: number;
  min: number;
  max: number;
}

export const CustomInputRange = styled.input.attrs<CustomInputRangeProps>(({ min = 0, max = 100 }) => ({
  type: 'range',
  min,
  max
}))`
  /* Custom styling for the track */
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: linear-gradient(to right, var(--primary) ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%, #e0e0e0 ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%);

  &::-webkit-slider-thumb {
    /* Custom styling for the handler (Webkit browsers) */
    appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 5px;
    background: var(--primary);
    cursor: pointer;
    margin-top: -2px;
    z-index: 2;
    box-shadow: 0px 0px 2px 1px #666;
  }

  &::-moz-range-thumb {
    /* Custom styling for the handler (Firefox) */
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background: var(--primary);
    cursor: pointer;
    margin-top: -5px; /* Center the handler vertically */
    z-index: 2; /* Ensure the handler appears on top of the filled area */
  }

  &:focus {
    /* Remove the default focus styling */
    outline: none;
  }
`;
