import { ModalButton, SubmitButton } from '../../style';
import { Container, Footer, Item, ItemLabel, ItemOption, Modal, Selected, WrapperModalContent } from './style';
import _ from 'lodash';
import { useChangeWorkspace } from './hooks/useChangeWorkspace';
import { useUser } from '../../../../contexts/UserContext';

type ChangeWorkspaceProps = {
  cardId?: number;
};

export function ChangeWorkspace({ cardId }: ChangeWorkspaceProps) {
  const { isEmployeeAccount } = useUser();
  const {
    workspaceModalRef,
    buttonRef,
    openWorkspaces,
    selected,
    filterWorkspaces,
    handleChangeSelectedWorkspace,
    handleSetOpenWorkspaces,
    handleChangeWorkspace
  } = useChangeWorkspace();

  if (isEmployeeAccount) return null;

  return (
    <Container>
      <ModalButton type='button' ref={buttonRef} onClick={handleSetOpenWorkspaces}>
        <i className={`fa ${openWorkspaces ? 'fa-folder-open' : 'fa-folder'}`}></i>
      </ModalButton>

      {openWorkspaces ? (
        <Modal ref={workspaceModalRef}>
          <WrapperModalContent>
            {filterWorkspaces.map(workspace => {
              const selectedItem = _.isEqual(selected, workspace.id);
              return (
                <Item
                  key={workspace.id.toString()}
                  selectedItem={selectedItem}
                  onClick={() => {
                    handleChangeSelectedWorkspace(workspace.id);
                  }}
                >
                  <ItemLabel>{workspace.title}</ItemLabel>
                  <ItemOption>{selectedItem ? <Selected /> : null}</ItemOption>
                </Item>
              );
            })}
          </WrapperModalContent>

          <Footer>
            <SubmitButton
              disabled={_.isNil(selected)}
              type='button'
              onClick={() => {
                handleChangeWorkspace.mutate({ card_id: cardId, workspace_id: selected as number });
              }}
            >
              Mudar de Estação
            </SubmitButton>
          </Footer>
        </Modal>
      ) : null}
    </Container>
  );
}
