import { type AxiosAdapters } from '../../../../../../../helpers/adapters/http/AxiosAdapters';

export type CreateCardMutationParams = {
  sublistTitle: string;
  workspaceId: string;
  sublistId: number;
};

export class CreateCardSublist {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ sublistId, workspaceId, sublistTitle }: CreateCardMutationParams) {
    return await this.http.post('list/clothing-projects/create-card', { sublistId, workspaceId, sublistTitle });
  }
}
