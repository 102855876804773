import React, { useCallback, useMemo } from 'react';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';

type SublistFinishingOptionsViewerType = {
  importedModels: ClothingModelType[];
  sublist: SublistType;
  alignLeft?: boolean;
}

export default function SublistFinishingOptionsViewer({ sublist, importedModels, alignLeft }: SublistFinishingOptionsViewerType) {
  const { Translate } = useAppTranslation();

  const isEmptyFinishingOptionsForModelId = useCallback((modelId: number) => {
    const selectedFinishingOptions = sublist.selected_finishing_options.filter(finishingOption => finishingOption.model_id === modelId);
    return selectedFinishingOptions.length === 0;
  }, [sublist.selected_finishing_options]);

  const getFinishingOptionsAsConcatenatedStringForModelId = useCallback((modelId: number) => {
    const selectedFinishingOptions = sublist.selected_finishing_options.filter(finishingOption => finishingOption.model_id === modelId);

    return selectedFinishingOptions.map(({ option, option_picked }, index) => (
      <tr key={index}>
        <td>{option.title}</td>
        <td>{option_picked.item}</td>
      </tr>
    ));
  }, [sublist.selected_finishing_options]);

  const horizontalAlignment = useMemo(() => alignLeft ? '' : 'mx-auto', [alignLeft]);

  if (sublist.selected_finishing_options.length === 0) return <></>;

  return (
    <table className={`table table-sm table-bordered w-auto ${horizontalAlignment} my-4`}>
      <thead>
        <tr>
          <th>{Translate('labels.model')}</th>
          <th>{Translate('labels.finishing-option')}</th>
        </tr>
      </thead>

      <tbody>
        {
          importedModels.map((model, modelIndex) => {
            if (isEmptyFinishingOptionsForModelId(model.id)) return <></>;

            return (
              <tr key={modelIndex}>
                <td className='text-center'>{model.name}</td>
                <td>
                  <table>
                    <tbody>
                      {getFinishingOptionsAsConcatenatedStringForModelId(model.id)}
                    </tbody>
                  </table>
                </td>
              </tr>

            );
          })
        }
      </tbody>
    </table>
  );
};
