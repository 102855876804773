import styled from 'styled-components';

export const WrapperContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const Container = styled.div`
  border-left: 1px #f1f1f1 solid;
  flex: 1;
`;

export const WrapperLine = styled.div`
  display: flex;
  font-size: 14px;
  border-bottom: 1px #f1f1f1 solid;
  padding-bottom: 20px;
`;

export const WrapperInputs = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;

  label {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
`;
