import { type Locale } from 'date-fns';
import React from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { displayCommonDateWithTime } from '../../../../../../helpers/dateTime';
import { type UserChangesHistoryType } from '../../../../../../types/UserChangesHistoryType';

type UserHistoryChangesModalListType = Pick<ModalBaseType, 'title' | 'message' | 'visible' | 'handleClose'> & {
  userChangesHistory: UserChangesHistoryType[] | null;
  dateFnsLocale: Locale;
}

export default function UserHistoryChangesModalList({ visible, title, message, handleClose, userChangesHistory, dateFnsLocale }: UserHistoryChangesModalListType) {
  const { Translate } = useAppTranslation();

  return (
    <ModalBase
      okOnly
      visible={visible}
      title={title}
      message={message}
      style='primary'
      size={'lg'}
      handleClose={handleClose}
      handleConfirm={handleClose}
    >
      <React.Fragment>

        <table className='table table-sm table-bordered table-hover'>
          <thead>
            <tr>
              <th>{Translate('labels.name')}</th>
              <th>E-mail</th>
              <th>{Translate('labels.date')}</th>
            </tr>
          </thead>
          <tbody>
            {
              userChangesHistory?.map(history => (
                <tr key={history.id}>
                  <td>{history.user.name}</td>
                  <td>{history.user.email}</td>
                  <td>{displayCommonDateWithTime(new Date(history.updated_at), dateFnsLocale)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>

      </React.Fragment>
    </ModalBase>
  );
};
