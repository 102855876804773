import styled from 'styled-components';

export const RemoveColumnButton = styled.button`
  margin-left: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  span {
    color: var(--black);
    font-size: 12px;

    &:hover {
      color: var(--danger);
    }
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;

  span {
    white-space: nowrap;
  }
`;
