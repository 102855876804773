import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';
import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';

export class CreateWorkspace {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(title: string): Promise<KanbanWorkspace> {
    return await this.http.post<KanbanWorkspace>('kanban/workspaces', { title });
  }
}
