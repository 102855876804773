import { type TFunction } from 'i18next';
import {
  type ClothingGenderType,
  type ClothingSizeType,
  type ClothingModelCreationType,
  type ClothingModelStoreUpdateRequestType,
  type ClothingModelType
} from '../../../../../types/ClothingModelType';
import { EmptyNameException } from '../CustomExceptions/EmptyNameException';
import { NullIconException } from '../CustomExceptions/NullIconException';

type BaseParamsToChangeClothingSizeAndPrices = {
  modelPricingInformations: ClothingGenderType;
  gender: keyof ClothingGenderType;
  targetIndex: number;
};

export type ChangeSizeNameParams = BaseParamsToChangeClothingSizeAndPrices & {
  updatedSize: string;
};

export type ChangeSizePriceParams = BaseParamsToChangeClothingSizeAndPrices & {
  updatedPrice: number;
};

export type ChangeSizeWeightParams = BaseParamsToChangeClothingSizeAndPrices & {
  updatedWeight: number;
};

export type ChangeSizeProductionTimeParams = BaseParamsToChangeClothingSizeAndPrices & {
  timeInMinutes: string;
};

export type ChangeSizeDimensionsParams = BaseParamsToChangeClothingSizeAndPrices & {
  dimensions: string;
};

export type ChangeSizeProductionCostParams = BaseParamsToChangeClothingSizeAndPrices & {
  productionCost: number;
};

export type ChangeSizeLengthInMetersParams = BaseParamsToChangeClothingSizeAndPrices & {
  lengthInMeters: number;
};

// size is a field inside informations item
// informations contains the sizes, prices, and weight of each model
export const changeSizeName = (params: ChangeSizeNameParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, updatedSize } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        size: updatedSize
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const changeClothePrice = (params: ChangeSizePriceParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, updatedPrice } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        price: updatedPrice
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const changeClotheWeight = (params: ChangeSizeWeightParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, updatedWeight } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        weight: updatedWeight
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const changeClotheProductionTime = (params: ChangeSizeProductionTimeParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, timeInMinutes } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        productionTimeInMinutes: timeInMinutes
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const changeClotheDimensions = (params: ChangeSizeDimensionsParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, dimensions } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        dimensions
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const changeClotheProductionCost = (params: ChangeSizeProductionCostParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, productionCost } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        productionCost
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const changeClotheLengthInMeters = (params: ChangeSizeLengthInMetersParams): ClothingGenderType => {
  const { modelPricingInformations, gender, targetIndex, lengthInMeters } = params;

  const updatedSizesForGender = modelPricingInformations[gender].map((information, index) => {
    if (index === targetIndex) {
      return {
        ...information,
        lengthInMeters
      };
    }

    return information;
  });

  return {
    ...modelPricingInformations,
    [gender]: [...updatedSizesForGender]
  };
};

export const FIELDS_FOR_MALE: number = 40;
export const FIELDS_FOR_FEMALE: number = 40;
export const FIELDS_FOR_CHILDISH: number = 9;

export const generateEmptyPricesForGender = (fields: number, startIndex: number = 1): ClothingSizeType[] => {
  const sizes: ClothingSizeType[] = [];

  for (let i = startIndex; i < fields + startIndex; i++) {
    sizes.push({
      size: '',
      price: 0,
      weight: 0,
      productionTimeInMinutes: ''
    });
  }

  return sizes;
};

export const generateDefaultModelData = (): ClothingModelCreationType => ({
  id: -1,
  name: '',
  icon: null,
  available: true,
  SBCode: 'CAM1',
  informations: {
    male: generateEmptyPricesForGender(FIELDS_FOR_MALE),
    female: generateEmptyPricesForGender(FIELDS_FOR_FEMALE),
    childish: generateEmptyPricesForGender(FIELDS_FOR_CHILDISH)
  },
  productsIds: []
});

export const isValidClothingModelData = (model: ClothingModelCreationType, Translate: TFunction): boolean => {
  if (model.name === '') {
    throw new EmptyNameException(Translate('error.model-has-empty-name'));
  }
  if (model.icon === null) {
    throw new NullIconException(Translate('error.model-has-no-icon'));
  }

  return true;
};

/**
 * Backend dont need to receive all informations about the selected icon for this model.
 * Just need to send the icon id, and it will be bound to the correct icon data and image.
 * @param model
 * @returns converted data ready to post to the backend
 */
export const convertModelToStoreUpdateRequest = (model: ClothingModelCreationType): ClothingModelStoreUpdateRequestType => {
  const { icon, id, ...modelWithNoIcon } = model;

  const modelStoreRequestData: ClothingModelStoreUpdateRequestType = {
    ...modelWithNoIcon,
    icon_id: model.icon!.id
  };

  return modelStoreRequestData;
};

const addMissingSizesToClothingInformation = (genderInformation: ClothingSizeType[], maxFieldsToGender: number) => {
  const sizesToGenerate = maxFieldsToGender - genderInformation.length;
  const missingSizes = generateEmptyPricesForGender(sizesToGenerate, genderInformation.length + 1);

  return [...genderInformation, ...missingSizes];
};

/**
 * Sometimes client asks to increase the number of fields to user fill
 * and previews saved models doesn't have these fields.
 * This function add the missing sizes to be able to edit and save the model.
 *
 * @param informations Clothing sizes, prices and weight to all genders.
 * @returns Information with updated quantity of fields.
 */
export const adjustClothingInformationsQuantity = (model: ClothingModelType): ClothingModelType => {
  const { informations } = model;
  const updatedInformations: ClothingModelType['informations'] = informations;

  if (informations.male.length < FIELDS_FOR_MALE) {
    updatedInformations.male = addMissingSizesToClothingInformation(informations.male, FIELDS_FOR_MALE);
  }

  if (informations.female.length < FIELDS_FOR_FEMALE) {
    updatedInformations.female = addMissingSizesToClothingInformation(informations.female, FIELDS_FOR_FEMALE);
  }

  if (informations.childish.length < FIELDS_FOR_CHILDISH) {
    updatedInformations.childish = addMissingSizesToClothingInformation(informations.childish, FIELDS_FOR_CHILDISH);
  }

  return { ...model, informations: updatedInformations };
};

export const extractClothingSizesInGenderFromModel = (
  model: ClothingModelType,
  genderToExtractSizes: keyof ClothingGenderType
) => {
  return model.informations[genderToExtractSizes].map(({ size }) => size).filter(size => size !== '');
};

export const extractClothingSizesInGenderFromImportedModels = (
  importedModels: ClothingModelType[],
  genderToExtractSizes: keyof ClothingGenderType
): string[] => {
  const sizesAvailableInImportedModels: string[][] = importedModels.map(importedModel =>
    extractClothingSizesInGenderFromModel(importedModel, genderToExtractSizes)
  );
  return sizesAvailableInImportedModels.flat();
};
