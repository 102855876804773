import { type AxiosInstance } from 'axios';
import { type SublistType } from '../../../../../../../../../types/SublistTypes';

export type RemoveSublistFinishingOptionParams = {
  sublist_id: number;
  picked_finishing_option_id: number;
}

export const removeSublistFinishingOption = async (params: RemoveSublistFinishingOptionParams, httpConnection: AxiosInstance): Promise<SublistType> => {
  return await new Promise<SublistType>((resolve, reject) => {
    httpConnection.post<SublistType>('/list/clothing-projects/sublist/remove-finishing-option', params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
