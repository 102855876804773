import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { pdfjs } from 'react-pdf';

import 'react-datepicker/dist/react-datepicker.css';
import './theme/css/fontawesome.all.min.css';
import './theme/css/sb-admin-2.min.css';
import './theme/css/dark-theme.css';
import './theme/css/printing-settings-fix.css';
import './theme/css/screenshot-settings.css';
import './theme/css/react-date-picker-fix.css';
import './theme/css/react-tooltip-fix.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-medium-image-zoom/dist/styles.css';

import './i18n.config';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
