import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '../../contexts/AuthContext';
import { useHeaderBackButton } from '../../contexts/HeaderBackButtonContext';
import { useTheme } from '../../contexts/ThemeContext';
import CircleButton from '../Buttons/CircleButton';
import { IconsCatalog } from '../IconsCatalog';
import { getInitialLetterNameAsUpperCase } from './services/navbarService';
import { ProfileRoundedContainer } from './styles';
import { useUser } from '../../contexts/UserContext';
import LanguagePicker from './LanguagePicker';
import { useAppTranslation } from '../../contexts/TranslationContext';
import { useSidebarCollapse } from '../../contexts/SidebarCollapseContext';
import { useCallback } from 'react';
import { Tooltip } from 'react-tooltip';

export default function Navbar() {
  const location = useLocation();
  const { isDark, toggleTheme } = useTheme();
  const { doLogout } = useAuth();
  const { user } = useUser();
  const { backButtonVisible } = useHeaderBackButton();
  const { hideSidebar, showSidebar, isCollapsed } = useSidebarCollapse();

  const navigate = useNavigate();
  const { Translate } = useAppTranslation();

  const toggleSidebar = useCallback(() => {
    if (isCollapsed) showSidebar();
    else hideSidebar();
  }, [hideSidebar, isCollapsed, showSidebar]);

  if (!user) return <></>;

  return (
    <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
      <Tooltip id='button-sidebar-toggle-key-bind' opacity={1}>
        <span className='text-monospace'>Alt + M</span>
      </Tooltip>

      <button
        id='sidebarToggleTop'
        className='btn btn-link rounded-circle mr-2'
        onClick={toggleSidebar}
        data-tooltip-id='button-sidebar-toggle-key-bind'
        data-tooltip-delay-show={1000}
      >
        <i className='fa fa-bars'></i>
      </button>

      {backButtonVisible && (
        <CircleButton
          testId='header-button-navigate-back'
          icon={IconsCatalog.arrowLeft}
          color='light'
          handleClick={() => {
            navigate(-1);
          }}
        />
      )}

      <CircleButton
        icon={!isDark ? IconsCatalog.moon : IconsCatalog.sun}
        color='light'
        handleClick={toggleTheme}
        marginLeft={backButtonVisible}
      />

      {location.state?.workspace ? (
        <h4 className='h4 ml-4 mb-0 text-gray-800 w-100 text-truncate'>{location.state.workspace.title}</h4>
      ) : null}

      <ul className='navbar-nav ml-auto'>
        <LanguagePicker />

        <div className='topbar-divider d-none d-sm-block'></div>

        <li className='nav-item dropdown no-arrow'>
          <a
            className='nav-link dropdown-toggle'
            href='sample.html'
            id='userDropdown'
            role='button'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span className='mr-2 d-none d-lg-inline text-gray-600 small' data-testid='logged-user-name'>
              {user.name}
            </span>
            <ProfileRoundedContainer>{getInitialLetterNameAsUpperCase(user.name)}</ProfileRoundedContainer>
          </a>
          <div className='dropdown-menu dropdown-menu-right shadow animated--grow-in'>
            <button className='dropdown-item' onClick={doLogout}>
              <i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
              {Translate('actions.disconnect')}
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
}
