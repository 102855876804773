import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 340px;
  gap: 20px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--background-card);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
`;
