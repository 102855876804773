/**
 * Convert time string to number in seconds. Must follow the format MM:SS like 01:20 for 1 minute and 20 seconds.
 * @param timeInMinutes
 * @returns number in seconds.
 */
export const convertMMTimeToSeconds = (timeInMinutes: string) => {
  if (timeInMinutes.length === 0) return 0;

  // Split the time string into minutes and seconds
  const [minutes, seconds] = timeInMinutes.split(':').map(Number);

  // Convert minutes to seconds and add the seconds
  return minutes * 60 + seconds;
};
