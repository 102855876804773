import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const AddedButton = styled.button`
  background-color: var(--success);
  color: white;
  border: none;
  border-radius: 4px;
  width: 45px;

  &:hover {
    background-color: #3fe7aa;
  }

  &:disabled {
    background-color: #f1f1f1;
    color: #333;
    cursor: not-allowed;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  gap: 15px;
`;

type WrapperSelectProps = {
  active: boolean;
};

export const WrapperSelect = styled.div<WrapperSelectProps>`
  display: flex;
  align-items: center;
  border: 1px solid rgba(80, 80, 80);
  padding: 10px 15px;
  border-radius: 4px;
  height: 45px;
  width: 100%;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      background-color: var(--success);
      color: white;
      border-color: white;
    `}

  &:hover {
    background-color: var(--success);
    color: white;
    border-color: white;

    div {
      border-color: white;
    }

    ${WrapperButtons} {
      opacity: 1;
    }
  }
`;

type SelectProps = {
  active: boolean;
};

export const Select = styled.div<SelectProps>`
  border: 1px solid rgba(80, 80, 80);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
  min-width: 20px;
  transition: all 0.3s ease-in-out;

  i {
    font-size: 8px;
    display: none;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: var(--success);
      color: white;

      i {
        display: block;
      }
    `}
`;

export const WrapperSelectTasks = styled.select`
  background-color: var(--light-v2);
  border: 1px solid var(--light);
  color: var(--black);
  outline: none;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  flex: 1;

  &:focus {
    border-color: rgba(120, 120, 120);
  }
`;

export const Option = styled.span`
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`;

export const EditButton = styled.button`
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: #ff5d5d;
  }
`;

export const RemoveButton = styled.button`
  background-color: var(--danger);
  color: white;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: #ff5d5d;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  color: #fff;

  i {
    &:hover {
      opacity: 0.7;
    }
  }
`;
