import { type AxiosResponse, type AxiosInstance, type AxiosRequestConfig } from 'axios';
import { type AuthManager } from '../../../managers/AuthManager';
import _ from 'lodash';
import { type I18Manager } from '../../../managers/i18Manager';
import { HttpResponse } from '../../../server/apiHelper';

import i18next from 'i18next';
import { toast } from 'react-hot-toast-promise';

export class AxiosAdapters {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly authManager: AuthManager,
    private readonly i18Manager: I18Manager
  ) {
    this.useInterceptorsRequest();
    this.useInterceptorsResponse();
  }

  private useInterceptorsRequest() {
    this.axios.interceptors.request.use(async config => {
      const token = await this.authManager.getToken();
      const language = await this.i18Manager.getToken();

      if (!_.isEmpty(token)) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['X-LOCALE'] = language;
      }

      return config;
    });
  }

  private useInterceptorsResponse() {
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async error => {
        if (error.response?.status === HttpResponse.UNAUTHORIZED) {
          toast.error(i18next.t('toast.session-disconnected-error'), { duration: 7000 });
        }
        return await Promise.reject(error);
      }
    );
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axios.get<T>(url, config);
    return data;
  }

  async post<T = any>(url: string, postData?: any, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axios.post<T>(url, postData, config);
    return data;
  }

  async put<T = any>(url: string, putData?: any, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axios.put<T>(url, putData, config);
    return data;
  }

  async delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axios.delete<T>(url, config);
    return data;
  }

  async patch<T = any>(url: string, putData?: any, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axios.patch<T>(url, putData, config);
    return data;
  }
}
