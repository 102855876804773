import { useFormContext } from 'react-hook-form';
import { WrapperInput, WrapperInputs } from '../../style';
import _ from 'lodash';

type InputsListProps = {
  rawMaterialId: number;
  productId: number;
  productUnits?: Array<{
    id: number;
    product_id: number;
    price: string;
    width: number;
    length: number | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  }>;
  typeMeter: 'unit' | 'meter' | 'liter';
  maxLength: number;
};

export function InputsList({ rawMaterialId, productId, productUnits, typeMeter, maxLength }: InputsListProps) {
  const { register, setValue, watch } = useFormContext();
  const rawMaterialIdKey = `${rawMaterialId}.${productId}`;
  const widthRawMaterial = `${rawMaterialIdKey}.width`;

  if (_.isNil(watch(rawMaterialIdKey))) {
    return (
      <p className='d-flex align-items-center text-success mt-2 mb-0'>
        <i className='fas fa-check mr-2' />
        <b>Matéria prima inserida e descontada do estoque</b>
      </p>
    );
  }

  return (
    <WrapperInputs>
      <WrapperInput>
        <label htmlFor={`width-${productId}`}>{typeMeter === 'meter' ? 'Estoque' : 'Quantidade'}:</label>
        <input
          id={`width-${productId}`}
          type='number'
          className={`form-control ${_.isEqual(maxLength, 0) ? 'is-invalid' : null}`}
          disabled={_.isEqual(maxLength, 0)}
          min={0}
          max={maxLength}
          defaultValue={0}
          {...register(widthRawMaterial)}
          onChange={e => {
            if (+e.target.value < 0 || _.isEmpty(e.target.value)) {
              setValue(widthRawMaterial, 0);
              return;
            }

            if (+e.target.value > maxLength) {
              setValue(widthRawMaterial, maxLength);
              return;
            }

            setValue(widthRawMaterial, e.target.value);
          }}
        />
      </WrapperInput>
    </WrapperInputs>
  );
}
