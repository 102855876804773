import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

export const WrapperColumn = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  gap: 8px;
  padding-bottom: 1rem;
`;
