import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { type ProjectsDeliveryCalendarType } from '../types/ProjectsDeliveryCalendarType';

type ProjectsDeliveryCalenderContextType = {
  deliveryCalendar: ProjectsDeliveryCalendarType[];
  setUpdatedDeliveryCalendar: (updatedCalendarEvents: ProjectsDeliveryCalendarType[]) => void;
  lastFetchTime: Date | null;
};

type ProjectsDeliveryCalenderProviderType = {
  children: JSX.Element;
};

const ProjectsDeliveryCalenderContext = createContext<ProjectsDeliveryCalenderContextType>(
  {} as ProjectsDeliveryCalenderContextType
);

export const ProjectsDeliveryCalendarProvider = ({ children }: ProjectsDeliveryCalenderProviderType) => {
  const [deliveryCalendar, setDeliveryCalendar] = useState<ProjectsDeliveryCalendarType[]>([]);
  const [lastFetchTime, setLastFetchTime] = useState<Date | null>(null);

  const setUpdatedDeliveryCalendar = useCallback((updatedCalendarEvents: ProjectsDeliveryCalendarType[]) => {
    setDeliveryCalendar(updatedCalendarEvents);
    setLastFetchTime(new Date());
  }, []);

  const contextValues = useMemo(
    () => ({ deliveryCalendar, setDeliveryCalendar, lastFetchTime, setUpdatedDeliveryCalendar }),
    [deliveryCalendar, setDeliveryCalendar, lastFetchTime, setUpdatedDeliveryCalendar]
  );

  return (
    <ProjectsDeliveryCalenderContext.Provider value={contextValues}>{children}</ProjectsDeliveryCalenderContext.Provider>
  );
};

export const useProjectsDeliveryCalendar = (): ProjectsDeliveryCalenderContextType => {
  const context = useContext(ProjectsDeliveryCalenderContext);

  if (!context) throw new Error('useProjectsDeliveryCalendar must be used within a ProjectsDeliveryCalendarProvider');

  return context;
};
