import { memo, useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import { type WEEK_DAYS } from '../constants';

type HoursProps = {
  hour: {
    id: string;
    start: string;
    end: string;
  };
  remove: boolean;
  add: boolean;
  dayId: string;
};

export const Hours = memo(function Hours({ hour, remove, add, dayId }: HoursProps) {
  const officeHours = useWatch({ name: 'officeHours' }) as typeof WEEK_DAYS;
  const { setValue } = useFormContext();

  const removeButton = useMemo(() => {
    if (!remove) return null;

    const removeHour = () => {
      const newOfficeHours = officeHours.map(day => {
        if (day.id !== dayId) return day;

        return {
          ...day,
          hours: day.hours?.filter(h => h.id !== hour.id)
        };
      });

      setValue('officeHours', newOfficeHours);
    };

    return (
      <button type='button' className='btn btn-danger btn-circle' onClick={removeHour}>
        <i className='fas fa-trash'></i>
      </button>
    );
  }, [remove, officeHours, dayId, hour.id, setValue]);

  const addButton = useMemo(() => {
    if (!add) return null;

    const addHour = () => {
      const newOfficeHours = officeHours.map(day => {
        if (day.id !== dayId) return day;

        return {
          ...day,
          hours: [
            ...day.hours,
            {
              id: _.uniqueId('hour_'),
              start: '',
              end: ''
            }
          ]
        };
      });

      setValue('officeHours', newOfficeHours);
    };

    return (
      <button type='button' className='btn btn-success btn-circle' onClick={addHour}>
        <i className='fas fa-plus'></i>
      </button>
    );
  }, [add, dayId, officeHours, setValue]);

  const changeHour = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
      const newOfficeHours = officeHours.map(day => {
        if (day.id !== dayId) return day;

        return {
          ...day,
          hours: day.hours?.map(h => {
            if (h.id !== hour.id) return h;

            return {
              ...h,
              [field]: e.target.value
            };
          })
        };
      });

      setValue('officeHours', newOfficeHours);
    },
    [dayId, hour.id, officeHours, setValue]
  );

  return (
    <div className='d-flex align-items-end' key={hour?.id} style={{ gap: 10 }}>
      <div className='w-100'>
        <label htmlFor='start'>Início</label>
        <input
          type='time'
          className='form-control'
          placeholder='Início'
          id='start'
          defaultValue={_.defaultTo(hour?.start, '')}
          onChange={e => {
            changeHour(e, 'start');
          }}
        />
      </div>

      <div className='w-100'>
        <label htmlFor='end'>Saída</label>
        <input
          type='time'
          className='form-control'
          placeholder='Fim'
          id='end'
          defaultValue={_.defaultTo(hour?.end, '')}
          onChange={e => {
            changeHour(e, 'end');
          }}
        />
      </div>

      {removeButton}

      {addButton}
    </div>
  );
});
