import styled from 'styled-components';

export const WrapperContainer = styled.form`
  position: relative;
`;

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const LoadingLabel = styled.span``;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`;

export const WrapperHoliday = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  background-color: var(--background);
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  min-height: 116px;
`;

export const AddButton = styled.button`
  background-color: transparent;
  color: var(--black);
  padding: 0.5rem;
  border: 1px dashed var(--black);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  left: 0.5rem;
  top: 0.5rem;

  &:hover {
    opacity: 0.8;
  }
`;

export const RemoveButton = styled.button`
  background-color: var(--danger);
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;

  &:hover {
    opacity: 0.8;
  }
`;
