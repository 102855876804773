import { type task } from '../../../../components/CardModal/fragments/CreateTasks';
import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';

type UpdateCardParams = {
  cardId: number;
  title: string;
  description?: string;
  taskList: task[];
  attachments?: File[];
  quantity_piece?: number;
  date?: { start: string; end: string };
  priority: number;
};
export class UpdateCard {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(updateData: UpdateCardParams) {
    try {
      const data = await this.http.put('kanban/card', updateData);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      // Clean
    }
  }
}
