import { useCallback, useState } from 'react';
import { useClothingModels } from '../../../contexts/ClothingModelsContext';
import ClothingIconViewer from '../../ClothingIconViewer';
import ModalBase from '../ModalBase';
import { type ClothingModelType } from '../../../types/ClothingModelType';
import { ButtonContainer } from './styles';
import ProgressBar from '../../ProgressBar';
import { useAppTranslation } from '../../../contexts/TranslationContext';

type ClothingModelsPickerModalType = {
  visible: boolean;
  handleClose: () => void;
  handleConfirm: (selectedModels: ClothingModelType[]) => void;
  alreadySelected: ClothingModelType[];
};

const MAX_CLOTHING_MODELS_PER_PRESET = 6;

export default function ClothingModelsPickerModal({
  visible,
  handleClose,
  handleConfirm,
  alreadySelected
}: ClothingModelsPickerModalType) {
  const { models } = useClothingModels();
  const { Translate } = useAppTranslation();

  const [selectedModels, setSelectedModels] = useState<ClothingModelType[]>(alreadySelected);

  const handleClickModel = useCallback(
    (model: ClothingModelType) => {
      const alreadyExists = selectedModels.find(currentModel => currentModel.id === model.id);

      if (!alreadyExists) {
        setSelectedModels([...selectedModels, model]);
      } else {
        const filteredModels = selectedModels.filter(currentModel => currentModel.id !== model.id);
        setSelectedModels(filteredModels);
      }
    },
    [selectedModels]
  );

  const isModelPresentOnSelectionList = useCallback(
    (model: ClothingModelType) => {
      return selectedModels.includes(model);
    },
    [selectedModels]
  );

  const isOptionDisabled = useCallback(
    (model: ClothingModelType) => {
      if (!selectedModels.includes(model) && selectedModels.length === MAX_CLOTHING_MODELS_PER_PRESET) return true;
      return false;
    },
    [selectedModels]
  );

  return (
    <ModalBase
      title={Translate('actions.import-models')}
      message={Translate('description.pick-models-to-use-in-project')}
      style='primary'
      visible={visible}
      handleClose={handleClose}
      handleConfirm={() => {
        handleConfirm(selectedModels);
        handleClose();
      }}
    >
      <>
        <ProgressBar value={selectedModels.length} maxValue={MAX_CLOTHING_MODELS_PER_PRESET} />

        <div className='list-group mt-3'>
          {models
            .filter(model => model.available)
            .map((model, index) => {
              const active = isModelPresentOnSelectionList(model);

              return (
                <ButtonContainer
                  key={index}
                  disabled={isOptionDisabled(model)}
                  type='button'
                  className={`list-group-item list-group-item-action ${active ? 'active' : ''}`}
                  onClick={() => {
                    handleClickModel(model);
                  }}
                >
                  <ClothingIconViewer icon={model.icon.url} label={model.name} useInlineIcon />
                </ButtonContainer>
              );
            })}
        </div>
      </>
    </ModalBase>
  );
}
