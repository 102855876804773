export const generatePath = ({ filename }: { filename: string }) =>
  `${process.env.REACT_APP_SERVER_BASEURL}storage/sublists-layouts/${filename}`;

export const generateSatisfactionSurveyImagePath = ({ filename }: { filename: string }) =>
  `${process.env.REACT_APP_SERVER_BASEURL}storage/${filename}`;

export const generateClothingIcons = ({ filename }: { filename: string }) =>
  `${process.env.REACT_APP_SERVER_BASEURL}storage/clothing-icons/${filename}`;

export const generatePathRegisterFile = ({ filename }: { filename: string }) =>
  `${process.env.REACT_APP_SERVER_BASEURL}storage/${filename}`;
