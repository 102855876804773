import { type AxiosInstance } from 'axios';
import {
  type ListClothingProjectOrderReplacementType
} from '../../../../../../../../../types/ClothingReplacementOption/ListClothingProjectOrderReplacementType';

type ChangeOrderClothingReplacementCompletionStatusType = {
  httpConnection: AxiosInstance;
  orderReplacementStatusId: number;
  completed: boolean;
}

export default async function({ httpConnection, orderReplacementStatusId, completed }: ChangeOrderClothingReplacementCompletionStatusType): Promise<ListClothingProjectOrderReplacementType[][]> {
  const putData = {
    id: orderReplacementStatusId,
    completed
  };

  return await new Promise<ListClothingProjectOrderReplacementType[][]>((resolve, reject) => {
    httpConnection.put<ListClothingProjectOrderReplacementType[][]>('list/clothing-projects/order-clothe-replacement/completed-status', putData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
