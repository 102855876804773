import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { type ClothingIcon } from '../types/ClothingIconType';
import { convertBase64ImageToBlob } from '../components/ImagePickerBase64/services/imageManipulation';
import { useHttpRequest } from './HttpRequestContext';
import { toast } from 'react-hot-toast-promise';
import { type RawAxiosRequestHeaders } from 'axios';
import { type ClothingIconDeleteReportType } from '../types/ClothingIconDeleteReportType';
import { useAppTranslation } from './TranslationContext';
import { getServerErrorMessageFromResponse } from '../utils/helper';

type ClothingIconsProviderProps = {
  children: JSX.Element;
};

type ClothingIconsContextType = {
  clothingIcons: ClothingIcon[];
  setClothingIcons: React.Dispatch<React.SetStateAction<ClothingIcon[]>>;
  addIcon: (imageData: string) => Promise<void>;
  deleteIcon: (id: number) => Promise<ClothingIconDeleteReportType>;
};

export const ClothingIconsContext = createContext<ClothingIconsContextType>({} as ClothingIconsContextType);

export function ClothingIconsProvider({ children }: ClothingIconsProviderProps) {
  const [clothingIcons, setClothingIcons] = useState<ClothingIcon[]>([]);

  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const addIcon = useCallback(
    async (imageData: string): Promise<void> => {
      try {
        const binaryImage = convertBase64ImageToBlob(imageData, Translate);

        const formData = new FormData();
        formData.append('image_data', binaryImage, 'image.png');

        const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;

        const response = await httpConnection.post<ClothingIcon>('/list/icons-catalog', formData, {
          headers: {
            ...defaultHeaders,
            'Content-Type': 'multipart/form-data'
          }
        });
        setClothingIcons([...clothingIcons, response.data]);
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      }
    },
    [Translate, clothingIcons, httpConnection]
  );

  const deleteIcon = useCallback(
    async (iconId: number): Promise<ClothingIconDeleteReportType> => {
      return await new Promise<ClothingIconDeleteReportType>((resolve, reject) => {
        httpConnection
          .delete<ClothingIconDeleteReportType>(`list/icons-catalog/${iconId}`)
          .then(response => {
            const { model_names } = response.data;

            if (!model_names) {
              const updatedIcons = clothingIcons.filter(icon => icon.id !== iconId);
              setClothingIcons(updatedIcons);
              resolve(response.data);
            }

            reject(response.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [clothingIcons, httpConnection]
  );

  const contextValues = useMemo<ClothingIconsContextType>(
    () => ({
      clothingIcons,
      setClothingIcons,
      addIcon,
      deleteIcon
    }),
    [clothingIcons, setClothingIcons, addIcon, deleteIcon]
  );

  return <ClothingIconsContext.Provider value={contextValues}>{children}</ClothingIconsContext.Provider>;
}

export function useClothingIcons(): ClothingIconsContextType {
  const context = useContext(ClothingIconsContext);

  if (!context) {
    throw new Error('useClothingIcons must be used within a ClothingIconsProvider');
  }

  return context;
}
