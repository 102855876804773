import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

type HolidayResponse = {
  id: string;
  date: string;
  title: string;
};

export class GetHolidays {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(): Promise<HolidayResponse[]> {
    return await this.http.get('/holidays');
  }
}
