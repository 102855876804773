import React, { useMemo } from 'react';
import { type SellsReportType } from '../../../../../../types/Report/SellsReportType';
import { displayAsCurrency } from '../../../../../../utils/helper';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { displayFormattedDate } from '../../../../../../helpers/dateTime';
import { type Locale } from 'date-fns';

type RenderSellsReportTableType = {
  sellsReport: SellsReportType[];
  currency: string;
  dateFnsLocale: Locale;
}

export default function RenderSellsReportTable({ sellsReport, currency, dateFnsLocale }: RenderSellsReportTableType) {
  const { Translate } = useAppTranslation();

  const sellsReportTotalPrice = useMemo(() => {
    return sellsReport.reduce((accumulator, report) => accumulator + report.total_project_price, 0);
  }, [sellsReport]);

  return (
    <table className='table table-bordered table-striped table-hover table-sm' width='100%'>
      <thead>
        <tr>
          <th className='text-center'>{Translate('labels.production-order-abbrev')}</th>
          <th>{Translate('labels.seller')}</th>
          <th>{Translate('labels.client')}</th>
          <th>{Translate('labels.project')}</th>
          <th className='text-center'>{Translate('labels.delivery-date')}</th>
          <th className='text-center'>{Translate('labels.qty-sold-pieces')}</th>
          <th className='text-right'>{Translate('labels.sell-price')}</th>
        </tr>
      </thead>

      <tbody>
        {
          sellsReport.map((report, index) => (
            <tr key={index}>
              <td className='text-center'>{report.project.order_number}</td>
              <td>{report.seller}</td>
              <td>{report.project.client_name}</td>
              <td>{report.project.name}</td>
              <td className='text-center'>{report.project.delivery_date ? displayFormattedDate(report.project.delivery_date, dateFnsLocale) : '-'}</td>
              <td className='text-center'>{report.total_project_clothing_items}</td>
              <td className='text-right'>{displayAsCurrency(report.total_project_price, currency)}</td>
            </tr>
          ))
        }

        {sellsReport.length > 0 && (
          <tr>
            <td colSpan={999} className='text-right'>
              <span>Total: </span>
              <span>{displayAsCurrency(sellsReportTotalPrice, currency)}</span>
            </td>
          </tr>
        )}

        {sellsReport.length === 0 && (
          <tr>
            <td colSpan={999} className='text-center'>{Translate('status.nothing-to-show-for-now')}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
