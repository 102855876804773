import { type PropsWithChildren } from 'react';
import * as Style from './style';

export function Columns({ children }: PropsWithChildren) {
  return (
    <Style.Container>
      <Style.WrapperColumn>{children}</Style.WrapperColumn>
    </Style.Container>
  );
}
