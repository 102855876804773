/* eslint-disable @typescript-eslint/indent */
import { Tooltip } from 'react-tooltip';
import { IconsCatalog } from '../../IconsCatalog';
import { type TextInputProps } from '../TextInput';
import React from 'react';

type TextAreaInputProps = Pick<
  TextInputProps,
  'id' | 'testId' | 'label' | 'value' | 'readonly' | 'disabled' | 'tabIndex' | 'placeholder' | 'maxLength'
> & {
  rows?: number;
  helpTooltipContent?: JSX.Element;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export default function TextAreaInput({
  id,
  testId,
  label,
  value,
  handleChange,
  placeholder,
  readonly,
  disabled,
  tabIndex,
  maxLength,
  helpTooltipContent,
  rows = 3
}: TextAreaInputProps) {
  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>

      {helpTooltipContent && (
        <React.Fragment>
          <Tooltip id='text-area-input-help-content' opacity={1}>
            {helpTooltipContent}
          </Tooltip>

          <i
            className={`${IconsCatalog.questionCircle} fa-fw fa-sm ml-1`}
            data-tooltip-id='text-area-input-help-content'
            data-tooltip-delay-hide={250}
            data-tooltip-variant='dark'
          />
        </React.Fragment>
      )}

      <textarea
        className='form-control'
        maxLength={maxLength}
        id={id}
        data-testid={testId}
        rows={rows}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        value={value}
        tabIndex={tabIndex}
      />
    </div>
  );
}
