import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';
import { type Entity } from '../../../../../types/Entity';
import { type Sections } from '../fragments/SectionsOptions';

type Params = {
  type?: Sections;
};

type GetEntitiesResponse = {
  execute: ({ type }: Params) => Promise<Entity[]>;
};

export class GetEntities implements GetEntitiesResponse {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ type = 'customer' }): Promise<Entity[]> {
    return await this.http.get(`/entity?filter=type:${type}&sort_by=created_at:desc`);
  }
}
