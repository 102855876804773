import _ from 'lodash';
import { useMemo } from 'react';
import * as Yup from 'yup';

import { FooterModal } from '../../style';
import { CancelButton, SubmitButton } from '../../../../style';
import { useManagerTask } from '../../useManagerTask';

const schema = Yup.object()
  .shape({
    taskList: Yup.array().min(1, 'Adicione pelo menos uma tarefa').required(),
    taskListGroupTitle: Yup.string().required('Título do grupo é obrigatório')
  })
  .required();

export function FooterButtons() {
  const { form, createTaskGroup, deleteTaskGroup } = useManagerTask();

  const createCheckGroup = useMemo(() => {
    try {
      schema.validateSync({ taskList: form.watch('taskList'), taskListGroupTitle: form.watch('group_title') });
      return false;
    } catch {
      return true;
    }
  }, [form]);

  return (
    <FooterModal>
      {!_.isNaN(Number(form.watch('group_list_select'))) && !_.isEmpty(form.watch('group_list_select')) ? (
        <CancelButton
          onClick={() => {
            deleteTaskGroup.mutate(Number(form.watch('group_list_select')));
          }}
        >
          Deletar
        </CancelButton>
      ) : null}

      {form.watch('group_list_select') === 'new' ? (
        <SubmitButton
          type='button'
          disabled={createCheckGroup || createTaskGroup.isLoading}
          onClick={() => {
            createTaskGroup.mutate({
              checkGroupTitle: form.watch('group_title'),
              checkGroupCardItems: form.watch('taskList')
            });
          }}
        >
          Salvar
        </SubmitButton>
      ) : null}
    </FooterModal>
  );
}
