import { type AxiosAdapters } from '../../../../../../../helpers/adapters/http/AxiosAdapters';

type WorkspacesResponse = {
  id: string;
  title: string;
  slug: string;
  created_at: string;
  updated_at: string;
};

export class GetAllWorkspaces {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(): Promise<WorkspacesResponse[]> {
    return await this.http.get('workspaces');
  }
}
