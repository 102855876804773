import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';

export type CreateCommentData = {
  card_id: number;
  comment: string;
  priority: number;
};

export class CreateComment {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: CreateCommentData) {
    return await this.http.post('/kanban/card/comment', data);
  }
}
