import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const WrapperLeft = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;
`;

export const WrapperIcon = styled.div`
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 4px;
  display: flex;
  min-height: 50px;
  max-height: 50px;
  min-width: 50px;
  max-width: 50px;
`;

export const RollbackButton = styled.button`
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  min-height: 50px;
  max-height: 50px;
  min-width: 50px;
  max-width: 50px;

  &:hover {
    opacity: 0.8;
  }
`;

export const Icon = styled.i`
  color: var(--white);
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

export const Subtitle = styled.h2`
  color: var(--gray);
  font-size: 0.9rem;
  margin: 0;
`;

export const Form = styled.form`
  display: flex;
  gap: 1rem;
`;

export const Search = styled.input``;

export const Button = styled.button`
  color: var(--white);
`;
