import styled, { css } from 'styled-components';

type CardProps = {
  show: boolean;
};

export const Container = styled.div<CardProps>`
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  height: 120px;
  position: relative;
  transition: height 0.3s ease-in-out;

  &:hover {
    background-color: var(--gray-100);
  }

  ${({ show }) =>
    show
      ? css`
          height: 256px;
        `
      : null}
`;

export const ShowHandleButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--gray-400);
  cursor: pointer;
  font-size: 1.2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
