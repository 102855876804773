export const moveElementInSameLine = (array: any[], sourceIndex: number, destinationIndex: number) => {
  // Check if sourceIndex and destinationIndex are valid
  if (sourceIndex < 0 || sourceIndex >= array.length || destinationIndex < 0 || destinationIndex > array.length) {
    return array;
  }

  // Remove the element from the sourceIndex
  const elementToMove = array.splice(sourceIndex, 1)[0];

  // Insert the element at the destinationIndex
  array.splice(destinationIndex, 0, elementToMove);

  return array;
};
