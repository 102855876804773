import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import SwitchInput from '../../Forms/SwitchInput';
import SplitButton from '../../Buttons/SplitButton';
import { IconsCatalog } from '../../IconsCatalog';
import TextInput from '../../Forms/TextInput';
import { type ProjectType } from '../../../types/ProjectType';
import { type ProjectSharedLinkOptionsType } from '../../../types/ProjectSharedLinkOptionsType';
import { useProjects } from '../../../contexts/ProjectsContext';
import toast from 'react-hot-toast-promise';
import Spinner from '../../Spinner';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

type ProjectSharedLinkControlPanelType = Pick<ModalBaseType, 'visible' | 'handleClose'> & {
  project: ProjectType;
  handleConfirm: (sharedLinkOptions: ProjectSharedLinkOptionsType) => void;
};

export default function ProjectSharedLinkControlPanel({
  project,
  visible,
  handleClose,
  handleConfirm
}: ProjectSharedLinkControlPanelType) {
  const [isUpdating, setIsUpdating] = useState(false);

  const { Translate } = useAppTranslation();

  const [sharedLinkOptions, setSharedLinkOptions] = useState<ProjectSharedLinkOptionsType>({
    final_client_hide_molds_prices: project.final_client_hide_molds_prices,
    final_client_readonly: project.final_client_readonly,
    final_client_link_offline: project.final_client_link_offline,
    token: project.token
  });

  const { updateSharedLinkOptions } = useProjects();

  const generateToken = useCallback(() => {
    const newToken = uuidv4();
    setSharedLinkOptions({ ...sharedLinkOptions, token: newToken });
  }, [sharedLinkOptions]);

  const handleSaveSharedLinkOptions = useCallback(async () => {
    try {
      setIsUpdating(true);
      await updateSharedLinkOptions(project.id!, sharedLinkOptions);
      toast.success(Translate('toast.shared-link-options-updated'));
      handleConfirm(sharedLinkOptions);
      handleClose();
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsUpdating(false);
    }
  }, [Translate, handleClose, handleConfirm, project, sharedLinkOptions, updateSharedLinkOptions]);

  return (
    <ModalBase
      visible={visible}
      style='primary'
      title={Translate('description.link-sharing')}
      message={Translate('description.link-sharing-description')}
      handleClose={handleClose}
      handleConfirm={handleSaveSharedLinkOptions}
    >
      <>
        <SwitchInput
          id='check-hide-model-prices'
          label={Translate('actions.hide-prices')}
          disabled={isUpdating}
          checked={sharedLinkOptions.final_client_hide_molds_prices}
          handleChange={checked => {
            setSharedLinkOptions({ ...sharedLinkOptions, final_client_hide_molds_prices: checked });
          }}
        />

        <SwitchInput
          id='check-readonly'
          label={Translate('actions.block-list-editings')}
          disabled={isUpdating}
          checked={sharedLinkOptions.final_client_readonly}
          handleChange={checked => {
            setSharedLinkOptions({ ...sharedLinkOptions, final_client_readonly: checked });
          }}
        />

        <SwitchInput
          id='check-link-offline'
          label={Translate('actions.block-shared-link-access')}
          disabled={isUpdating}
          checked={sharedLinkOptions.final_client_link_offline}
          handleChange={checked => {
            setSharedLinkOptions({ ...sharedLinkOptions, final_client_link_offline: checked });
          }}
        />

        <div className='row'>
          <div className='col pr-0'>
            <TextInput
              disabled
              testId='text-input-access-token'
              id='text-input-access-token'
              label={Translate('labels.access-token')}
              value={sharedLinkOptions.token ?? Translate('status.none')}
              helpText={Translate('description.generate-new-access-token-warning-message')}
            />
          </div>
          <div className='col-auto'>
            <SplitButton
              testId='shared-link-generate-new-token'
              disabled={isUpdating}
              color='danger'
              title={Translate('actions.new')}
              icon={IconsCatalog.sync}
              handleClick={generateToken}
              simulateLabelMarginTop
            />
            <SplitButton
              simulateLabelMarginTop
              marginLeft
              disabled={isUpdating}
              color='danger'
              icon={IconsCatalog.trash}
              handleClick={() => {
                setSharedLinkOptions({ ...sharedLinkOptions, token: null });
              }}
            />
          </div>
        </div>

        {isUpdating && (
          <div className='row'>
            <div className='col d-flex align-items-center'>
              <Spinner small /> <span className='ml-2'>{Translate('progress.saving-wait')}</span>
            </div>
          </div>
        )}
      </>
    </ModalBase>
  );
}
