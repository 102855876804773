import { useCallback, useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import Zoom from 'react-medium-image-zoom';
import { ImageFile, RemoveAttachment, WrapperAttachment } from '../../../../style';
import { type KanbanCardImage } from '../../../../../../types/Kanban/KanbanCardImage';
import { generatePath } from '../../../../../../helpers/general';

type AttachmentProps = { attachment: File | KanbanCardImage; onRemove: () => void };

export const Attachment = ({ attachment, onRemove }: AttachmentProps) => {
  const [numPages, setNumPages] = useState<number>(1);

  const handleRemove = useCallback(() => {
    onRemove && onRemove();
  }, [onRemove]);

  const imagePath = useMemo(() => {
    if ((attachment as KanbanCardImage).file) {
      return generatePath({
        filename: (attachment as KanbanCardImage).file
      });
    }

    return URL.createObjectURL(attachment as File);
  }, [attachment]);

  if ((attachment as File).type === 'application/pdf' || (attachment as KanbanCardImage)?.file?.includes('.pdf')) {
    const file = (attachment as KanbanCardImage).file
      ? {
          url: generatePath({
            filename: (attachment as KanbanCardImage).file
          })
        }
      : (attachment as File);

    return (
      <WrapperAttachment>
        <RemoveAttachment onClick={handleRemove}>
          <span className='fa fa-trash'></span>
        </RemoveAttachment>

        <Document
          file={file}
          onLoadSuccess={({ numPages }) => {
            if (numPages > 5) {
              setNumPages(5);
              return;
            }

            setNumPages(numPages);
          }}
        >
          {Array.from(new Array(numPages), (_, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </WrapperAttachment>
    );
  }

  return (
    <WrapperAttachment>
      <RemoveAttachment onClick={handleRemove}>
        <span className='fa fa-trash'></span>
      </RemoveAttachment>
      <Zoom>
        <ImageFile src={imagePath} alt='attachment' />
      </Zoom>
    </WrapperAttachment>
  );
};
