import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

type HeaderBackButtonContextType = {
  backButtonVisible: boolean;
  showBackButton: () => void;
  hideBackButton: () => void;
};

type HeaderBackButtonProviderType = {
  children: JSX.Element;
};

const HeaderBackButtonContext = createContext({} as HeaderBackButtonContextType);

export const HeaderBackButtonProvider = ({ children }: HeaderBackButtonProviderType) => {
  const [backButtonVisible, setBackButtonVisible] = useState(false);

  const showBackButton = useCallback(() => {
    setBackButtonVisible(true);
  }, []);

  const hideBackButton = useCallback(() => {
    setBackButtonVisible(false);
  }, []);

  const contextValues = useMemo(
    () => ({ backButtonVisible, showBackButton, hideBackButton }),
    [backButtonVisible, showBackButton, hideBackButton]
  );

  return <HeaderBackButtonContext.Provider value={contextValues}>{children}</HeaderBackButtonContext.Provider>;
};

export const useHeaderBackButton = (): HeaderBackButtonContextType => {
  const context = useContext(HeaderBackButtonContext);

  if (!context) throw new Error('useHeaderBackButton must be used within a HeaderBackButtonProvider');

  return context;
};
