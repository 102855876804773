import React from 'react';
import { Container } from './styles';

type SpanButtonType = {
  text: string;
  handleClick: () => void;
  disabled?: boolean;
  testId?: string;
};

export default function SpanButton({ text, handleClick, testId, disabled = false }: SpanButtonType) {
  return (
    <Container disabled={disabled} onClick={!disabled ? handleClick : () => {}} data-testid={testId}>
      {text}
    </Container>
  );
}
