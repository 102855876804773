import { Container, EvaluateInfo, Label, WrapperEvalutate } from './style';

export function StatusProject() {
  return (
    <Container>
      {Array.from({ length: 5 }).map((_, index, array) => {
        const lastItem = index === array.length - 1;

        return (
          <WrapperEvalutate key={index} lastItem={lastItem} active={index <= 2}>
            <EvaluateInfo active={index <= 2}>{index + 1}</EvaluateInfo>
            <Label>Andamento da Tarefa {index + 1}</Label>
          </WrapperEvalutate>
        );
      })}
    </Container>
  );
}
