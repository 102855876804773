import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--dark);
  background-color: var(--light);
  color: var(--dark);
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;

  span {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
  }

  &:hover {
    background-color: var(--light-v2);
  }
`;
