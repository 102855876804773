import React, { useCallback, useEffect, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import TextAreaInput from '../../Forms/TextAreaInput';

type TextAreaInputModalType = Omit<ModalBaseType, 'children' | 'handleConfirm'> & {
  label: string;
  currentValue: string;
  readonly?: boolean;
  handleConfirm: (response: string) => void;
};

export default function TextAreaInputModal({
  title,
  message,
  label,
  currentValue,
  readonly,
  hideFooter,
  style,
  visible,
  handleClose,
  handleConfirm,
  okOnly,
  zIndexIncrement,
  disableButtons
}: TextAreaInputModalType) {
  const [inputValue, setInputValue] = useState<string>('');

  const handleBeforeConfirm = useCallback(() => {
    handleConfirm(inputValue);
  }, [handleConfirm, inputValue]);

  useEffect(() => {
    setInputValue(currentValue);
  }, [currentValue]);

  return (
    <ModalBase
      visible={visible}
      style={style}
      title={title}
      message={message}
      handleConfirm={handleBeforeConfirm}
      handleClose={handleClose}
      okOnly={okOnly}
      hideFooter={hideFooter}
      zIndexIncrement={zIndexIncrement}
      disableButtons={disableButtons}
    >
      <React.Fragment>
        <div className='row'>
          <div className='col'>
            <TextAreaInput
              disabled={disableButtons}
              id='textarea-input-modal'
              label={label}
              value={inputValue}
              readonly={readonly}
              rows={6}
              handleChange={({ target }) => {
                setInputValue(target.value);
              }}
            />
          </div>
        </div>
      </React.Fragment>
    </ModalBase>
  );
}
