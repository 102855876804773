import React from 'react';
import { type ComboBoxDataType } from '../../../types/ComboBoxDataType';

export type ComboBoxType = {
  id: string;
  value: string;
  header?: string;
  data: ComboBoxDataType[];
  tabIndex?: number;
  disabled?: boolean;
  testId?: string;
  noMarginBottom?: boolean;
  noMarginTop?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export default function ComboBox({ id, value, header, data, tabIndex, disabled, testId, handleChange, noMarginBottom, noMarginTop }: ComboBoxType) {
  return (
    <div className={`form-group ${noMarginBottom ? 'mb-0' : ''}`}>
      {!!header && <label htmlFor={id}>{header}</label>}
      <select
        className={`form-control ${!header && !noMarginTop ? 'mt-3' : ''}`}
        id={id}
        data-testid={testId}
        value={value}
        tabIndex={tabIndex}
        onChange={handleChange}
        disabled={disabled}
      >
        {data.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
