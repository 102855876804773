import React, { useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from '../../contexts/TranslationContext';
import { type ClothingGenderType } from '../../types/ClothingModelType';
import { CustomTableFixedCellsWidth } from '../../pages/Authenticated/Clothing/MyClothingModels/styles';
import BasicTextInput from '../Forms/BasicTextInput';
import {
  changeClothePrice,
  changeSizeName,
  changeClotheWeight,
  changeClotheProductionTime,
  changeClotheDimensions,
  type ChangeSizePriceParams,
  type ChangeSizeNameParams,
  type ChangeSizeWeightParams,
  type ChangeSizeProductionTimeParams,
  type ChangeSizeDimensionsParams,
  changeClotheProductionCost,
  type ChangeSizeProductionCostParams,
  type ChangeSizeLengthInMetersParams,
  changeClotheLengthInMeters
} from '../../pages/Authenticated/Clothing/MyClothingModels/services/clothingModelsService';
import { NumericFormat } from 'react-number-format';
import Tabs from '../Tabs';
import SplitButton from '../Buttons/SplitButton';
import { IconsCatalog } from '../IconsCatalog';
import TextInputModal from '../Modals/TextInputModal';
import TimeInput from '../Forms/TimeInput';

type ModelPricingEditorType = {
  modelPricingInformations: ClothingGenderType;
  onModelBudgetChange: (updatedModel: ClothingGenderType) => void;
  maleTabId: string;
  femaleTabId: string;
  childishTabId: string;
  disabled?: boolean;
  disableSizeEditing?: boolean;
}

export default function ModelPricingEditor({
  modelPricingInformations,
  onModelBudgetChange,
  maleTabId,
  femaleTabId,
  childishTabId,
  disabled,
  disableSizeEditing
}: ModelPricingEditorType) {
  const { Translate } = useAppTranslation();

  const [hideExtraInputs, setHideExtraInputs] = useState(true);
  const [showPriceInputModal, setShowPriceInputModal] = useState<boolean>(false);
  const [activeGender, setActiveGender] = useState<keyof ClothingGenderType>('male');

  const rowVisibility = useMemo(() => hideExtraInputs ? 'd-none' : '', [hideExtraInputs]);

  const handleChangeSizeName = useCallback(
    (params: Omit<ChangeSizeNameParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeSizeName({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleChangePrice = useCallback(
    (params: Omit<ChangeSizePriceParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeClothePrice({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleChangeWeight = useCallback(
    (params: Omit<ChangeSizeWeightParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeClotheWeight({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleChangeProductionTime = useCallback(
    (params: Omit<ChangeSizeProductionTimeParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeClotheProductionTime({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleChangeDimensions = useCallback(
    (params: Omit<ChangeSizeDimensionsParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeClotheDimensions({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleChangeProductionCost = useCallback(
    (params: Omit<ChangeSizeProductionCostParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeClotheProductionCost({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleChangeLengthInMeters = useCallback(
    (params: Omit<ChangeSizeLengthInMetersParams, 'model'>) => {
      const updatedModelPricingGendersInformations = changeClotheLengthInMeters({ ...params });
      onModelBudgetChange(updatedModelPricingGendersInformations);
    },
    [onModelBudgetChange]
  );

  const handleSetAllPricesAtOnce = useCallback((gender: keyof ClothingGenderType, price: number) => {
    const targetGenderToSetAllPrices = modelPricingInformations[gender];
    const updatedPricingInformations = targetGenderToSetAllPrices.map(budget => ({ ...budget, price }));

    const updatedModelPricingGendersInformations = {
      ...modelPricingInformations,
      [gender]: [...updatedPricingInformations]
    };

    onModelBudgetChange(updatedModelPricingGendersInformations);
  }, [modelPricingInformations, onModelBudgetChange]);

  const generateTableCellsForGender = useCallback((gender: keyof ClothingGenderType) => (
    <React.Fragment>
      <CustomTableFixedCellsWidth className='table table-bordered mt-3' width='100%' cellSpacing='0'>
        <thead>
          <tr>
            <th className='text-right'>
              <small>{Translate('labels.size')}</small>
            </th>
            {modelPricingInformations[gender].map((variation, index) => (
              <th key={index}>
                <BasicTextInput
                  smallText
                  disabled={disableSizeEditing ?? disabled}
                  value={variation.size}
                  onChange={({ target }) => {
                    handleChangeSizeName({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      updatedSize: target.value.toUpperCase()
                    });
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className={rowVisibility}>
            <td className='text-right'>
              <small>{Translate('labels.value')}</small>
            </td>
            {modelPricingInformations[gender].map((variation, index) => (
              <td key={index}>
                <NumericFormat
                  value={variation.price > 0 ? variation.price : ''}
                  decimalSeparator=','
                  decimalScale={2}
                  fixedDecimalScale
                  customInput={BasicTextInput}
                  disabled={disabled}
                  onValueChange={values => {
                    handleChangePrice({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      updatedPrice: values.floatValue ?? 0
                    });
                  }}
                />
              </td>
            ))}
          </tr>

          {/* WEIGHT */}
          <tr className={rowVisibility}>
            <td className='text-right'>
              <small>{Translate('labels.weight')}</small>
            </td>
            {modelPricingInformations[gender].map((variation, index) => (
              <td key={index}>
                <NumericFormat
                  value={variation.weight > 0 ? variation.weight : ''}
                  decimalSeparator=','
                  decimalScale={0}
                  fixedDecimalScale
                  suffix='g'
                  customInput={BasicTextInput}
                  disabled={disabled}
                  onValueChange={values => {
                    handleChangeWeight({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      updatedWeight: values.floatValue ?? 0
                    });
                  }}
                />
              </td>
            ))}
          </tr>

          {/* PRODUCTION TIME */}
          <tr className={rowVisibility}>
            <td className='text-right'>
              <small>{Translate('labels.time')}</small>
            </td>
            {modelPricingInformations[gender].map((variation, index) => (
              <td key={index}>
                <TimeInput
                  value={variation.productionTimeInMinutes ?? ''}
                  onChange={({ target }) => {
                    handleChangeProductionTime({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      timeInMinutes: target.value.trim()
                    });
                  }}
                />
              </td>
            ))}
          </tr>

          {/* CLOTHING DIMENSIONS */}
          <tr className={rowVisibility}>
            <td className='text-right'>
              <small>{Translate('labels.dimensions')}</small>
            </td>
            {modelPricingInformations[gender].map((variation, index) => (
              <td key={index}>
                <BasicTextInput
                  value={variation.dimensions ?? ''}
                  onChange={({ target }) => {
                    handleChangeDimensions({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      dimensions: target.value.trim()
                    });
                  }}
                />
              </td>
            ))}
          </tr>

          {/* PRODUCTION COST */}
          <tr className={rowVisibility}>
            <td className='text-right'>
              <small>{Translate('labels.production-cost')}</small>
            </td>
            {modelPricingInformations[gender].map((variation, index) => (
              <td key={index}>
                <NumericFormat
                  value={variation.productionCost && variation.productionCost > 0 ? variation.productionCost : ''}
                  decimalSeparator=','
                  decimalScale={2}
                  fixedDecimalScale
                  customInput={BasicTextInput}
                  disabled={disabled}
                  onValueChange={values => {
                    handleChangeProductionCost({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      productionCost: values.floatValue ?? 0
                    });
                  }}
                />
              </td>
            ))}
          </tr>

          {/* LENGTH IN METERS */}
          <tr className={rowVisibility}>
            <td className='text-right'>
              <small>{Translate('labels.length-paper-fabric')}</small>
            </td>
            {modelPricingInformations[gender].map((variation, index) => (
              <td key={index}>
                <NumericFormat
                  value={variation.lengthInMeters && variation.lengthInMeters > 0 ? variation.lengthInMeters : ''}
                  decimalSeparator=','
                  suffix='m'
                  decimalScale={2}
                  fixedDecimalScale
                  customInput={BasicTextInput}
                  disabled={disabled}
                  onValueChange={values => {
                    handleChangeLengthInMeters({
                      modelPricingInformations,
                      gender,
                      targetIndex: index,
                      lengthInMeters: values.floatValue ?? 0
                    });
                  }}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </CustomTableFixedCellsWidth>

      <div className='my-2'>
        <SplitButton
          marginLeft
          disabled={disabled}
          size='sm'
          color='secondary'
          title={hideExtraInputs ? Translate('labels.more-options') : Translate('labels.less-options')}
          icon={hideExtraInputs ? IconsCatalog.caretDown : IconsCatalog.caretUp}
          handleClick={() => {
            setHideExtraInputs(!hideExtraInputs);
          }}
        />

        {!hideExtraInputs && (
          <SplitButton
            marginLeft
            disabled={disabled}
            size='sm'
            color='primary'
            title={Translate('actions.define-values')}
            icon={IconsCatalog.dollar}
            handleClick={() => {
              setActiveGender(gender);
              setShowPriceInputModal(true);
            }}
          />
        )}
      </div>
    </React.Fragment>

  ), [
    Translate,
    disableSizeEditing,
    disabled,
    rowVisibility,
    hideExtraInputs,
    handleChangePrice,
    handleChangeProductionTime,
    handleChangeSizeName,
    handleChangeWeight,
    handleChangeDimensions,
    handleChangeProductionCost,
    handleChangeLengthInMeters,
    modelPricingInformations
  ]);

  return (
    <React.Fragment>
      <TextInputModal
        visible={showPriceInputModal}
        renderAsCurrencyInput
        currentValue=''
        label={`${Translate('labels.value')}:`}
        message={Translate('description.enter-value-to-set-to-all-sizes')}
        title={Translate('actions.define-values')}
        style='primary'
        handleClose={() => {
          setShowPriceInputModal(false);
        }}
        handleConfirm={(price) => {
          handleSetAllPricesAtOnce(activeGender, parseFloat(price));
        }}
      />

      <div className='table-responsive mb-3'>
        <Tabs
          data={[
            {
              id: maleTabId,
              active: true,
              label: Translate('labels.male'),
              content: generateTableCellsForGender('male')
            },
            {
              id: femaleTabId,
              label: Translate('labels.female'),
              content: generateTableCellsForGender('female')
            },
            {
              id: childishTabId,
              label: Translate('labels.childish'),
              content: generateTableCellsForGender('childish')
            }
          ]}
        />
      </div>
    </React.Fragment>
  );
};
