import React from 'react';
import { generateArrayOfNumbersInRange } from '../../utils/helper';

type PaginationControlType = {
  totalPages: number;
  activePage: number;
  handleNavigateToPage: (pageNumber: number) => void;
};

export default function PaginationControl({ totalPages, activePage, handleNavigateToPage }: PaginationControlType) {
  return (
    <nav>
      <ul className='pagination'>
        {generateArrayOfNumbersInRange(1, totalPages).map((num, index) => (
          <li className={`page-item ${activePage === num ? 'active' : ''}`} key={index}>
            <button
              className='page-link'
              type='button'
              onClick={() => {
                handleNavigateToPage(num);
              }}
            >
              {num}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}
