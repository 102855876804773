import React, { useRef, useState } from 'react';
import { ChromePicker, type ColorResult } from 'react-color';
import { Container } from './styles';
import BasicButton from '../../../../../../../components/Buttons/BasicButton';

type FloatingColorPickerType = {
  colorCode: string;
  onChangeColor: (color: ColorResult) => void;
  onRequestClose: () => void;
}

export default function FloatingColorPicker({
  colorCode,
  onChangeColor,
  onRequestClose
}: FloatingColorPickerType) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [color, setColor] = useState<string>(colorCode);

  return (
    <Container ref={containerRef}>
      <ChromePicker color={color} disableAlpha={true} onChange={(color) => {
        setColor(color.hex);
        onChangeColor(color);
      }} />

      <div className="d-flex justify-content-end mt-2">
        <BasicButton title={'Selecionar'} size={'sm'} color={'success'} handleClick={onRequestClose} />
      </div>

    </Container>
  );
}
