import { type TFunction } from 'i18next';
import { type ProjectShippingType } from '../../../../../../../types/ProjectShippingType';

export const generateEmptyShippingInformation = (): ProjectShippingType => ({
  carrier: '',
  code: '',
  price: 0
});

export const validateShippingInformation = (shipping: ProjectShippingType | null, Translate: TFunction) => {
  if (!shipping) return;

  if (shipping.carrier === '' && shipping.code === '' && shipping.price === 0) throw new Error(Translate('error.shipping-all-empty'));

  if (shipping.carrier === '') throw new Error(Translate('error.shipping-carrier-mandatory'));

  if (shipping.code === '') throw new Error(Translate('error.shipping-tracking-number-mandatory'));
};
