import React from 'react';
import packageJson from '../../../package.json';

export default function Footer() {
  return (
    <footer className='sticky-footer bg-white d-print-none p-3'>
      <div className='container'>
        <div className='copyright text-center'>
          <span className='d-block'>Copyright &copy; Aistenlab Technology</span>
          <br />
          <span>v{packageJson.version}</span>
        </div>
      </div>
    </footer>
  );
};
