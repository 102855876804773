import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { PrintOverlay } from '../OrderList/ProjectReport/styles';
import { type ProjectInvoiceData } from '../../../types/ProjectInvoiceData';
import Spinner from '../../../components/Spinner';
import getInvoiceData from './api/getInvoiceData';
import { toast } from 'react-hot-toast-promise';
import { useHttpRequest } from '../../../contexts/HttpRequestContext';
import { displayAsCurrency, getServerErrorMessageFromResponse } from '../../../utils/helper';
import SplitButton from '../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../components/IconsCatalog';
import CircleButton from '../../../components/Buttons/CircleButton';
import { useTheme } from '../../../contexts/ThemeContext';
import { takeScreenshot } from '../OrderList/services/screenshotService';
import { downloadBase64Image } from '../../../components/ImagePickerBase64/services/imageManipulation';
import Badge from '../../../components/Buttons/Badge';

export default function Invoice() {
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();
  const { isDark, toggleTheme } = useTheme();

  const invoiceContainerRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState<ProjectInvoiceData | null>(null);

  const handleGetInvoiceData = useCallback(() => {
    const urlParts = location.pathname.split('/');
    const projectUUID = urlParts[urlParts.length - 1];

    getInvoiceData({ httpConnection, projectUUID })
      .then((invoice) => {
        setInvoice(invoice);
      })
      .catch(err => {
        toast.error(getServerErrorMessageFromResponse(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [httpConnection]);

  const handleDownloadInvoiceScreenshot = useCallback(async () => {
    if (invoiceContainerRef.current === null) return;

    const screenshotProcessor = takeScreenshot({
      targetDivElement: invoiceContainerRef.current,
      delayInMilliseconds: 1500
    });

    await toast.promise(screenshotProcessor, {
      loading: Translate('progress.generating-image'),
      success: screenshotData => {
        downloadBase64Image(screenshotData, `${Translate('labels.invoice')}.png`);
        return <span>{Translate('toast.done')}</span>;
      },
      error: getServerErrorMessageFromResponse
    });
  }, [Translate]);

  useEffect(() => {
    handleGetInvoiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Spinner />;

  if (!loading && !invoice) return <>{Translate('error.failed-to-load-invoice')}</>;

  if (!invoice) return <></>;

  return (
    <React.Fragment>
      <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
        <CircleButton
          icon={!isDark ? IconsCatalog.moon : IconsCatalog.sun}
          color='light'
          handleClick={toggleTheme}
        />
      </nav>

      <div className="container">
        <div className="row d-print-none mt-3">
          <div className="col text-right">
            <SplitButton
              color='primary'
              icon={IconsCatalog.print}
              handleClick={() => {
                window.print();
              }}
            />

            <SplitButton
              marginLeft
              color='primary'
              icon={IconsCatalog.download}
              handleClick={handleDownloadInvoiceScreenshot}
            />
          </div>
        </div>
      </div>

      <PrintOverlay>
        <div className="container" ref={invoiceContainerRef}>
          <div className="row">
            {!!invoice.company.logo && (
              <div className="col-auto">
                <img
                  className="img-responsive"
                  width={130}
                  src={invoice.company.logo}
                />
              </div>
            )}

            <div className="col d-flex flex-column justify-content-center">
              <section>
                <span>{Translate('labels.company')}: </span>
                <b>{invoice.company.name}</b>
              </section>
              <section>
                <span>{Translate('labels.client')}: </span>
                <b>{invoice.client_name}</b>
              </section>
            </div>

            <div className="col d-flex flex-column justify-content-center align-items-end">
              <h1>{Translate('labels.invoice')}</h1>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col">
              {
                invoice.sublists_invoice.map(({
                  sublist_name,
                  discount,
                  pieces_quantity,
                  total_price,
                  selected_finishing_options
                }, index) => (
                  <table className="table table-sm table-bordered" key={index}>
                    <thead>
                    <tr>
                      <th>{sublist_name}</th>
                      <th className="text-right">
                        {discount > 0 && (
                          <span>
                              {Translate('labels.discount')}: {discount}%
                            </span>
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className="text-center">{Translate('labels.pieces-quantity')}</th>
                      <th className="text-right">{Translate('labels.total-price')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td className="text-center" width={400}>{pieces_quantity}</td>
                      <td className="text-right">{displayAsCurrency(total_price, invoice.currency)}</td>
                    </tr>

                    {
                      selected_finishing_options.length > 0 && (
                        <React.Fragment>
                          <tr>
                            <td colSpan={99} className={'text-center font-weight-bold'}>Acabamentos</td>
                          </tr>

                          <tr>
                            <td colSpan={99} className={'text-center'}>
                              {
                                selected_finishing_options.map((selected_finishing_option, index) => (
                                  <span className={'border mr-2 px-1'} key={index}>
                                    <strong>{selected_finishing_option.option.title}</strong>:
                                      <span>{selected_finishing_option.option_picked.item}
                                        <Badge color={''}
                                               content={`(${displayAsCurrency(selected_finishing_option.option_picked.price, invoice.currency)})`} />

                                  </span>
                              </span>
                                ))
                              }
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    }
                    </tbody>
                  </table>
                ))
              }
            </div>
          </div>

          {!!invoice.acceptance_message && (
            <p className={'border-left px-1'}>{invoice.acceptance_message}</p>
          )}

          <div className="row mt-4">
            <div className="col-5 ml-auto">
              <table className="table table-sm table-bordered">
                <tbody>
                <tr>
                  <td className="text-right">{Translate('labels.final-price')}</td>
                  <td
                    className="text-center font-weight-bold">{displayAsCurrency(invoice.total_project_price, invoice.currency)}</td>
                </tr>
                <tr>
                  <td className="text-right">{Translate('labels.to-receive')}</td>
                  <td
                    className="text-center font-weight-bold">{displayAsCurrency(invoice.payment_to_receive, invoice.currency)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          {!!invoice.digital_signature && (
            <div className="row">
              <div className="col text-right">
                <img
                  className="img-responsive"
                  width={350}
                  src={invoice.digital_signature}
                  alt={Translate('labels.digital-signature')}
                />
              </div>
            </div>
          )}
        </div>
      </PrintOverlay>
    </React.Fragment>
  );
};
