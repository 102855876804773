import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useHttpRequest } from './HttpRequestContext';
import { convertBase64ImageToBlob } from '../components/ImagePickerBase64/services/imageManipulation';
import { type ProjectType } from '../types/ProjectType';
import { type ProjectBasicType } from '../types/ProjectBasicType';
import { type ProjectUpdateType } from '../types/ProjectUpdateType';
import { type PaginationInfo } from '../types/PaginationInfo';
import { type SublistLayoutType } from '../types/SublistLayoutType';
import { type AxiosResponse, type RawAxiosRequestHeaders } from 'axios';
import { type PaginatedProjects } from '../types/PaginatedProjects';
import { type ProjectDataFinalClientEditor } from '../types/FinalClientEditor/ProjectDataFinalClientEditor';
import { type SublistType } from '../types/SublistTypes';
import { type ProjectSharedLinkOptionsType } from '../types/ProjectSharedLinkOptionsType';
import { downloadBlobFile } from '../utils/helper';
import { useAppTranslation } from './TranslationContext';

type ProjectsProviderType = {
  children: JSX.Element;
};

type FinishFinalClientEditingsParams = {
  projectId: number;
  accessToken: string;
  clientNameAccepting: string;
  signatureImageData: string;
}

export type SearchProjectType = {
  searchText: string;
  searchFilter: string;
  userId: number;
  pageNumber: number;
};

type ProjectsContextType = {
  projects: ProjectBasicType[];
  setProjects: React.Dispatch<React.SetStateAction<ProjectBasicType[]>>;
  getProjectData: (projectId: number) => Promise<ProjectType>;
  createProject: (project: ProjectType) => Promise<ProjectType>;
  updateProject: (project: ProjectUpdateType) => Promise<ProjectType>;
  updateProjectBasicData: (projectBasicData: ProjectBasicType) => Promise<void>;
  updateProjectWithFinalClientChanges: (projectId: number, accessToken: string, sublists: SublistType[]) => Promise<void>;
  finishFinalClientEditings: (params: FinishFinalClientEditingsParams) => Promise<void>;
  deleteProjects: (projects: ProjectBasicType[]) => Promise<void>;
  searchProject: (params: SearchProjectType) => Promise<void>;
  loadProjects: (pageNumber: number) => Promise<void>;
  loadProjectForAnonymousUser: (projectId: number, accessToken: string) => Promise<ProjectDataFinalClientEditor>;
  updateSharedLinkOptions: (projectId: number, projectSharedLinkOptions: ProjectSharedLinkOptionsType) => Promise<void>;
  processProjectsListResponse: (projectsPaginatedData: PaginatedProjects) => void;
  addLayoutToSublist: (sublistId: number, image: string) => Promise<SublistLayoutType>;
  removeLayoutFromSublist: (sublistId: number) => Promise<void>;
  downloadProjectAsZip: (project: ProjectType) => Promise<void>;
  downloadAllProjectsAsZip: () => Promise<void>;
  paginationControl: PaginationInfo;
};

const ProjectsContext = createContext<ProjectsContextType>({} as ProjectsContextType);

export const ProjectsProvider = ({ children }: ProjectsProviderType) => {
  const [projects, setProjects] = useState<ProjectBasicType[]>([]);
  const [paginationControl, setPaginationControl] = useState<PaginationInfo>({} as PaginationInfo);

  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const getProjectData = useCallback(
    async (projectId: number) => {
      return await new Promise<ProjectType>((resolve, reject) => {
        httpConnection
          .get<ProjectType>(`/list/clothing-projects/${projectId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error);
          });
      });
    },
    [httpConnection]
  );

  const processProjectsListResponse = useCallback((projectsPaginatedData: PaginatedProjects) => {
    const { data, ...pagination } = projectsPaginatedData;
    setProjects(data);
    setPaginationControl({ ...pagination, isSearchResult: false });
  }, []);

  const loadProjects = useCallback(
    async (pageNumber: number): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        const paginationUrl = `/list/clothing-projects?page=${pageNumber}`;

        httpConnection
          .get<PaginatedProjects>(paginationUrl)
          .then(response => {
            processProjectsListResponse(response.data);
            resolve();
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error);
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [httpConnection]
  );

  const loadProjectForAnonymousUser = useCallback(
    async (projectId: number, accessToken: string): Promise<ProjectDataFinalClientEditor> => {
      return await new Promise<ProjectDataFinalClientEditor>((resolve, reject) => {
        const url = `/list/clothing-projects/load-with-token?project_id=${projectId}&access_token=${accessToken}`;

        httpConnection
          .get<ProjectDataFinalClientEditor>(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [httpConnection]
  );

  const createProject = useCallback(
    async (project: ProjectType): Promise<ProjectType> => {
      const postData = {
        ...project,
        status_id: project.status?.id ?? undefined
      };

      return await new Promise<ProjectType>((resolve, reject) => {
        httpConnection
          .post<ProjectType>('/list/clothing-projects', postData)
          .then(response => {
            const { id, created_at, updated_at, user_id, order_number, status } = response.data;
            const { sublists, ...projectBasicData } = project;

            const newProject: ProjectBasicType = {
              ...projectBasicData,
              id: id!,
              user_id,
              created_at: created_at!,
              updated_at: updated_at!,
              order_number,
              status
            };

            setProjects([...projects, newProject]);
            setPaginationControl({ ...paginationControl, total: paginationControl.total + 1 });
            resolve(response.data);
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error);
          });
      });
    },
    [httpConnection, paginationControl, projects]
  );

  const updateProject = useCallback(
    async (project: ProjectUpdateType): Promise<ProjectType> => {
      const cleanedSublists = project.sublists.map(sublist => {
        const { selected_finishing_options, ...fields } = sublist;
        return fields;
      });

      const projectWithCleanedSublists: ProjectUpdateType = {
        ...project,
        sublists: cleanedSublists
      };

      return await new Promise((resolve, reject) => {
        httpConnection
          .put<ProjectType>(`/list/clothing-projects/${project.id}`, projectWithCleanedSublists)
          .then(response => {
            const { updated_at } = response.data;
            const updatedProjectsList = projects.map<ProjectBasicType>(currentProject => {
              if (currentProject.id === project.id) {
                const updatedProjectData = { ...currentProject, name: project.name, updated_at: updated_at! };
                return updatedProjectData;
              }

              return currentProject;
            });

            const sortedProjectsList = updatedProjectsList.sort((a, b) => {
              const dateA = a.updated_at ? new Date(a.updated_at).getTime() : 0;
              const dateB = b.updated_at ? new Date(b.updated_at).getTime() : 0;

              return dateB - dateA;
            });

            setProjects(sortedProjectsList);
            resolve(response.data);
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error);
          });
      });
    },
    [httpConnection, projects]
  );

  const updateProjectBasicData = useCallback(
    async (projectBasicData: ProjectBasicType): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        const url = `list/clothing-projects/basic-data/${projectBasicData.id}`;

        httpConnection
          .put<ProjectBasicType>(url, projectBasicData)
          .then(response => {
            const updatedProjectsList = projects.map(project => {
              if (project.id === projectBasicData.id) return { ...project, ...response.data };
              return project;
            });

            setProjects(updatedProjectsList);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [httpConnection, projects]
  );

  const updateProjectWithFinalClientChanges = useCallback(
    async (projectId: number, accessToken: string, sublists: SublistType[]): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        const preparedSublists = sublists.map(sublist => {
          return {
            id: sublist.id,
            orders: sublist.orders
          };
        });

        const url = `list/clothing-projects/save-with-token?project_id=${projectId}&access_token=${accessToken}`;

        httpConnection
          .put(url, { sublists: preparedSublists })
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [httpConnection]
  );

  const finishFinalClientEditings = useCallback(
    async ({ projectId, accessToken, clientNameAccepting, signatureImageData }: FinishFinalClientEditingsParams): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        const url = `list/clothing-projects/save-with-token-finish-changes?project_id=${projectId}&access_token=${accessToken}`;

        const formData = new FormData();
        formData.append('signature_image_data', convertBase64ImageToBlob(signatureImageData, Translate), 'image.png');
        formData.append('client_name_authorize_production', clientNameAccepting);

        const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;

        httpConnection.post(url, formData, {
          headers: {
            ...defaultHeaders,
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          resolve();
        })
          .catch(err => {
            reject(err);
          });
      });
    },
    [Translate, httpConnection]
  );

  const updateSharedLinkOptions = useCallback(
    async (projectId: number, projectSharedLinkOptions: ProjectSharedLinkOptionsType): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        httpConnection
          .put(`/list/clothing-projects/update-shared-link-options/${projectId}`, projectSharedLinkOptions)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [httpConnection]
  );

  const deleteProjects = useCallback(
    async (selectedProjects: ProjectBasicType[]) => {
      await new Promise<void>((resolve, reject) => {
        const selectedProjectsIds = selectedProjects.map(project => project.id);
        const url = '/list/clothing-projects/bulk-delete';

        httpConnection
          .post(url, { selected_ids: selectedProjectsIds })
          .then(async () => {
            await loadProjects(1);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [httpConnection, loadProjects]
  );

  const searchProject = useCallback(
    async ({ searchText, searchFilter, userId, pageNumber }: SearchProjectType): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        const url = `/list/clothing-projects/search?page=${pageNumber}`;

        const searchSettings = {
          search_text: searchText,
          search_filter: searchFilter,
          user_id: userId
        };

        httpConnection
          .post<PaginatedProjects>(url, searchSettings)
          .then(response => {
            const { data, ...pagination } = response.data;

            setProjects(data);
            setPaginationControl({ ...pagination, isSearchResult: true });
            resolve();
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error);
          });
      });
    },
    [httpConnection]
  );

  const downloadProjectAsZip = useCallback(
    async (project: ProjectType): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        httpConnection
          .get(`list/clothing-projects/download/${project.id}`, {
            responseType: 'blob'
          })
          .then(response => {
            downloadBlobFile({
              data: response.data,
              contentType: response.headers['content-type'],
              filename: `${project.name}.zip`
            });
            resolve();
          })
          .catch(() => {
            reject(new Error(Translate('error.failed-to-download')));
          });
      });
    },
    [Translate, httpConnection]
  );

  const downloadAllProjectsAsZip = useCallback(
    async (): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        httpConnection
          .get('list/clothing-projects/download/all-projects', {
            responseType: 'blob'
          })
          .then(response => {
            downloadBlobFile({
              data: response.data,
              contentType: response.headers['content-type'],
              filename: Translate('labels.projects')
            });
            resolve();
          })
          .catch(() => {
            reject(new Error(Translate('error.failed-to-download')));
          });
      });
    },
    [Translate, httpConnection]
  );

  const addLayoutToSublist = useCallback(
    async (sublistId: number, imageData: string): Promise<SublistLayoutType> => {
      return await new Promise<SublistLayoutType>((resolve, reject) => {
        const binaryImage = convertBase64ImageToBlob(imageData, Translate);

        const formData = new FormData();
        formData.append('sublist_id', sublistId.toString());
        formData.append('image', binaryImage, 'image.png');

        const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;
        const url = '/list/clothing-projects/sublist/add-layout';

        const fileUploadHeaders = {
          headers: {
            ...defaultHeaders,
            'Content-Type': 'multipart/form-data'
          }
        };

        httpConnection
          .post<SublistLayoutType>(url, formData, fileUploadHeaders)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [Translate, httpConnection]
  );

  const removeLayoutFromSublist = useCallback(
    async (sublistId: number): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        httpConnection
          .delete(`/list/clothing-projects/sublist/${sublistId}/remove-layout`)
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    [httpConnection]
  );

  const contextValues = useMemo(
    () => ({
      projects,
      setProjects,
      getProjectData,
      createProject,
      updateProject,
      updateProjectBasicData,
      updateProjectWithFinalClientChanges,
      finishFinalClientEditings,
      deleteProjects,
      paginationControl,
      searchProject,
      downloadProjectAsZip,
      downloadAllProjectsAsZip,
      loadProjects,
      loadProjectForAnonymousUser,
      updateSharedLinkOptions,
      processProjectsListResponse,
      addLayoutToSublist,
      removeLayoutFromSublist
    }),
    [
      projects,
      setProjects,
      getProjectData,
      createProject,
      updateProject,
      updateProjectBasicData,
      updateProjectWithFinalClientChanges,
      finishFinalClientEditings,
      deleteProjects,
      paginationControl,
      searchProject,
      downloadProjectAsZip,
      downloadAllProjectsAsZip,
      loadProjects,
      loadProjectForAnonymousUser,
      updateSharedLinkOptions,
      processProjectsListResponse,
      addLayoutToSublist,
      removeLayoutFromSublist
    ]
  );

  return <ProjectsContext.Provider value={contextValues}>{children}</ProjectsContext.Provider>;
};

export const useProjects = (): ProjectsContextType => {
  const context = useContext(ProjectsContext);

  if (!context) throw new Error('useProjects must be used within a ProjectsProvider');

  return context;
};
