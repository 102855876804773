import { type AxiosInstance } from 'axios';
import { type Order } from '../../../../../../../types/OrderTypes';

type SaveSublistSortingConfigParams = {
  httpConnection: AxiosInstance;
  projectId: number;
  sublistId: number;
  lastSortedField: string;
}

export const sortOrdersInSublist = async ({ httpConnection, projectId, sublistId, lastSortedField }: SaveSublistSortingConfigParams): Promise<Order[]> => {
  return await new Promise<Order[]>((resolve, reject) => {
    const putData = {
      project_id: projectId,
      sublist_id: sublistId,
      sorting: {
        field: lastSortedField
      }
    };

    httpConnection.put<Order[]>('list/clothing-projects/sublist/sorting', putData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
