import { type SublistType } from '../../../../../types/SublistTypes';
type UpdateSublistOrdersParams = {
  sublist: SublistType;
  sublists: SublistType[];
}

/**
 * Find the `sublist` inside the array of `sublists` and return the updated array
 * @param sublist
 * @param sublists
 */
export const updateSublistInCachedSublists = ({ sublist, sublists }: UpdateSublistOrdersParams) => {
  return sublists.map(currentSublist => currentSublist.id === sublist.id ? sublist : currentSublist);
};
