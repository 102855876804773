import { useQuery } from 'react-query';
import { Content } from './fragments/Content';
import { Header } from './fragments/Header';
import { Container } from './style';
import { showAllSurvey } from './service';
import { type SurveyResponse } from './contractors/SurveyResponse';

export function DashboardSurvey() {
  const { data } = useQuery<SurveyResponse>(
    ['survey'],
    async () => {
      const response = await showAllSurvey.execute();
      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );

  return (
    <Container>
      <Header data={data?.header} />
      <Content data={data?.data} />
    </Container>
  );
}
