import styled from 'styled-components';

type OverlayType = {
  zIndexIncrement?: number;
}

export const Overlay = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'zIndexIncrement' }) <OverlayType>`
  background-color: #00000088;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${prop => prop.zIndexIncrement ? 999 + prop.zIndexIncrement : 999};
`;
