import styled, { createGlobalStyle } from 'styled-components';

export const GlobalWorkspace = createGlobalStyle`
  .sticky-footer {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
