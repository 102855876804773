import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';
import { type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type Entity } from '../../../../../types/Entity';

type Params = {
  id?: number;
};

export type EntityResponse = {
  id: number;
  entity_id: number;
  list_clothing_model_id: number;
  price: string;
  created_at: string;
  updated_at: string;
  entity: Entity;
  list_clothing_project_sublist_and_factions: {
    id: number;
    sublist_id: number;
    faction_id: number;
    price: string;
    quantity: number;
    created_at: string;
    updated_at: string;
  };
  list_clothing_model: ClothingModelType;
};

type ShowEntityReturn = Record<string, EntityResponse[]>;

export class ShowEntity {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ id }: Params): Promise<ShowEntityReturn[]> {
    return await this.http.get(`/entity/${id}`);
  }
}
