import { useUser } from '../../../../../../contexts/UserContext';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { displayAsCurrency } from '../../../../../../utils/helper';
import { type UserPreferences } from '../../../../../../types/UserPreferences';

type FooterDetailsForPrintScreenType = {
  projectTotalPrice: number | null;
  projectTotalPriceWithDiscount: number | null;
  piecesTotalCount: number;
  preferences: UserPreferences;
  hidePrices: boolean;
};

export default function FooterDetailsForPrintScreen({
  projectTotalPrice,
  projectTotalPriceWithDiscount,
  piecesTotalCount,
  hidePrices,
  preferences
}: FooterDetailsForPrintScreenType) {
  const { user } = useUser();
  const { Translate } = useAppTranslation();

  return (
    <div className='border shadow bg-white p-3 mb-4'>
      <div className='row align-items-center'>
        <div className='col-4'>
          {!!projectTotalPrice && (
            <p className='mb-2 h5'>
              <span>{Translate('labels.full-total-price')}:</span> <strong>{displayAsCurrency(projectTotalPrice, preferences.currency)}</strong>
            </p>
          )}
          {!!projectTotalPriceWithDiscount && (projectTotalPriceWithDiscount !== projectTotalPrice) && !hidePrices && (
            <p className='mb-2 h5'>
              <span>{Translate('labels.total-price-with-discount')}:</span> <strong>{displayAsCurrency(projectTotalPriceWithDiscount, preferences.currency)}</strong>
            </p>
          )}
          <p className='m-0 h5'>
            <span>{Translate('labels.full-total-pieces-count')}:</span> <strong>{piecesTotalCount}</strong>
          </p>
        </div>
        <div className='col-4 text-center'>
          <p className='m-0'>{preferences.screenshot_description ?? ''}</p>
        </div>
        <div className='col-4 text-right'>
          {user?.logo && <img src={user.logo} width={100} height={100} />}
        </div>
      </div>
    </div>
  );
}
