import { type AxiosInstance } from 'axios';
import { type KanbanColumn } from '../../../../types/Kanban/KanbanColumn';
import { type KanbanColumnsUpdatedResponse } from '../../../../types/Kanban/KanbanColumnsUpdatedResponse';

type RenameColumnParams = Pick<KanbanColumn, 'id' | 'workspace_id' | 'title'>;

export const renameColumn = async (data: RenameColumnParams, httpConnection: AxiosInstance): Promise<KanbanColumnsUpdatedResponse> => {
  const { id, title, workspace_id } = data;

  return await new Promise<KanbanColumnsUpdatedResponse>((resolve, reject) => {
    httpConnection.put<KanbanColumnsUpdatedResponse>(`kanban/workspaces/columns/${id}`, { title, workspace_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
