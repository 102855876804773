import styled from 'styled-components';

export const ClothingHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  padding: 15px;
  background-color: var(--light-v2);
  border: 1px solid var(--border-color-v1);
  border-radius: 5px;

  & > h5 {
    margin: 0;
    padding: 0;
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const GridSizes = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust the column width as needed */
  grid-gap: 15px; /* Adjust the gap between items as needed */
  align-items: center;
  justify-items: center;
  margin: 20px 0 20px 0;
  border: 1px dashed var(--border-color-v1);
  border-width: 0 0 1px 0;
  padding-bottom: 20px;
`;
