import { type FinishingOption } from '../../../../../../../../../types/ClothingFinishingOptions/FinishingOption';
import { type FinishingOptionBasic } from '../../../../../../../../../types/ClothingFinishingOptions/FinishingOptionBasic';
import { type FinishingOptionItem } from '../../../../../../../../../types/ClothingFinishingOptions/FinishingOptionItem';
import { type SublistFinishingOption } from '../../../../../../../../../types/ClothingFinishingOptions/SublistFinishingOption';
import { type ComboBoxDataType } from '../../../../../../../../../types/ComboBoxDataType';
import { displayAsCurrency } from '../../../../../../../../../utils/helper';

export const pickFirstFinishinOption = (finishingOptions: FinishingOption[]) => {
  return finishingOptions[0];
};

export const generateComboBoxFinishingOptions = (finishingOptions: FinishingOption[]) => {
  const options = finishingOptions.map<ComboBoxDataType>(({ id, title }) => ({
    value: id,
    label: title
  }));

  return options;
};

export const generateComboBoxFinishingOptionsItems = (finishingOptionsItems: FinishingOptionItem[], currency: string) => {
  const options = finishingOptionsItems.map<ComboBoxDataType>(({ id, item, price }) => ({
    value: id,
    label: `${item} - ${displayAsCurrency(price, currency)}`
  }));

  return options;
};

type IsDuplicatedFinishingOptionInModelParams = {
  sublistFinishingOptions: SublistFinishingOption[];
  modelId: number;
  selectedFinishingOptionId: number;
}

export const isDuplicatedFinishingOptionInModel = ({ sublistFinishingOptions, modelId, selectedFinishingOptionId }: IsDuplicatedFinishingOptionInModelParams) => {
  return sublistFinishingOptions.find(sublistFinishingOption => {
    const isSameModel = sublistFinishingOption.model_id === modelId;
    const isSameOption = sublistFinishingOption.option.id === selectedFinishingOptionId;
    return isSameModel && isSameOption;
  });
};

export const updateCachedFinishingOptions = (finishingOptions: FinishingOption[], editedFinishingOption: FinishingOptionBasic) => {
  const updatedFinishingOptions = finishingOptions.map(finishingOption => {
    if (finishingOption.id === editedFinishingOption.id) {
      return ({ ...finishingOption, ...editedFinishingOption });
    }

    return finishingOption;
  });

  return updatedFinishingOptions;
};

export const updateCachedFinishingOptionsAndItems = (finishingOptions: FinishingOption[], editedFinishingOption: FinishingOption) => {
  const updatedFinishingOptions = finishingOptions.map(finishingOption => {
    if (finishingOption.id === editedFinishingOption.id) {
      return ({ ...finishingOption, ...editedFinishingOption });
    }

    return finishingOption;
  });

  return updatedFinishingOptions;
};

export const deleteFromCachedFinishingOptions = (finishingOptions: FinishingOption[], finishingOptionToDelete: FinishingOption) => {
  return finishingOptions.filter(finishingOption => finishingOption.id !== finishingOptionToDelete.id);
};

export const generateInitialSelectedFinishingOption = (finishingOptions: FinishingOption[]): FinishingOption => {
  if (finishingOptions.length === 0) return { id: 0, title: '', option_items: [] };
  return finishingOptions[0];
};

export const generateInitialSelectedFinishingOptionItem = (selectedFinishingOption: FinishingOption): FinishingOptionItem => {
  if (selectedFinishingOption.option_items.length === 0) return { id: 0, item: '', price: 0 };
  return selectedFinishingOption.option_items[0];
};
