import styled from 'styled-components';

type SidebarContainerProps = {
  isCollapsed: boolean;
}

export const Container = styled.ul.withConfig({ shouldForwardProp: prop => prop !== 'isCollapsed' }) <SidebarContainerProps>`
  width: ${props => props.isCollapsed ? '0px !important' : 'inherit'};
  overflow: ${props => props.isCollapsed ? 'hidden' : 'none'};
  transition: width .3s ease;
`;
