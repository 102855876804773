import React, { useCallback, useMemo, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../../../components/Modals/ModalBase';
import { useClothingReplacementOptions } from '../../../../../../../../contexts/ClothingReplacementOptionsContext';
import ComboBox from '../../../../../../../../components/Forms/ComboBox';
import {
  type OrderClothingReplacementNewRegistryType
} from '../../../../../../../../types/ClothingReplacementOption/OrderClothingReplacementNewRegistryType';
import TextAreaInput from '../../../../../../../../components/Forms/TextAreaInput';
import { type ClothingModelType } from '../../../../../../../../types/ClothingModelType';
import { generateComboboxOptionsFromImportedModels } from '../../../../services/modelService';
import Tabs, { type TabParam } from '../../../../../../../../components/Tabs';
import { type Order } from '../../../../../../../../types/OrderTypes';
import BasicButton from '../../../../../../../../components/Buttons/BasicButton';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import addOrderClothingReplacement from './api/addOrderClothingReplacement';
import { useHttpRequest } from '../../../../../../../../contexts/HttpRequestContext';
import { toast } from 'react-hot-toast-promise';
import { getServerErrorMessageFromResponse } from '../../../../../../../../utils/helper';
import validateOrderClothingReplacement from './services/validateOrderClothingReplacement';
import checkDuplicatedClothingReplacement from './services/checkDuplicatedClothingReplacement';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';
import ColorableRow from '../../../../../../../../components/Tables/ColorableRow';
import { ContextMenuProvider } from '../../../../../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../../../../../components/ContextMenuViewer';
import generateInitialOrderCothingReplacementState from './services/generateInitialOrderCothingReplacementState';
import checkDuplicateOnEditOrderClothingReplacement from './services/checkDuplicateOnEditOrderClothingReplacement';
import editOrderClothingReplacement from './api/editOrderClothingReplacement';
import {
  type ListClothingProjectOrderReplacementType
} from '../../../../../../../../types/ClothingReplacementOption/ListClothingProjectOrderReplacementType';
import ConfirmationModal from '../../../../../../../../components/Modals/ConfirmationModal';
import deleteOrderClothingReplacement from './api/deleteOrderClothingReplacement';
import { useNavigate } from 'react-router';
type OrderClothingReplacementManagerModalType =
  Omit<Pick<ModalBaseType, 'title' | 'message' | 'handleClose' | 'handleConfirm'>, 'handleConfirm'>
  & {
    importedModels: ClothingModelType[];
    order: Order;
    onUpdateOrder: (updatedOrder: Order) => void;
  }

export default function OrderClothingReplacementManagerModal({
  importedModels,
  order,
  onUpdateOrder,
  ...props
}: OrderClothingReplacementManagerModalType) {
  const { clothingReplacementOptionsComboboxOptions } = useClothingReplacementOptions();
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();
  const navigate = useNavigate();

  const initialData = useMemo(() => {
    return generateInitialOrderCothingReplacementState({
      orderId: order.id!,
      importedModels,
      clothingReplacementOptionsComboboxOptions
    });
  }, [clothingReplacementOptionsComboboxOptions, importedModels, order.id]);

  const [orderClothingReplacementRegistry, setOrderClothingReplacementRegistry] = useState<OrderClothingReplacementNewRegistryType>(initialData);
  const [orderClothingReplacementToDelete, setOrderClothingReplacementToDelete] = useState<OrderClothingReplacementNewRegistryType | null>(null);

  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const isSelectedReplacement = useCallback((replacement: ListClothingProjectOrderReplacementType) => {
    const isEditingReplacement = !!orderClothingReplacementRegistry?.id && orderClothingReplacementRegistry.id === replacement.id;
    const isMarkedToDeleteReplacement = !!orderClothingReplacementToDelete && orderClothingReplacementToDelete.id === replacement.id;

    return isEditingReplacement || isMarkedToDeleteReplacement;
  }, [orderClothingReplacementRegistry.id, orderClothingReplacementToDelete]);

  const tabsContent: TabParam[] = useMemo<TabParam[]>(() => {
    const tabsContentTemp: TabParam[] = [];

    if (order.clothing_replacements.length === 0) return [];

    order.clothing_replacements.forEach((currentClothingReplacement) => {
      let tabModelSettings: Pick<ClothingModelType, 'id' | 'name'> | null = null;

      const modelTabContent = (
        <React.Fragment>
          <table className={'table table-sm table-stripped table-hover table-bordered'}>
            <thead>
            <tr>
              <th>{Translate('labels.clothe-to-replace')}</th>
              <th>{Translate('labels.notes')}</th>
            </tr>
            </thead>
            <tbody>
            {
              currentClothingReplacement.map((replacement, key) => {
                const { id, name } = replacement.model;
                if (!tabModelSettings) tabModelSettings = { id, name };

                const isSelected = isSelectedReplacement(replacement);

                return (
                  <ColorableRow data={replacement} color={'red'} key={key} isSelected={isSelected}>
                    <td>{replacement.clothing_piece.name}</td>

                    <td className={'text-truncate'} style={{ maxWidth: '150px' }}>
                      <span title={replacement.notes}>{replacement.notes ?? '-'}</span>
                    </td>
                  </ColorableRow>
                );
              })
            }
            </tbody>
          </table>
        </React.Fragment>
      );

      const modelTabConfig: TabParam = {
        id: `model-replacement-item-${tabModelSettings!.id}`,
        label: tabModelSettings!.name,
        active: orderClothingReplacementRegistry.model_id === tabModelSettings!.id,
        content: modelTabContent
      };

      tabsContentTemp.push(modelTabConfig);
    });

    return tabsContentTemp;
  }, [Translate, isSelectedReplacement, order.clothing_replacements, orderClothingReplacementRegistry.model_id]);

  const handleSave = useCallback(() => {
    try {
      validateOrderClothingReplacement(orderClothingReplacementRegistry, Translate);

      checkDuplicatedClothingReplacement({
        orderClothingReplacement: orderClothingReplacementRegistry,
        cachedOrderClothingReplacementInOrder: order.clothing_replacements,
        Translate
      });

      const addOrderClothingReplacementPromise = addOrderClothingReplacement({
        httpConnection,
        orderClothingReplacementRegistry
      });

      setIsRequesting(true);

      toast.promise(addOrderClothingReplacementPromise, {
        loading: Translate('progress.loading'),
        success: (updatedOrderClothingReplacements) => {
          onUpdateOrder({ ...order, clothing_replacements: updatedOrderClothingReplacements });
          setOrderClothingReplacementRegistry({ ...orderClothingReplacementRegistry, notes: '' });
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  }, [Translate, httpConnection, onUpdateOrder, order, orderClothingReplacementRegistry]);

  const handleEdit = useCallback(() => {
    try {
      validateOrderClothingReplacement(orderClothingReplacementRegistry, Translate);

      checkDuplicateOnEditOrderClothingReplacement({
        orderClothingReplacement: orderClothingReplacementRegistry,
        cachedOrderClothingReplacementInOrder: order.clothing_replacements,
        Translate
      });

      const editOrderClothingReplacementPromise = editOrderClothingReplacement({
        httpConnection,
        orderClothingReplacementRegistry
      });

      setIsRequesting(true);

      toast.promise(editOrderClothingReplacementPromise, {
        loading: Translate('progress.loading'),
        success: (updatedOrderClothingReplacements) => {
          onUpdateOrder({ ...order, clothing_replacements: updatedOrderClothingReplacements });
          setOrderClothingReplacementRegistry({ ...orderClothingReplacementRegistry, id: undefined, notes: '' });
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  }, [Translate, httpConnection, onUpdateOrder, order, orderClothingReplacementRegistry]);

  const handleDeleteOrderClothingReplacement = useCallback(() => {
    if (!orderClothingReplacementToDelete) return;

    const deleteOrderClothingReplacementPromise = deleteOrderClothingReplacement({
      httpConnection,
      orderClothingReplacementRegistryId: orderClothingReplacementToDelete.id!
    });

    setIsRequesting(true);

    toast.promise(deleteOrderClothingReplacementPromise, {
      loading: Translate('progress.loading'),
      success: (updatedOrderClothingReplacements) => {
        onUpdateOrder({ ...order, clothing_replacements: updatedOrderClothingReplacements });
        setOrderClothingReplacementToDelete(null);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, httpConnection, onUpdateOrder, order, orderClothingReplacementToDelete]);

  const handleSubmit = useCallback(() => {
    if (orderClothingReplacementRegistry?.id) handleEdit();
    else handleSave();
  }, [handleEdit, handleSave, orderClothingReplacementRegistry?.id]);

  const handleCancelEditing = useCallback(() => {
    setOrderClothingReplacementRegistry(initialData);
  }, [initialData]);

  const orderClothingreplacementContextMenuOptions = useMemo<ContextMenuItemType[]>(() => [
    {
      name: Translate('actions.edit'),
      icon: IconsCatalog.pen,
      handleClick: selectedOption => {
        setOrderClothingReplacementRegistry(selectedOption as OrderClothingReplacementNewRegistryType);
      }
    },
    {
      name: Translate('actions.delete'),
      icon: IconsCatalog.trash,
      handleClick: selectedOption => {
        setOrderClothingReplacementToDelete(selectedOption as ListClothingProjectOrderReplacementType);
      }
    }
  ], [Translate]);

  return (
    <ModalBase {...props}
       visible
       hideFooter
       style="primary"
       disableEscToClose={!!orderClothingReplacementToDelete}
       disableClickOutsideToClose
       handleConfirm={() => {}}>
      <React.Fragment>

        {
          clothingReplacementOptionsComboboxOptions.length === 0 && (
            <section>
              <p className={'alert alert-warning'}>
                <i className={IconsCatalog.exclamationTriangle} />
                <span className={'ml-2'}>{Translate('status.no-clothing-replacements-available')}</span>
              </p>

              <div className={'d-flex justify-content-end'}>
                <BasicButton title={Translate('actions.do-register')} color={'primary'} handleClick={() => {
                  navigate('/orderlist/settings?tab=replacements');
                }} />
              </div>
            </section>
          )
        }

        {
          clothingReplacementOptionsComboboxOptions.length > 0 && (
            <section>
              {
                orderClothingReplacementToDelete && (
                  <ConfirmationModal
                    visible
                    message={Translate('modal.confirm-delete-replacement-request')}
                    disableButtons={isRequesting}
                    title={Translate('actions.confirm')}
                    style={'danger'}
                    handleClose={() => {
                      setOrderClothingReplacementToDelete(null);
                    }}
                    handleConfirm={handleDeleteOrderClothingReplacement} />
                )
              }

              <section>
                <div className={'row'}>
                  <div className={'col'}>
                    <ComboBox
                      disabled={isRequesting}
                      id={'order-clothing-replacement-item'}
                      value={orderClothingReplacementRegistry?.clothing_replacement_option_id?.toString() ?? ''}
                      header={Translate('labels.clothe-to-replace')}
                      data={clothingReplacementOptionsComboboxOptions}
                      handleChange={({ target }) => {
                        setOrderClothingReplacementRegistry({
                          ...orderClothingReplacementRegistry,
                          clothing_replacement_option_id: parseInt(target.value)
                        });
                      }}
                    />
                  </div>

                  <div className={'col'}>
                    <ComboBox
                      disabled={isRequesting}
                      id={'order-clothing-replacement-model'}
                      value={orderClothingReplacementRegistry?.model_id?.toString() ?? ''}
                      header={Translate('labels.which-model')}
                      data={generateComboboxOptionsFromImportedModels(importedModels)}
                      handleChange={({ target }) => {
                        setOrderClothingReplacementRegistry({
                          ...orderClothingReplacementRegistry,
                          model_id: parseInt(target.value)
                        });
                      }}
                    />
                  </div>
                </div>

                <div className={'row'}>
                  <div className={'col'}>
                    <TextAreaInput
                      disabled={isRequesting}
                      id={'order-clothing-replacement-notes'}
                      label={Translate('labels.notes-about-replacement')}
                      value={orderClothingReplacementRegistry.notes}
                      maxLength={200}
                      handleChange={({ target }) => {
                        setOrderClothingReplacementRegistry({
                          ...orderClothingReplacementRegistry,
                          notes: target.value
                        });
                      }}
                    />
                  </div>
                </div>

                <div className={'row'}>
                  <div className={'col text-right'}>
                    {
                      orderClothingReplacementRegistry?.id && (
                        <BasicButton
                          disabled={isRequesting}
                          size={'sm'}
                          title={Translate('actions.cancel')}
                          color={'danger'}
                          handleClick={handleCancelEditing}
                        />
                      )
                    }

                    <BasicButton
                      marginLeft={!!orderClothingReplacementRegistry?.id}
                      disabled={isRequesting}
                      size={'sm'}
                      title={orderClothingReplacementRegistry?.id ? Translate('actions.save') : Translate('actions.add')}
                      color={'success'}
                      handleClick={handleSubmit}
                    />
                  </div>
                </div>
              </section>

              <section>
                <div className={'row'}>
                  <div className={'col'}>
                    {order.clothing_replacements.length === 0 && (
                      <React.Fragment>
                        <hr className={''} />
                        <i className={'text-center d-block border-top-1'}>{Translate('status.no-replacements-to-this-order')}</i>
                      </React.Fragment>
                    )}

                    {order.clothing_replacements.length > 0 && (
                      <ContextMenuProvider>
                        <ContextMenuViewer
                          id='order-replacement-context-menu-viewer'
                          options={orderClothingreplacementContextMenuOptions}
                          disabled={!!orderClothingReplacementRegistry?.id}
                        />

                        <Tabs data={tabsContent} />
                        <small className={'form-text text-muted font-italic'}>{Translate('description.right-click-to-edit-delete')}</small>
                      </ContextMenuProvider>
                    )}
                  </div>
                </div>
              </section>
            </section>
          )
        }
      </React.Fragment>
    </ModalBase>
  );
}
