import { type AxiosInstance } from 'axios';
import { type ProjectBasicType } from '../../../../../types/ProjectBasicType';

type ApproveProjectBySeller = {
  projectId: number;
}

export type ProjectApprovalResponse = Pick<ProjectBasicType, 'approved_by'> & {
  approved_at: string;
};

export default async function (httpConnection: AxiosInstance, params: ApproveProjectBySeller): Promise<ProjectApprovalResponse> {
  return await new Promise<ProjectApprovalResponse>((resolve, reject) => {
    const { projectId } = params;

    httpConnection.post<ProjectApprovalResponse>(`list/clothing-projects/seller/approve-production?project_id=${projectId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
