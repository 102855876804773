import styled from 'styled-components';

export const Container = styled.div`

  & > ul {
    margin: 0px;
    padding: 20px;
  }

  & > ul > li {
    color: #000;
    font-size: 16px;
    font-weight: normal;
  }
`;
