import styled from 'styled-components';

export const HeaderLeftSection = styled.div`
  display: flex;
  align-items: center;

  > img {
    margin-right: 20px;
  }

  > section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > h4 {
      font-weight: bold;
    }
  }
`;

export const HeaderRightSection = styled.div`

  h5, span {
    display: block;
    text-align: right;
  }

  div > h5 {
    font-size: 16px;
    font-weight: bold;

    font-size: 18px;
    border: 1px dashed #dcdcdc;
    padding: 8px;
  }

  section {

    h5 {
      font-size: 16px;
      font-weight: bold;

      &:last-child {
        font-size: 18px;
        border: 1px dashed #000;
        padding: 8px;
      }
    }

  }
`;
