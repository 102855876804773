import { type AxiosInstance } from 'axios';
import { type ProjectBasicType } from '../../types/ProjectBasicType';
import { type ProjectType } from '../../types/ProjectType';
import { type UserType } from '../../types/UserType';
import { type BasicUserIdentificationType } from '../../types/BasicUserIdentificationType';

export const isProjectAuthor = (project: ProjectType | ProjectBasicType, user: UserType | null): boolean => {
  if (!user) return false;
  return user.id === project.user_id;
};

export const isProjectAuthorOrCompanyAccount = (project: ProjectType | ProjectBasicType, user: UserType | null): boolean => {
  if (!user) return false;
  return user.id === project.user_id || user.id === project.company_id;
};

export const getUsersInSameCompany = async (httpConnection: AxiosInstance) => {
  return await new Promise<BasicUserIdentificationType[]>((resolve, reject) => {
    httpConnection.get<BasicUserIdentificationType[]>('users/in-same-company')
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
};
