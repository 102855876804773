import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import TextInput from '../../Forms/TextInput';
import SplitButton from '../../Buttons/SplitButton';
import { v4 as uuidv4 } from 'uuid';
import { IconsCatalog } from '../../IconsCatalog';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import { type SublistType } from '../../../types/SublistTypes';

type SublistJsonLinkManagerType = Pick<ModalBaseType, 'visible' | 'handleClose'> & {
  sublist: SublistType;
  handleConfirm: (sublist: SublistType) => void;
};

export default function SublistJsonLinkManager({ visible, handleClose, handleConfirm, sublist }: SublistJsonLinkManagerType) {
  const { Translate } = useAppTranslation();

  const [token, setToken] = useState<string | null>(sublist.token);

  const generateToken = useCallback(() => {
    const newToken = uuidv4();
    setToken(newToken);
  }, []);

  const handleUpdateSublistToken = useCallback(() => {
    const updatedSublist = { ...sublist, token };
    handleConfirm(updatedSublist);
    handleClose();
  }, [handleClose, handleConfirm, sublist, token]);

  if (!sublist) return <></>;

  return (
    <ModalBase
      visible={visible}
      style='primary'
      title={Translate('modal.json-link')}
      message={Translate('description.sublist-json-link-description')}
      handleClose={handleClose}
      handleConfirm={handleUpdateSublistToken}
    >
      <div className='row'>
        <div className='col pr-0'>
          <TextInput
            disabled
            id='text-input-sublist-access-token'
            label={Translate('labels.access-token')}
            value={token ?? Translate('status.none')}
            helpText={Translate('description.json-token-description')}
          />
        </div>
        <div className='col-auto'>
          <SplitButton
            color='danger'
            title={Translate('actions.new')}
            icon={IconsCatalog.sync}
            handleClick={generateToken}
            simulateLabelMarginTop
          />
          <SplitButton
            simulateLabelMarginTop
            marginLeft
            color='danger'
            icon={IconsCatalog.trash}
            handleClick={() => {
              setToken(null);
            }}
          />
        </div>
      </div>
    </ModalBase>
  );
}
