import { useClothingIcons } from '../../../contexts/ClothingIconsContext';
import { type ClothingIcon } from '../../../types/ClothingIconType';
import CatalogSquareBlock from '../../CatalogSquareBlock';
import GridContainer from '../../GridContainer';
import ModalBase, { type ModalBaseType } from '../ModalBase';

type IconPickerModalType = Omit<ModalBaseType, 'children' | 'handleConfirm' | 'style'> & {
  handleConfirm: (icon: ClothingIcon) => void;
};

export default function IconPickerModal({ title, message, visible, handleClose, handleConfirm }: IconPickerModalType) {
  const { clothingIcons } = useClothingIcons();

  return (
    <ModalBase
      title={title}
      message={message}
      style='primary'
      size={'lg'}
      visible={visible}
      hideFooter
      handleClose={handleClose}
      handleConfirm={() => {}}
    >
      <GridContainer>
        <>
          {clothingIcons.map((icon, index) => (
            <CatalogSquareBlock
              key={index}
              imagePath={icon.url}
              handleClick={() => {
                handleConfirm(icon);
                handleClose();
              }}
            />
          ))}
        </>
      </GridContainer>
    </ModalBase>
  );
}
