import { NumericFormat } from 'react-number-format';
import { type TextInputProps } from '../TextInput';
import { useSettings } from '../../../contexts/SettingsContext';

type CurrencyInputProps = Pick<TextInputProps, 'id' | 'label' | 'tabIndex' | 'disabled'> & {
  value: number;
  onChange: (value: number) => void;
};

export default function CurrencyInput({
  id,
  label,
  value,
  disabled,
  onChange
}: CurrencyInputProps) {
  const { preferences } = useSettings();

  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <NumericFormat
        disabled={disabled}
        value={value}
        className='form-control'
        decimalSeparator=','
        decimalScale={2}
        prefix={`${preferences.currency} `}
        fixedDecimalScale
        onValueChange={values => {
          onChange(values.floatValue ?? 0);
        }}
      />
    </div>
  );
};
