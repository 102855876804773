import { type AxiosInstance } from 'axios';
import { type CreateCardParams } from '../../../../types/Kanban/CreateCardParams';
import { type KanbanCard } from '../../../../types/Kanban/KanbanCard';

export type CreateCardResponse = Pick<KanbanCard, 'id' | 'title' | 'column_id' | 'position_index'>;

export const createCard = async (data: CreateCardParams, httpConnection: AxiosInstance): Promise<CreateCardResponse> => {
  const { data: responseData } = await httpConnection.post<CreateCardResponse>('kanban/cards', data);
  return responseData;
};
