import { type AxiosInstance } from 'axios';
import { type ClothingReplacementOptionType } from '../../../../../../types/ClothingReplacementOption';

type EditClothingReplacementOptionParams = {
  httpConnection: AxiosInstance;
  replacementOption: ClothingReplacementOptionType;
}

export default async function ({ httpConnection, replacementOption }: EditClothingReplacementOptionParams): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    const putData = {
      ...replacementOption,
      price: replacementOption.price ?? 0
    };

    httpConnection.put(`list/clothing-projects/clothing-replacements/${replacementOption.id}`, putData)
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}
