import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const TextViewer = styled.span`
  flex: 1;
   min-height: 24px;
`;
