import { Link } from 'react-router-dom';
import useRouteHelpers from '../../../utils/hooks/useRouteHelpers';
import React from 'react';
import SidebarDivider from '../SidebarDivider';
import SidebarHeading from '../SidebarHeading';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import Badge from '../../Buttons/Badge';

export type CollapsableItemType = {
  title: string;
  route: string;
  hidden?: boolean;
  showDevelopmentTag?: boolean;
};

type CollapsableMenuProps = {
  controlId: string;
  icon: string;
  title: string;
  header: string;
  items: CollapsableItemType[];
  routeSection: string;
};

export default function CollapsableMenu({ icon, title, header, items, routeSection, controlId }: CollapsableMenuProps) {
  const { Translate } = useAppTranslation();

  const routesHelper = useRouteHelpers();
  const isExpandedMenu = routesHelper.isExpandedSideMenuOption(routeSection, '', 'collapsed');
  const hasExpandedLinks = routesHelper.isExpandedSideMenuOption(routeSection, 'show', '');

  return (
    <React.Fragment>
      <SidebarDivider />
      <SidebarHeading title={header} />

      <li className={`nav-item ${routesHelper.isActiveFirstRouteSection(routeSection)}`}>
        <a
          className={`nav-link ${isExpandedMenu}`}
          href='#'
          data-toggle='collapse'
          data-target={`#${controlId}`}
          aria-expanded='true'
          aria-controls={controlId}
        >
          <i className={`${icon} fa-fw`} />
          <span>{title}</span>
        </a>
        <div
          id={controlId}
          className={`collapse ${hasExpandedLinks}`}
          aria-labelledby={routeSection}
          data-parent='#accordionSidebar'
        >
          <div className='bg-white py-2 collapse-inner rounded'>
            <h6 className='collapse-header'>{Translate('labels.options')}</h6>
            {items.map(item => {
              if (item.hidden) return false;

              return (
                <Link
                  key={item.title}
                  className={`collapse-item ${routesHelper.isActivePage(routeSection, item.route)}`}
                  to={`${routeSection}/${item.route}`}
                >
                  {item.title} {item.showDevelopmentTag && <Badge color={'#ff0000'} content={'DEV'} />}
                </Link>
              );
            })}
          </div>
        </div>
      </li>
    </React.Fragment>
  );
}
