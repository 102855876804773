import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, type DropResult } from '@hello-pangea/dnd';

import Column from './components/Column';
import { moveCardToColumn } from './service/kanbanCardService';
import { GlobalKanban, KanbanContainer, WrapperColumns } from './style';
import { useHeaderBackButton } from '../../../contexts/HeaderBackButtonContext';
import { useLocation, useNavigate } from 'react-router';
import { type KanbanWorkspace } from '../../../types/Kanban/KanbanWorkspace';
import { type KanbanColumn } from '../../../types/Kanban/KanbanColumn';
import { AddColumnButton } from './fragments/AddColumnButton';
import { useKanbanView } from './hooks/useKanbanView';
import { CardModal } from '../../../components/CardModal';
import { useUser } from '../../../contexts/UserContext';

export default function KanbanView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isCompanyAccount } = useUser();
  const { workspaceColumns, moveCardMutation } = useKanbanView();
  const [workspace, setWorkspace] = useState<KanbanWorkspace | null>(null);

  const refKanbanContainer = useRef<HTMLDivElement>(null);

  const { showBackButton, hideBackButton } = useHeaderBackButton();

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!workspace) return;

      const { source, destination } = result;

      const moveResult = moveCardToColumn({ source, destination, kanbanColumns: workspaceColumns.data as KanbanColumn[] });

      if (!moveResult) return;

      const { updatedColumnCards, affectedCards } = moveResult;

      moveCardMutation.mutate({ affectedCards, updatedColumnCards });
    },
    [moveCardMutation, workspace, workspaceColumns.data]
  );

  useEffect(() => {
    if (!location.state?.workspace) {
      navigate('/kanban/workspaces');
      return;
    }

    const workspace: KanbanWorkspace = location.state.workspace;
    setWorkspace(workspace);
  }, [location, navigate]);

  useEffect(() => {
    showBackButton();

    return () => {
      hideBackButton();
    };
  }, [hideBackButton, showBackButton]);

  if (!workspace) return <></>;

  return (
    <React.Fragment>
      <CardModal />
      {/* <KanbanTitle>{workspace.title}</KanbanTitle> */}

      <DragDropContext onDragEnd={handleDragEnd}>
        <KanbanContainer ref={refKanbanContainer}>
          <WrapperColumns>
            {workspaceColumns?.data?.map((list, listIndex) => (
              <Column column={list} columnIndex={listIndex} key={list.id} />
            ))}
          </WrapperColumns>

          {isCompanyAccount ? (
            <AddColumnButton workspace={workspace} columns={workspaceColumns.data as KanbanColumn[]} />
          ) : null}
        </KanbanContainer>
      </DragDropContext>

      <GlobalKanban />
    </React.Fragment>
  );
}
