import { type AxiosInstance } from 'axios';

type DeleteLayoutDetailsFileParams = {
  httpConnection: AxiosInstance;
  sublistId: number;
}

export default async function ({ httpConnection, sublistId }: DeleteLayoutDetailsFileParams): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    httpConnection.delete(`list/clothing-projects/sublist/${sublistId}/layout-details`)
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}
