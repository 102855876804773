import styled from 'styled-components';

export const WrapperList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const DismemberButton = styled.button`
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  color: var(--white);
  background-color: var(--warning);
  white-space: nowrap;
  padding: 6px 12px;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
`;

export const NavigateButton = styled(DismemberButton)`
  background-color: var(--primary);
`;
