import React, { type ChangeEvent, useCallback, useState } from 'react';
import { CustomInputRange } from './styles';

type InputRangeType = {
  initialValue?: number;
  min: number;
  max: number;
  label: string;
  onChangeValue: (updatedValue: number) => void;
}

export default function InputRange({ min, max, initialValue, label, onChangeValue }: InputRangeType) {
  const [sliderValue, setSliderValue] = useState(initialValue ?? min);

  const handleSliderChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = parseInt(target.value);
    setSliderValue(updatedValue);
    onChangeValue(updatedValue);
  }, [onChangeValue]);

  return (
    <React.Fragment>
      <span>{label}</span>
      <CustomInputRange value={sliderValue} onChange={handleSliderChange} min={min} max={max} />
    </React.Fragment>
  );
};
