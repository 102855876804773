import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';

export class MoveCardToAnotherWorkspace {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ card_id, workspace_id }: { card_id: number; workspace_id: number }) {
    try {
      await this.http.put('kanban/move/workspace/card', { card_id, workspace_id });
    } catch {
      throw new Error('Error moving card to another workspace');
    }
  }
}
