import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useWorkspaces } from '../../../../../pages/Authenticated/KanbanWorkspaces/hooks/useWorkspaces';
import { useMutation, useQueryClient } from 'react-query';
import { moveCardToAnotherWorkspace } from '../services';

type HandleChangeWorkspaceParams = {
  card_id?: number;
  workspace_id?: number;
};

export const useChangeWorkspace = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workspaces } = useWorkspaces();
  const workspaceModalRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const queryClient = useQueryClient();
  const workspaceId = location.state?.workspace.id;

  const [openWorkspaces, setOpenWorkspaces] = useState(false);
  const [selected, setSelected] = useState<number | undefined>();

  const handleChangeWorkspace = useMutation(
    async ({ card_id, workspace_id }: HandleChangeWorkspaceParams) => {
      if (!card_id || !workspace_id) {
        return;
      }

      await moveCardToAnotherWorkspace.execute({ card_id, workspace_id });
    },
    {
      onSuccess: () => {
        setOpenWorkspaces(false);
        const workspace = workspaces.data?.find(workspace => workspace.id === selected);
        queryClient.invalidateQueries('workspaces');
        navigate('/kanban/workspaces/view', {
          state: { workspace }
        });
      }
    }
  );

  const filterWorkspaces = useMemo(() => {
    return workspaces?.data?.filter(workspace => workspace.id !== workspaceId) ?? [];
  }, [workspaceId, workspaces.data]);

  const handleChangeSelectedWorkspace = useCallback((workspaceId: number | undefined) => {
    setSelected(prevState => {
      if (prevState === workspaceId) {
        return;
      }

      return workspaceId;
    });
  }, []);

  const handleSetOpenWorkspaces = useCallback(() => {
    setOpenWorkspaces(prevState => !prevState);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!workspaceModalRef?.current?.contains(event.target as Node) && event.target !== buttonRef?.current) {
        setOpenWorkspaces(false);
      }
    }
    window.addEventListener('click', handleClickOutside);
  }, [openWorkspaces]);

  return {
    workspaceModalRef,
    buttonRef,
    openWorkspaces,
    selected,
    filterWorkspaces,
    handleChangeSelectedWorkspace,
    handleSetOpenWorkspaces,
    handleChangeWorkspace
  };
};
