import { type ProjectPaymentRegistryType } from '../../../../../../../types/ProjectPaymentRegistry';

export const updateCachedPaymentRegistries = (
  cachedPayments: ProjectPaymentRegistryType[],
  updatedPayment: ProjectPaymentRegistryType): ProjectPaymentRegistryType[] => {
  return cachedPayments.map(payment => {
    if (payment.id === updatedPayment.id) return updatedPayment;
    return payment;
  });
};
