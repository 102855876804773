import styled from 'styled-components';

export const Container = styled.div`
  max-height: 420px;
  overflow-y: auto;
`;

export const RowModels = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem 0;
  border-bottom: 1px solid #ccc;
`;

export const WrapperIconAndName = styled.div`
  display: flex;
  align-items: center;
  img {
    padding: 0.3rem;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.35rem;
  }
`;

export const WrapperInput = styled.div`
  padding: 0;
  input {
    border: none;
    border-radius: 0.35rem;
    outline: none;
    padding: 0.375rem 0.75rem;
  }
`;
