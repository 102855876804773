import { type ImageDimensions } from '../../../types/ImageDimensions';
import { type ImageScale } from '../../../types/ImageScale';
import { type ImageCropCoords } from '../../../types/ImageCropCoords';
import { type TFunction } from 'i18next';
import { type ResizeParams } from '..';
import { type ImageResizeDimensions } from '../../../types/ImageResizeDimensions';

type ImageCropParams = {
  x: number;
  y: number;
  width: number;
  height: number;
  imageData: string;
}

export const cropImage = async ({ x, y, width, height, imageData }: ImageCropParams): Promise<string> => {
  return await new Promise((resolve) => {
    const croppedImage = new Image();
    croppedImage.src = imageData;

    croppedImage.onload = () => {
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = width;
      croppedCanvas.height = height;

      const croppedCtx = croppedCanvas.getContext('2d');

      croppedCtx!.drawImage(croppedImage, x, y, width, height, 0, 0, width, height);

      const cropped = croppedCanvas.toDataURL('image/png');

      resolve(cropped);
    };
  });
};

export const resizeImage = async (image: string, width?: number, height?: number): Promise<string> => {
  return await new Promise((resolve) => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const aspectRatio = img.width / img.height;

      // Calculate dimensions if only one of width or height is provided
      if (width && !height) {
        height = width / aspectRatio;
      } else if (!width && height) {
        width = height * aspectRatio;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const outputWidth = width ?? img.width;
      const outputHeight = height ?? img.height;

      canvas.width = outputWidth;
      canvas.height = outputHeight;

      ctx!.drawImage(img, 0, 0, outputWidth, outputHeight);

      const resized = canvas.toDataURL('image/png');
      resolve(resized);
    };
  });
};

export const convertBase64ImageToBlob = (base64Image: string, Translate: TFunction): Blob => {
  // Remove the data URI prefix, e.g., "data:image/jpeg;base64,"
  const base64Data = base64Image.replace(/^data:[^;]+;base64,/, '');

  // Decode the base64 string to a Uint8Array
  const binaryData = atob(base64Data);
  const uint8Array = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  try {
    return new Blob([uint8Array], { type: 'image/png' });
  } catch (error) {
    console.error('Error creating Blob:', error);
    throw new Error(Translate('error.failed-convert-image-to-blob'));
  }
};

export const getImageDimensions = async (imageData: string): Promise<ImageDimensions> => {
  return await new Promise<ImageDimensions>((resolve, reject) => {
    const image = new Image();

    image.src = imageData;

    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
  });
};

export const getImageScale = (originalDimensions: ImageDimensions, visualDimensions: ImageDimensions): ImageScale => {
  const scaleX = originalDimensions.width / visualDimensions.width;
  const scaleY = originalDimensions.height / visualDimensions.height;

  return { scaleX, scaleY };
};

export const adjustCropCoordsToScale = (cropCoords: ImageCropCoords, imageScale: ImageScale): ImageCropCoords => {
  const { scaleX, scaleY } = imageScale;

  return {
    x: cropCoords.x * scaleX,
    y: cropCoords.y * scaleY,
    width: cropCoords.width * scaleX,
    height: cropCoords.height * scaleY
  };
};

export const downloadBase64Image = (imageData: string, filename: string): void => {
  const anchor = document.createElement('a');

  anchor.href = imageData;
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
};

type calculateImageHeightInAspectRatioType = {
  width: number;
  aspectRatioWidth: number;
  aspectRatioHeight: number;
}

export const calculateImageHeightInAspectRatio = ({
  width,
  aspectRatioWidth,
  aspectRatioHeight
}: calculateImageHeightInAspectRatioType): number => {
  const height = (width / aspectRatioWidth) * aspectRatioHeight;
  return height;
};

type CalculateFinalImageDimensionsParams = {
  resizeSettings: ResizeParams | undefined;
  cropWidth: number;
  cropHeight: number;
}

export const calculateFinalImageDimensions = ({ resizeSettings, cropWidth, cropHeight }: CalculateFinalImageDimensionsParams): ImageResizeDimensions => {
  const finalWidth = !!resizeSettings?.width && resizeSettings.width < cropWidth
    ? resizeSettings.width // USE SETTINGS WIDTH
    : resizeSettings?.width ? cropWidth : undefined; // USE CROP WIDTH

  const finalHeight = !!resizeSettings?.height && resizeSettings.height < cropHeight
    ? resizeSettings.height // USE SETTINGS HEIGHT
    : resizeSettings?.height ? cropHeight : undefined; // USE CROP HEIGHT

  return { width: finalWidth, height: finalHeight };
};
