import { type ClothingReplacementOptionType } from '../../../../../../types/ClothingReplacementOption';

type UpdateCachedReplacementOptionParams = {
  updatedReplacementOption: ClothingReplacementOptionType;
  clothingReplacementOptions: ClothingReplacementOptionType[];
}

export default function({ updatedReplacementOption, clothingReplacementOptions }: UpdateCachedReplacementOptionParams): ClothingReplacementOptionType[] {
  return clothingReplacementOptions.map(option => {
    if (option.id === updatedReplacementOption.id) return updatedReplacementOption;
    return option;
  });
}
