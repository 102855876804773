import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createComment, getCardComment, deleteComment } from '../api';
import { useKanbanWorkspaces } from '../../../../../contexts/KanbanWorkspacesContext';

type createCommentParams = {
  comment: string;
  priority: number;
};

export function useComments() {
  const { kanbanCard } = useKanbanWorkspaces();
  const queryClient = useQueryClient();

  const keyKanbanComments = `comments-cards-${kanbanCard?.id}`;
  const comments = useQuery(keyKanbanComments, async () => await getCardComment.execute(kanbanCard!.id));

  const createCommentMutation = useMutation(
    async ({ comment, priority }: createCommentParams) => {
      await createComment.execute({
        card_id: kanbanCard!.id,
        comment,
        priority
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keyKanbanComments);
      }
    }
  );

  const deleteCommentMutation = useMutation(
    async (commentId: number) => {
      await deleteComment.execute(commentId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keyKanbanComments);
      }
    }
  );

  return { comments, createCommentMutation, deleteCommentMutation };
}
