import { useCallback, useState } from 'react';

import * as Style from './style';

export function Card() {
  const [show, setShow] = useState(false);

  const handleShow = useCallback(() => {
    setShow(prev => !prev);
  }, []);

  return (
    <Style.Container show={show}>
      <Style.ShowHandleButton onClick={handleShow}>
        {show ? <i className='fas fa-chevron-up' /> : <i className='fas fa-chevron-down' />}
      </Style.ShowHandleButton>
    </Style.Container>
  );
}
