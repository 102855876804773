import { Item } from './fragments/Item';
import { ErrorContent, HistoryContainer, SuccessContent, WrapperContent, WrapperUpdateContent } from './style';
import { type CardHistoryResponse } from './services/GetCardHistory';

type HistoryProps = {
  isExpanded: boolean;
  cardLoading: boolean;
  cardHistoryData: CardHistoryResponse[];
};

export function History({ cardHistoryData, cardLoading }: HistoryProps) {
  return (
    <HistoryContainer>
      {cardLoading ? (
        <p>Carregando histórico...</p>
      ) : (
        <WrapperContent>
          {cardHistoryData?.map(card => (
            <WrapperUpdateContent key={card.id}>
              <Item title={card.title} author={card.user.name} created_at={card.created_at} />

              {card?.new_value ? (
                <SuccessContent dangerouslySetInnerHTML={{ __html: card?.new_value.replaceAll('\n', '<br />') }} />
              ) : null}

              {card?.old_value ? (
                <ErrorContent dangerouslySetInnerHTML={{ __html: card?.old_value.replaceAll('\n', '<br />') }} />
              ) : null}
            </WrapperUpdateContent>
          ))}
        </WrapperContent>
      )}
    </HistoryContainer>
  );
}
