import { type AxiosInstance } from 'axios';
import { type ClothingReplacementOptionType } from '../../../../../../types/ClothingReplacementOption';

type AddClothingReplacementOption = {
  httpConnection: AxiosInstance;
  replacementOption: ClothingReplacementOptionType;
}

export default async function({ httpConnection, replacementOption }: AddClothingReplacementOption): Promise<ClothingReplacementOptionType> {
  const postData = {
    ...replacementOption,
    price: replacementOption.price ?? 0
  };

  return await new Promise<ClothingReplacementOptionType>((resolve, reject) => {
    httpConnection.post<ClothingReplacementOptionType>('list/clothing-projects/clothing-replacements', postData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
