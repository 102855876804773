import { useCallback, useState } from 'react';
import SplitButton from '../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../components/IconsCatalog';
import TextInputModal from '../../../../../components/Modals/TextInputModal';
import { useKanbanView } from '../../hooks/useKanbanView';
import { type KanbanWorkspace } from '../../../../../types/Kanban/KanbanWorkspace';
import { type KanbanColumn } from '../../../../../types/Kanban/KanbanColumn';

type AddColumnButtonType = {
  workspace: KanbanWorkspace;
  columns: KanbanColumn[];
};

export const AddColumnButton = ({ workspace, columns }: AddColumnButtonType) => {
  const [listTitle, setListTitle] = useState<string | null>(null);
  const { createColumnMutation } = useKanbanView();

  const handleCreateColumn = useCallback(
    (title: string): void => {
      if (!workspace && !columns) return;
      createColumnMutation.mutate({ title, workspace, columns });
    },
    [columns, createColumnMutation, workspace]
  );

  return (
    <div className='row'>
      <TextInputModal
        visible={listTitle !== null}
        title='Criar Coluna'
        message='Com as colunas você pode adicionar cartões de tarefas e gerenciar as etapas dos seus projetos.'
        label='Digite o nome da coluna:'
        style='primary'
        currentValue={listTitle ?? ''}
        handleClose={() => {
          setListTitle(null);
        }}
        handleConfirm={handleCreateColumn}
      />

      <div className='col'>
        <SplitButton
          icon={IconsCatalog.plus}
          color='primary'
          handleClick={() => {
            setListTitle('');
          }}
        />
      </div>
    </div>
  );
};
