import { type AxiosInstance } from 'axios';
import { type EmployeesWithPermissionType } from '../../../../../../types/ EmployeesWithPermissionType';

type LoadCompanyEmployeesParams = {
  httpConnection: AxiosInstance;
}

export default async function async ({ httpConnection }: LoadCompanyEmployeesParams): Promise<EmployeesWithPermissionType> {
  return await new Promise((resolve, reject) => {
    httpConnection.get<EmployeesWithPermissionType>('company/employees-with-permissions')
      .then((response) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
