import _ from 'lodash';
import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

export type SatisfactionSurveySaveServiceProps = {
  user_id: number;
  project_id: number;
  note: number;
  is_recommend: string;
  comment: string;
  accessToken: string;
  files?: File[];
};

export class SatisfactionSurveySaveService {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({
    user_id,
    project_id,
    note,
    is_recommend,
    comment,
    accessToken,
    files
  }: SatisfactionSurveySaveServiceProps) {
    const formData = new FormData();

    if (!_.isEmpty(files)) {
      files?.forEach(file => {
        formData.append('files[]', file);
      });
    } else {
      formData.append('files[]', '');
    }

    formData.append('user_id', user_id.toString());
    formData.append('project_id', project_id.toString());
    formData.append('note', note.toString());
    formData.append('is_recommend', is_recommend);
    formData.append('comment', comment);
    formData.append('active', false.toString());

    return await this.http.post(`/list/clothing-projects/satisfaction-survey?accessToken=${accessToken}`, formData);
  }
}
