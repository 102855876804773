/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef } from 'react';
import { type ModalBaseType } from '../ModalBase';
import { Overlay } from '../styles';
import BasicButton from '../../Buttons/BasicButton';

type ConfirmationTwoOptionsModalType = Pick<
  ModalBaseType,
  'handleClose' | 'handleConfirm' | 'visible' | 'title' | 'message'
> & {
  handleDeny: () => void;
  buttonConfirmLabel: string;
  buttonDenyLabel: string;
};

export default function ConfirmationTwoOptionsModal({
  handleClose,
  handleConfirm,
  handleDeny,
  title,
  message,
  visible,
  buttonConfirmLabel,
  buttonDenyLabel
}: ConfirmationTwoOptionsModalType) {
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (visible) confirmButtonRef.current?.focus();
  }, [visible]);

  if (!visible) return <></>;

  return (
    <Overlay id='modal-overlay-two-options' className='fade show'>
      <div className='modal' style={{ display: 'block' }}>
        <div className='modal-dialog modal-dialog-scrollable'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{title}</h5>
              <button type='button' className='close' onClick={handleClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <p>{message}</p>
            </div>
            <div className='modal-footer'>
              <BasicButton title={buttonDenyLabel} color='danger' handleClick={handleDeny} />
              <BasicButton
                title={buttonConfirmLabel}
                color='success'
                handleClick={handleConfirm}
                buttonRef={confirmButtonRef}
              />
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
}
