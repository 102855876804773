import React, { useCallback } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import MainFormEditor, { type MainFormEditorType } from '../../modules/MainFormEditor';
import { generateInitialOrder } from '../../helper/orderHelper';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { type Order } from '../../../../../../types/OrderTypes';

type MainFormEditorModalType = MainFormEditorType & Pick<ModalBaseType, 'visible'> & {
  onEditOrder: (order: Order) => void;
};

export default function MainFormEditorModal({
  order,
  setOrder,
  sublists,
  importedModels,
  targetSublistIndex,
  setTargetSublistIndex,
  renderAsStepper,
  visible,
  onEditOrder,
  isCompanyEditor,
  isInsideModal
}: MainFormEditorModalType) {
  const { Translate } = useAppTranslation();

  const handleCancelEditing = useCallback(() => {
    setOrder(generateInitialOrder(importedModels));
  }, [importedModels, setOrder]);

  return (
    <ModalBase
      hideFooter
      visible={visible}
      style='primary'
      size={'lg'}
      title={Translate('actions.edit')}
      handleConfirm={() => { }}
      handleClose={handleCancelEditing}
      disableEscToClose
      disableClickOutsideToClose
    >
      <React.Fragment>
        <MainFormEditor
          order={order}
          setOrder={setOrder}
          sublists={sublists}
          onEditOrder={onEditOrder}
          importedModels={importedModels}
          targetSublistIndex={targetSublistIndex}
          setTargetSublistIndex={setTargetSublistIndex}
          renderAsStepper={renderAsStepper}
          isCompanyEditor={isCompanyEditor}
          isInsideModal={isInsideModal}
        />
      </React.Fragment>
    </ModalBase>

  );
};
