import { type AxiosResponse } from 'axios';

export const isDev = (): boolean => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const sleep = async (milliseconds: number): Promise<void> => {
  await new Promise((resolve) => setInterval(resolve, milliseconds));
};

export const generateArrayOfNumbersInRange = (start: number, end: number): number[] => {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
};

export const sumArrayOfNumbers = (numbers: number[]): number => {
  return numbers.reduce((acc, current) => acc + current, 0);
};

type DownloadBlobFileParams = {
  data: any;
  contentType: string;
  filename: string;
}

export const downloadBlobFile = ({ data, contentType, filename }: DownloadBlobFileParams): void => {
  // Create a Blob from the response data
  const blob = new Blob([data], { type: contentType });

  // Create a link element to trigger the download
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);

  // Specify the file name
  link.download = filename;

  // Append the link to the document and trigger the click event
  document.body.appendChild(link);

  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

export const convertFileToBlob = (file: File): Blob => {
  const blob = new Blob([file], { type: file.type });
  return blob;
};

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return true;
      // You can provide user feedback or perform additional actions on success
    } else {
      // For browsers that do not support the Clipboard API
      // You may use the document.execCommand method (deprecated in modern browsers)
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Set the position to be off-screen
      textArea.style.position = 'fixed';
      textArea.style.left = '-9999px';

      document.body.appendChild(textArea);

      textArea.select();

      try {
        // Execute the copy command
        document.execCommand('copy');
        return true;
      } catch (error) {
        return false;
        // Handle errors, provide user feedback, or offer alternative copying methods
      } finally {
        // Clean up by removing the temporary textarea
        document.body.removeChild(textArea);
      }
    }
  } catch (error) {
    return false;
  }
};

export const generateFullUrl = (path: string): string => {
  const { protocol, hostname, port } = window.location;
  const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return `${baseUrl}${path}`;
};

export const displayFriendlyWeight = (weight: number): string => {
  if (weight < 1000) return `${weight}g`;

  const weightInKg = weight / 1000;
  return `${weightInKg}Kg`;
};

export const isiPhone = (): boolean => {
  return navigator.userAgent.includes('iPhone');
};

export const displayAsCurrency = (amount: number, currencySymbol: string): string => {
  const parsedAmount: number = amount ?? 0;

  const formattedAmount = parsedAmount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return `${currencySymbol} ${formattedAmount}`;
};

export const getServerErrorMessageFromResponse = (err: any) => {
  const axiosError = err as AxiosResponse;
  const genericError = err as Error;

  return axiosError?.data?.message ?? genericError.message;
};
