import { type FinishingOption } from '../../../../../../../../types/ClothingFinishingOptions/FinishingOption';
import { type FinishingOptionItem } from '../../../../../../../../types/ClothingFinishingOptions/FinishingOptionItem';

export const generateEmptyOptionItemEditData = () => ({ item: '', price: 0 });

export const addFinishingOptionItemToFinishingOptionGroup = (finishingOption: FinishingOption, addedFinishingOptionItem: FinishingOptionItem) => {
  return ({
    ...finishingOption,
    option_items: [...finishingOption.option_items, addedFinishingOptionItem]
  });
};

export const editFinishingOptionItemInFinishingOptionGroup = (finishingOption: FinishingOption, editedFinishingOptionItem: FinishingOptionItem) => {
  const updatedFinishingOptionItems = finishingOption.option_items.map(optionItem => {
    if (optionItem.id === editedFinishingOptionItem.id) return editedFinishingOptionItem;
    return optionItem;
  });

  const updatedFinishingOption: FinishingOption = {
    ...finishingOption,
    option_items: updatedFinishingOptionItems
  };

  return updatedFinishingOption;
};

export const deleteFinishingOptionItemFromFinishingOptionGroup = (finishingOption: FinishingOption, deletedFinishingOptionItem: FinishingOptionItem) => {
  const filteredFinishingOptionItems = finishingOption.option_items.filter(optionItem => optionItem.id !== deletedFinishingOptionItem.id);

  const updatedFinishingOption: FinishingOption = {
    ...finishingOption,
    option_items: filteredFinishingOptionItems
  };

  return updatedFinishingOption;
};
