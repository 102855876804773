import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import IntlCurrencyInput from 'react-intl-currency-input';
import { useFormContext } from 'react-hook-form';

import { Container, RowModels, WrapperIconAndName, WrapperInput } from './style';
import { useClothingModels } from '../../../../../../../../contexts/ClothingModelsContext';

export function Fraccao() {
  const { models } = useClothingModels();
  const methods = useFormContext();

  const formModels = useMemo(() => methods.watch('factions') || [], [methods]);

  const handlerPriceChange = useCallback(
    (modelId: number, value: number) => {
      methods.setValue(
        'factions',
        formModels.map((model: any) => {
          if ((model?.list_clothing_model_id ?? model?.id) === modelId) {
            return { ...model, price: value };
          }

          return model;
        })
      );
    },
    [formModels, methods]
  );

  return (
    <div className='mb-3'>
      <h4 className='h6 text-center w-100'>
        Escolha e adicione as peças que a costureira irá produzir. <br />
        <small className='text-muted'>Caso a costureira não produza a peça, apenas remova o preço.</small>
      </h4>

      <Container>
        {models?.map(model => {
          const findModel = formModels?.find(
            (formModel: any) => (formModel?.list_clothing_model_id ?? formModel?.id) === model.id
          );

          return (
            <RowModels key={model.id}>
              <WrapperIconAndName>
                <img src={model.icon.url} alt='' srcSet='' />
                <span>{model.name}</span>
              </WrapperIconAndName>

              <div>
                {_.isEmpty(findModel) ? (
                  <button
                    type='button'
                    className='btn btn-success'
                    onClick={() => {
                      methods.setValue('factions', [...formModels, { id: model.id, price: 0 }]);
                    }}
                  >
                    Adicionar a costureira
                  </button>
                ) : (
                  <div>
                    <label htmlFor='price'>Preço</label>
                    <div className='d-flex'>
                      <WrapperInput className={`form-control ${_.isEqual(findModel?.price ?? 0, 0) && 'is-invalid'}`}>
                        <IntlCurrencyInput
                          currency='BRL'
                          config={{
                            locale: 'pt-BR',
                            formats: {
                              number: {
                                BRL: {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                }
                              }
                            }
                          }}
                          defaultValue={Number(findModel?.price ?? 0)}
                          max={0}
                          value={findModel?.price ?? 0}
                          onChange={(_, value) => {
                            handlerPriceChange(model.id, value);
                          }}
                        />
                      </WrapperInput>

                      <button
                        type='button'
                        className='btn btn-danger ml-2'
                        onClick={() => {
                          methods.setValue(
                            'factions',
                            formModels.filter(
                              (formModel: any) => (formModel?.list_clothing_model_id ?? formModel?.id) !== model.id
                            )
                          );
                        }}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </RowModels>
          );
        })}
      </Container>
    </div>
  );
}
