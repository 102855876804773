import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 10px;
  margin-bottom: 2rem;
`;

export const WrapperColumn = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 10px;
  padding-bottom: 20px;
`;

export const Column = styled.div`
  background-color: var(--background);
  border-radius: 10px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  gap: 10px;
  min-height: 448px;
`;

export const BadgeColumnPosition = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: var(--danger);
  color: var(--white);
  border-radius: 15px;
  font-size: 12px;
  width: 30px;
  text-align: center;
  height: 30px;
`;

export const WrapperButton = styled.div`
  button {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    border: none;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 170px;
    font-size: 14px;
  }
`;

export const LabelProcessInput = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: var(--text);
  margin-bottom: 5px;
  display: block;
`;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  margin-top: 10px;
`;

export const CardSkeleton = styled.div`
  width: 100%;
  flex: 1;
  background-color: var(--light-v2);
  border-radius: 10px;
`;

export const CardSkeletonAdd = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--text);
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
  border: 2px dashed var(--light);
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  top: 20px;
`;
