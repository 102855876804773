import styled from 'styled-components';

type ButtonDeleteType = {
  deleteButtonDisabled: boolean;
}

export const ButtonDelete = styled.button.withConfig({ shouldForwardProp: prop => prop !== 'deleteButtonDisabled' }) <ButtonDeleteType>`
  display: ${props => props.deleteButtonDisabled ? 'none !important' : 'none'};
  background-color: var(--danger);
  color: #fff;
  border: none;
  font-size: 10px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -7px;
  transition: transform .1s ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`;

export const Container = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 1px #ddd;
  transition: box-shadow .25s ease-in-out, transform .1s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 12px 4px #ddd;
    transform: scale(1.05);

    ${ButtonDelete} {
      display: flex;
    }
  }
`;

export const ButtonWrapper = styled.button`
  border: none;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 0px 2px 2px #ddd;
  transition: all .25s ease-in-out;
  background-color: #fff;

  &:hover {
    box-shadow: 0px 0px 12px 4px #ddd;
    transform: scale(1.05);
  }
`;
