import { useEffect, useMemo } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { type KanbanCard } from '../../../../../types/Kanban/KanbanCard';
import {
  Bar,
  CardBody,
  CardFooter,
  CardHeader,
  CardImage,
  CardNumber,
  CardOptions,
  Container,
  Lack,
  PercentageLabel,
  ProgressBar,
  QtdPiece,
  Title,
  WithoutAttachment,
  WrapperCardImage,
  WrapperNumber
} from './style';
import Carousel from 'react-multi-carousel';
import { useKanbanWorkspaces } from '../../../../../contexts/KanbanWorkspacesContext';
import _ from 'lodash';
import { generatePath } from '../../../../../helpers/general';
import { differenceInCalendarDays, differenceInHours } from 'date-fns';
import { BACKGROUND_COLORS } from '../../../../../helpers/colors';
import { useLocation } from 'react-router';

type CardType = {
  cardData: KanbanCard;
  cardIndex: number;
};

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

export default function Card({ cardData, cardIndex }: CardType) {
  const location = useLocation();
  const { handleShowUpdateModal } = useKanbanWorkspaces();

  const widthProgress = useMemo(() => {
    const cards = cardData?.check_groups[0]?.check_items;
    const total = cards?.length ?? 0;
    const progress = cards?.filter(card => card.checked)?.length ?? 0;
    return _.defaultTo(Math.floor((progress / total) * 100), 0);
  }, [cardData?.check_groups]);

  const dateStartDiff = useMemo(() => {
    const diffCount = differenceInCalendarDays(new Date(cardData.end_date ?? ''), new Date());
    if (diffCount < 0) {
      return 0;
    }

    const hoursDiff = differenceInHours(new Date(cardData.end_date ?? ''), new Date());

    if (hoursDiff <= 24) {
      return 1;
    }

    return _.defaultTo(diffCount, 0);
  }, [cardData]);

  const quantityPieceCard = useMemo(() => {
    if (cardData?.clothing_project) {
      return cardData?.clothing_project?.sublists.reduce((acc, sublist) => {
        if (sublist.card) {
          return 0;
        }

        return sublist.orders.reduce((acc, order) => {
          return order.clothes.reduce((acc, cloth) => {
            return acc + cloth.quantity;
          }, acc);
        }, acc);
      }, 0);
    }

    if (cardData?.sublist?.orders) {
      return cardData?.sublist?.orders.reduce((acc, order) => {
        return order.clothes.reduce((acc, cloth) => {
          return acc + cloth.quantity;
        }, acc);
      }, 0);
    }

    return cardData.quantity_piece;
  }, [cardData?.clothing_project, cardData.quantity_piece, cardData.sublist]);

  useEffect(() => {
    if (!location.state.card) return;

    handleShowUpdateModal(location.state.card);
  }, [handleShowUpdateModal, location, location.state.card]);

  return (
    <Draggable draggableId={cardData.id.toString()} index={cardIndex} key={cardData.id}>
      {(provided, snapshot) => {
        const handleClick = () => {
          Object.assign(cardData, { quantity_piece: quantityPieceCard });
          handleShowUpdateModal(cardData);
        };

        return (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isGlowing={snapshot.isDragging}
            alert={dateStartDiff <= 2}
            backgroundColor={BACKGROUND_COLORS[cardData.priority]}
          >
            <WrapperNumber onClick={handleClick}>
              <CardNumber>#{cardData.id}</CardNumber>

              <CardOptions>
                <Lack>Faltam: {dateStartDiff} Dias</Lack>
              </CardOptions>
            </WrapperNumber>

            <CardHeader onClick={handleClick}>
              <Title>{cardData.title}</Title>
            </CardHeader>

            <CardBody>
              {!_.isEmpty(cardData?.images) ? (
                <Carousel responsive={responsive}>
                  {cardData?.images?.map(image => (
                    <WrapperCardImage key={image.id}>
                      <CardImage src={generatePath({ filename: image.file })} />
                    </WrapperCardImage>
                  ))}
                </Carousel>
              ) : (
                <WithoutAttachment onClick={handleClick}>
                  <span>Sem anexo</span>
                </WithoutAttachment>
              )}
            </CardBody>

            <CardFooter onClick={handleClick}>
              <ProgressBar>
                {!_.isEqual(widthProgress, 0) ? (
                  <>
                    <PercentageLabel>{widthProgress}%</PercentageLabel>
                    <Bar style={{ width: widthProgress + '%' }} />
                  </>
                ) : null}
              </ProgressBar>

              <QtdPiece>{quantityPieceCard === 0 ? 'nenhuma peça' : `${quantityPieceCard} peças`}</QtdPiece>
            </CardFooter>
          </Container>
        );
      }}
    </Draggable>
  );
}
