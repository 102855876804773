import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { ImageFile, WrapperFile } from '../../style';
import InputMask from 'react-input-mask';
import { type Sections } from '../../../SectionsOptions';
import { generatePathRegisterFile } from '../../../../../../../../helpers/general';

type CreateOrUpdateFormProps = {
  section: Sections | 'raw_material' | 'faccao';
};

export function CreateOrUpdateForm({ section }: CreateOrUpdateFormProps) {
  const methods = useFormContext();

  const photo = methods.watch('photo') as File[];
  const id = methods.watch('id');

  return (
    <>
      <div className='row'>
        <WrapperFile>
          {!_.isEmpty(photo) ? (
            <ImageFile
              src={typeof photo === 'string' ? generatePathRegisterFile({ filename: photo }) : URL.createObjectURL(photo[0])}
              alt='Imagem do cliente'
            />
          ) : (
            <span>Adicionar foto</span>
          )}

          <input type='file' {...methods.register('photo')} />
        </WrapperFile>
      </div>

      <div className='row'>
        <div className='form-group col-6'>
          <label htmlFor='name'>Nome</label>
          <input type='text' className='form-control' placeholder='Nome do cliente' {...methods.register('name')} />
        </div>

        <div className='form-group col-6'>
          <label htmlFor='email'>Email</label>
          <input type='email' className='form-control' {...methods.register('email')} placeholder='email@email.com.br' />
        </div>
      </div>

      <div className='row'>
        <div className='form-group col-6'>
          <label htmlFor='phone'>Whatsapp</label>
          <InputMask
            mask={'55 (99) 9 9999-9999'}
            type='text'
            className='form-control'
            {...methods.register('phone')}
            placeholder='55 (##) # ####-####'
          />
        </div>

        {section === 'supplier' ? (
          <div className='form-group col-6'>
            <label htmlFor='register_number'>CNPJ</label>
            <InputMask
              mask={'99.999.999/9999-99'}
              type='text'
              className='form-control'
              {...methods.register('register_number')}
              disabled={!_.isEmpty(methods.watch('id'))}
              placeholder='##.###.###/####-##'
            />
          </div>
        ) : (
          <div className='form-group col-6'>
            <label htmlFor='register_number'>CPF</label>
            <InputMask
              mask={'999.999.999-99'}
              type='text'
              className='form-control'
              disabled={!_.isNil(id)}
              {...methods.register('register_number')}
              placeholder='###.###.###-##'
            />
          </div>
        )}
      </div>

      <div className='form-group'>
        <label htmlFor='address'>Endereço</label>
        <input type='text' className='form-control' {...methods.register('address')} placeholder='Digite o endereço' />
      </div>

      <div className='row'>
        <div className='form-group col-6'>
          <label htmlFor='city'>Cidade</label>
          <input type='text' className='form-control' {...methods.register('city')} placeholder='Digite a cidade' />
        </div>

        <div className='form-group col-3'>
          <label htmlFor='state'>Estado</label>
          <input type='text' className='form-control' {...methods.register('state')} placeholder='##' />
        </div>

        <div className='form-group col-3'>
          <label htmlFor='zip_code'>CEP</label>
          <InputMask
            mask={'99.999-999'}
            type='text'
            className='form-control'
            {...methods.register('zip_code')}
            placeholder='00.000-000'
          />
        </div>
      </div>
    </>
  );
}
