import BasicCard from '../../../../../../../../components/Cards/BasicCard';
import TextInput from '../../../../../../../../components/Forms/TextInput';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import ComboBox from '../../../../../../../../components/Forms/ComboBox';
import { generateBloodTypeOptions, generateComboBoxOptionsForClothingInput, generateSublistOptions } from '../../../../services/orderFormService';
import { type Order } from '../../../../../../../../types/OrderTypes';
import { type ClothingModelType } from '../../../../../../../../types/ClothingModelType';
import ClothingInputData from '../../../../../../../../components/Forms/ClothingInputData';
import SplitButton from '../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';
import { type DefaultListEditorType } from '../../../../../../../../types/FinalClientEditor/DefaultListEditorType';

export default function DefaultListEditor({
  isCompanyEditor,
  order,
  setOrder,
  targetSublistIndex,
  setTargetSublistIndex,
  handleChangeGender,
  handleFindSublistIndex,
  sublists,
  importedModels,
  dynamicLargeClass,
  handleChangeClothingData,
  handleClearClothingData,
  handleAddOrder,
  handleEditOrder,
  handleClearForm,
  handleCancelEdit,
  disabled
}: DefaultListEditorType) {
  const { Translate } = useAppTranslation();

  return (
    <BasicCard>
      <div className='row'>
        <div className='col-md-12 col-lg-6'>
          {/* FORM ROWS LEFT SIDE */}
          <div className='row'>
            <div className={`${!isCompanyEditor ? 'col-12 col-sm-6' : 'col-12 col-md-12 col-lg-6'}`}>
              <TextInput
                disabled={disabled}
                label={Translate('labels.name')}
                value={order.name ?? ''}
                id='txtName'
                testId='order-input-name'
                tabIndex={1}
                autofocus
                onChange={({ target }) => {
                  setOrder({ ...order, name: target.value });
                }}
              />
            </div>

            <div className={`${!isCompanyEditor ? 'col-12 col-sm-6' : 'col-12 col-md-12 col-lg-6'}`}>
              <TextInput
                disabled={disabled}
                label={Translate('labels.number')}
                value={order.number ?? ''}
                id='txtNumber'
                testId='order-input-number'
                tabIndex={2}
                onChange={({ target }) => {
                  setOrder({ ...order, number: target.value });
                }}
              />
            </div>
          </div>

          <div className='row'>
            <div className={`${!isCompanyEditor ? 'col-12 col-sm-6' : 'col-12 col-md-12 col-lg-6'}`}>
              <TextInput
                disabled={disabled}
                label={Translate('labels.nickname')}
                value={order.nickname ?? ''}
                id='txtNickname'
                testId='order-input-nickname'
                tabIndex={3}
                onChange={({ target }) => {
                  setOrder({ ...order, nickname: target.value });
                }}
              />
            </div>

            <div className={`${!isCompanyEditor ? 'col-12 col-sm-6' : 'col-12 col-md-12 col-lg-6'}`}>
              <ComboBox
                disabled={disabled}
                id='blood-type'
                testId='order-input-blood-type'
                value={order.bloodType ?? ''}
                header={Translate('labels.blood-type')}
                tabIndex={4}
                handleChange={({ target }) => {
                  setOrder({ ...order, bloodType: target.value });
                }}
                data={generateBloodTypeOptions(Translate('status.no-info'))}
              />
            </div>
          </div>

          <div className='row'>
            <div className={`${!isCompanyEditor ? 'col-12 col-sm-6' : 'col-12 col-md-12 col-lg-6'}`}>
              <ComboBox
                disabled={disabled}
                id='modeling'
                value={order.gender}
                header={Translate('labels.model')}
                tabIndex={5}
                handleChange={({ target }) => {
                  handleChangeGender(target.value as Order['gender']);
                }}
                data={[
                  { label: Translate('labels.male'), value: 'male' },
                  { label: Translate('labels.female'), value: 'female' },
                  { label: Translate('labels.childish'), value: 'childish' }
                ]}
              />
            </div>

            <div className={`${!isCompanyEditor ? 'col-12 col-sm-6' : 'col-12 col-md-12 col-lg-6'}`}>
              <ComboBox
                id='target-sublist'
                value={!order.id ? targetSublistIndex.toString() : handleFindSublistIndex(order.sublist_id).toString()}
                header={Translate('labels.sublist')}
                tabIndex={6}
                disabled={!!order.id || disabled}
                handleChange={({ target }) => {
                  setTargetSublistIndex(parseInt(target.value));
                }}
                data={generateSublistOptions(sublists)}
              />
            </div>
          </div>
        </div>

        {/* CLOTHING INPUT RIGHT SIDE */}
        <div className='col-md-6 border-left' style={{ marginTop: '32px' }}>
          <div className='row'>
            {importedModels.map((model: ClothingModelType, index) => {
              return (
                <div className={`col-md-12 ${dynamicLargeClass}`} key={index}>
                  <ClothingInputData
                    disabled={disabled}
                    testIdComboBox={`clothing-input-data-${index}-combobox`}
                    testIdInputNumber={`clothing-input-data-${index}-input-number`}
                    testIdClearButton={`clothing-input-data-${index}-clear-button`}
                    sizeIndex={order.clothes[index].sizeIndex}
                    quantity={order.clothes[index].quantity}
                    clothingName={model.name}
                    clotheIcon={model.icon.url}
                    onChangeSize={sizeIndex => {
                      handleChangeClothingData({ value: sizeIndex, field: 'sizeIndex', clothingIndex: index });
                    }}
                    onChangeQuantity={price => {
                      handleChangeClothingData({ value: price, field: 'quantity', clothingIndex: index });
                    }}
                    onClearAll={() => {
                      handleClearClothingData(index);
                    }}
                    tabIndex={7}
                    data={generateComboBoxOptionsForClothingInput(model, order.gender)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <SplitButton
            disabled={disabled}
            testId='clothing-button-save-edit-order'
            color='success'
            icon={!order.id ? IconsCatalog.save : IconsCatalog.pen}
            title={!order.id ? Translate('actions.add') : Translate('actions.save')}
            tabIndex={14}
            handleClick={() => {
              !order.id ? handleAddOrder() : handleEditOrder();
            }}
          />

          <SplitButton
            disabled={disabled}
            marginLeft
            testId='clothing-button-clear-cancel'
            color='light'
            icon={!order.id ? IconsCatalog.trash : IconsCatalog.times}
            title={!order.id ? Translate('actions.clear') : Translate('actions.cancel')}
            tabIndex={15}
            handleClick={() => {
              !order.id ? handleClearForm() : handleCancelEdit();
            }}
          />
        </div>
      </div>
    </BasicCard>
  );
};
