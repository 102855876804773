import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { type CreateCardMutationParams } from '../../services/CreateCardSublist';
import { createCardSublist } from '../../services';
import { toast } from 'react-toastify';

export function useDismemberInCard() {
  const navigate = useNavigate();

  const createCardMutation = useMutation(
    async ({ sublistTitle, workspaceId, sublistId }: CreateCardMutationParams) => {
      const card = await createCardSublist.execute({ sublistTitle, workspaceId, sublistId });
      return card;
    },
    {
      onSuccess: data => {
        toast.success('Card criado com sucesso');
        navigate('/kanban/workspaces/view', {
          state: {
            workspace: data?.columns?.workspaces,
            card: data
          }
        });
      },
      onError: () => {
        toast.error('Erro ao criar card');
      }
    }
  );

  return { createCardMutation };
}
