export class AuthManager {
  constructor(private readonly storageKey: string) {}

  async getToken(): Promise<string> {
    const token = localStorage.getItem(this.storageKey);

    if (!token) {
      throw new Error('Token not found');
    }

    return token;
  }
}
