import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { type CoordsType } from '../components/ContextMenuViewer';

type ContextMenuType = {
  children: React.ReactNode;
};

type IContextMenu = {
  /** You can adjust the x and y coords, adding or subtracting to better positioning. */
  isVisible: boolean;
  coords: CoordsType;
  selectedItem: object;
  handleShow: (coords: CoordsType, selectedItem: object) => void;
};

export const ContextMenuContext = createContext<IContextMenu>({} as IContextMenu);

export function ContextMenuProvider({ children }: ContextMenuType) {
  const [selectedItem, setSelectedItem] = useState<object>({});
  const [isVisible, setIsVisible] = useState(false);
  const [coords, setCoords] = useState<CoordsType>({ x: 0, y: 0 });

  const handleClickOutside = useCallback(() => {
    if (isVisible) setIsVisible(false);
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside, isVisible]);

  const handleShow = useCallback((coords: CoordsType, selectedItem: object) => {
    setCoords(coords);
    setSelectedItem(selectedItem);
    setIsVisible(true);
  }, []);

  return (
    <ContextMenuContext.Provider value={{ handleShow, isVisible, coords, selectedItem }}>
      {children}
    </ContextMenuContext.Provider>
  );
}

export function useContextMenu(): IContextMenu {
  const context = useContext(ContextMenuContext);

  if (!context) {
    throw new Error('useContextMenu must be used within a ContextMenuProvider');
  }
  return context;
}
