import { type RawAxiosRequestHeaders, type AxiosInstance } from 'axios';
import { convertFileToBlob } from '../../../../../../../utils/helper';
import { type ProjectPaymentRegistryType } from '../../../../../../../types/ProjectPaymentRegistry';

type UpdatePaymentRegistryParams = {
  paymentRegistryId: number;
  file: File;
}

export const updatePaymentRegistry = async (params: UpdatePaymentRegistryParams, httpConnection: AxiosInstance): Promise<ProjectPaymentRegistryType> => {
  return await new Promise<ProjectPaymentRegistryType>((resolve, reject) => {
    const { paymentRegistryId, file } = params;
    const formData = new FormData();

    formData.append('receipt', convertFileToBlob(file), file.name);

    const defaultHeaders: RawAxiosRequestHeaders = httpConnection.defaults.headers;

    httpConnection.post<ProjectPaymentRegistryType>(`list/clothing-projects/payment-receipts/${paymentRegistryId}`, formData, {
      headers: {
        ...defaultHeaders,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
