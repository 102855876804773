import { VictoryBar, VictoryChart, VictoryGroup, VictoryPie } from 'victory';
import { Select } from '../../../../components/Select';
import { Container, GridColumns, Card, Title, Value, ContentCard } from './style';

const options = [
  { id: 1, label: 'Mês atual', value: 0 },
  { id: 2, label: 'Últimos 3 meses', value: 1 },
  { id: 3, label: 'Últimos 6 meses', value: 2 },
  { id: 4, label: 'Últimos 9 meses', value: 3 },
  { id: 5, label: 'Últimos 12 meses', value: 4 }
];

export function FinancialDashboard() {
  return (
    <Container>
      <h1 className='h3 text-gray-800 font-weight-bold' data-testid='dashboard-title'>
        Dashboard
      </h1>

      <GridColumns>
        <Select options={options} />
      </GridColumns>

      <GridColumns>
        <Card>
          <Title>Clientes</Title>
          <Value className='text-primary'>10</Value>
        </Card>

        <Card>
          <Title>Receitas</Title>
          <Value className='text-success'>R$ 1380,00</Value>
        </Card>

        <Card>
          <Title>Contas a pagar</Title>
          <Value className='text-danger'>- R$ 400</Value>
        </Card>

        <Card>
          <Title>A receber</Title>
          <Value className='text-success'>R$ 510,00</Value>
        </Card>

        <Card>
          <Title>Peças em andamentos</Title>
          <Value className='text-warning'>2800</Value>
        </Card>
      </GridColumns>

      <GridColumns row={2}>
        <Card>
          <Title>Produção</Title>
          <ContentCard>
            <VictoryPie
              height={280}
              style={{ data: { fill: ({ index }) => (+Number(index) % 2 === 0 ? '#e74a3b' : '#1cc88a') } }}
              data={[
                { x: 'Em dia', y: 50 },
                { x: 'Em atraso', y: 50 }
              ]}
            />
          </ContentCard>
        </Card>

        <Card>
          <Title>Balanço</Title>
          <ContentCard>
            <VictoryChart height={280}>
              <VictoryBar
                style={{ data: { fill: ({ index }) => (+Number(index) % 2 === 0 ? '#e74a3b' : '#1cc88a') } }}
                alignment='start'
                barWidth={30}
                data={[
                  { x: 'Jan', y: 30 },
                  { x: 'Fev', y: 20 },
                  { x: 'Mar', y: 15 },
                  { x: 'Abr', y: 25 },
                  { x: 'Mai', y: 75 },
                  { x: 'Jun', y: 65 },
                  { x: 'Jul', y: 55 },
                  { x: 'Ago', y: 85 },
                  { x: 'Set', y: 90 },
                  { x: 'Out', y: 20 },
                  { x: 'Nov', y: 70 },
                  { x: 'Dez', y: 10 }
                ]}
              />
            </VictoryChart>
          </ContentCard>
        </Card>
      </GridColumns>

      <GridColumns row={1}>
        <Card>
          <Title>Setores de produção</Title>
          <ContentCard>
            <VictoryChart height={280} width={1200}>
              <VictoryGroup offset={32} colorScale={'qualitative'}>
                <VictoryBar
                  barWidth={30}
                  alignment='start'
                  data={[
                    { x: 'Diagramação', y: 10 },
                    { x: 'Impressão', y: 40 },
                    { x: 'Corte a laser', y: 25 },
                    { x: 'Costureira', y: 55 }
                  ]}
                />
                <VictoryBar
                  barWidth={30}
                  alignment='start'
                  data={[
                    { x: 'Diagramação', y: 85 },
                    { x: 'Impressão', y: 10 },
                    { x: 'Corte a laser', y: 35 },
                    { x: 'Costureira', y: 65 }
                  ]}
                />
                <VictoryBar
                  barWidth={30}
                  alignment='start'
                  data={[
                    { x: 'Diagramação', y: 70 },
                    { x: 'Impressão', y: 35 },
                    { x: 'Corte a laser', y: 68 },
                    { x: 'Costureira', y: 20 }
                  ]}
                />
                <VictoryBar
                  barWidth={30}
                  alignment='start'
                  data={[
                    { x: 'Diagramação', y: 10 },
                    { x: 'Impressão', y: 23 },
                    { x: 'Corte a laser', y: 89 },
                    { x: 'Costureira', y: 25 }
                  ]}
                />
              </VictoryGroup>
            </VictoryChart>
          </ContentCard>
        </Card>
      </GridColumns>

      <GridColumns row={1}>
        <Card>
          <Title>Peças produzidas</Title>
          <ContentCard>
            <VictoryChart height={280} width={1200}>
              <VictoryBar
                style={{ data: { fill: ({ index }) => (+Number(index) % 2 === 0 ? '#e74a3b' : '#1cc88a') } }}
                alignment='start'
                barWidth={90}
                data={[
                  { x: 'Jan', y: 20 },
                  { x: 'Fev', y: 40 },
                  { x: 'Mar', y: 30 },
                  { x: 'Abr', y: 40 },
                  { x: 'Mai', y: 50 },
                  { x: 'Jun', y: 20 },
                  { x: 'Jul', y: 24 },
                  { x: 'Ago', y: 50 },
                  { x: 'Set', y: 68 },
                  { x: 'Out', y: 100 },
                  { x: 'Nov', y: 80 },
                  { x: 'Dez', y: 120 }
                ]}
              />
            </VictoryChart>
          </ContentCard>
        </Card>
      </GridColumns>
    </Container>
  );
}
