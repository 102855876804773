import { type PropsWithChildren } from 'react';
import { Container } from './style';
import { useHeight } from '../../../../helpers/hooks/useHeight';

type CenterCardModalProps = PropsWithChildren & {
  isExpanded: boolean;
};

export function CenterCardModal({ children, isExpanded }: CenterCardModalProps) {
  const { height } = useHeight({ className: '.card_body' });

  return (
    <Container className='card_body' isExpanded={isExpanded} height={height}>
      {children}
    </Container>
  );
}
