import React, { useMemo } from 'react';
import ProgressBar from '../ProgressBar';

type QuotaStatusBarType = {
  value: number;
  maxValue: number;
  /** Can use {value} and {maxValue} keys to use the values inside the message. */
  message: string;
  /** If true, progressbar will always have the 'primary' color. */
  useConstantColor?: boolean;
};

export default function QuotaStatusBar({ value, maxValue, message, useConstantColor }: QuotaStatusBarType) {
  // Split the message into parts and create an array of React elements
  const messageParts = useMemo(() => {
    return message.split(/(\{value\}|\{maxValue\})/).map((part, index) => {
      if (part === '{value}') {
        return (
          <span key={index} className='badge badge-primary' data-testid='quota-statusbar-current-value'>
            {value}
          </span>
        );
      } else if (part === '{maxValue}') {
        return (
          <span key={index} className='badge badge-primary' data-testid='quota-statusbar-max-value'>
            {maxValue}
          </span>
        );
      } else {
        return part; // Keep other parts of the message as plain text
      }
    });
  }, [value, maxValue, message]);

  return (
    <div className='row mb-3'>
      <div className='col'>
        <p className='mb-2'>{messageParts}</p>

        <ProgressBar value={value} maxValue={maxValue} useConstantColor={useConstantColor} />
      </div>
    </div>
  );
}
