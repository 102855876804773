import { format } from 'date-fns';
import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';
import _ from 'lodash';

type GetAllInventoriesParams = {
  startDate: Date;
  endDate: Date;
  selectSeamstress: string;
};

type GetAllSeamstressesResponse = Array<{
  id: number;
  name: string;
  factions: Array<{
    id: number;
    price: string;
    quantity: number;
    sublist_title: string;
    project_title: string;
    total: number;
  }>;
}>;

export class GetAllSeamstresses {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ startDate, endDate, selectSeamstress }: GetAllInventoriesParams): Promise<GetAllSeamstressesResponse> {
    const params = {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd')
    };

    if (!_.isEmpty(selectSeamstress)) {
      Object.assign(params, {
        seamstress: selectSeamstress
      });
    }

    return await this.http.get(`/list-clothing-project/seamstress?${new URLSearchParams(params).toString()}`);
  }
}
