import { createContext, useContext, type PropsWithChildren } from 'react';

const PcpContext = createContext({});

export function PCPContext({ children }: PropsWithChildren) {
  return <PcpContext.Provider value={{}}>{children}</PcpContext.Provider>;
}

export function usePCPContext() {
  const context = useContext(PcpContext);

  if (!context) {
    throw new Error('usePCPContext must be used within a PCPContext');
  }

  return context;
}
