import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
`;

export const WrapperContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

type ProductProps = {
  active: boolean;
};

export const Product = styled.div<ProductProps>`
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  height: 185px;
  overflow: hidden;
  border: 1px solid var(--dark);
  transition: all ease-in-out 0.3s;

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  ${props =>
    props?.active &&
    `
    background-color: var(--success);
    color: var(--white);
    border-color: transparent;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

  h4 {
    font-size: 22px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const SelectedBadge = styled.span`
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-90%, -10%);
  padding: 5px;
  border-radius: 50%;
  background-color: var(--success);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;
