import { type AxiosResponse, type AxiosInstance } from 'axios';

type EditFinishingOptionItemParams = {
  finishingOptionItemId: number;
  httpConnection: AxiosInstance;
}

export default async function ({ httpConnection, finishingOptionItemId }: EditFinishingOptionItemParams): Promise<void> {
  await new Promise<void>((resolve, reject) => {
    httpConnection.delete(`list/finishing-option-items/${finishingOptionItemId}`)
      .then(() => {
        resolve();
      })
      .catch(err => {
        const error = err as AxiosResponse;
        reject(error);
      });
  });
}
