import styled, { createGlobalStyle } from 'styled-components';

export const GlobalKanban = createGlobalStyle`
  #content {
    background-color: var(--background);
  }

  .sticky-footer {
    display: none;
  }
`;

export const KanbanTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: bold;
  color: var(--black);
`;

export const KanbanContainer = styled.div`
  display: flex;
  height: calc(100vh - 100px);
  gap: 20px;
`;

export const WrapperColumns = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 20px;
  flex: 1;
  padding-bottom: 10px;
`;
