import { type AxiosResponse, type AxiosInstance } from 'axios';
import { type FinishingOptionBasic } from '../../../../../../types/ClothingFinishingOptions/FinishingOptionBasic';

type EditFinishingOptionParams = {
  httpConnection: AxiosInstance;
  id: number;
  title: string;
}

export default async function ({ httpConnection, title, id }: EditFinishingOptionParams): Promise<FinishingOptionBasic> {
  return await new Promise<FinishingOptionBasic>((resolve, reject) => {
    httpConnection.put<FinishingOptionBasic>(`list/finishing-options/${id}`, { title })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        const error = err as AxiosResponse;
        reject(error);
      });
  });
}
