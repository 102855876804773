import { type AxiosAdapters } from '../../../../../../../helpers/adapters/http/AxiosAdapters';

export type CardHistoryResponse = {
  card_id: number;
  created_at: string;
  id: number;
  new_value: string;
  old_value: string;
  title: string;
  updated_at: string;
  user_id: number;
  user: {
    id: number;
    name: string;
  };
};

export class GetCardHistory {
  constructor(private readonly httpClient: AxiosAdapters) {}

  async execute({ cardId }: { cardId: number }): Promise<CardHistoryResponse[]> {
    return await this.httpClient.get(`/kanban/card/history/${cardId}`);
  }
}
