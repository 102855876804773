import { type TFunction } from 'i18next';

type ExampleSizesHelpTextParams = {
  prefix?: string;
  sufix?: string;
  Translate: TFunction;
};

export const generateExampleSizesHelpText = ({ prefix, sufix, Translate }: ExampleSizesHelpTextParams): string => {
  if (prefix) return `ex: ${prefix}P, ${prefix}M, ${prefix}G`;
  if (sufix) return `ex: 10${sufix}, 12${sufix}, 14${sufix}`;
  return Translate('labels.not-used');
};
