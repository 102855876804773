import React from 'react';
import { type ClothingGenderType, type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type ClothingQuantitiesDetailed } from '../../../../../../types/ClothingQuantitiesDetailed';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';

type DetailedClothingReportType = {
  useSeparatedLinesToShowDetailedReport: boolean;
  quantitiesDetailedReport: ClothingQuantitiesDetailed;
  importedModels: ClothingModelType[];
};

export default function DetailedClothingReport({
  quantitiesDetailedReport,
  importedModels,
  useSeparatedLinesToShowDetailedReport
}: DetailedClothingReportType) {
  const { Translate } = useAppTranslation();

  return (
    <div className={!useSeparatedLinesToShowDetailedReport ? 'row' : ''}>
      {Object.keys(quantitiesDetailedReport).map((gender, index) => {
        const genderKey = gender as keyof ClothingGenderType;
        return (
          <div key={index} className={!useSeparatedLinesToShowDetailedReport ? 'd-flex' : 'row'}>
            <div className='col-auto d-flex justify-content-center align-items-center font-weight-bold mb-2 pr-1'>
              {Translate('labels.' + genderKey)}
            </div>

            {importedModels.map((model, modelIndex) => (
              <div className='col-auto d-flex align-items-center mb-2 pr-1' key={modelIndex}>
                <table className='table table-sm table-bordered m-0'>
                  <tbody>
                    <tr>
                      <td>
                        <img src={model.icon.url} width={25} height={25} />
                      </td>

                      {Object.keys(quantitiesDetailedReport[genderKey][model.id]).map((sizeKey, sizeIndex) => {
                        const clothingSizeIndex: number = parseInt(sizeKey.replace('size', ''));

                        const hasOnlyOneEmptySize =
                          clothingSizeIndex === -1 &&
                          Object.keys(quantitiesDetailedReport[genderKey][model.id]).length === 1;

                        if (hasOnlyOneEmptySize) {
                          return (
                            <td key={sizeIndex}>
                              <span>0</span>
                            </td>
                          );
                        }

                        if (clothingSizeIndex === -1) return null;

                        return (
                          <td key={sizeIndex}>
                            <span>{importedModels[modelIndex].informations[genderKey][clothingSizeIndex].size}</span>
                            <span>-</span>
                            <span>{quantitiesDetailedReport[genderKey][model.id][sizeKey]}</span>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
