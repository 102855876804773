import React from 'react';
import { Link } from 'react-router-dom';
import { useAppTranslation } from '../../contexts/TranslationContext';

export default function NotFound() {
  const { Translate } = useAppTranslation();

  return (
    <div className='text-center'>
      <div className='error mx-auto' data-text='404'>
        404
      </div>
      <p className='lead text-gray-800 mb-5'>{Translate('labels.page-not-found')}</p>
      <p className='text-gray-500 mb-0'>{Translate('labels.matrix-bug-humor-message')}</p>
      <Link to='dashboard'>&larr; {Translate('actions.back-to-dashboard')}</Link>
    </div>
  );
}
