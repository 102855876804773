import html2canvas from 'html2canvas';
import { sleep } from '../../../../utils/helper';

type TakeScreenshotParams = {
  targetDivElement: HTMLDivElement;
  delayInMilliseconds: number;
}

export const takeScreenshot = async ({ targetDivElement, delayInMilliseconds }: TakeScreenshotParams): Promise<string> => {
  if (delayInMilliseconds > 0) await sleep(delayInMilliseconds);

  return await new Promise<string>((resolve, reject) => {
    html2canvas(targetDivElement, {
      allowTaint: true,
      useCORS: true
    }).then(canvas => {
      const screenshotBase64 = canvas.toDataURL();
      resolve(screenshotBase64);
    }).catch((err) => {
      reject(err);
    });
  });
};
