import { type ChangeEvent, type FocusEvent, useCallback, useState, useEffect } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../../../components/Modals/ModalBase';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';

type SublistDiscountManagerType = Pick<ModalBaseType, 'visible' | 'style' | 'title' | 'message' | 'handleClose'> & {
  sublist: SublistType;
  handleConfirm: (sublistWithDiscount: SublistType) => void;
}

export default function SublistDiscountManager({ sublist, handleConfirm, ...props }: SublistDiscountManagerType) {
  const [discountPercent, setDiscountPercent] = useState(0);

  const { Translate } = useAppTranslation();

  const onConfirm = useCallback(() => {
    const sublistWithDiscount: SublistType = { ...sublist, discount: discountPercent };
    handleConfirm(sublistWithDiscount);
    props.handleClose();
  }, [discountPercent, handleConfirm, props, sublist]);

  const handleChangeDiscountPercent = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value.trim();
    if (!value) setDiscountPercent(0);
    else setDiscountPercent(parseInt(value));
  }, []);

  const handleBlurDiscountInput = useCallback(({ target }: FocusEvent<HTMLInputElement>) => {
    const typedDiscount = parseInt(target.value.trim());
    if (typedDiscount < 0) setDiscountPercent(0);
    else if (typedDiscount > 100) setDiscountPercent(100);
  }, []);

  useEffect(() => {
    setDiscountPercent(sublist?.discount ?? 0);
  }, [sublist]);

  if (!sublist) return <></>;

  return (
    <ModalBase {...props} handleConfirm={onConfirm}>
      <div className="row">
        <div className="col-auto">

          <span className='mb-3'>
            {Translate('labels.discount-applied-to-sublist')}: <strong>{sublist.title}</strong>
          </span>

          <section style={{ width: '130px' }}>
            <div className="input-group my-3">

              <input
                type="number"
                className="form-control"
                placeholder="0"
                value={discountPercent.toString()}
                min={0} max={100}
                onChange={handleChangeDiscountPercent}
                onBlur={handleBlurDiscountInput}
              />

              <div className="input-group-append">
                <span className="input-group-text">%</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </ModalBase>);
};
