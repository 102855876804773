import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';

export type WorkspaceColumn = {
  id: number;
  workspace_id: number;
  title: string;
  daily_amount: number;
  position: number;
  created_at: string;
  updated_at: string;
};

export type Workspace = {
  id: number;
  user_id: number;
  title: string;
  slug: string;
  created_at: string;
  updated_at: string;
  columns: WorkspaceColumn[];
};

export class ShowWorkspace {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ slug }: { slug?: string }): Promise<Workspace> {
    return await this.http.get(`workspace/${slug}`);
  }
}
