import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import * as Yup from 'yup';

import { Container, ContentCard } from '../Register/style';
import { getEntities } from './api';
import { RegisterForm } from './fragments/RegisterForm';
import { EntityTable } from './fragments/EntityTable';
import { SectionsOptions, type Sections } from './fragments/SectionsOptions';
import { getLabels } from './helpers/getLabels';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object().shape({
  photo: Yup.mixed().required('A foto é obrigatória'),
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string().email('Digite um email válido').required('O email é obrigatório'),
  phone: Yup.string().required('O whatsapp é obrigatório'),
  register_number: Yup.string().optional(),
  address: Yup.string().required('O endereço é obrigatório'),
  city: Yup.string().required('A cidade é obrigatória'),
  state: Yup.string().required('O estado é obrigatório'),
  zip_code: Yup.string().required('O campo CEP é obrigatório'),
  products: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().optional(),
      photo: Yup.mixed().required('A foto é obrigatória'),
      title: Yup.string().required('O nome é obrigatório'),
      description: Yup.string().required('A descrição é obrigatória'),
      color: Yup.string().required('A cor é obrigatória')
    })
  )
});

export function Entities() {
  const [section, setSection] = useState<Sections>('customer');
  const [showModal, setShowModal] = useState(false);
  const entities = useQuery(['entities', section], async () => await getEntities.execute({ type: section }), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    refetchIntervalInBackground: false
  });
  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const handleSection = useCallback((section: Sections) => {
    setSection(section);
  }, []);

  const labels = useMemo(() => {
    return getLabels(section);
  }, [section]);

  const content = useMemo(() => {
    if (entities.isLoading) {
      return <div className='alert alert-info'>Carregando...</div>;
    }

    if (_.isEmpty(entities.data)) {
      return <div className='alert alert-info'>Nenhum registro encontrado.</div>;
    }

    return <EntityTable entities={entities} setShowModal={setShowModal} section={section} />;
  }, [entities, section]);

  return (
    <Container>
      <h1 className='h5 text-gray-800 font-weight-bold text-uppercase' data-testid='dashboard-title'>
        Cadastro de Clientes, Costureiras e Fornecedores.
      </h1>

      <div className='row flex-sm-column flex-md-row'>
        <div className='col-sm-12 col-lg-4 col-xl-2 mb-sm-3'>
          <SectionsOptions section={section} handleSection={handleSection} />
        </div>

        <div className='col-sm-12 col-lg-8 col-xl-10'>
          <FormProvider {...methods}>
            <ContentCard>
              <div className='d-flex w-100 justify-content-between'>
                <h2 className='h4 text-gray-800 font-weight-bold mb-0'>{labels.title}</h2>

                <RegisterForm section={section} setShowModal={setShowModal} showModal={showModal} />
              </div>

              {content}
            </ContentCard>
          </FormProvider>
        </div>
      </div>
    </Container>
  );
}
