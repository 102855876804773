import { Outlet } from 'react-router';
import { KanbanWorkspacesProvider } from '../../../contexts/KanbanWorkspacesContext';

export function Kanban() {
  return (
    <KanbanWorkspacesProvider>
      <Outlet />
    </KanbanWorkspacesProvider>
  );
}
