import React, { useState } from 'react';
import { type SublistType } from '../../../types/SublistTypes';
import ModalBase from '../ModalBase';

type SublistPickerModalType = {
  title: string;
  message: string;
  visible: boolean;
  style: 'primary' | 'danger';
  sublists: SublistType[];
  handleClose: () => void;
  handleConfirm: (sublistId: number) => void;
};

export default function SublistPickerModal({
  title,
  message,
  visible,
  style,
  sublists,
  handleClose,
  handleConfirm
}: SublistPickerModalType) {
  const [selectedSublistId, setSelectedSublistId] = useState<number>(() => {
    return sublists.length > 0
      ? sublists[0].id ?? -1
      : -1;
  });

  return (
    <ModalBase
      visible={visible}
      style={style}
      title={title}
      message={message}
      handleClose={handleClose}
      handleConfirm={() => {
        handleConfirm(selectedSublistId);
      }}
    >
      <div className="form-group">
        <select
          className="form-control"
          onChange={({ target }) => {
            setSelectedSublistId(parseInt(target.value));
          }}
          value={selectedSublistId}
        >
          {sublists.map((sublist, index) => (
            <option key={index} value={sublist.id}>
              {sublist.title}
            </option>
          ))}
        </select>
      </div>
    </ModalBase>
  );
}
