import { type AxiosInstance } from 'axios';
import { type KanbanCardIdentifier } from '../../../../types/Kanban/KanbanCardIdentifier';

export const updateCardsAffectedByMove = async (
  affected_cards: KanbanCardIdentifier[],
  httpConnection: AxiosInstance
): Promise<void> => {
  await new Promise<void>((resolve, reject) => {
    httpConnection
      .put('kanban/cards/move', { affected_cards })
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
