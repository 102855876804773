import React from 'react';

type SwitchInputType = {
  testId?: string;
  id: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  handleChange: (checked: boolean) => void;
};

export default function SwitchInput({ testId, id, checked, disabled, label, handleChange }: SwitchInputType) {
  return (
    <React.Fragment>
      <div className='custom-control custom-switch mb-2'>
        <input
          disabled={disabled}
          type='checkbox'
          className='custom-control-input'
          id={id}
          checked={checked}
          onChange={({ target }) => {
            handleChange(target.checked);
          }}
        />
        <label className='custom-control-label' htmlFor={id} data-testid={testId}>
          {label}
        </label>
      </div>
    </React.Fragment>
  );
}
