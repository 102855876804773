import type {
  OrderClothingReplacementNewRegistryType
} from '../../../../../../../../../types/ClothingReplacementOption/OrderClothingReplacementNewRegistryType';
import {
  type ListClothingProjectOrderReplacementType
} from '../../../../../../../../../types/ClothingReplacementOption/ListClothingProjectOrderReplacementType';
import { type TFunction } from 'i18next';

type CheckDuplicatedClothingReplacementParams = {
  orderClothingReplacement: OrderClothingReplacementNewRegistryType;
  cachedOrderClothingReplacementInOrder: ListClothingProjectOrderReplacementType[][];
  Translate: TFunction;
}

export default function({ orderClothingReplacement, cachedOrderClothingReplacementInOrder, Translate }: CheckDuplicatedClothingReplacementParams) {
  cachedOrderClothingReplacementInOrder.flatMap(innerArray => innerArray).forEach(replacement => {
    const sameModelId = replacement.model_id === orderClothingReplacement.model_id;
    const sameClothingPieceToReplace = replacement.clothing_replacement_option_id === orderClothingReplacement.clothing_replacement_option_id;
    const sameOrderId = replacement.order_id === orderClothingReplacement.order_id;

    if (sameModelId && sameClothingPieceToReplace && sameOrderId) throw new Error(Translate('error.replacement-request-already-exists'));
  });
}
