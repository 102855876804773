import { type KanbanColumn } from '../../../../types/Kanban/KanbanColumn';
import { type KanbanColumnsUpdatedResponse } from '../../../../types/Kanban/KanbanColumnsUpdatedResponse';
import { type KanbanWorkspace } from '../../../../types/Kanban/KanbanWorkspace';

/**
 * Throws an error if the title already exists in some of the stored lists.
 * @param title New list title.
 * @param columns Stored lists.
 */
export const validateDuplicatedColumnTitle = (title: string, columns: KanbanColumn[]) => {
  const duplicatedColumn = columns.find(column => column.title.toLowerCase() === title.toLocaleLowerCase());

  if (duplicatedColumn) throw new Error('Já existe uma coluna com esse nome.');
};

export const updateCachedColumn = (columnUpdatedFields: KanbanColumnsUpdatedResponse, workspace: KanbanWorkspace): KanbanWorkspace => {
  const updatedWorkspace: KanbanWorkspace = {
    ...workspace,
    columns: workspace.columns.map(column => {
      if (column.id === columnUpdatedFields.id) return { ...column, ...columnUpdatedFields };
      return column;
    })
  };

  return updatedWorkspace;
};

export const addColumnToWorkspace = (createdColumn: KanbanColumn, workspace: KanbanWorkspace): KanbanWorkspace => {
  const updatedWorkspace: KanbanWorkspace = {
    ...workspace,
    columns: [...workspace.columns, createdColumn]
  };

  return updatedWorkspace;
};

export const deleteCachedColumn = (columnId: number, workspace: KanbanWorkspace): KanbanWorkspace => {
  const updatedWorkspace: KanbanWorkspace = {
    ...workspace,
    columns: workspace.columns.filter(column => column.id !== columnId)
  };

  return updatedWorkspace;
};
