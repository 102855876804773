import { useCallback, useEffect, useRef, useState } from 'react';
import { Wrapper, Container, Label, WrapperContainer, Option, WrapperOptions } from './style';
import _ from 'lodash';

type SelectProps = {
  options: any[];
};

export function Select({ options }: SelectProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const handleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const handleSelected = useCallback((option: any) => {
    setSelected(option);
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Wrapper ref={ref}>
      <Container onClick={handleOpen}>
        <Label>{selected?.label}</Label>
      </Container>

      <WrapperContainer openModal={open}>
        {options.map(option => (
          <WrapperOptions
            key={option.id}
            onClick={() => {
              handleSelected(option);
            }}
          >
            <Option active={_.isEqual(option, selected)} />
            <Label>{option.label}</Label>
          </WrapperOptions>
        ))}
      </WrapperContainer>
    </Wrapper>
  );
}
