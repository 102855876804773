import { useCallback, useMemo, useState } from 'react';
import { useUser } from '../../../../../../../../../contexts/UserContext';
import { Dropdown, DropdownButton, DropdownItem, ResetButton, WrapperDropdown } from './style';
import _ from 'lodash';
import { useKanbanView } from '../../../../../../hooks/useKanbanView';
import { type KanbanColumn } from '../../../../../../../../../types/Kanban/KanbanColumn';

type DropdownMenuType = {
  first?: boolean;
  handleSetDeleteColumn: () => void;
  color: string;
  handleSetColumnColor: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  column: KanbanColumn;
};

export function DropdownMenu({ first, handleSetDeleteColumn, color, handleSetColumnColor, column }: DropdownMenuType) {
  const { isCompanyAccount } = useUser();
  const { updateColumnBackgroundColorMutation } = useKanbanView();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShowDropdown = useCallback(() => {
    setShowDropdown(prev => !prev);
  }, []);

  const handleResetColor = useCallback(() => {
    handleSetColumnColor('#d2d2d21a');
  }, [handleSetColumnColor]);

  const equalColor = useMemo(() => {
    return _.isEqual(color, '#d2d2d21a');
  }, [color]);

  return (
    <WrapperDropdown active={showDropdown}>
      <i className='fa fa-ellipsis-v' onClick={handleShowDropdown}></i>

      {showDropdown ? (
        <Dropdown>
          <DropdownItem>
            <span>Cor da coluna</span>
            <input
              type='color'
              placeholder='Nome da coluna'
              value={color}
              onChange={handleSetColumnColor}
              onBlur={e => {
                updateColumnBackgroundColorMutation.mutate({ column_id: column.id, color: e.target.value });
              }}
            />
            <ResetButton type='button' disabled={equalColor} onClick={handleResetColor}>
              resetar
            </ResetButton>
          </DropdownItem>

          {first && isCompanyAccount ? (
            <DropdownButton type='button' onClick={handleSetDeleteColumn}>
              <i className='fa fa-trash'></i>
              <span>Remover</span>
            </DropdownButton>
          ) : null}
        </Dropdown>
      ) : null}
    </WrapperDropdown>
  );
}
