import React, { useCallback, useEffect, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import TextInput from '../../../../../../components/Forms/TextInput';
import Checkbox from '../../../../../../components/Forms/Checkbox';
import CurrencyInput from '../../../../../../components/Forms/CurrencyInput';
import { type ProjectShippingType } from '../../../../../../types/ProjectShippingType';
import { generateEmptyShippingInformation, validateShippingInformation } from './services/shippingService';
import { toast } from 'react-hot-toast-promise';

type ScreenshotSettingsModalType = Pick<ModalBaseType, 'handleClose' | 'visible'> & {
  currentValue: ProjectShippingType | null;
  handleConfirm: (shippingData: ProjectShippingType | null) => void;
};

export default function ShippingEditorModal({ visible, currentValue, handleClose, handleConfirm }: ScreenshotSettingsModalType) {
  const { Translate } = useAppTranslation();

  const [pickUpOnSite, setPickUpOnSite] = useState<boolean>(() => currentValue === null);

  const [shippingData, setShippingData] = useState<ProjectShippingType | null>(() => {
    return !currentValue ? generateEmptyShippingInformation() : currentValue;
  });

  const handleBeforeConfirm = useCallback(() => {
    try {
      const data = !pickUpOnSite ? shippingData : null;

      if (data) validateShippingInformation(shippingData, Translate);
      handleConfirm(data);
      handleClose();
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  }, [Translate, handleClose, handleConfirm, pickUpOnSite, shippingData]);

  useEffect(() => {
    if (pickUpOnSite) {
      setShippingData(generateEmptyShippingInformation());
    }
  }, [pickUpOnSite]);

  return (
    <ModalBase
      title={Translate('labels.shipping')}
      message={Translate('description.shipping-modal')}
      style='primary'
      size={'lg'}
      visible={visible}
      handleClose={handleClose}
      handleConfirm={handleBeforeConfirm}
    >
      <>
        <div className="row">
          <div className='col'>
            <Checkbox
              id='checkbox-shipping-pickup-on-site'
              checked={pickUpOnSite}
              label={Translate('actions.pick-up-in-store')}
              name='checkbox-pickup-on-site'
              handleChange={(checked) => {
                setPickUpOnSite(checked);
              }} />
          </div>
        </div>

        {
          !pickUpOnSite && (
            <div className='row'>
              <div className='col'>
                <TextInput
                  id='shipping-tracking-code'
                  label={Translate('labels.tracking-number')}
                  disabled={pickUpOnSite}
                  value={shippingData?.code ?? ''}
                  onChange={({ target }) => {
                    if (!shippingData) return;
                    setShippingData({ ...shippingData, code: target.value.trim().toUpperCase() });
                  }}
                />
              </div>
              <div className='col'>
                <TextInput
                  id='shipping-carrier'
                  label={Translate('labels.carrier')}
                  disabled={pickUpOnSite}
                  value={shippingData?.carrier ?? ''}
                  onChange={({ target }) => {
                    if (!shippingData) return;

                    setShippingData({ ...shippingData, carrier: target.value });
                  }}
                />
              </div>
              <div className='col'>
                <CurrencyInput
                  id='shipping-price'
                  label={Translate('labels.value')}
                  disabled={pickUpOnSite}
                  value={shippingData?.price ?? 0} onChange={(value) => {
                    if (!shippingData) return;
                    setShippingData({ ...shippingData, price: value });
                  }}
                />
              </div>
            </div>
          )
        }
      </>
    </ModalBase>
  );
};
