import styled from 'styled-components';

export const HistoryContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const WrapperUpdateContent = styled.div`
  border: 1px dashed var(--light-v2);
  border-radius: 4px;
  width: 100%;
`;

const Content = styled.div`
  padding: 1rem;
  font-size: 0.875rem;
  color: #000;
  border-radius: 4px;
`;

export const SuccessContent = styled(Content)`
  background-color: #dbfff2;
`;

export const ErrorContent = styled(Content)`
  background-color: #f9cbc7;
  text-decoration: line-through;
`;
