import React from 'react';

export type TabParam = {
  id: string;
  label: string;
  active?: boolean;
  hidden?: boolean;
  content: JSX.Element;
  icon?: string;
};

type TabsType = {
  data: TabParam[];
};

export default function Tabs({ data = [] }: TabsType) {
  return (
    <React.Fragment>
      <ul className='nav nav-tabs'>
        {data.map((tab, index) => !tab.hidden && (
          <li className='nav-item' key={index}>
            <a className={`nav-link ${tab.active ? 'active' : ''}`} data-toggle='tab' href={`#${tab.id}`}>
              {tab.icon && <i className={`${tab.icon} fa-sm fa-fw`} />} {tab.label}
            </a>
          </li>
        ))}
      </ul>

      <div className='tab-content'>
        {data.map((tab, index) => !tab.hidden && (
          <div key={index} id={tab.id} className={`tab-pane fade ${tab.active ? 'in active show' : ''}`}>
            {tab.content}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
