import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../../components/Modals/ModalBase';
import SwitchInput from '../../../../../../../components/Forms/SwitchInput';
import { ListContainer } from './styles';
import type { EmployeePermissionType, PermissionItem } from '../../../../../../../types/EmployeePermissionType';
import attatchPermissionToUser from '../api/attatchPermissionToUser';
import detatchPermissionFromUser from '../api/detatchPermissionFromUser';
import { useHttpRequest } from '../../../../../../../contexts/HttpRequestContext';
import { toast } from 'react-hot-toast-promise';
import { getServerErrorMessageFromResponse } from '../../../../../../../utils/helper';
import SpinnerWithMessage from '../../../../../../../components/SpinnerWithMessage';
import { useAppTranslation } from '../../../../../../../contexts/TranslationContext';

type EmployeePermissionManagerModalType = Pick<ModalBaseType, 'handleClose' | 'handleConfirm'> & {
  employee: EmployeePermissionType;
  availablePermissions: PermissionItem[];
  onReceiveUpdatedPermissions: (updatedPermissions: PermissionItem[]) => void;
}

export default function EmployeePermissionManagerModal({ handleClose, handleConfirm, employee, onReceiveUpdatedPermissions, availablePermissions }: EmployeePermissionManagerModalType) {
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const checkPermission = useCallback((permissionName: string) => {
    return employee.permissions.findIndex(currentPermission => currentPermission.name === permissionName) > -1;
  }, [employee.permissions]);

  const togglePermission = useCallback((permissionId: number) => {
    const permissionEnabled = employee.permissions.findIndex(currentPermission => currentPermission.id === permissionId) > -1;

    if (permissionEnabled) {
      const promise = detatchPermissionFromUser({ httpConnection, permissionId, userId: employee.id });

      setIsRequesting(true);

      toast.promise(promise, {
        loading: Translate('progress.loading'),
        success: (updatedPermissions) => {
          onReceiveUpdatedPermissions(updatedPermissions);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });

      return;
    }

    const promise = attatchPermissionToUser({ httpConnection, permissionId, userId: employee.id });

    setIsRequesting(true);

    toast.promise(promise, {
      loading: Translate('progress.loading'),
      success: (updatedPermissions) => {
        onReceiveUpdatedPermissions(updatedPermissions);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, employee.id, employee.permissions, httpConnection, onReceiveUpdatedPermissions]);

  return (
    <ModalBase visible={true} title={'Permissões'} style={'primary'} handleClose={handleClose} handleConfirm={handleConfirm}>
      <React.Fragment>

        <p>Editando permissões do funcionário: <b>{employee.name}</b></p>

        <ListContainer>
          {
            availablePermissions.map((permission, index) => (
              <li key={index}>
                <SwitchInput
                  disabled={isRequesting}
                  id={permission.name}
                  label={permission.description}
                  checked={checkPermission(permission.name)}
                  handleChange={() => {
                    togglePermission(permission.id);
                  }}
                />
              </li>
            ))
          }
        </ListContainer>

        {
          isRequesting && (
            <div className="row mt-4">
              <div className="col">
                <div className={'d-flex'}>
                  <SpinnerWithMessage message={'Salvando...'} />
                </div>
              </div>
            </div>
          )
        }

      </React.Fragment>
    </ModalBase>
  );
}
