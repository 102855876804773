import { type AxiosInstance } from 'axios';
import { type Order } from '../../../../types/OrderTypes';

type MoveOrderToSublistParams = {
  projectId: number;
  destinationSublistId: number;
  orders: Order[];
  httpConnection: AxiosInstance;
}

export const moveOrdersToSublist = async ({ httpConnection, projectId, destinationSublistId, orders }: MoveOrderToSublistParams): Promise<void> => {
  const putData = {
    destination_sublist_id: destinationSublistId,
    moved_orders: orders.map(order => ({ id: order.id, sublist_id: order.sublist_id }))
  };

  await new Promise<void>((resolve, reject) => {
    httpConnection.put(`list/clothing-projects/sublist/orders/move-to-sublist?project_id=${projectId}`, putData)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};
