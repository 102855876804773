import React, { useEffect, useState } from 'react';
import TextInput from '../../../../../../components/Forms/TextInput';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import { generatePresetOptions } from '../../services/newProjectFormService';
import DatePickerInput from '../../../../../../components/Forms/DatePickerInput';
import SplitButton from '../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { type ProjectType } from '../../../../../../types/ProjectType';
import type ClothingPresetType from '../../../../../../types/ClothingPresetType';
import TextAreaInput from '../../../../../../components/Forms/TextAreaInput';
import Spinner from '../../../../../../components/Spinner';
import { useWorkspaces } from '../../../../KanbanWorkspaces/hooks/useWorkspaces';
import _ from 'lodash';
import { ClientsSelect } from '../ClientsSelect';
import { type Entity } from '../../../../../../types/Entity';
import { useProjectsStatuses } from '../../../../../../contexts/ProjectStatusContext';

type FormCreateProjectType = {
  project: ProjectType;
  presets: ClothingPresetType[];
  creatingOrEditingProject: boolean;
  setProject: React.Dispatch<React.SetStateAction<ProjectType>>;
  handleSubmitProject: () => void;
  handleCancelEditProject: () => void;
  handleOpenCreatePreset: () => void;
};

export default function FormCreateProject({
  project,
  presets,
  creatingOrEditingProject,
  setProject,
  handleSubmitProject,
  handleCancelEditProject,
  handleOpenCreatePreset
}: FormCreateProjectType) {
  const { Translate } = useAppTranslation();
  const { workspaces } = useWorkspaces();
  const { projectStatusComboboxOptions } = useProjectsStatuses();

  const [selectedClient, setSelectedClient] = useState<Entity | null>(null);

  useEffect(() => {
    setProject(project => ({ ...project, selectedClient }));
  }, [selectedClient, setProject]);

  return (
    <div className="d-flex flex-column">
      <div className="row mt-3">
        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          <TextInput
            disabled={creatingOrEditingProject}
            label={Translate('labels.name')}
            value={project.name}
            id="txtProjectName"
            testId="text-input-project-name"
            tabIndex={1}
            autofocus
            onChange={({ target }) => {
              setProject({ ...project, name: target.value });
            }}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <ComboBox
            id={'combobox-project-status'}
            tabIndex={2}
            value={project.status?.id?.toString() ?? ''}
            header={'Status'}
            data={projectStatusComboboxOptions}
            handleChange={({ target }) => {
              setProject({
                ...project,
                status: {
                  id: parseInt(target.value),
                  name: '',
                  color: ''
                }
              });
            }}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <div className="row">
            <div className="col">
              <ComboBox
                disabled={creatingOrEditingProject || !!project?.id}
                id="target-preset"
                testId="combobox-target-preset"
                value={project.preset_id.toString()}
                header={Translate('labels.preset')}
                tabIndex={2}
                handleChange={({ target }) => {
                  setProject({ ...project, preset_id: parseInt(target.value) });
                }}
                data={generatePresetOptions(presets, Translate)}
              />
            </div>

            {!project.id && (
              <div className="col-auto pl-0">
                <SplitButton
                  simulateLabelMarginTop
                  icon={IconsCatalog.plus}
                  color="primary"
                  handleClick={handleOpenCreatePreset}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <DatePickerInput
            id="project-delivery-date-picker"
            label={Translate('labels.delivery-date')}
            tabIndex={3}
            disabled={creatingOrEditingProject}
            startDate={project.delivery_date ? new Date(project.delivery_date) : undefined}
            handleDateChange={selectedDate => {
              setProject({ ...project, delivery_date: selectedDate.toISOString() });
            }}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <div className="d-flex align-items-end">
            {_.isEmpty(selectedClient) ? (
              <TextInput
                disabled={creatingOrEditingProject}
                label={Translate('labels.client')}
                value={typeof project.client_name === 'string' ? project.client_name : ''}
                id="txtClientName"
                testId="text-input-client-name"
                tabIndex={4}
                onChange={({ target }) => {
                  setProject({ ...project, client_name: target.value });
                }}
              />
            ) : (
              <TextInput
                disabled={true}
                label={Translate('labels.client')}
                value={selectedClient.name}
                id="txtClientName"
                testId="text-input-client-name"
                tabIndex={4}
              />
            )}

            <ClientsSelect selectedClient={selectedClient} setSelectedClient={setSelectedClient} />
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
          <TextInput
            disabled={creatingOrEditingProject}
            label="WhatsApp"
            value={project.client_whatsapp ?? ''}
            id="txtClientWhatsapp"
            testId="text-input-whatsapp-number"
            tabIndex={5}
            onChange={({ target }) => {
              setProject({ ...project, client_whatsapp: target.value });
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <TextAreaInput
            id="text-area-client-informations"
            testId="textarea-client-informations"
            label={Translate('labels.client-annotations')}
            placeholder={Translate('description.client-annotations-placeholder')}
            value={project.client_informations ?? ''}
            rows={5}
            tabIndex={7}
            handleChange={({ target }) => {
              setProject({ ...project, client_informations: target.value });
            }}
          />
        </div>
      </div>

      {!_.isEmpty(workspaces.data) ? (
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="workspace">Espaço de trabalho</label>

              <select
                name="workspace"
                id="workspace"
                className="form-control"
                onChange={e => {
                  setProject(project => {
                    return { ...project, workspace: e.target.value };
                  });
                }}
              >
                <option value="">Selecione</option>
                {workspaces.data?.map(workspace => (
                  <option key={workspace.id} value={workspace.id}>
                    {workspace.title}
                  </option>
                ))}
              </select>

              <div className="form-text small">Escolha uma área de trabalho para a lista se tornar um card.</div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row mb-3">
        <div className="col-auto">
          {creatingOrEditingProject && <Spinner alignLeft />}

          {!creatingOrEditingProject && (
            <SplitButton
              title={!project?.id ? Translate('actions.create-project') : Translate('actions.save')}
              icon={IconsCatalog.save}
              testId="button-create-edit"
              color="success"
              tabIndex={8}
              handleClick={handleSubmitProject}
            />
          )}

          {!!project.id && !creatingOrEditingProject && (
            <SplitButton
              marginLeft
              title={Translate('actions.cancel')}
              icon={IconsCatalog.times}
              color="danger"
              tabIndex={8}
              handleClick={handleCancelEditProject}
            />
          )}
        </div>
      </div>
    </div>
  );
}
