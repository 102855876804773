import React, { useCallback, useMemo, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../components/Modals/ModalBase';
import { type SublistType } from '../../../../../../types/SublistTypes';
import CollapsableCard from '../../../../../../components/Cards/CollapsableCard';
import OrderClothingReplacementRow from './components/OrderClothingReplacementRow';
import { updateCachedOrderInSublist } from '../../../services/orderService';
import ComboBox from '../../../../../../components/Forms/ComboBox';
import { type ComboBoxDataType } from '../../../../../../types/ComboBoxDataType';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';

type ProjectOrderClothingReplacementsManagerModalType = Pick<ModalBaseType, 'handleClose'> & {
  sublists: SublistType[];
  importedModels: ClothingModelType[];
  onUpdateSublists: (updatedSublists: SublistType[]) => void;
}

export type OrderClothingReplacentsManagerFilterType = '' | 'pending' | 'completed';

type ReplacementssReportType = {
  totalPiecesToReplace: number;
  totalPiecesReplaced: number;
}

export default function ProjectOrderClothingReplacementsManagerModal({ sublists, importedModels, handleClose, onUpdateSublists }: ProjectOrderClothingReplacementsManagerModalType) {
  const { Translate } = useAppTranslation();

  const [checkboxesDisabled, setCheckboxesDisabled] = useState<boolean>(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<OrderClothingReplacentsManagerFilterType>('');

  const filteredOrdersThatContainsReplacements = useCallback((sublist: SublistType) => {
    return sublist.orders.filter(order => order.clothing_replacements.length > 0);
  }, []);

  const filteredSublistsThatContainsReplacements = useCallback((sublists: SublistType[]) => {
    return sublists.filter(sublist => filteredOrdersThatContainsReplacements(sublist).length > 0);
  }, [filteredOrdersThatContainsReplacements]);

  const replacementReport = useMemo<ReplacementssReportType>(() => {
    let totalPiecesToReplace = 0;
    let totalPiecesReplaced = 0;

    sublists.forEach((sublist) => {
      sublist.orders.forEach(order => {
        order.clothing_replacements.flatMap((replacement) => replacement).forEach(replacement => {
          if (replacement.completed) totalPiecesReplaced++;
          totalPiecesToReplace++;
        });
      });
    });

    return { totalPiecesReplaced, totalPiecesToReplace };
  }, [sublists]);

  const comboboxFilterOptions = useMemo<ComboBoxDataType[]>(() => [
    { label: Translate('labels.show-all-replacements'), value: '' },
    { label: Translate('labels.show-pending'), value: 'pending' },
    { label: Translate('labels.show-done'), value: 'completed' }
  ], [Translate]);

  return (
    <ModalBase
      visible
      hideFooter
      size={'xl'}
      title={Translate('labels.project-replacements')}
      style={'primary'}
      handleClose={handleClose}
      handleConfirm={() => {}}>
      <React.Fragment>

        <section>
          <div className="row">
            <div className="col">
              <section>
                <strong>{Translate('labels.clothes-to-replace')}:</strong>
                &nbsp;
                <span>{replacementReport.totalPiecesToReplace}</span>

              </section>

              <section>
                <strong>{Translate('labels.missing')}:</strong>
                &nbsp;
                <span>{replacementReport.totalPiecesToReplace - replacementReport.totalPiecesReplaced}</span>
              </section>
            </div>
            <div className="col-auto">
              <ComboBox
                noMarginTop
                id={'order-clothing-replacements-filter'}
                value={selectedFilterOption}
                data={comboboxFilterOptions}
                handleChange={({ target }) => {
                  setSelectedFilterOption(target.value as OrderClothingReplacentsManagerFilterType);
                }} />
            </div>
          </div>
        </section>

        {
          filteredSublistsThatContainsReplacements(sublists).map((sublist, sublistIndex) => (
            <CollapsableCard id={`ocrm-${sublistIndex}`} header={sublist.title} key={sublistIndex}>
              <table className={'table table-sm table-striped table-bordered table-hover'}>
                <thead>
                <tr>
                  <th>{Translate('labels.name')}</th>
                  <th className={'text-center'}>{Translate('labels.number')}</th>
                  <th className={'text-center'}>{Translate('labels.nickname')}</th>
                  <th className={'text-center'}>{Translate('labels.size')}</th>
                  <th className={'text-center'}>{Translate('labels.user')}</th>
                  <th>{Translate('labels.notes')}</th>
                  <th>{Translate('labels.done')}?</th>
                </tr>
                </thead>
                <tbody>
                {
                  filteredOrdersThatContainsReplacements(sublist).map((order, orderIndex) => (
                    <OrderClothingReplacementRow
                      key={orderIndex}
                      filterBy={selectedFilterOption}
                      checkboxesDisabled={checkboxesDisabled}
                      setCheckboxesDisabled={setCheckboxesDisabled}
                      orderIndex={orderIndex}
                      sublistIndex={sublistIndex}
                      importedModels={importedModels}
                      order={order}
                      onUpdateOrder={(updatedOrder) => {
                        const updatedSublists = updateCachedOrderInSublist({
                          sublists,
                          updatedOrder,
                          targetSublistId: updatedOrder.sublist_id!
                        });

                        onUpdateSublists(updatedSublists);
                      }}
                    />
                  ))
                }

                {filteredOrdersThatContainsReplacements(sublist).length === 0 && (
                  <tr className={'text-center'}>
                    <td colSpan={999}>{Translate('ststus.nothing-to-show-for-now')}</td>
                  </tr>
                )}
                </tbody>
              </table>
            </CollapsableCard>
          ))
        }
      </React.Fragment>
    </ModalBase>
  );
}
