import { http } from '../../../../helpers/adapters/http';
import { ListWorkspace } from './listWorkspace';
import { CreateWorkspace } from './createWorkspace';
import { DeleteWorkspaces } from './deleteWorkspaces';
import { UpdateWorkspaceTitle } from './updateWorkspace';

export const listWorkspace = new ListWorkspace(http);
export const createWorkspace = new CreateWorkspace(http);
export const deleteWorkspaces = new DeleteWorkspaces(http);
export const updateWorkspaceTitle = new UpdateWorkspaceTitle(http);
