import { useMemo } from 'react';
import { type BasicButtonType } from '../types/BasicButtonType';
import { getTextColorBasedOnBackgroundColor, isHexColor } from '../../../helpers/colors';

export default function BasicButton({
  testId,
  title,
  size,
  color,
  marginLeft = false,
  disabled = false,
  tabIndex,
  buttonRef,
  handleClick,
  simulateLabelMarginTop
}: BasicButtonType) {
  const buttonSize = useMemo(() => (size !== undefined ? `btn-${size}` : ''), [size]);
  const useMarginLeft = useMemo(() => (marginLeft ? 'ml-2' : ''), [marginLeft]);
  const useSimulatedMarginTopLabel = useMemo(() => (simulateLabelMarginTop ? '32px' : '0'), [simulateLabelMarginTop]);

  const customStyle = useMemo(() => {
    return isHexColor(color)
      ? ({ backgroundColor: color, color: getTextColorBasedOnBackgroundColor(color) })
      : ({});
  }, [color]);

  return (
    <button
      data-testid={testId}
      ref={buttonRef}
      onClick={handleClick}
      type='button'
      className={`btn btn-${color} ${buttonSize} ${useMarginLeft}`}
      style={{ marginTop: useSimulatedMarginTopLabel, ...customStyle }}
      tabIndex={tabIndex}
      disabled={disabled}
    >
      {title}
    </button>
  );
}
