import React from 'react';
import { CheckIcon, CheckboxContainer, CheckboxLabel, HiddenCheckbox, StyledCheckbox } from './styles';
import Spinner from '../../Spinner';

type CheckboxType = {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  tabIndex?: number;
  disabled?: boolean;
  isLoading?: boolean;
  handleChange: (checked: boolean) => void;
};

export default function Checkbox({ id, name, label, checked, tabIndex, disabled, isLoading, handleChange }: CheckboxType) {
  const handleCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(target.checked);
  };

  return (
    <CheckboxContainer>
      {isLoading && <Spinner small />}

      {!isLoading && (
        <React.Fragment>
          <HiddenCheckbox checked={checked} onChange={handleCheckboxChange} id={id} name={name} tabIndex={tabIndex} disabled={disabled}/>
          <StyledCheckbox checked={checked} disabled={disabled}>
            {checked && <CheckIcon className="fas fa-check fa-fw fa-sm" />}
          </StyledCheckbox>
        </React.Fragment>
      )}

      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
}
