import { NumericFormat } from 'react-number-format';
import { type ComboBoxDataType } from '../../../types/ComboBoxDataType';
import { IconsCatalog } from '../../IconsCatalog';
import { ButtonClearAll } from './styles';
import { Tooltip } from 'react-tooltip';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type ClothingInputDataType = {
  clothingName: string;
  clotheIcon: string;
  data: ComboBoxDataType[];
  tabIndex?: number;
  sizeIndex: number;
  quantity: number;
  testIdComboBox?: string;
  testIdInputNumber?: string;
  testIdClearButton?: string;
  disabled?: boolean;
  onChangeSize: (sizeIndex: number) => void;
  onChangeQuantity: (quantity: number) => void;
  onClearAll: () => void;
};

export default function ClothingInputData({
  clothingName,
  clotheIcon,
  data,
  tabIndex,
  sizeIndex,
  quantity,
  testIdComboBox,
  testIdInputNumber,
  testIdClearButton,
  disabled,
  onChangeSize,
  onChangeQuantity,
  onClearAll
}: ClothingInputDataType) {
  const clothingInputDataIconUUID: string = useMemo(() => `tooltip-model-name-${uuidv4()}`, []);

  return (
    <div className='input-group' style={{ marginBottom: 19.5 }}>
      <div className='input-group-prepend'>
        <span className='input-group-text' style={{ backgroundColor: '#fff' }}>
          <Tooltip id={clothingInputDataIconUUID} opacity={1}>
            {clothingName}
          </Tooltip>
          <img src={clotheIcon} width={20} height={20}
            data-tooltip-id={clothingInputDataIconUUID}
            data-tooltip-delay-hide={250}
            data-tooltip-variant='dark' />
        </span>
      </div>

      <select
        data-testid={testIdComboBox}
        disabled={disabled}
        className='custom-select'
        tabIndex={tabIndex}
        value={sizeIndex}
        onChange={({ target }) => {
          onChangeSize(parseInt(target.value));
        }}
      >
        {data.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <NumericFormat
        data-testid={testIdInputNumber}
        disabled={disabled}
        tabIndex={tabIndex}
        value={quantity}
        className='form-control'
        decimalScale={2}
        allowedDecimalSeparators={['.', ',']}
        fixedDecimalScale={false}
        onFocus={event => {
          event.target.select();
        }}
        onValueChange={values => {
          onChangeQuantity(values.floatValue ?? 0);
        }}
      />

      <div className='input-group-append'>
        <ButtonClearAll className='btn btn-outline-danger' onClick={onClearAll} data-testid={testIdClearButton} disabled={disabled}>
          <i className={`${IconsCatalog.times} fa-sm fa-fw`} />
        </ButtonClearAll>
      </div>
    </div>
  );
}
