import React, { type MouseEvent, useCallback, useState } from 'react';
import { type ClothingModelType } from '../../../../../../../../types/ClothingModelType';
import { useContextMenu } from '../../../../../../../../contexts/ContextMenuContext';
import TableCell from '../TableCell';
import { type ListContentTableHeader } from '../../../../../../../../types/ListContentTable/ListContentTableHeader';
import { type ListContentTableCellContextMenuDataType } from '../../../../../../../../types/ListContentTable/ListContentTableCellContextMenuDataType';
import { type TableCellCoords } from '../../../../../../../../types/ListContentTable/TableCellCoords';
import { type TableCellUpdateType } from '../../../../../../../../types/ListContentTable/TableCellUpdateType';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';

type ListContentTableManagerType = {
  defaultHeaders: ListContentTableHeader;
  importedModels: ClothingModelType[];
  extractedContent: string[][];
  listContentParsingErrors: string[][];
  onChangeTableCell: (updatedCell: TableCellUpdateType) => void;
}

type RenderModelsColumnsType = Pick<ListContentTableManagerType, 'importedModels'>;

const RenderModelsColumns = ({ importedModels }: RenderModelsColumnsType) => {
  return (
    <React.Fragment>
      {
        importedModels.map((_, index) => (
          <th className='text-center' key={index}>{index + 1}°</th>
        ))
      }
    </React.Fragment>
  );
};

export default function ListContentTableManager({ defaultHeaders, importedModels, extractedContent, listContentParsingErrors, onChangeTableCell }: ListContentTableManagerType) {
  const { handleShow } = useContextMenu();
  const { Translate } = useAppTranslation();

  const [tableCellToEdit, setTableCellToEdit] = useState<TableCellCoords | null>(null);

  const isEditingTableCell = useCallback((currentRowIndex: number, currentColIndex: number): boolean => {
    if (tableCellToEdit === null) return false;

    const matchRow = tableCellToEdit.row === currentRowIndex;
    const matchCol = tableCellToEdit.col === currentColIndex;
    return matchRow && matchCol;
  }, [tableCellToEdit]);

  const handleshowContextMenu = useCallback((event: MouseEvent<HTMLTableCellElement>, selectedItem: ListContentTableCellContextMenuDataType) => {
    event.preventDefault();
    handleShow({ x: event.clientX, y: event.clientY }, selectedItem);
  }, [handleShow]);

  const hasError = useCallback((rowIndex: number) => {
    if (!listContentParsingErrors || listContentParsingErrors.length === 0) return;
    return listContentParsingErrors[rowIndex].length > 0;
  }, [listContentParsingErrors]);

  return (
    <React.Fragment>

      <ul>
        <li>
          <small>
            <i>{Translate('description.pasted-list-editing-tip-right-click')}</i>
          </small>
        </li>
        <li>
          <small>
            <i>{Translate('description.pasted-list-editing-tip-double-click')}</i>
          </small>
        </li>
      </ul>

      <table className='table table-sm table-bordered table-hover'>
        <thead>
          <tr>
            <th></th>
            <th>{defaultHeaders.name}</th>

            <th className='text-center'>{defaultHeaders.number}</th>

            <RenderModelsColumns importedModels={importedModels} />

            <th className='text-center'>{defaultHeaders.nickname}</th>

            <th className='text-center'>{defaultHeaders.bloodType}</th>
          </tr>

        </thead>

        <tbody>
          {
            extractedContent.map((row, rowIndex) => (
              <tr key={rowIndex} className={hasError(rowIndex) ? 'bg-danger text-white' : ''}>
                <td className='text-center'>
                  <small style={{ fontSize: '10px' }}>{rowIndex + 1}</small>
                </td>
                {
                  row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      className={colIndex > 0 ? 'text-center' : ''}
                      onContextMenu={(event) => {
                        handleshowContextMenu(event, { row, rowIndex, sourceIndex: colIndex });
                      }}
                    >

                      <TableCell
                        text={cell}
                        textCenter={colIndex > 0}
                        isEditing={isEditingTableCell(rowIndex, colIndex)}

                        onChange={(text) => {
                          if (!tableCellToEdit) return;
                          onChangeTableCell({ coords: tableCellToEdit, updatedText: text });
                        }}

                        onDoubleClick={() => {
                          setTableCellToEdit({ row: rowIndex, col: colIndex });
                        }}

                        disableEditingMode={() => {
                          setTableCellToEdit(null);
                        }}
                      />

                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>

      {
        listContentParsingErrors.length > 0 && (
          <table className='table table-sm table-bordered'>
            <thead>
              <tr>
                <th colSpan={999}>{Translate('labels.errors-found')}</th>
              </tr>
              <tr>
                <th>{Translate('labels.line')}</th>
                <th>{Translate('labels.message')}</th>
              </tr>
            </thead>

            <tbody>
              {
                listContentParsingErrors.map((errorsInRow, rowIndex) => {
                  if (errorsInRow.length === 0) return false;

                  return errorsInRow.map((errorMessage, errorIndex) => (
                    <tr key={errorIndex}>
                      <td>{rowIndex + 1}</td>
                      <td>{errorMessage}</td>
                    </tr>
                  ));
                })
              }
            </tbody>
          </table>
        )
      }
    </React.Fragment>
  );
};
