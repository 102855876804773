import { createContext, useContext, useMemo, useState } from 'react';
import { type FinishingOption } from '../types/ClothingFinishingOptions/FinishingOption';

type ClothingFinishingOptionsProviderProps = {
  children: JSX.Element;
}

type ClothingFinishingOptionsContextType = {
  finishingOptions: FinishingOption[];
  setFinishingOptions: React.Dispatch<React.SetStateAction<FinishingOption[]>>;
}

export const ClothingFinishingOptionsContext = createContext<ClothingFinishingOptionsContextType>({} as ClothingFinishingOptionsContextType);

export const ClothingFinishingOptionsProvider = ({ children }: ClothingFinishingOptionsProviderProps) => {
  const [finishingOptions, setFinishingOptions] = useState<FinishingOption[]>([]);

  const values = useMemo(() => ({
    finishingOptions, setFinishingOptions
  }), [finishingOptions]);

  return (
    <ClothingFinishingOptionsContext.Provider value={values}>
      {children}
    </ClothingFinishingOptionsContext.Provider>
  );
};

export const useClothingFinishingOptions = (): ClothingFinishingOptionsContextType => {
  const context = useContext(ClothingFinishingOptionsContext);

  if (!context) throw new Error('useClothingFinishingOptions must be used within a ClothingFinishingOptionsProvider');

  return context;
};
