import { type AxiosAdapters } from '../../../../../../../../../helpers/adapters/http/AxiosAdapters';

type Insert = {
  height: number;
  width: number;
};

export type InsertInventoryProps = Record<number, Insert>;

export class InsertInventory {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: InsertInventoryProps, sublistId: number): Promise<void> {
    await this.http.post(`/inventory/${sublistId}`, data);
  }
}
