import type SignaturePad from 'react-signature-pad-wrapper';
import { type TFunction } from 'i18next';

const isSignatureValid = async (base64: string): Promise<boolean> => {
  const ACCEPTABLE_BLANK_CANVAS_SPACE_IN_PERCENTAGE = 98;

  const image = new Image();
  image.src = base64;

  return await new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Falha ao obeter o contexto do canvas.'));
        return;
      }

      ctx.drawImage(image, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      let whitePixelCount = 0;
      const totalPixelCount = canvas.width * canvas.height;

      for (let i = 0; i < imageData.length; i += 4) {
        const r = imageData[i];
        const g = imageData[i + 1];
        const b = imageData[i + 2];
        const a = imageData[i + 3];

        if (r === 255 && g === 255 && b === 255 && a === 255) {
          whitePixelCount++;
        }
      }

      const whitePercentage = (whitePixelCount / totalPixelCount) * 100;
      resolve(whitePercentage < ACCEPTABLE_BLANK_CANVAS_SPACE_IN_PERCENTAGE); // Adjust this threshold based on your requirements
    };
  });
};

export const processUserSignatureOnAcceptToProduction = async (signaturePadRef: React.RefObject<SignaturePad>, Translate: TFunction): Promise<string> => {
  if (!signaturePadRef.current) throw new Error(Translate('error.failed-to-handle-digital-signature-canvas'));

  const isEmptySignature = signaturePadRef.current.isEmpty();

  if (isEmptySignature) throw new Error(Translate('error.empty-digital-signature'));

  const signatureImageData = signaturePadRef.current.toDataURL();

  const isValidSignature = await isSignatureValid(signatureImageData);

  if (!isValidSignature) throw new Error(Translate('error.insufficient-signature'));

  return signatureImageData;
};
