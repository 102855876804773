import React, { useCallback, useMemo, useRef, useState } from 'react';
import TextInput from '../../../../../components/Forms/TextInput';
import SplitButton from '../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../contexts/TranslationContext';
import { type ProjectStatusType } from '../../../../../types/ProjectStatusType';
import { useHttpRequest } from '../../../../../contexts/HttpRequestContext';
import { getServerErrorMessageFromResponse } from '../../../../../utils/helper';
import { toast } from 'react-hot-toast-promise';
import { isDuplicatedProjectStatus } from './services/statusValidation';
import addProjectStatus from './api/addProjectStatus';
import editProjectStatus from './api/editProjectStatus';
import { updateCachedStatusList } from './services/projectStatusListService';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationModal';
import deleteProjectStatus from './api/deleteProjectStatus';
import { useProjectsStatuses } from '../../../../../contexts/ProjectStatusContext';
import BasicButton from '../../../../../components/Buttons/BasicButton';
import FloatingColorPicker from './components/FloatingColorPicker';
import Badge from '../../../../../components/Buttons/Badge';

export default function ProjectStatusManager() {
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();
  const { projectStatusList, setProjectStatusList } = useProjectsStatuses();

  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [projectStatus, setProjectStatus] = useState<ProjectStatusType | null>(null);
  const [projectStatusToDelete, setProjectStatusToDelete] = useState<ProjectStatusType | null>(null);

  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const projectStatusInputRef = useRef<HTMLInputElement | null>(null);
  const defaultColor = useMemo(() => '#7159c1', []);

  const handleAddStatus = useCallback(() => {
    try {
      if (!projectStatus) return;
      isDuplicatedProjectStatus({ projectStatus, projectStatusList, Translate });

      const addProjectStatusPromise = addProjectStatus({
        httpConnection,
        statusName: projectStatus.name,
        statusColor: projectStatus.color ?? defaultColor
      });

      setIsRequesting(true);

      toast.promise(addProjectStatusPromise, {
        loading: Translate('progress.loading'),
        success: (statusResponse) => {
          setProjectStatusList([...projectStatusList, statusResponse]);
          setProjectStatus(null);
          setTimeout(() => projectStatusInputRef?.current?.focus(), 50);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      const message = getServerErrorMessageFromResponse(err);
      toast.error(message);
    }
  }, [Translate, defaultColor, httpConnection, projectStatus, projectStatusList, setProjectStatusList]);

  const handleEditStatus = useCallback(() => {
    try {
      if (!projectStatus?.id) return;
      isDuplicatedProjectStatus({ projectStatus, projectStatusList, Translate });

      const editProjectStatusPromise = editProjectStatus({
        httpConnection,
        statusName: projectStatus.name,
        statusColor: projectStatus.color,
        statusId: projectStatus.id
      });

      setIsRequesting(true);

      toast.promise(editProjectStatusPromise, {
        loading: Translate('progress.loading'),
        success: () => {
          const updatedProjectStatusList = updateCachedStatusList({ projectStatus, projectStatusList });
          setProjectStatusList(updatedProjectStatusList);
          setProjectStatus(null);
          return Translate('toast.done');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setIsRequesting(false);
        }
      });
    } catch (err) {
      const message = getServerErrorMessageFromResponse(err);
      toast.error(message);
    }
  }, [Translate, httpConnection, projectStatus, projectStatusList, setProjectStatusList]);

  const handleSubmit = useCallback((event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (!projectStatus?.id) handleAddStatus();
    else handleEditStatus();
  }, [handleAddStatus, handleEditStatus, projectStatus]);

  const prepareUIToEditProjectStatus = useCallback((projectStatus: ProjectStatusType) => {
    setProjectStatus(projectStatus);

    projectStatusInputRef?.current?.focus();

    setTimeout(() => projectStatusInputRef?.current?.select(), 50);
  }, []);

  const handleDeleteProjectStatus = useCallback(() => {
    if (!projectStatusToDelete?.id) return;
    const deleteProjectStatusPromise = deleteProjectStatus({ httpConnection, statusId: projectStatusToDelete.id });

    setIsRequesting(true);

    toast.promise(deleteProjectStatusPromise, {
      loading: Translate('progress.deleting'),
      success: () => {
        const updatedProjectStatusList = projectStatusList.filter(currentProjectStatus => currentProjectStatus.id !== projectStatusToDelete.id);
        setProjectStatusList(updatedProjectStatusList);
        setProjectStatusToDelete(null);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setIsRequesting(false);
      }
    });
  }, [Translate, httpConnection, projectStatusList, projectStatusToDelete?.id, setProjectStatusList]);

  return (
    <React.Fragment>
      <ConfirmationModal
        title={Translate('actions.delete')}
        message={Translate('modal.confirm-delete-project-status')}
        visible={!!projectStatusToDelete}
        style={'danger'}
        handleClose={() => {
          setProjectStatusToDelete(null);
        }}
        handleConfirm={handleDeleteProjectStatus}
        disableButtons={isRequesting}
        disableClickOutsideToClose={isRequesting}
      />

      <div className="row mt-2">
        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4">
          <div className="row">
            <div className="col">
              <form onSubmit={handleSubmit} className={'d-flex align-items-start'}>
                <TextInput
                  inputRef={projectStatusInputRef}
                  id="project-status-name-input"
                  disabled={isRequesting}
                  label={projectStatus?.id ? Translate('actions.edit') : Translate('actions.add')}
                  value={projectStatus?.name ?? ''}
                  onChange={({ target }) => {
                    setProjectStatus({ ...projectStatus!, name: target.value });
                  }}
                />

                <div style={{ position: 'relative', display: 'inline-block' }}>
                  <BasicButton
                    simulateLabelMarginTop
                    marginLeft
                    disabled={isRequesting}
                    color={projectStatus?.color ? projectStatus.color : defaultColor}
                    title={Translate('labels.color')}
                    handleClick={() => {
                      setShowColorPicker(true);
                    }}
                  />

                  {
                    showColorPicker && (
                      <FloatingColorPicker
                        colorCode={projectStatus?.color ?? defaultColor}
                        onChangeColor={({ hex }) => {
                          setProjectStatus({ ...projectStatus!, color: hex });
                        }}
                        onRequestClose={() => {
                          setShowColorPicker(false);
                        }}
                      />
                    )
                  }
                </div>

                <SplitButton
                  disabled={isRequesting || !projectStatus?.name}
                  marginLeft
                  simulateLabelMarginTop
                  title={projectStatus?.id ? Translate('actions.edit') : Translate('actions.add')}
                  icon={projectStatus?.id ? IconsCatalog.check : IconsCatalog.plus}
                  color={projectStatus?.id ? 'success' : 'primary'}
                  handleClick={handleSubmit} />

                {projectStatus?.id && (
                  <SplitButton
                    disabled={isRequesting}
                    marginLeft
                    simulateLabelMarginTop
                    icon={IconsCatalog.times}
                    color="danger"
                    handleClick={() => {
                      setProjectStatus(null);
                    }} />
                )}
              </form>
            </div>
          </div>

          <table className="table table-sm table-bordered table-striped">
            <thead>
            <tr>
              <th>{Translate('labels.title')}</th>
              <th className={'text-center'}>{Translate('labels.color')}</th>
              <th colSpan={2} className="text-center">-</th>
            </tr>
            </thead>

            <tbody>
            {
              projectStatusList.map((projectStatus, index) => (
                <tr key={index}>
                  <td className="align-middle">
                    {projectStatus.name}
                  </td>

                  <td className="align-middle table-column-fit text-center">
                    <Badge color={projectStatus.color} content={projectStatus.color} />
                  </td>

                  <td className="table-column-fit">
                    <SplitButton
                      size="sm"
                      color="primary"
                      icon={IconsCatalog.pen}
                      handleClick={() => {
                        prepareUIToEditProjectStatus(projectStatus);
                      }}
                    />
                  </td>

                  <td className="table-column-fit">
                    <SplitButton
                      size="sm"
                      color="danger"
                      icon={IconsCatalog.trash}
                      handleClick={() => {
                        setProjectStatusToDelete(projectStatus);
                      }}
                    />
                  </td>
                </tr>
              ))
            }

            {
              projectStatusList.length === 0 && (
                <tr className={'text-center'}>
                  <td colSpan={999}>{Translate('status.nothing-to-show-for-now')}</td>
                </tr>
              )
            }
            </tbody>

          </table>
        </div>
      </div>
    </React.Fragment>
  );
}
