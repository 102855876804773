import React from 'react';
import { Container } from './styles';
import { useAppTranslation } from '../../../contexts/TranslationContext';

type StickyMenuBarType = {
  children: React.ReactNode;
  countSelectedItems: number;
};

export default function StickyMenuBar({ children, countSelectedItems }: StickyMenuBarType) {
  const { Translate } = useAppTranslation();

  return (
    <Container>
      <nav className='navbar bg-light d-flex justify-content-end p-2'>
        <span className='badge badge-danger mr-4'>
          {countSelectedItems} {Translate('status.selected-items')}
        </span>
        {children}
      </nav>
    </Container>
  );
}
