import { type ClothingGenderType, type ClothingModelType } from '../../../../../types/ClothingModelType';
import { type ComboBoxDataType } from '../../../../../types/ComboBoxDataType';
import { type ClothingProductionData, type Order } from '../../../../../types/OrderTypes';
import { type SublistType } from '../../../../../types/SublistTypes';
import { type TFunction } from 'i18next';
import { convertMMTimeToSeconds } from '../../../../../components/Forms/TimeInput/services/timeInputService';

export const generateComboBoxOptionsForClothingInput = (model: ClothingModelType, gender: keyof ClothingGenderType): ComboBoxDataType[] => {
  const comboBoxOptions = model.informations[gender].filter(budget => !!budget.size).map<ComboBoxDataType>((budget, index) => ({
    label: budget.size,
    value: index
  }));

  return [{ label: '-', value: -1 }, ...comboBoxOptions];
};

export const generateBloodTypeOptions = (emptyLabel: string): ComboBoxDataType[] => {
  const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
  const options = bloodTypes.map<ComboBoxDataType>(blood => ({ label: blood, value: blood }));

  options.unshift({ label: emptyLabel, value: '' });

  return options;
};

export const generateSublistOptions = (sublists: SublistType[]): ComboBoxDataType[] => {
  return sublists
    .filter(item => !!item.id) // filter sublist that are not yet saved in backend
    .map<ComboBoxDataType>((item, index) => ({ label: item.title, value: index.toString() }));
};

export const generateClothingProductionDataFromModels = (models: ClothingModelType[]): ClothingProductionData[] => {
  return models.map<ClothingProductionData>(currentModel => ({
    modelId: currentModel.id,
    sizeIndex: -1,
    quantity: 0
  }));
};

export const emptySublistData = (title: string): SublistType[] => {
  return [{
    orders: [],
    title,
    details: null,
    sorting: null,
    layout: null,
    layout_details: null,
    token: null,
    exclusive_model_pricing: [],
    use_exclusive_model_pricing: false,
    selected_finishing_options: []
  }];
};

type ChangeGenderAndResetClotheSizesType = {
  order: Order;
  gender: Order['gender'];
}

/**
 * When the user changes the gender after fill some clothing data in the form,
 * the previews selected size will be reseted in UI but not reflected on state.
 * This function reset sizes for all clothes to keep UI in sync with state.
 * @param param0 parameters to generate updated order.
 * @returns Order object to update the state.
 */
export const changeGenderAndResetClotheSizes = ({ order, gender }: ChangeGenderAndResetClotheSizesType): Order => {
  const clothingProductionData = order.clothes.map<ClothingProductionData>(item => ({
    ...item,
    size: '',
    quantity: 0
  }));

  return {
    ...order,
    gender,
    clothes: clothingProductionData
  };
};

type DeleteMultipleOrdersType = {
  sublists: SublistType[];
  deletedOrderIds: number[];
}

export const deleteMultipleOrders = ({ sublists, deletedOrderIds }: DeleteMultipleOrdersType): SublistType[] => {
  return sublists.map(sublist => {
    const filteredOrders = sublist.orders.filter(order => !deletedOrderIds.includes(order.id!));

    return {
      ...sublist,
      orders: filteredOrders
    };
  });
};

export const calculateOrderWeight = (order: Order, importedModels: ClothingModelType[], Translate: TFunction): number => {
  return order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const genderSizeInfos = model.informations[order.gender];
    const targetSizeIndex = currentClothe.sizeIndex;

    if (targetSizeIndex === -1) return accumulator;

    const clothingWeight = genderSizeInfos[targetSizeIndex].weight;
    const totalWeight = currentClothe.quantity * clothingWeight;

    return accumulator + totalWeight;
  }, 0);
};

export const calculateOrderProductionTimeInSeconds = (order: Order, importedModels: ClothingModelType[], Translate: TFunction): number => {
  return order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const genderSizeInfos = model.informations[order.gender];
    const targetSizeIndex = currentClothe.sizeIndex;
    const clothingInformations = genderSizeInfos[targetSizeIndex];

    if (targetSizeIndex === -1) return accumulator;

    const clothingProductionTimeInMinutes = clothingInformations.productionTimeInMinutes ?? '';
    const timeInSeconds = convertMMTimeToSeconds(clothingProductionTimeInMinutes);
    const totalTimeInSeconds = currentClothe.quantity * timeInSeconds;

    return accumulator + totalTimeInSeconds;
  }, 0);
};

export const calculateOrderLengthInMeters = (order: Order, importedModels: ClothingModelType[], Translate: TFunction): number => {
  return order.clothes.reduce((accumulator, currentClothe) => {
    const model = importedModels.find(model => model.id === currentClothe.modelId);
    if (!model) throw new Error(Translate('error.model-needed-not-found'));

    const genderSizeInfos = model.informations[order.gender];
    const targetSizeIndex = currentClothe.sizeIndex;
    const clothingInformations = genderSizeInfos[targetSizeIndex];

    if (targetSizeIndex === -1) return accumulator;

    const clothingLengthInMeters = clothingInformations.lengthInMeters ?? 0;
    const totalLengthInMeters = currentClothe.quantity * clothingLengthInMeters;

    return accumulator + totalLengthInMeters;
  }, 0);
};

type MoveSelectedOrdersToSublistType = {
  sublist_id: number;
  sublists: SublistType[];
  selectedOrders: Order[];
}

export const moveSelectedOrdersToSublist = ({ selectedOrders, sublist_id, sublists }: MoveSelectedOrdersToSublistType): SublistType[] => {
  const updatedOrdersWithNewSublistId = selectedOrders.map<Order>(selectedOrder => ({ ...selectedOrder, sublist_id }));

  const movedOrders = sublists.map<SublistType>(sublist => {
    if (sublist.id === sublist_id) {
      return {
        ...sublist,
        orders: [
          ...sublist.orders,
          ...updatedOrdersWithNewSublistId
        ]
      };
    }

    return sublist;
  });

  /** Remove selected orders from its original sublists */
  const updatedSublists = movedOrders.map<SublistType>(sublist => {
    const updatedOrders = sublist.orders.filter(order => !selectedOrders.includes(order));
    return { ...sublist, orders: updatedOrders };
  });

  return updatedSublists;
};
