import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';
import { type Product } from './interfaces/Product';

export class GetProducts {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(): Promise<Product[]> {
    return await this.http.get('/products');
  }
}
