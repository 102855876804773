import _ from 'lodash';

import { useKanbanWorkspaces } from '../../../../../../contexts/KanbanWorkspacesContext';
import { WrapperSelectTasks } from '../../style';
import { ModalTitle } from '../../../../style';
import { useManagerTask } from '../../useManagerTask';
import { useCallback } from 'react';

export function CreateNewGroupTask() {
  const { kanbanCard } = useKanbanWorkspaces();
  const { taskGroupList, form } = useManagerTask();

  const handleGroupListSelect = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const groupList = taskGroupList.data?.find(group => _.isEqual(group.id, _.toNumber(e.target.value)));
      if (!_.isEmpty(groupList)) {
        form.setValue('taskList', groupList.check_items);
        return;
      }

      form.setValue('taskList', []);
      form.setValue('group_title', '');
      form.setValue('group_list_select', e.target.value);
    },
    [form, taskGroupList.data]
  );

  if (!_.isEmpty(kanbanCard?.check_groups[0]?.check_items)) {
    return null;
  }

  return (
    <>
      <WrapperSelectTasks {...form.register('group_list_select')} onChange={handleGroupListSelect}>
        <option selected value=''>
          Selecionar grupo de tarefas
        </option>

        {taskGroupList.data?.map(group => (
          <option key={group.id} value={group.id}>
            {group.title}
          </option>
        ))}

        <option value='new'>Criar novo grupo</option>
      </WrapperSelectTasks>

      {_.isEqual(form.watch('group_list_select'), 'new') ? (
        <ModalTitle placeholder='Título do grupo' {...form.register('group_title')} />
      ) : null}
    </>
  );
}
