import React, { useMemo } from 'react';
import { IconsCatalog } from '../../IconsCatalog';
import { type BasicButtonType } from '../types/BasicButtonType';

type DropdownMenuItemType = {
  name: string;
  icon?: string;
  handleClick: () => void;
};

type DropdownButtonType = Pick<BasicButtonType, 'size' | 'marginLeft' | 'color'> & {
  id: string;
  label?: string;
  options: DropdownMenuItemType[];
  noMarginBottom?: boolean;
};

export default function DropdownButton({ id, label, size, marginLeft, color, options, noMarginBottom }: DropdownButtonType) {
  const buttonSize = useMemo(() => (size ? `btn-${size}` : ''), [size]);

  const useMarginLeft = useMemo(() => (marginLeft ? 'ml-2' : ''), [marginLeft]);

  const useMarginBottom = useMemo(() => (noMarginBottom ? '' : 'mb-4'), [noMarginBottom]);

  return (
    <React.Fragment>
      <div className={`dropdown ${useMarginBottom}`}>
        <button
          className={`btn btn-${color} ${buttonSize} dropdown-toggle ${useMarginLeft}`}
          type='button'
          id={id}
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <i className={`${IconsCatalog.bars} fa-sm fa-fw mr-2`} /> {label ?? ''}
        </button>
        <div className='dropdown-menu dropdown-menu-right animated--fade-in'>
          {options.map((option, index) => (
            <button className='dropdown-item' key={index} onClick={option.handleClick}>
              {option.icon && <i className={`${option.icon} fa-sm fa-fw mr-2 text-gray-400`} />}
              {option.name}
            </button>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
