import { type BasicTextInputProps } from '../BasicTextInput';

export type TextInputProps = BasicTextInputProps & {
  id: string;
  label: string;
  helpText?: string;
};

export default function TextInput({
  id,
  label,
  value,
  helpText,
  handleBlur,
  onChange,
  tabIndex,
  autofocus,
  disabled = false,
  inputRef,
  minValue,
  maxValue,
  testId,
  type = 'text',
  maxLength,
  placeholder
}: TextInputProps) {
  return (
    <div className='form-group'>
      <label htmlFor={id}>{label}</label>
      <input
        ref={inputRef}
        type={type}
        value={value}
        className='form-control'
        id={id}
        data-testid={testId}
        min={minValue}
        max={maxValue}
        onChange={onChange}
        onBlur={handleBlur}
        tabIndex={tabIndex}
        autoFocus={autofocus}
        autoComplete='off'
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
      />
      {!!helpText && (
        <small id='emailHelp' className='form-text text-muted'>
          {helpText}
        </small>
      )}
    </div>
  );
}
