import { useEffect } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';

import ModalBase from '../../../../../../../../components/Modals/ModalBase';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import { getInventory, getRawMaterial, insertInventory } from './service';
import { generateClothingIcons, generatePathRegisterFile } from '../../../../../../../../helpers/general';

import { Container, WrapperContainer, WrapperLine } from './style';
import { InputsList } from './fragments/InputsList';
import { type InsertInventoryProps } from './service/InsertInventory';
import _ from 'lodash';

type RawSublistMaterialProps = {
  selectedSublist: SublistType;
  handleClose: () => void;
};

const rawUnits = {
  unit: 'Unidade',
  meter: 'Metro',
  liter: 'Litros'
};

export function RawSublistMaterial({ selectedSublist, handleClose }: RawSublistMaterialProps) {
  const methods = useForm();
  const queries = useQueryClient();

  const [rawMaterialData, getInventoryData] = useQueries([
    {
      queryKey: `getRawMaterial-${selectedSublist.id}`,
      queryFn: async () => await getRawMaterial.execute({ sublistId: selectedSublist.id as number }),
      enabled: !!selectedSublist.id,
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true
    },
    {
      queryKey: `getInventory-${selectedSublist.id}`,
      queryFn: async () => await getInventory.execute({ sublistId: selectedSublist.id as number }),
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: true
    }
  ]);

  const rawMaterialMutation = useMutation(
    async (data: InsertInventoryProps) => {
      await insertInventory.execute(data, selectedSublist.id as number);
    },
    {
      onSuccess: () => {
        queries.invalidateQueries(`getRawMaterial-${selectedSublist.id}`);
        queries.invalidateQueries(`getInventory-${selectedSublist.id}`);
      }
    }
  );

  useEffect(() => {
    if (getInventoryData.isSuccess && rawMaterialData.isSuccess) {
      rawMaterialData?.data?.forEach(rawMaterial => {
        rawMaterial.data.forEach(product => {
          const inventory = getInventoryData.data?.find(item => {
            return item?.clothing_model_id === rawMaterial?.model?.id && item?.product_id === product?.id;
          });

          if (!_.isNil(inventory)) {
            methods.setValue(`${rawMaterial.model.id}.${product?.id}`, undefined);
            return;
          }

          if (product?.type_meter === 'meter') {
            methods.setValue(`${rawMaterial.model.id}.${product?.id}.height`, 0);
          }

          methods.setValue(`${rawMaterial.model.id}.${product?.id}.width`, 0);
        });
      });
    }
  }, [getInventoryData.data, getInventoryData.isSuccess, methods, rawMaterialData?.data, rawMaterialData.isSuccess]);

  return (
    <ModalBase
      visible={true}
      hideFooter
      disableClickOutsideToClose
      title={`${selectedSublist.title} - Matéria Prima`}
      style='primary'
      size={'lg'}
      handleClose={handleClose}
      handleConfirm={() => {}}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(data => {
            rawMaterialMutation.mutate(data);
          })}
        >
          <WrapperContainer>
            {rawMaterialData.data?.map(rawMaterial => (
              <div key={rawMaterial.model.id}>
                <div className='d-flex mb-2 align-items-center'>
                  <img
                    src={generateClothingIcons({ filename: rawMaterial.model.icon })}
                    alt={rawMaterial.model.name}
                    style={{ width: 40, height: 40, border: '1px #f1f1f1 solid', borderRadius: 5 }}
                    className='mr-2 p-1'
                  />
                  <b>{rawMaterial.model.name}</b>
                </div>

                <Container className='d-flex flex-column pl-3' style={{ gap: 20, marginLeft: 20 }}>
                  {rawMaterial.data?.map(product => {
                    const maxHeightValue =
                      product?.product_units?.reduce((acc, item) => {
                        return acc + (item?.length ?? 0);
                      }, 0) ?? 0;

                    const inventoryMaxWidth = product?.inventories?.reduce((acc, item) => {
                      return acc + (item?.width ?? 0);
                    }, 0);

                    const inventoryMaxHeight = product?.inventories?.reduce((acc, item) => {
                      return acc + (item?.height ?? 0);
                    }, 0);

                    const sumInventory = maxHeightValue - (inventoryMaxWidth + inventoryMaxHeight);

                    const Unit = () => {
                      if (product?.type_meter === 'meter') {
                        return (
                          <p className='mb-0'>
                            <b className={`${_.isEqual(_.defaultTo(sumInventory, 0), 0) ? 'text-danger' : null}`}>
                              Estoque: {sumInventory ?? 0}
                            </b>
                          </p>
                        );
                      }

                      return (
                        <p className={`mb-0 ${_.isEqual(sumInventory, 0) ? 'text-danger' : null}`}>
                          <b className='mr-2'>Quantidade:</b>
                          {sumInventory} - {rawUnits[product?.type_meter as keyof typeof rawUnits] || rawUnits.unit}
                        </p>
                      );
                    };

                    const productValue = Number(_.defaultTo(_.last(product?.product_units)?.price, 0));

                    return (
                      <WrapperLine key={product?.id}>
                        <img
                          src={generatePathRegisterFile({ filename: product?.photo })}
                          alt={product?.title}
                          style={{ width: 50, height: 50 }}
                          className='mr-2'
                        />

                        <div className='d-flex flex-column' style={{ width: '100%' }}>
                          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                            <div className='d-flex flex-column'>
                              <p className='mb-0'>
                                <b className='mr-2'>Fornecedor:</b>
                              </p>
                              <p className='mb-0'>{product?.entity?.name}</p>
                            </div>

                            <div className='d-flex flex-column'>
                              <p className='mb-0'>
                                <b className='mr-2'>Nome do material:</b>
                              </p>
                              <p className='mb-0'>{product?.title}</p>
                            </div>

                            <div className='d-flex flex-column'>
                              <b className='mr-2'>Preço:</b>
                              <p className='mb-0'>
                                {new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                }).format(productValue ?? 0)}
                              </p>
                            </div>

                            <div className='d-flex flex-column'>
                              <Unit />
                            </div>
                          </div>

                          {_.isEmpty(product?.product_units) ? (
                            <p className='d-flex align-items-center text-danger mt-2 mb-0'>
                              <i className='fas fa-times mr-2' />
                              <b>Matéria prima sem estoque</b>
                            </p>
                          ) : (
                            <InputsList
                              rawMaterialId={rawMaterial?.model?.id}
                              productId={product?.id}
                              productUnits={product?.product_units}
                              typeMeter={product?.type_meter as keyof typeof rawUnits}
                              maxLength={sumInventory}
                            />
                          )}
                        </div>
                      </WrapperLine>
                    );
                  })}
                </Container>
              </div>
            ))}
          </WrapperContainer>

          <div className='d-flex justify-content-end mt-3'>
            <button type='submit' className='btn btn-primary' disabled={rawMaterialMutation.isLoading}>
              {rawMaterialMutation.isLoading ? 'Salvando...' : 'Salvar'}
            </button>
          </div>
        </form>
      </FormProvider>
    </ModalBase>
  );
}
