import React from 'react';
import { AnimatedText } from './styles';

export default function TextAnimation() {
  return (
    <AnimatedText>
      <i className='fas fa-list-alt mr-3' />
      LIST&nbsp;<sup>v3</sup>
    </AnimatedText>
  );
}
