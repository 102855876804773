import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;
  max-width: 480px;
  width: 100%;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const WrapperVotes = styled.div`
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;

  label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    input {
      display: none;

      &:checked + img {
        border: 2px solid #000;
        border-radius: 50%;
        transform: scale(1.1);
      }
    }

    img {
      width: 48px;
      height: 48px;
      transition: 0.3s;
      transform: scale(0.8);

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Options = styled.div`
  display: flex;
  gap: 20px;

  label {
    cursor: pointer;
    display: flex;
    gap: 5px;
  }
`;

export const WrapperNote = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;

  textarea {
    border: none;
    color: #000;
    font-size: 16px;
    height: 100px;
    width: 100%;
    resize: none;
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;

    &::placeholder {
      color: #000;
    }
  }
`;
