import React, { type ChangeEvent, useCallback } from 'react';
import { toast } from 'react-hot-toast-promise';
import { type BasicTextInputProps } from '../BasicTextInput';
import { useAppTranslation } from '../../../contexts/TranslationContext';

type FilePickerType = Pick<BasicTextInputProps, 'inputRef'> & {
  id: string;
  label: string;
  filename: string | undefined;
  acceptedMimeTypes: string[];
  acceptedFileExtensions: string;
  visible?: boolean;
  onPick: (file: File | null) => void;
}

export default function FilePicker({ id, label, filename, acceptedMimeTypes, acceptedFileExtensions, inputRef, onPick, visible = true }: FilePickerType) {
  const { Translate } = useAppTranslation();

  const isValidFileType = useCallback((file: File): boolean => {
    return acceptedMimeTypes.includes(file.type);
  }, [acceptedMimeTypes]);

  const handleFilePicker = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const selectedFile = event.target.files?.[0];

    if (!selectedFile) {
      onPick(null);
      return;
    }

    if (!isValidFileType(selectedFile)) {
      toast.error(Translate('error.invalid-file-type'));
      onPick(null);
      return;
    }

    onPick(selectedFile);
  }, [Translate, isValidFileType, onPick]);

  return (
    <React.Fragment>
      <div className={`form-group ${visible ? 'd-block' : 'd-none'}`}>
        <label>{label}</label>
        <div className="input-group">
          <div className="custom-file">
            <input type="file" className="custom-file-input" id={id} onChange={handleFilePicker} accept={acceptedFileExtensions} ref={inputRef} />
            <label className="custom-file-label" htmlFor={id} data-browse={Translate('actions.select')}>
              {filename ?? Translate('status.none')}
            </label>
          </div>
        </div>
        <small className='form-text text-muted'>{Translate('labels.accepts')}: {acceptedFileExtensions}</small>
      </div>
    </React.Fragment>
  );
};
