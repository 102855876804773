import { type AxiosInstance } from 'axios';
import type {
  OrderClothingReplacementNewRegistryType
} from '../../../../../../../../../types/ClothingReplacementOption/OrderClothingReplacementNewRegistryType';
import {
  type ListClothingProjectOrderReplacementType
} from '../../../../../../../../../types/ClothingReplacementOption/ListClothingProjectOrderReplacementType';

type AddOrderClothingReplacementType = {
  httpConnection: AxiosInstance;
  orderClothingReplacementRegistry: OrderClothingReplacementNewRegistryType;
}

export default async function({ httpConnection, orderClothingReplacementRegistry }: AddOrderClothingReplacementType): Promise<ListClothingProjectOrderReplacementType[][]> {
  const { id, clothing_replacement_option_id, model_id, order_id } = orderClothingReplacementRegistry;

  const postData = {
    id,
    clothing_replacement_option_id,
    model_id,
    order_id,
    notes: orderClothingReplacementRegistry.notes
  };

  return await new Promise<ListClothingProjectOrderReplacementType[][]>((resolve, reject) => {
    httpConnection.put<ListClothingProjectOrderReplacementType[][]>(`list/clothing-projects/order-clothe-replacement/${id}`, postData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}
