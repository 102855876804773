import React, { useCallback, useRef, type MouseEvent } from 'react';
import { useContextMenu } from '../../../contexts/ContextMenuContext';
import { type Order } from '../../../types/OrderTypes';
import { type BootstrapColorStyle } from '../../../types/BootstrapColorStyle';
import { isiPhone } from '../../../utils/helper';
import { type Timeout } from 'react-number-format/types/types';
import { Container } from './styles';
import { getTextColorBasedOnBackgroundColor } from '../../../helpers/colors';

type ColorableRowType = {
  testId?: string;
  children: React.ReactNode;
  color?: string;
  data: object;
  isSelected?: boolean;
  title?: string;
  showBackgroundColor?: boolean;
  marked?: {
    show: boolean;
    color: BootstrapColorStyle;
  };
};

export default function ColorableRow({
  testId,
  children,
  color = '',
  isSelected = false,
  title,
  marked,
  showBackgroundColor,
  data
}: ColorableRowType) {
  const { handleShow } = useContextMenu();
  const timeoutRef = useRef<Timeout | null>(null);

  const handleshowContextMenu = useCallback(
    (event: MouseEvent<HTMLTableRowElement>, order: Order) => {
      event.preventDefault();
      handleShow({ x: event.clientX, y: event.clientY }, order);
    },
    [handleShow]
  );

  /** Selection color has pririty over normal backgound colorr */
  const calculateRowStyle = useCallback((): React.CSSProperties => {
    if (isSelected) return { backgroundColor: 'var(--primary)', color: '#fff' };
    if (showBackgroundColor) return { backgroundColor: color, color: getTextColorBasedOnBackgroundColor(color, { darkTextColor: 'var(--dark)' }) };
    return {};
  }, [color, isSelected, showBackgroundColor]);

  const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    if (isiPhone()) {
      event.preventDefault();
      timeoutRef.current = setTimeout(() => {
        const touch = event.touches[0];
        handleShow({ x: touch.clientX, y: touch.clientY }, data as Order);
      }, 500);
    }
  }, [data, handleShow]);

  const handleTouchEnd = useCallback(() => {
    clearTimeout(timeoutRef.current!);
  }, []);

  return (
    <Container
      data-testid={testId}
      title={title}
      className={marked?.show ? `border-left-${marked.color}` : ''}
      style={calculateRowStyle()}
      onContextMenu={event => {
        handleshowContextMenu(event, data as Order);
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </Container>
  );
}
