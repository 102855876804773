import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../../../../../../../../components/Modals/ModalBase';
import FilePicker from '../../../../../../../../components/Forms/FilePicker';
import SplitButton from '../../../../../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../../../../../components/IconsCatalog';
import { getFilenameFromUrl } from '../../../../../../../../helpers/file';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import { useAppTranslation } from '../../../../../../../../contexts/TranslationContext';
import uploadLayoutDetailsFile
  from './api/uploadLayoutDetailsFile';
import { toast } from 'react-hot-toast-promise';
import { getServerErrorMessageFromResponse } from '../../../../../../../../utils/helper';
import deleteLayoutDetailsFile
  from './api/deleteLayoutDetailsFile';
import { useHttpRequest } from '../../../../../../../../contexts/HttpRequestContext';
import type { SublistLayoutDetailsType } from '../../../../../../../../types/SublistLayoutDetailsType';

type SublistPDFAttachmentModalType = Pick<ModalBaseType, 'handleClose'> & {
  sublist: SublistType;
  onLayoutDetailsAdded: (layoutDetails: SublistLayoutDetailsType) => void;
  onLayoutDetailsRemoved: () => void;
}

export default function SublistPDFAttachmentModal({ sublist, onLayoutDetailsRemoved, onLayoutDetailsAdded, handleClose }: SublistPDFAttachmentModalType) {
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [isRequestingPdfActions, setIsRequestingPdfActions] = useState(false);

  const handleUploadLayoutDetailsFile = useCallback(() => {
    if (!sublist.id || !pdfFile) return;

    const promise = uploadLayoutDetailsFile({
      httpConnection,
      sublistId: sublist.id,
      file: pdfFile
    });

    setIsRequestingPdfActions(true);

    toast.promise(promise, {
      loading: Translate('progress.loading'),
      success: (sublistLayoutDetails) => {
        onLayoutDetailsAdded(sublistLayoutDetails);
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setPdfFile(null);
        setIsRequestingPdfActions(false);
      }
    });
  }, [Translate, httpConnection, onLayoutDetailsAdded, pdfFile, sublist.id]);

  const handleDeleteLayoutDetails = useCallback(() => {
    if (!sublist.id) return;

    const promise = deleteLayoutDetailsFile({
      httpConnection,
      sublistId: sublist.id
    });

    setIsRequestingPdfActions(true);

    toast.promise(promise, {
      loading: Translate('progress.loading'),
      success: () => {
        onLayoutDetailsRemoved();
        return Translate('toast.done');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setPdfFile(null);
        setIsRequestingPdfActions(false);
      }
    });
  }, [Translate, httpConnection, onLayoutDetailsRemoved, sublist.id]);

  return (
    <ModalBase visible hideFooter title={`${Translate('labels.attachment')} - ${sublist.title}`} size={'lg'} style={'primary'} handleClose={handleClose} handleConfirm={() => {}} >
      <React.Fragment>
        {
          !sublist.layout_details && (
            <div className="row">
              <div className="col">
                <FilePicker
                  id='layout-pdf-picker'
                  acceptedFileExtensions={'.pdf'}
                  acceptedMimeTypes={['application/pdf']}
                  label={Translate('description.attach-pdf-to-sublist')}
                  filename={pdfFile?.name ?? ''}
                  onPick={(file) => { setPdfFile(file); }}
                />
              </div>

              <div className="col-auto pl-0">
                <SplitButton
                  simulateLabelMarginTop
                  disabled={!pdfFile || isRequestingPdfActions}
                  color={'success'}
                  icon={IconsCatalog.cloudUpload}
                  handleClick={handleUploadLayoutDetailsFile}
                />
              </div>
            </div>
          )
        }

        {
          !!sublist.layout_details && (
            <React.Fragment>
              <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col-auto">
                  <div className='alert alert-primary m-0'>
                    <i className={`${IconsCatalog.pdf} fa-fw fa-fs`} />
                    <a href={sublist.layout_details.file} target='_blank' rel='noreferrer'>{getFilenameFromUrl(sublist.layout_details.file)}</a>
                  </div>
                </div>
              </div>

              <div className="row mt-3 d-flex justify-content-center align-items-center">
                <div className="col-auto">
                  <SplitButton
                    disabled={!sublist.layout_details || isRequestingPdfActions}
                    title={Translate('actions.delete')}
                    color={'danger'}
                    icon={IconsCatalog.times}
                    handleClick={handleDeleteLayoutDetails}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }
      </React.Fragment>
    </ModalBase>
  );
}
