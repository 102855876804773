import styled from 'styled-components';

export const ButtonClearAll = styled.button`
  border: 1px solid var(--border-color-v1) !important;
  padding: 2px;

  &:hover {
    border: 1px solid var(--danger) !important;
  }
`;
