import { http } from '../../../../../helpers/adapters/http';
import { CreateEntity } from './CreateEntity';
import { DeleteEntity } from './DeleteEntity';
import { UpdateEntity } from './UpdateEntity';
import { GetEntities } from './GetEntities';
import { ShowEntity } from './ShowEntity';

export const getEntities = new GetEntities(http);
export const showEntity = new ShowEntity(http);
export const createEntity = new CreateEntity(http);
export const updateEntity = new UpdateEntity(http);
export const deleteEntity = new DeleteEntity(http);
