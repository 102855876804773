import styled from 'styled-components';

export const Container = styled.div`
  & > ul > li {
    padding: 8px 0px;

    &:hover {
      background-color: var(--light);
    }
  }
`;
