import { Content } from './fragments/Content';
import { Header } from '../components/Header';
import { WrapperContainer } from './style';

export function Workspace() {
  return (
    <WrapperContainer>
      <Header showForm />
      <Content />
    </WrapperContainer>
  );
}
