import { type AxiosInstance } from 'axios';
import { type PermissionItem } from '../../../../../../../types/EmployeePermissionType';

type DetatchPermissionFromUser = {
  httpConnection: AxiosInstance;
  userId: number;
  permissionId: number;
}

export default async function async ({ httpConnection, userId, permissionId }: DetatchPermissionFromUser): Promise<PermissionItem[]> {
  const postData = {
    user_id: userId,
    permission_id: permissionId
  };

  return await new Promise<PermissionItem[]>((resolve, reject) => {
    httpConnection.post<PermissionItem[]>('users/permissions/revoke', postData)
      .then((response) => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
