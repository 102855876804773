import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

type SidebarCollapseContextType = {
  isCollapsed: boolean;
  showSidebar: () => void;
  hideSidebar: () => void;
};

type SidebarCollapseProviderType = {
  children: JSX.Element;
};

const SidebarCollapseContext = createContext<SidebarCollapseContextType>({} as SidebarCollapseContextType);

export const SidebarCollapseProvider = ({ children }: SidebarCollapseProviderType) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const showSidebar = useCallback(() => {
    setIsCollapsed(false);
  }, []);

  const hideSidebar = useCallback(() => {
    setIsCollapsed(true);
  }, []);

  const handleToggleSidebar = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && event.key.toUpperCase() === 'M') {
        setIsCollapsed(!isCollapsed);
      }
    },
    [isCollapsed]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleToggleSidebar);

    return () => {
      document.removeEventListener('keydown', handleToggleSidebar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed]);

  const contextValues = useMemo(
    () => ({
      showSidebar,
      hideSidebar,
      isCollapsed
    }),
    [showSidebar, hideSidebar, isCollapsed]
  );

  return <SidebarCollapseContext.Provider value={contextValues}>{children}</SidebarCollapseContext.Provider>;
};

export const useSidebarCollapse = (): SidebarCollapseContextType => {
  const context = useContext(SidebarCollapseContext);

  if (!context) throw new Error('useSidebarCollapse should be used within a SidebarCollapseProvider');

  return context;
};
