import { Container, Content, Subtitle, WrapperContent } from './style';

type TitleProps = {
  title: string;
  subtitle: string;
  right?: React.ReactNode;
};

export function Title({ title, subtitle, right }: TitleProps) {
  return (
    <Container>
      <WrapperContent>
        <Content>{title}</Content>
        <Subtitle>{subtitle}</Subtitle>
      </WrapperContent>

      {right}
    </Container>
  );
}
