import styled from 'styled-components';

export const ProfileRoundedContainer = styled.div`
  width: 32px;
  height: 32px;
  background-color: var(--primary);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--white);
`;
