import _ from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { getEntities } from '../../../../Financial/Entities/api';
import ModalBase from '../../../../../../components/Modals/ModalBase';
import { WrapperSelect, Select, Option } from '../../../../../../components/CardModal/fragments/CreateTasks/style';
import { type Entity } from '../../../../../../types/Entity';

type ClientsSelectProps = {
  selectedClient: Entity | null;
  setSelectedClient: React.Dispatch<React.SetStateAction<Entity | null>>;
};

export function ClientsSelect({ selectedClient, setSelectedClient }: ClientsSelectProps) {
  const entities = useQuery<Entity[]>(['entities'], async () => await getEntities.execute({ type: 'customer' }));
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState('');

  if (_.isEmpty(entities.data)) {
    return null;
  }

  return (
    <>
      <ModalBase
        title='Selecione um cliente'
        handleClose={() => {
          setShowModal(false);
        }}
        handleConfirm={() => {
          setShowModal(false);
        }}
        visible={showModal}
        style={'primary'}
        size={'lg'}
        disableClickOutsideToClose
      >
        <>
          <input
            type='text'
            className='form-control mb-3'
            placeholder='Pesquisar cliente'
            value={filter}
            onChange={e => {
              setFilter(e.target.value);
            }}
          />

          <div className='d-flex flex-column' style={{ gap: 10, maxHeight: 400, overflowY: 'auto' }}>
            {entities?.data
              ?.filter(entity => entity.name.toLowerCase().includes(filter))
              .map(entity => (
                <WrapperSelect
                  active={selectedClient?.id === entity.id}
                  key={entity.id}
                  onClick={() => {
                    setSelectedClient(prev => {
                      if (prev?.id === entity.id) {
                        return null;
                      }

                      return entity;
                    });
                  }}
                >
                  <Select active={selectedClient?.id === entity.id}>
                    <i className='fa fa-check' />
                  </Select>

                  <Option>{entity.name}</Option>
                </WrapperSelect>
              ))}
          </div>
        </>
      </ModalBase>

      <button
        className='btn btn-primary mb-3 ml-2'
        type='button'
        onClick={() => {
          setShowModal(true);
        }}
      >
        <i className='fas fa-plus' />
      </button>
    </>
  );
}
