import styled, { css } from 'styled-components';

type ContainerProps = {
  isExpanded: boolean;
  height?: number;
};

export const Container = styled.div<ContainerProps>`
  background-color: var(--background-card);
  padding: 10px;
  height: 100vh;
  width: 100vw;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-height: 80%;
  max-width: 90%;
  border-radius: 8px;
  gap: 20px;
  display: grid;

  ${({ height }) => height && `grid-template-rows: 50px ${height - 160}px 50px;`}

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          max-height: 100%;
          max-width: 100%;
          border-radius: 0;
        `
      : null}

  .react-pdf__Document {
    height: 100%;
    overflow-y: auto;
    width: 100%;
  }
`;
