import React, { useCallback, useState } from 'react';
import BasicCard from '../../../../components/Cards/BasicCard';
import CatalogSquareBlock from '../../../../components/CatalogSquareBlock';
import CircleButton from '../../../../components/Buttons/CircleButton';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import IconCreationModal from '../../../../components/Modals/IconCreationModal';
import { useClothingIcons } from '../../../../contexts/ClothingIconsContext';
import GridContainer from '../../../../components/GridContainer';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import { type ClothingIcon } from '../../../../types/ClothingIconType';
import Spinner from '../../../../components/Spinner';
import QuotaStatusBar from '../../../../components/QuotaStatusBar';
import { useUser } from '../../../../contexts/UserContext';
import { toast } from 'react-hot-toast-promise';
import { type ClothingIconDeleteReportType } from '../../../../types/ClothingIconDeleteReportType';
import BulkDeleteMiniReportModal from '../../../../components/Modals/BulkDeleteMiniReportModal';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../../../utils/helper';

export default function IconCatalog() {
  const [isShowingIconCreationModal, setIsShowingIconCreationModal] = useState(false);
  const [isShowingConfirmationDeleteIcon, setIsShowingConfirmationDeleteIcon] = useState(false);
  const [iconToDelete, setIconToDelete] = useState<ClothingIcon | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteReport, setDeleteReport] = useState<ClothingIconDeleteReportType | null>(null);

  const { user, isCompanyAccount, isEmployeeAccount } = useUser();
  const { clothingIcons, addIcon, deleteIcon } = useClothingIcons();
  const { Translate } = useAppTranslation();

  const openIconCreationModal = useCallback(() => {
    setIsShowingIconCreationModal(true);
  }, []);

  const closeIconCreationModal = useCallback(() => {
    setIsShowingIconCreationModal(false);
  }, []);

  const handlePrepareIconToDelete = useCallback((icon: ClothingIcon) => {
    setIconToDelete(icon);
    setIsShowingConfirmationDeleteIcon(true);
  }, []);

  const handleCancelIconDeletion = useCallback(() => {
    setIconToDelete(null);
    setIsShowingConfirmationDeleteIcon(false);
  }, []);

  const handleDeleteIcon = useCallback(async () => {
    try {
      if (iconToDelete) {
        setIsDeleting(true);
        setIsShowingConfirmationDeleteIcon(false);

        const response = await deleteIcon(iconToDelete.id);
        toast.success(response.message);
      }
    } catch (err) {
      if ('model_names' in (err as any)) {
        setDeleteReport(err as ClothingIconDeleteReportType);
        return;
      }

      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIconToDelete(null);
      setIsDeleting(false);
    }
  }, [deleteIcon, iconToDelete]);

  if (!user) return <></>;

  return (
    <React.Fragment>
      <IconCreationModal
        title={Translate('modal.new-icon')}
        message={Translate('description.pick-image-to-create-icon')}
        style='primary'
        visible={isShowingIconCreationModal}
        handleClose={closeIconCreationModal}
        handleConfirm={async image => {
          await addIcon(image);
        }}
      />

      <ConfirmationModal
        visible={isShowingConfirmationDeleteIcon}
        title={Translate('modal.remove-icon-confirm')}
        message={Translate('description.confirm-delete-icon')}
        style='danger'
        handleClose={handleCancelIconDeletion}
        handleConfirm={handleDeleteIcon}
      />

      {!!deleteReport && (
        <BulkDeleteMiniReportModal
          message={Translate('error.cant-delete-icon-in-use')}
          handleClose={() => {
            setDeleteReport(null);
          }}
          report={deleteReport}
          reportSubItemsLabel={Translate('labels.models-using-icon')}
          reportSubItemsKey='model_names'
        />
      )}

      <h1 className='h3 mb-2 text-gray-800'>{Translate('labels.icons-gallery')}</h1>
      <p className='mb-4'>{Translate('description.icon-gallery-header-description')}</p>

      <BasicCard title={Translate('labels.my-gallery')}>
        {!isEmployeeAccount && (
          <QuotaStatusBar
            value={clothingIcons.length}
            maxValue={user.clothing_icons_max_count}
            message={Translate('status.icons-quota-message')}
          />
        )}

        <GridContainer>
          {clothingIcons.map((icon, index) =>
            iconToDelete?.id === icon.id && isDeleting ? (
              <Spinner key={index} />
            ) : (
              <CatalogSquareBlock
                key={index}
                imagePath={icon.url}
                deleteButtonDisabled={!isCompanyAccount}
                handleDelete={() => {
                  handlePrepareIconToDelete(icon);
                }}
              />
            )
          )}

          {clothingIcons.length < user.clothing_icons_max_count && isCompanyAccount && (
            <CircleButton icon={IconsCatalog.plus} handleClick={openIconCreationModal} color='primary' />
          )}
        </GridContainer>
      </BasicCard>
    </React.Fragment>
  );
}
