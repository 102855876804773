import { useEffect, useMemo } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';

import ModalBase from '../../../../../../../../components/Modals/ModalBase';
import { showEntity } from '../../../../../../Financial/Entities/api';
import { generateClothingIcons } from '../../../../../../../../helpers/general';

import { Container, InputFormControl, WrapperContent, WrapperInput, WrapperItems } from './styles';
import IntlCurrencyInput from 'react-intl-currency-input';
import { type SublistType } from '../../../../../../../../types/SublistTypes';
import { saveSeamstress } from './api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { type EntityResponse } from '../../../../../../Financial/Entities/api/ShowEntity';

type SeamstressModalProps = {
  selectedSublist: SublistType;
  handleClose: () => void;
};

export function SeamstressModal({ selectedSublist, handleClose }: SeamstressModalProps) {
  const methods = useForm();
  const queryClient = new QueryClient();
  const values = methods.watch();

  const seamstressShowData = useQuery(
    ['showSeamstress', selectedSublist?.id],
    async () => {
      return await showEntity.execute({ id: selectedSublist?.id });
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false
    }
  );

  const saveSeamstressMutation = useMutation(
    async (data: any) => {
      await saveSeamstress.execute(data);
    },
    {
      onSuccess: () => {
        toast.success('Costureiras salvas com sucesso');
        queryClient.invalidateQueries(['showSeamstress', selectedSublist?.id]);
        handleClose();
        window.location.reload();
      }
    }
  );

  const seamstressData = useMemo(() => {
    return seamstressShowData.data ? Object.keys(seamstressShowData.data) : [];
  }, [seamstressShowData.data]);

  const quantity = useMemo(() => {
    return selectedSublist?.orders?.reduce((acc, order) => {
      return acc + order.clothes.reduce((acc, clothes) => acc + clothes.quantity, 0);
    }, 0);
  }, [selectedSublist?.orders]);

  const totalQuantity = useMemo(() => {
    return Object.values(values).reduce((acc, value) => {
      return acc + Number(value?.quantity);
    }, 0);
  }, [values]);

  const totalQuantityGreaterThanQuantity = useMemo(() => {
    return totalQuantity > quantity;
  }, [totalQuantity, quantity]);

  const formContent = useMemo(() => {
    return (
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(data => {
            saveSeamstressMutation.mutate({ sublist_id: selectedSublist?.id, data });
          })}
        >
          <h6 className='mb-3 d-flex justify-content-between'>
            <span>
              Quantidade total de peças: <b>{quantity}</b>
            </span>{' '}
            <span>
              Faltam <b>{totalQuantityGreaterThanQuantity ? 0 : _.defaultTo(quantity - totalQuantity, 0)}</b> peças
            </span>
          </h6>

          <Container>
            {seamstressData?.map(seamstressKey => {
              const seamstress = (
                seamstressShowData.data ? (seamstressShowData.data as Record<string, any>)[seamstressKey] : []
              ) as EntityResponse[];

              const seamstressEntityFirst = _.first(seamstress) as EntityResponse;

              return (
                <div key={seamstressKey}>
                  <h5>{seamstressEntityFirst?.entity?.name}</h5>

                  {seamstress?.map(faction => {
                    return (
                      <WrapperItems key={faction.id}>
                        <img
                          src={generateClothingIcons({ filename: faction?.list_clothing_model?.icon?.image_name })}
                          alt=''
                        />

                        <div>
                          <h6>
                            <b>{faction?.list_clothing_model?.name}</b>
                          </h6>
                          <p>
                            Valor padrão:{' '}
                            {new Intl.NumberFormat('pt-Br', {
                              currency: 'BRL',
                              style: 'currency'
                            }).format(Number(faction.price))}
                          </p>
                        </div>

                        <WrapperContent>
                          <div>
                            <label htmlFor='price'>Preço</label>
                            <WrapperInput className={'form-control'}>
                              <IntlCurrencyInput
                                currency='BRL'
                                config={{
                                  locale: 'pt-BR',
                                  formats: {
                                    number: {
                                      BRL: {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                      }
                                    }
                                  }
                                }}
                                defaultValue={Number(faction.price)}
                                value={Number(methods.getValues(`${faction.id}.price`))}
                                onChange={(_, value) => {
                                  methods.setValue(`${faction.id}.price`, Number(value));
                                }}
                                max={Number.MAX_SAFE_INTEGER}
                              />
                            </WrapperInput>
                          </div>

                          <div>
                            <label htmlFor={'qtd'}>Quantidade</label>
                            <InputFormControl
                              className={`form-control ${totalQuantityGreaterThanQuantity ? 'is-invalid' : ''}`}
                              type='number'
                              id='qtd'
                              min={0}
                              defaultValue={0}
                              max={quantity}
                              {...methods.register(`${faction.id}.quantity`)}
                              onChange={event => {
                                const value = Number(event.target.value);

                                if (quantity <= totalQuantity) {
                                  methods.setValue(`${faction.id}.quantity`, totalQuantity - quantity);
                                  return;
                                }

                                if (value > quantity) {
                                  methods.setValue(`${faction.id}.quantity`, 0);
                                  return;
                                }

                                methods.setValue(`${faction.id}.quantity`, value);
                              }}
                            />
                          </div>
                        </WrapperContent>
                      </WrapperItems>
                    );
                  })}
                </div>
              );
            })}
          </Container>

          <div className='d-flex justify-content-end align-items-center mt-3'>
            <button type='submit' className={'btn btn-primary'} disabled={totalQuantityGreaterThanQuantity}>
              Salvar
            </button>
          </div>
        </form>
      </FormProvider>
    );
  }, [
    methods,
    quantity,
    saveSeamstressMutation,
    seamstressData,
    seamstressShowData.data,
    selectedSublist?.id,
    totalQuantity,
    totalQuantityGreaterThanQuantity
  ]);

  useEffect(() => {
    seamstressData?.forEach(seamstressKey => {
      const seamstress = (
        seamstressShowData.data ? (seamstressShowData.data as Record<string, any>)[seamstressKey] : []
      ) as EntityResponse[];

      seamstress?.forEach(faction => {
        if (faction.list_clothing_project_sublist_and_factions) {
          const price = _.isEqual(_.toNumber(faction.list_clothing_project_sublist_and_factions.price), 0)
            ? faction.price
            : faction.list_clothing_project_sublist_and_factions.price;

          methods.setValue(`${faction.id}`, {
            price,
            quantity: _.defaultTo(faction.list_clothing_project_sublist_and_factions.quantity, 0)
          });
        }
      });
    });
  }, [methods, seamstressData, seamstressShowData.data]);

  return (
    <ModalBase
      visible={true}
      hideFooter
      disableClickOutsideToClose
      title='Costureiras'
      style='primary'
      size={'lg'}
      handleClose={handleClose}
      handleConfirm={() => {}}
    >
      {_.isEmpty(seamstressShowData.data) ? <p>Nenhuma costureira cadastrada</p> : formContent}
    </ModalBase>
  );
}
