import { http } from '../../../../helpers/adapters/http';
import { AddImageCard } from './addImageCard';
import { DeleteCard } from './DeleteCard';
import { ListColumnsWorkspace } from './listColumns';
import { UpdateCard } from './updateCard';
import { UpdateColumnBackgroundColor } from './UpdateColumnBackgroundColor';

export const listColumnsWorkspace = new ListColumnsWorkspace(http);
export const updateCard = new UpdateCard(http);
export const addImageCard = new AddImageCard(http);
export const deleteCard = new DeleteCard(http);
export const updateColumnBackgroundColor = new UpdateColumnBackgroundColor(http);
