import { Container } from './style';
import { CreateNewGroupTask } from './fragments/CreateNewGroupTask';
import { TasksLists } from './fragments/TasksLists';
import { CreateTitleTask } from './fragments/CreateTitleTask';
import { FooterButtons } from './fragments/FooterButtons';

export type task = {
  id?: number;
  title: string;
  checked: boolean;
};

export function CreateTasks() {
  return (
    <Container>
      <h5 className='mt-3 mb-0 h6'>etapas de produção</h5>
      <CreateNewGroupTask />
      <CreateTitleTask />
      <TasksLists />
      <FooterButtons />
    </Container>
  );
}
