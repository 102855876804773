import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    color: var(--dark);
  }
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  padding: 10px 0;

  & > div:nth-child(2n - 1) {
    background-color: var(--light-v2);
  }
`;

export const QtdComments = styled.span`
  font-size: 22px;
`;

type ContentProps = { backgroundColor: string };

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 4px;
  position: relative;

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  p {
    margin-bottom: 0;
  }
`;

export const WrapperAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--white);
  border: 1px solid var(--light-v2);
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 14px;
    color: var(--dark);
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const Author = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: var(--dark);
`;

export const WrapperForm = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  height: 46px;
  width: 46px;
  background-color: var(--success);

  &:hover {
    background-color: var(--teal);
  }

  i {
    color: #fff;
    font-size: 14px;
  }

  &:disabled {
    background-color: var(--dark);
    cursor: not-allowed;
  }
`;

export const WithoutComments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f0f0f0;
  font-size: 14px;
  background-color: #333;
  height: 120px;
  border-radius: 4px;
  margin-bottom: 20px;

  p {
    margin: 0;
    color: var(--dark);
  }
`;
