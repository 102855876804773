import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Input, Select } from '../../../../style';
import { SendButton, WrapperForm } from '../../style';
import { useComments } from '../../hooks/useComments';
import _ from 'lodash';

const schema = yup.string().trim().required('Um comentário é obrigatório');

export function AddComment() {
  const { createCommentMutation } = useComments();
  const [comment, setComment] = useState('');
  const [priority, setPriority] = useState(0);

  const dontSendMessage = useMemo(() => {
    try {
      schema.validateSync(comment);
      return false;
    } catch {
      return true;
    }
  }, [comment]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      createCommentMutation.mutate({ comment, priority });
      setComment('');
      setPriority(0);
    },
    [comment, createCommentMutation, priority]
  );

  const handleEnterKey = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !dontSendMessage) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [dontSendMessage, handleSubmit]
  );

  return (
    <WrapperForm>
      <Select
        width={200}
        value={priority}
        onChange={e => {
          setPriority(_.toNumber(e.target.value));
        }}
      >
        <option selected disabled>
          Selecione a prioridade
        </option>
        <option value={0}>Baixa</option>
        <option value={1}>Média</option>
        <option value={2}>Alta</option>
      </Select>

      <Input
        placeholder={createCommentMutation.isLoading ? 'Adicionando o comentário' : 'Adicione um comentário'}
        value={comment}
        onChange={e => {
          setComment(e.target.value);
        }}
        onKeyDown={handleEnterKey}
      />

      <SendButton type='submit' disabled={dontSendMessage} onClick={handleSubmit}>
        <i className={createCommentMutation.isLoading ? 'fa fa-spinner fa-spin' : 'fas fa-paper-plane'}></i>
      </SendButton>
    </WrapperForm>
  );
}
