import styled from 'styled-components';

type ContainerType = {
  isSynching: boolean;
}

export const Container = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'isSynching' }) <ContainerType>`
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 888;
  height: 8px;
  background-color: ${props => props.isSynching ? 'var(--danger)' : 'var(--success)'};
  animation: ${props => props.isSynching ? 'slowBlink .7s infinite' : 'none'};

  @keyframes slowBlink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`;
