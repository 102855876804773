import styled from 'styled-components';

export const Container = styled.div`
  flex: 0 0 320px;
  padding: 10px;
  border: none !important;
  background-color: ${({ color }) => color};
`;

export const ListTitle = styled.h5`
  margin: 0;
  padding: 10px 15px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 5px 5px 0px 0px;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardBody = styled.div`
  flex: 1;
  overflow-y: auto;
  border-radius: 16px;
`;

export const ListCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListCardHeaderIcon = styled.i`
  display: none;
`;

export const ListCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 10px;
`;

export const Title = styled.div`
  outline: none;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: var(--black);

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

export const CountCard = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
  min-width: 25px;
  font-size: 12px;
  color: #fff;
  border-radius: 50%;
  background-color: var(--primary);
`;

export const BlurredOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
