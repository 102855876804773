import React, { useCallback, useMemo } from 'react';
import { type ProjectType } from '../../../../../../../types/ProjectType';
import { getBadgeStatusColorForProject, getBadgeStatusTextForProject } from './projectSharedLinkStatusService';
import { useAppTranslation } from '../../../../../../../contexts/TranslationContext';
import { copyToClipboard } from '../../../../../../../utils/helper';
import toast from 'react-hot-toast-promise';
import { generateFinalClientEditorUrl } from './services/sharedLinkInfoService';

type ProjectSharedLinkInfoType = {
  project: ProjectType;
};

type SharedLinkBadgeStatus = {
  color: string;
  label: string;
};

export default function ProjectSharedLinkInfo({ project }: ProjectSharedLinkInfoType) {
  const { Translate } = useAppTranslation();

  const projectSharedLinkUrl = useMemo(() => {
    return generateFinalClientEditorUrl(project.id!, project.token!);
  }, [project]);

  const sharedLinkBadgeStatus = useMemo<SharedLinkBadgeStatus>(() => {
    const badgeColor = getBadgeStatusColorForProject(project);
    const badgeText = getBadgeStatusTextForProject(project, Translate);

    return {
      color: badgeColor,
      label: badgeText
    };
  }, [Translate, project]);

  const handleCopyLinkToClipboard = useCallback(async () => {
    await copyToClipboard(projectSharedLinkUrl);
    toast.success(Translate('toast.link-copied'));
  }, [Translate, projectSharedLinkUrl]);

  if (!project.token) return <></>;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-auto">
          <span className='d-inline-block text-primary' role='button' onClick={handleCopyLinkToClipboard}>
            <small>{projectSharedLinkUrl}</small>
          </span>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-auto">
          <span className={`badge badge-${sharedLinkBadgeStatus.color}`}>
            <small>{sharedLinkBadgeStatus.label}</small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}
