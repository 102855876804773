import { type AxiosAdapters } from '../../../../../../../../../helpers/adapters/http/AxiosAdapters';

type Params = {
  sublistId: number;
};

type GetInventoryResponse = {
  id: number;
  clothing_model_id: number;
  product_id: number;
  width: number;
  height?: number;
  created_at: string;
  updated_at: string;
};

export class GetInventory {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ sublistId }: Params): Promise<GetInventoryResponse[]> {
    return await this.http.get(`/inventory/${sublistId}`);
  }
}
