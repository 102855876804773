import { useCallback, useEffect, useMemo } from 'react';
import { Container } from './style';
import _ from 'lodash';
import IntlCurrencyInput from 'react-intl-currency-input';
import { useFormContext } from 'react-hook-form';
import { WrapperInput } from '../styles';

type InventoryProps = {
  isInventory: any;
  handleInventory: (rawMaterial: any) => void;
};

export function Inventory({ isInventory, handleInventory }: InventoryProps) {
  const methods = useFormContext();
  const inventoryKey = `products.${isInventory?.index}`;
  const typeMeter = methods.watch(`${inventoryKey}.type_meter`);

  const productInventory = useMemo(() => {
    return methods.watch(`${inventoryKey}.product_units`) || [];
  }, [inventoryKey, methods]);

  const leftButton = useMemo(() => {
    return (
      <div>
        <button
          className='btn'
          onClick={() => {
            handleInventory(null);
          }}
        >
          <i className='fas fa-arrow-left' />
        </button>

        <b>
          <span className='text-small'>{_.isEmpty(isInventory?.title) ? 'Voltar' : isInventory.title}</span>
        </b>
      </div>
    );
  }, [handleInventory, isInventory.title]);

  const typeMeterOptions = useCallback(
    (index: number, id?: number) => {
      if (typeMeter === 'liter') {
        return (
          <div className='col'>
            <div className='form-group'>
              <label>Volume</label>
              <input
                className='form-control'
                {...methods.register(`${inventoryKey}.product_units[${index}].width`)}
                min={0}
                disabled={!_.isNil(id)}
              />
            </div>
          </div>
        );
      }

      if (typeMeter === 'meter') {
        return (
          <>
            <div className='col'>
              <div className='form-group'>
                <label>Largura</label>
                <input
                  className='form-control'
                  {...methods.register(`${inventoryKey}.product_units[${index}].width`)}
                  min={0}
                  defaultValue={0}
                  placeholder='0'
                  disabled={!_.isNil(id)}
                />
              </div>
            </div>

            <div className='col'>
              <div className='form-group'>
                <label>Estoque</label>
                <input
                  className='form-control'
                  {...methods.register(`${inventoryKey}.product_units[${index}].length`)}
                  min={0}
                  defaultValue={0}
                  placeholder='0'
                  disabled={!_.isNil(id)}
                />
              </div>
            </div>
          </>
        );
      }

      return (
        <div className='col'>
          <div className='form-group'>
            <label>Quantidade</label>
            <input
              type='number'
              className='form-control'
              min={0}
              disabled={!_.isNil(id)}
              {...methods.register(`${inventoryKey}.product_units[${index}].width`)}
            />
          </div>
        </div>
      );
    },
    [inventoryKey, methods, typeMeter]
  );

  const meterTypeLabel = useMemo(() => {
    const types = {
      meter: 'Preço por metro',
      liter: 'Preço por litro',
      unit: 'Preço por unidade'
    };

    const typeMeterValue = methods.watch(`${inventoryKey}.type_meter`) as keyof typeof types;
    return types[typeMeterValue] || types.unit;
  }, [inventoryKey, methods]);

  useEffect(() => {
    const inventory = methods.getValues(`${inventoryKey}.product_units`);
    const inventoryUnits = [{}];

    if (_.isEmpty(productInventory)) {
      console.log('inventory', inventory);

      methods.setValue(`${inventoryKey}.product_units`, inventoryUnits);
    }

    return () => {
      if (_.isEqual(inventory, inventoryUnits)) {
        methods.setValue(`${inventoryKey}.product_units`, []);
      }
    };
  }, [methods, inventoryKey, productInventory]);

  return (
    <Container>
      {leftButton}

      <div className='mt-3'>
        {productInventory.map((product: any, index: number) => {
          return (
            <div className='row' key={index}>
              <div className='col'>
                <div className='form-group'>
                  <label>Tipo</label>
                  <select
                    className='form-control'
                    {...methods.register(`${inventoryKey}.type_meter`)}
                    disabled={!_.isEmpty(isInventory.product_units)}
                  >
                    <option value=''>Selecione</option>
                    <option value='unit'>Unidade</option>
                    <option value='liter'>Litro</option>
                    <option value='meter'>Metros</option>
                  </select>
                </div>
              </div>

              {typeMeterOptions(index, product?.id)}

              <div className='col d-flex align-items-center'>
                <div className='form-group'>
                  <label>{meterTypeLabel}</label>

                  <WrapperInput className='form-control'>
                    <IntlCurrencyInput
                      currency='BRL'
                      config={{
                        locale: 'pt-BR',
                        formats: {
                          number: {
                            BRL: {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          }
                        }
                      }}
                      value={Number(methods.getValues(`${inventoryKey}.product_units[${index}].price`))}
                      onChange={(event, value) => {
                        if (product?.id) {
                          methods.setValue(`${inventoryKey}.product_units[${index}].price`, Number(product?.price));
                          return;
                        }
                        methods.setValue(`${inventoryKey}.product_units[${index}].price`, Number(value));
                      }}
                      max={!_.isEmpty(product?.price) ? Number(product?.price) : Number.MAX_SAFE_INTEGER}
                      defaultValue={0}
                    />
                  </WrapperInput>
                </div>

                {_.isNil(product?.id) && index !== 0 ? (
                  <button
                    type='button'
                    className='btn btn-circle btn-danger mt-3 ml-4'
                    onClick={() => {
                      const inventory = methods.getValues(`${inventoryKey}.product_units`);
                      methods.setValue(
                        `${inventoryKey}.product_units`,
                        inventory.filter((_: any, i: number) => i !== index)
                      );
                    }}
                  >
                    <i className='fas fa-trash'></i>
                  </button>
                ) : null}

                {index === 0 ? (
                  <button
                    type='button'
                    className='btn btn-circle btn-success mt-3 ml-4'
                    onClick={() => {
                      const inventory = methods.getValues(`${inventoryKey}.product_units`);
                      const inventoryUnits = [{ price: 0, width: 0, length: 0 }];

                      methods.setValue(`${inventoryKey}.product_units`, [...inventoryUnits, ...inventory]);
                    }}
                  >
                    <i className='fas fa-plus'></i>
                  </button>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
