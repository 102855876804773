import { type AxiosInstance } from 'axios';
import { type Order } from '../../../../types/OrderTypes';

type EditOrderFromSublistParams = {
  projectId: number;
  order: Order;
  httpConnection: AxiosInstance;
  finalClientAccessToken?: string;
}

export const editOrderFromSublist = async ({ httpConnection, projectId, order, finalClientAccessToken }: EditOrderFromSublistParams): Promise<Order> => {
  return await new Promise<Order>((resolve, reject) => {
    const url = !finalClientAccessToken
      ? `list/clothing-projects/sublist/orders/${order.id}?project_id=${projectId}`
      : `/list/clothing-projects/final-client/orders/${order.id}?project_id=${projectId}&access_token=${finalClientAccessToken}`;

    httpConnection.put<Order>(url, order)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
