import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type ClothingPresetType from '../types/ClothingPresetType';
import { useHttpRequest } from './HttpRequestContext';
import { type BulkDeletePresetReportType } from '../types/BulkDeletePresetReportType';
import { type BulkDeleteReportType } from '../types/BulkDeleteReportType';
import { useAppTranslation } from './TranslationContext';
import { type AxiosResponse } from 'axios';
import { getServerErrorMessageFromResponse } from '../utils/helper';

type ClothingPresetContextType = {
  presets: ClothingPresetType[];
  setPresets: React.Dispatch<React.SetStateAction<ClothingPresetType[]>>;
  getPresetById: (presetId: number) => ClothingPresetType;
  addPreset: (preset: ClothingPresetType) => Promise<void>;
  editPreset: (preset: ClothingPresetType) => Promise<BulkDeleteReportType<BulkDeletePresetReportType[]>>;
  deletePresets: (selectedPresets: ClothingPresetType[]) => Promise<BulkDeleteReportType<BulkDeletePresetReportType[]>>;
};

type ClothingPresetProviderType = {
  children: JSX.Element;
};

const ClothingPresetContext = createContext<ClothingPresetContextType>({} as ClothingPresetContextType);

export const ClothingPresetProvider = ({ children }: ClothingPresetProviderType) => {
  const { httpConnection } = useHttpRequest();
  const { Translate } = useAppTranslation();

  const [presets, setPresets] = useState<ClothingPresetType[]>([]);

  const getPresetById = useCallback(
    (presetId: number): ClothingPresetType => {
      const preset = presets.find(currentPreset => currentPreset.id === presetId);
      if (!preset) throw new Error(`${Translate('error.preset-not-found-with-id')} ${presetId}`);
      return preset;
    },
    [Translate, presets]
  );

  const addPreset = useCallback(
    async (preset: ClothingPresetType): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        httpConnection
          .post<ClothingPresetType>('/list/clothing-presets', preset)
          .then(response => {
            setPresets([...presets, response.data]);
            resolve();
          })
          .catch(err => {
            const error = err as AxiosResponse;
            reject(error);
          });
      });
    },
    [httpConnection, presets]
  );

  const editPreset = useCallback(
    async (preset: ClothingPresetType): Promise<BulkDeleteReportType<BulkDeletePresetReportType[]>> => {
      return await new Promise<BulkDeleteReportType<BulkDeletePresetReportType[]>>((resolve, reject) => {
        httpConnection
          .put<BulkDeleteReportType<BulkDeletePresetReportType[]>>(`/list/clothing-presets/${preset.id}`, preset)
          .then(response => {
            const updatedPresets = presets.map<ClothingPresetType>(currentPreset => {
              if (currentPreset.id === preset.id) {
                return { ...preset };
              }

              return currentPreset;
            });

            setPresets(updatedPresets);
            resolve(response.data);
          })
          .catch(err => {
            const { data } = err;
            reject(data);
          });
      });
    },
    [httpConnection, presets]
  );

  const deletePresets = useCallback(
    async (selectedPresets: ClothingPresetType[]): Promise<BulkDeleteReportType<BulkDeletePresetReportType[]>> => {
      return await new Promise<BulkDeleteReportType<BulkDeletePresetReportType[]>>((resolve, reject) => {
        const selectedPresetsIds = selectedPresets.map(preset => preset.id);
        const url = '/list/clothing-presets/bulk-delete';

        httpConnection
          .post<BulkDeleteReportType<BulkDeletePresetReportType[]>>(url, { selected_ids: selectedPresetsIds })
          .then(response => {
            const { success } = response.data;

            if (success) {
              const filteredPresets = presets.filter(currentPreset => !selectedPresets.includes(currentPreset));
              setPresets(filteredPresets);
              resolve(response.data);
            }

            reject(response.data);
          })
          .catch(err => {
            reject(getServerErrorMessageFromResponse(err));
          });
      });
    },
    [httpConnection, presets]
  );

  const contextValues = useMemo(
    () => ({ getPresetById, presets, setPresets, addPreset, editPreset, deletePresets }),
    [getPresetById, presets, setPresets, addPreset, editPreset, deletePresets]
  );

  return <ClothingPresetContext.Provider value={contextValues}>{children}</ClothingPresetContext.Provider>;
};

export const useClothingPreset = (): ClothingPresetContextType => {
  const context = useContext(ClothingPresetContext);

  if (!context) {
    throw new Error('useClothingPreset must be used within a ClothingPresetProvider');
  }

  return context;
};
