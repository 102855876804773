import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { fillExtractedContentWithEmptyItems, moveCellInRow, updateCellInExtractedContentTable, updateExtractedContent } from './services/extractedContentService';
import { ContextMenuProvider } from '../../../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../../../components/ContextMenuViewer';
import { IconsCatalog } from '../../../../../../components/IconsCatalog';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import ListContentTableManager from './components/ListContentTableManager';
import { toast } from 'react-hot-toast-promise';
import { type ListContentParsedResultData } from '../../../../../../types/ListContentParsedResultData';
import { type ListContentTableHeader } from '../../../../../../types/ListContentTable/ListContentTableHeader';
import { type ListContentTableCellContextMenuDataType } from '../../../../../../types/ListContentTable/ListContentTableCellContextMenuDataType';
import { type TableCellUpdateType } from '../../../../../../types/ListContentTable/TableCellUpdateType';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';

type ListContentTableViewParam = {
  data: string;
  importedModels: ClothingModelType[];
  handleChange: (parsedList: ListContentParsedResultData[]) => void;
  listContentParsingErrors: string[][];
}

export default function ListContentTableView({ data, importedModels, listContentParsingErrors, handleChange }: ListContentTableViewParam) {
  const { Translate } = useAppTranslation();

  const [extractedContent, setExtractedContent] = useState<string[][]>([]);

  const handleMoveCell = useCallback((selectedItem: object, destinationIndex: number) => {
    const { row, rowIndex, sourceIndex } = selectedItem as ListContentTableCellContextMenuDataType;
    const updatedRow = moveCellInRow({ row, sourceIndex, destinationIndex });
    const updatedExtractedContent = updateExtractedContent(extractedContent, updatedRow, rowIndex);
    setExtractedContent(updatedExtractedContent);
  }, [extractedContent]);

  const handleAddEmptyCellLeft = useCallback((selectedItem: object) => {
    try {
      const { row, rowIndex, sourceIndex } = selectedItem as ListContentTableCellContextMenuDataType;
      const lastElementIsEmpty = row[row.length - 1] === '';

      if (!lastElementIsEmpty) throw new Error(Translate('error.cant-add-empty-cell-no-space-available'));

      row.pop();
      row.splice(sourceIndex, 0, '');

      const updatedExtractedContent = updateExtractedContent(extractedContent, row, rowIndex);
      setExtractedContent(updatedExtractedContent);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, extractedContent]);

  const handleAddEmptyCellRight = useCallback((selectedItem: object) => {
    try {
      const { row, rowIndex, sourceIndex } = selectedItem as ListContentTableCellContextMenuDataType;

      const lastElementIsEmpty = row[row.length - 1] === '';

      if (!lastElementIsEmpty) throw new Error(Translate('error.cant-add-empty-cell-no-space-available'));

      row.pop();

      row.splice(sourceIndex + 1, 0, '');

      const updatedExtractedContent = updateExtractedContent(extractedContent, row, rowIndex);
      setExtractedContent(updatedExtractedContent);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [Translate, extractedContent]);

  const onChangeTableCell = useCallback((updatedCell: TableCellUpdateType) => {
    const updatedExtractedContent = updateCellInExtractedContentTable(updatedCell, extractedContent);
    setExtractedContent(updatedExtractedContent);
  }, [extractedContent]);

  const defaultHeaders = useMemo<ListContentTableHeader>(() => ({
    name: Translate('labels.name'),
    nickname: Translate('labels.nickname'),
    number: Translate('labels.number'),
    bloodType: Translate('labels.blood-type')
  }), [Translate]);

  const contextMenuOptions = useMemo(
    (): ContextMenuItemType[] => {
      const importedModelsCount = importedModels.length;

      const firstOptions: ContextMenuItemType[] = [
        {
          name: `${Translate('labels.move-to')} ${defaultHeaders.name}`,
          icon: IconsCatalog.share,
          handleClick: (selectedItem) => {
            handleMoveCell(selectedItem, 0);
          }
        },
        {
          name: `${Translate('labels.move-to')} ${defaultHeaders.number}`,
          icon: IconsCatalog.share,
          handleClick: (selectedItem) => {
            handleMoveCell(selectedItem, 1);
          }
        }
      ];

      const modelsOptions: ContextMenuItemType[] = importedModels.map((_, index) => (
        {
          name: `${Translate('labels.move-to')} ${index + 1}°`,
          icon: IconsCatalog.share,
          handleClick: (selectedItem) => {
            handleMoveCell(selectedItem, index + 2);
          }
        }
      ));

      const lastOptions: ContextMenuItemType[] = [
        {
          name: `${Translate('labels.move-to')} ${defaultHeaders.nickname}`,
          icon: IconsCatalog.share,
          handleClick: (selectedItem) => {
            handleMoveCell(selectedItem, 2 + importedModelsCount);
          }
        },
        {
          name: `${Translate('labels.move-to')} ${defaultHeaders.bloodType}`,
          icon: IconsCatalog.share,
          handleClick: (selectedItem) => {
            handleMoveCell(selectedItem, 3 + importedModelsCount);
          }
        }
      ];

      const extraOptions: ContextMenuItemType[] = [
        {
          name: Translate('actions.add-empty-cell-left'),
          icon: IconsCatalog.arrowLeft,
          handleClick: (selectedItem) => {
            handleAddEmptyCellLeft(selectedItem);
          }
        },
        {
          name: Translate('actions.add-empty-cell-right'),
          icon: IconsCatalog.arrowRight,
          handleClick: (selectedItem) => {
            handleAddEmptyCellRight(selectedItem);
          }
        }
      ];

      return [...firstOptions, ...modelsOptions, ...lastOptions, ...extraOptions];
    },
    [defaultHeaders, handleMoveCell, importedModels, handleAddEmptyCellLeft, handleAddEmptyCellRight, Translate]
  );

  // updated parsedList when extractedContent changes
  useEffect(() => {
    if (extractedContent.length === 0) return;

    const parsedList = extractedContent.map<ListContentParsedResultData>(row => {
      const currentRow = [...row];
      const fixDataLeft = currentRow.splice(0, 2); // name and number
      const fixDataRight = currentRow.splice(-2); // nickname and blood type
      const modelsData = currentRow;

      return {
        name: fixDataLeft[0],
        number: fixDataLeft[1],
        nickname: fixDataRight[0],
        bloodType: fixDataRight[1],
        sizes: [...modelsData]
      };
    });

    handleChange(parsedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractedContent]);

  useEffect(() => {
    try {
      if (data.length === 0) {
        setExtractedContent([]);
        return;
      }

      const lines = data.split('\n');
      const splittedContent: string[][] = [];
      const expectedExtractedFieldslength = Object.keys(defaultHeaders).length + importedModels.length;

      lines.forEach(line => {
        const extractedRawContent = line.split(',')?.slice(0, expectedExtractedFieldslength);
        const extractedContent = extractedRawContent.map(item => item.trim());

        if (extractedContent.length === expectedExtractedFieldslength) {
          splittedContent.push(extractedContent);
          return;
        }

        const filledExtractedContent = fillExtractedContentWithEmptyItems(extractedContent, expectedExtractedFieldslength);
        splittedContent.push(filledExtractedContent);
      });

      setExtractedContent(splittedContent);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [data, defaultHeaders, importedModels.length]);

  return (
    <ContextMenuProvider>
      <ContextMenuViewer id='list-content-table-view' options={contextMenuOptions} />
      <ListContentTableManager
        defaultHeaders={defaultHeaders}
        extractedContent={extractedContent}
        importedModels={importedModels}
        onChangeTableCell={onChangeTableCell}
        listContentParsingErrors={listContentParsingErrors}
      />
    </ContextMenuProvider>
  );
};
