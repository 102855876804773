import { createContext, useContext, useMemo, useState } from 'react';
import { type ProjectStatusType } from '../types/ProjectStatusType';
import { type ComboBoxDataType } from '../types/ComboBoxDataType';

type ProjectsProviderType = {
  children: JSX.Element;
};

type ProjectsStatusContextType = {
  projectStatusList: ProjectStatusType[];
  setProjectStatusList: React.Dispatch<React.SetStateAction<ProjectStatusType[]>>;
  projectStatusComboboxOptions: ComboBoxDataType[];
};

const ProjectsStatusContext = createContext<ProjectsStatusContextType>({} as ProjectsStatusContextType);

export const ProjectsStatusProvider = ({ children }: ProjectsProviderType) => {
  const [projectStatusList, setProjectStatusList] = useState<ProjectStatusType[]>([]);

  const projectStatusComboboxOptions = useMemo<ComboBoxDataType[]>(() => {
    if (projectStatusList.length === 0) return [];

    const options = projectStatusList.map<ComboBoxDataType>(status => ({
      label: status.name,
      value: status.id!.toString()
    }));

    return [{ label: '', value: '' }, ...options];
  }, [projectStatusList]);

  const contextValues = useMemo(() => ({
    projectStatusList,
    setProjectStatusList,
    projectStatusComboboxOptions
  }), [
    projectStatusList,
    setProjectStatusList,
    projectStatusComboboxOptions
  ]);

  return (
    <ProjectsStatusContext.Provider value={contextValues}>
      {children}
    </ProjectsStatusContext.Provider>
  );
};

export const useProjectsStatuses = (): ProjectsStatusContextType => {
  const context = useContext(ProjectsStatusContext);

  if (!context) throw new Error('useProjectsStatuses must be used within a ProjectsStatusProvider');

  return context;
};
