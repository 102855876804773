import React, { useCallback, useMemo, useState } from 'react';
import BasicCard from '../../../../components/Cards/BasicCard';
import SplitButton from '../../../../components/Buttons/SplitButton';
import { IconsCatalog } from '../../../../components/IconsCatalog';
import ColorableRow from '../../../../components/Tables/ColorableRow';
import CircleButton from '../../../../components/Buttons/CircleButton';
import TextInputGroupOneButtonRight from '../../../../components/Forms/TextInputGroupOneButtonRight';
import ClothingModelsPickerModal from '../../../../components/Modals/ClothingModelsPickerModal';
import TextAreaInput from '../../../../components/Forms/TextAreaInput';
import TextInput from '../../../../components/Forms/TextInput';
import { type ClothingModelType } from '../../../../types/ClothingModelType';
import { generateDefaultPresetData, validatePreset } from './services/formService';
import { toast } from 'react-hot-toast-promise';
import { useClothingPreset } from '../../../../contexts/ClothingPresetContext';
import Spinner from '../../../../components/Spinner';
import { ContextMenuProvider } from '../../../../contexts/ContextMenuContext';
import ContextMenuViewer, { type ContextMenuItemType } from '../../../../components/ContextMenuViewer';
import StickyMenuBar from '../../../../components/Menu/StickyMenuBar';
import type ClothingPresetType from '../../../../types/ClothingPresetType';
import { useClothingModels } from '../../../../contexts/ClothingModelsContext';
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal';
import ClothingModelViewerModal from '../../../../components/Modals/ClothingModelsViewerModal';
import { type BulkDeleteReportType } from '../../../../types/BulkDeleteReportType';
import { type BulkDeletePresetReportType } from '../../../../types/BulkDeletePresetReportType';
import BulkDeleteMiniReportModal from '../../../../components/Modals/BulkDeleteMiniReportModal';
import { useUser } from '../../../../contexts/UserContext';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import { getServerErrorMessageFromResponse } from '../../../../utils/helper';

type ClothingModelViewerModalData = {
  preset: ClothingPresetType;
  models: ClothingModelType[];
};

type ClothingPresetsType = {
  stretchForm?: boolean;
}

export default function ClothingPresets({ stretchForm }: ClothingPresetsType) {
  const [isProcessingForm, setIsProcessingForm] = useState(false);
  const [showImportModels, setShowImportModels] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [bulkDeleteReport, setBulkDeleteReport] = useState<BulkDeleteReportType<BulkDeletePresetReportType[]> | null>(null);

  const [selectedModels, setSelectedModels] = useState<ClothingModelType[]>([]);
  const [selectedPresets, setSelectedPresets] = useState<ClothingPresetType[]>([]);
  const [modelViewerData, setModelViewerData] = useState<ClothingModelViewerModalData | null>(null);

  const [preset, setPreset] = useState<ClothingPresetType>(generateDefaultPresetData());

  const { presets, addPreset, editPreset, deletePresets } = useClothingPreset();
  const { getModelsById } = useClothingModels();
  const { user, isCompanyAccount } = useUser();
  const { Translate } = useAppTranslation();

  const isEditingPreset = useMemo(() => preset.id > 0, [preset]);
  const formClass = useMemo(() => stretchForm ? 'col-12' : 'col-8', [stretchForm]);

  const handleAddPreset = useCallback(async () => {
    try {
      validatePreset(preset, Translate);
      setIsProcessingForm(true);

      await addPreset(preset);
      setPreset(generateDefaultPresetData());
      setSelectedModels([]);
      toast.success(Translate('toast.preset-saved'));
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsProcessingForm(false);
    }
  }, [Translate, addPreset, preset]);

  const handleEditPreset = useCallback(async () => {
    const promise = async (): Promise<void> => {
      await new Promise<void>((resolve, reject) => {
        try {
          validatePreset(preset, Translate);
          setIsProcessingForm(true);

          editPreset(preset)
            .then(() => {
              resolve();
            })
            .catch(reject);
        } catch (err) {
          reject(err);
        }
      });
    };

    toast.promise(promise(), {
      loading: Translate('progress.loading'),
      success: () => {
        setPreset(generateDefaultPresetData());
        setSelectedModels([]);
        return Translate('toast.changes-saved');
      },
      error: err => {
        const error = err as Error;

        if ('success' in error) {
          const bulkDeleteReport = err as BulkDeleteReportType<BulkDeletePresetReportType[]>;
          setBulkDeleteReport(bulkDeleteReport);
          return bulkDeleteReport.message;
        }

        return error.message;
      },
      finally: () => {
        setIsProcessingForm(false);
      }
    });
  }, [Translate, editPreset, preset]);

  const handleFormSubmit = useCallback(() => {
    if (isEditingPreset) handleEditPreset();
    else handleAddPreset();
  }, [handleAddPreset, handleEditPreset, isEditingPreset]);

  const handleClickFormSecondaryButton = useCallback(() => {
    if (isEditingPreset) toast.error(Translate('toast.editing-canceled'));
    else toast.error(Translate('toast.form-canceled'));
    setPreset(generateDefaultPresetData());
    setSelectedModels([]);
  }, [Translate, isEditingPreset]);

  const handleExitSelectionMode = useCallback(() => {
    setSelectionMode(false);
    setSelectedPresets([]);
  }, []);

  const handleCheckPreset = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, preset: ClothingPresetType) => {
      const { checked } = event.target;

      if (checked) {
        setSelectedPresets([...selectedPresets, preset]);
        return;
      }

      const updatedSelectedPresets = selectedPresets.filter(currentPreset => currentPreset.id !== preset.id);
      setSelectedPresets(updatedSelectedPresets);
    },
    [selectedPresets]
  );

  const handleCheckAllPresets = useCallback((event: React.ChangeEvent<HTMLInputElement>, presets: ClothingPresetType[]) => {
    const { checked } = event.target;

    if (checked) setSelectedPresets([...presets]);
    else setSelectedPresets([]);
  }, []);

  const handleCancelDeleteSelectedPresets = useCallback(() => {
    setSelectedPresets([]);
    setShowDeleteConfirmationModal(false);
  }, []);

  const handleConfirmDeleteSelectedPresets = useCallback(() => {
    toast.promise(deletePresets(selectedPresets), {
      loading: Translate('progress.deleting'),
      success: () => {
        handleExitSelectionMode();
        return Translate('toast.presets-deleted');
      },
      error: err => {
        handleExitSelectionMode();

        // rejected with report informations
        if ('success' in err) {
          const bulkDeleteReport = err as BulkDeleteReportType<BulkDeletePresetReportType[]>;
          setBulkDeleteReport(bulkDeleteReport);
          return bulkDeleteReport.message;
        }

        // rejected with any other errors
        return getServerErrorMessageFromResponse(err);
      }
    });
  }, [Translate, deletePresets, handleExitSelectionMode, selectedPresets]);

  const handleCloseBulkDeleteReportModal = useCallback(() => {
    setBulkDeleteReport(null);
  }, []);

  const contextMenuPresetOptions = useMemo<ContextMenuItemType[]>(
    () => [
      {
        name: Translate('actions.edit'),
        icon: IconsCatalog.pen,
        handleClick: selectedOption => {
          const presetToEdit = selectedOption as ClothingPresetType;
          const includedModelsIDs = presetToEdit.imported_clothes_id;
          const includedModels = getModelsById(includedModelsIDs);

          setPreset(presetToEdit);
          setSelectedModels(includedModels);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      },
      {
        name: Translate('actions.delete'),
        icon: IconsCatalog.trash,
        handleClick: selectedOption => {
          setSelectedPresets([selectedOption as ClothingPresetType]);
          setShowDeleteConfirmationModal(true);
        }
      },
      {
        name: Translate('actions.selection-mode'),
        icon: IconsCatalog.checkSquare,
        handleClick: () => {
          setSelectionMode(true);
        }
      }
    ],
    [Translate, getModelsById]
  );

  if (!user) return <></>;

  return (
    <React.Fragment>
      {showImportModels && (
        <ClothingModelsPickerModal
          alreadySelected={selectedModels}
          visible={true}
          handleClose={() => {
            setShowImportModels(false);
          }}
          handleConfirm={(selectedModels: ClothingModelType[]) => {
            setSelectedModels(selectedModels);

            const selectedModelsIDs = selectedModels.map(model => model.id);
            setPreset({ ...preset, imported_clothes_id: selectedModelsIDs });
          }}
        />
      )}

      <ConfirmationModal
        title={Translate('labels.confirmation')}
        message={Translate('description.confirm-delete-selected-presets')}
        style='danger'
        visible={showDeleteConfirmationModal}
        handleClose={handleCancelDeleteSelectedPresets}
        handleConfirm={() => {
          setShowDeleteConfirmationModal(false);
          handleConfirmDeleteSelectedPresets();
        }}
      />

      {!!bulkDeleteReport && (
        <BulkDeleteMiniReportModal
          message={Translate('description.selected-presets-report-failed-delete')}
          handleClose={handleCloseBulkDeleteReportModal}
          report={bulkDeleteReport.report}
          reportItemKey='preset_name'
          reportSubItemsKey='project_names'
          reportSubItemsLabel={Translate('labels.projects-using')}
        />
      )}

      {modelViewerData && (
        <ClothingModelViewerModal
          handleClose={() => {
            setModelViewerData(null);
          }}
          visible={true}
          preset={modelViewerData.preset}
          models={modelViewerData.models}
        />
      )}

      <h1 className='h3 mb-2 text-gray-800'>{Translate('labels.presets')}</h1>
      <p className='mb-4'>{Translate('description.presets-page-header-description')}</p>

      {isCompanyAccount && (
        <div className='row'>
          <div className={formClass}>
            <BasicCard title={Translate('labels.register')}>
              <div className='row'>
                <div className='col'>
                  <div className='row'>
                    <div className='col-6'>
                      <TextInput
                        disabled={isProcessingForm}
                        id='preset-name'
                        label={Translate('labels.preset-name')}
                        value={preset.name}
                        onChange={({ target }) => {
                          setPreset({ ...preset, name: target.value });
                        }}
                      />
                    </div>
                    <div className='col-6'>
                      <TextInputGroupOneButtonRight
                        readonly
                        buttonDisabled={isProcessingForm}
                        id='preset-count'
                        label={Translate('labels.models')}
                        value={
                          selectedModels.length > 0 ? `${Translate('status.selected-models')} ${selectedModels.length}` : ''
                        }
                        buttonText={selectedModels.length === 0 ? Translate('actions.select') : Translate('actions.modify')}
                        handleButtonClick={() => {
                          setShowImportModels(true);
                        }}
                        placeholder={Translate('status.nothing-selected')}
                      />
                    </div>
                  </div>

                  <TextAreaInput
                    id='comments'
                    label={Translate('labels.details')}
                    disabled={isProcessingForm}
                    value={preset.details ?? ''}
                    handleChange={({ target }) => {
                      setPreset({ ...preset, details: target.value });
                    }}
                  />

                  {isProcessingForm && <Spinner alignLeft />}

                  {!isProcessingForm && (
                    <React.Fragment>
                      <SplitButton
                        color='success'
                        title={isEditingPreset ? Translate('actions.save') : Translate('actions.do-register')}
                        icon={IconsCatalog.save}
                        handleClick={handleFormSubmit}
                      />

                      <SplitButton
                        marginLeft
                        color='secondary'
                        title={isEditingPreset ? Translate('actions.cancel') : Translate('actions.clear')}
                        icon={IconsCatalog.save}
                        handleClick={handleClickFormSecondaryButton}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </BasicCard>
          </div>
        </div>
      )}

      {selectionMode && (
        <StickyMenuBar countSelectedItems={selectedPresets.length}>
          <SplitButton
            size='sm'
            icon={IconsCatalog.times}
            title={Translate('actions.cancel')}
            color='light'
            handleClick={handleExitSelectionMode}
          />

          <SplitButton
            size='sm'
            icon={IconsCatalog.trash}
            title={Translate('actions.delete-selected')}
            color='danger'
            marginLeft
            handleClick={() => {
              setShowDeleteConfirmationModal(true);
            }}
          />
        </StickyMenuBar>
      )}

      <BasicCard title={Translate('labels.listing')} description={Translate('description.presets-available-at-now')}>
        <ContextMenuProvider>
          <ContextMenuViewer
            id='presets'
            options={contextMenuPresetOptions}
            disabled={selectionMode || isEditingPreset || !isCompanyAccount}
          />

          <table className='table table-bordered' width='100%'>
            <thead>
              <tr>
                {selectionMode && (
                  <th className='text-center'>
                    <input
                      type='checkbox'
                      defaultChecked={false}
                      onChange={event => {
                        handleCheckAllPresets(event, presets);
                      }}
                    />
                  </th>
                )}

                <th>{Translate('labels.name')}</th>
                <th>{Translate('labels.contains')}</th>
                <th style={{ width: 75 }} className='text-center'>
                  {Translate('actions.see')}
                </th>
              </tr>
            </thead>
            <tbody>
              {presets.map((preset, index) => (
                <ColorableRow data={preset} isSelected={selectedPresets.includes(preset)} key={index}>
                  {selectionMode && (
                    <td width={50} className='text-center align-middle'>
                      <input
                        type='checkbox'
                        checked={selectedPresets.includes(preset)}
                        onChange={event => {
                          handleCheckPreset(event, preset);
                        }}
                      />
                    </td>
                  )}

                  <td className='align-middle'>{preset.name}</td>
                  <td className='align-middle'>
                    {preset.imported_clothes_id.length} <span className='text-lowercase'>{Translate('labels.models')}</span>
                  </td>
                  <td className='align-middle text-center'>
                    <CircleButton
                      size='sm'
                      color='secondary'
                      icon={IconsCatalog.eye}
                      handleClick={() => {
                        const importedClothes = getModelsById(preset.imported_clothes_id);
                        setModelViewerData({ preset, models: importedClothes });
                      }}
                    />
                  </td>
                </ColorableRow>
              ))}

              {presets.length === 0 && (
                <tr className='text-center'>
                  <td colSpan={99}>{Translate('status.no-presets-registered')}</td>
                </tr>
              )}
            </tbody>
          </table>
        </ContextMenuProvider>
      </BasicCard>
    </React.Fragment>
  );
}
