import styled from 'styled-components';

export const WrapperFile = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 2px dashed #c4c4c4;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  margin: 0 auto 20px;

  input {
    display: none;
  }
`;

export const ImageFile = styled.img`
  width: 190px;
  height: 190px;
  object-fit: cover;
  border-radius: 16px;
  pointer-events: none;
`;

export const Container = styled.div`
  min-height: 320px;
  overflow-y: auto;
  flex: 1;
`;
