import React from 'react';
import { Container } from './styles';

type SyncStatusBarType = {
  isSynching: boolean;
}

export default function SyncStatusBar({ isSynching }: SyncStatusBarType) {
  return (
    <Container isSynching={isSynching} data-testid="sync-status-bar" />
  );
};
