import styled from 'styled-components';

type ContainerType = {
  useInlineIcon?: boolean;
}

export const Container = styled.div.withConfig({ shouldForwardProp: prop => prop !== 'useInlineIcon' }) <ContainerType>`
  display: ${props => props.useInlineIcon ? 'inline-flex' : 'block'};
  align-items: center;
`;

type RoundedCornersParams = {
  renderAsButton?: boolean;
  disabled: boolean;
}

export const RoundedCorners = styled.div.withConfig({ shouldForwardProp: prop => !['renderAsButton', 'disabled'].includes(prop) }) <RoundedCornersParams>`
  padding: 0.3rem 0.40rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${props => props.renderAsButton && !props.disabled ? 'pointer' : 'auto'};
`;
