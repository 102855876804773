import { type AxiosAdapters } from '../../../../helpers/adapters/http/AxiosAdapters';

type UpdateColumnBackgroundColorParams = {
  columnId: number;
  color: string;
};

export class UpdateColumnBackgroundColor {
  constructor(private readonly http: AxiosAdapters) {
    this.http = http;
  }

  async execute({ columnId, color }: UpdateColumnBackgroundColorParams) {
    return await this.http.put(`/kanban/column/${columnId}/color`, { color });
  }
}
