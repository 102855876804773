import React from 'react';
import Spinner from '../Spinner';

type SpinnerWithMessageType = {
  message: string;
};

export default function SpinnerWithMessage({ message }: SpinnerWithMessageType) {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <Spinner />
      <span className='ml-3'>{message}</span>
    </div>
  );
}
