import { type AxiosAdapters } from '../../../../../../../../../helpers/adapters/http/AxiosAdapters';

type SaveSeamstressParams = {
  sublist_id: number;
  data: { price?: number; quantity?: string };
};

export class SaveSeamstress {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: SaveSeamstressParams) {
    return await this.http.post(`/list-clothing-project/seamstress/${data.sublist_id}`, { ...data.data });
  }
}
