import { type AxiosAdapters } from '../../../../../../helpers/adapters/http/AxiosAdapters';

export class GetProductsInModel {
  constructor(private readonly http: AxiosAdapters) { }

  async execute(modelId?: number): Promise<number[]> {
    if (modelId === 0) return [];
    return await this.http.get(`/products/model/${modelId}`);
  }
}
