import React, { type SetStateAction, useCallback } from 'react';
import { ClothingHeaderContainer, GridSizes } from './styles';
import ClothingIconViewer from '../../../../../../../../components/ClothingIconViewer';
import { type ClothingModelType, type ClothingGenderType } from '../../../../../../../../types/ClothingModelType';
import { type ClothingProductionData } from '../../../../../../../../types/OrderTypes';
import NumericInputPrependedText from '../../../../../../../../components/Forms/NumericInputPrependedText';
import { updateProductionDataQuantity, type HandleUpdateQuantityParams } from './services/productionDataService';

type RenderFastCreationModelsType = {
  importedModels: ClothingModelType[];
  gender: keyof ClothingGenderType;
  modelsProductionData: ClothingProductionData[][];
  updateModelsProductionData: React.Dispatch<SetStateAction<ClothingProductionData[][]>>;
  disabled?: boolean;
};

export default function RenderFastCreationModels({
  importedModels,
  gender,
  modelsProductionData,
  updateModelsProductionData,
  disabled
}: RenderFastCreationModelsType) {
  const handleUpdateQuantity = useCallback(
    ({ modelIndex, sizeIndex, quantity }: HandleUpdateQuantityParams) => {
      const updatedModelsProductionData = updateProductionDataQuantity(modelsProductionData, {
        modelIndex,
        sizeIndex,
        quantity
      });

      updateModelsProductionData(updatedModelsProductionData);
    },
    [modelsProductionData, updateModelsProductionData]
  );

  return (
    <React.Fragment>
      <div>
        {importedModels.map((model, modelIndex) => (
          <React.Fragment key={modelIndex}>
            <ClothingHeaderContainer>
              <ClothingIconViewer icon={model.icon.url} />
              <h5>{model.name}</h5>
            </ClothingHeaderContainer>

            <GridSizes>
              {model.informations[gender]
                .filter(budget => budget.size !== '')
                .map((sizeBudget, sizeIndex) => {
                  const quantity = modelsProductionData[modelIndex][sizeIndex].quantity;

                  return (
                    <NumericInputPrependedText
                      disabled={disabled}
                      key={sizeIndex}
                      id={`input-data-${sizeIndex}`}
                      tabIndex={modelIndex}
                      prependedText={sizeBudget.size}
                      value={quantity === 0 ? '' : quantity.toString()}
                      onChangeQuantity={(value) => {
                        handleUpdateQuantity({
                          modelIndex,
                          sizeIndex,
                          quantity: value
                        });
                      }}
                    />
                  );
                })}
            </GridSizes>
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
}
