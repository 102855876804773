import ModalBase, { type ModalBaseType } from '../ModalBase';

type ConfirmationModalType = Omit<ModalBaseType, 'children'>;

export default function ConfirmationModal({ message, ...props }: ConfirmationModalType) {
  return (
    <ModalBase {...props}>
      <p>{message}</p>
    </ModalBase>
  );
}
