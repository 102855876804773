import { formatDistance, formatRelative, format, type Locale } from 'date-fns';

export const displayTimePassedSinceDate = (initialDate: Date, locale: Locale): string => {
  return formatDistance(initialDate, new Date(), {
    addSuffix: true,
    locale
  });
};

export const displayTimePassedWithDay = (initialDate: Date, locale: Locale): string => {
  return formatRelative(initialDate, new Date(), { locale });
};

export const displayTimeWithHourAndMinutes = (date: Date, locale: Locale): string => {
  const formattedDate = format(date, 'PPPpp', { locale });
  return formattedDate;
};

export const displayCurrentTimeWithHourAndMinutes = (locale: Locale): string => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, 'PPPpp', { locale });
  return formattedDate;
};

export const displayCommonDateWithTime = (date: Date, locale: Locale): string => {
  const formattedDate = format(new Date(date), 'Pp', { locale });
  return formattedDate;
};

export const displayFormattedDateWithAbbreviatedMonth = (date: string, locale: Locale): string => {
  const formattedDate = format(new Date(date), 'PP', { locale });
  return formattedDate;
};

export const displayFormattedDate = (date: string, locale: Locale): string => {
  const formattedDate = format(new Date(date), 'P', { locale });
  return formattedDate;
};

/**
 * Function to convert seconds into a duration object
 */
const convertSecondsToDuration = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
};

export const getElapsedTimeStatus = (timeInSeconds: number): string | null => {
  // Convert elapsed time to a duration object
  const duration = convertSecondsToDuration(timeInSeconds);

  // Manually format the duration as "1h45m" or "5m"
  const formattedDuration = `${duration.hours ? duration.hours + 'h' : ''}${duration.minutes ? duration.minutes + 'm' : ''}`;

  // Output will be '5m' for 5 minutes, '1h45m' for 1 hour 45 minutes, etc.
  const output = formattedDuration || null;

  return output;
};
