import { type AxiosAdapters } from '../../../../../../../../../helpers/adapters/http/AxiosAdapters';
import { type Product } from '../../../../../../../Clothing/MyClothingModels/RawMaterialModelsModal/services/interfaces/Product';

type Params = {
  sublistId: number;
};

type Response = Array<{
  model: {
    id: number;
    name: string;
    icon: string;
  };
  data: Product[];
}>;

export class GetRawMaterial {
  constructor(private readonly http: AxiosAdapters) {}

  async execute({ sublistId }: Params): Promise<Response> {
    return await this.http.get(`products/sublist/${sublistId}`);
  }
}
