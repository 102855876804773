import { type Dispatch, type SetStateAction } from 'react';
import { useMutation, type UseQueryResult } from 'react-query';
import { deleteEntity } from '../../api';
import { toast } from 'react-toastify';
import { type Entity } from '../../../../../../types/Entity';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { type Sections } from '../SectionsOptions';

type EntityTableProps = {
  entities: UseQueryResult<Entity[]>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  section: Sections;
};

export function EntityTable({ entities, setShowModal, section }: EntityTableProps) {
  const methods = useFormContext();
  const deleteEntityMutation = useMutation(
    async (id: number) => {
      await deleteEntity.execute(id);
    },
    {
      onSuccess: () => {
        entities.refetch();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.error || 'An error occurred');
      }
    }
  );

  return (
    <table className='table table-borderless'>
      <thead>
        <tr>
          <th scope='col'>Nome</th>
          <th scope='col'>E-mail</th>
          <th scope='col'>Whatsapp</th>
          <th scope='col'>Estado</th>
          {section === 'supplier' ? <th scope='col'>Produtos</th> : null}
          <th scope='col'>Ações</th>
        </tr>
      </thead>
      <tbody>
        {entities?.data?.map(entity => (
          <tr key={entity.id}>
            <td>{entity.name}</td>
            <td>{entity.email}</td>
            <td>
              <a href={`https://wa.me/${entity.phone}`} className='text-success' target='_blank' rel='noreferrer'>
                <i className='fab fa-whatsapp mr-2'></i>
                <span>{entity.phone}</span>
              </a>
            </td>
            <td>{entity.state.toUpperCase()}</td>

            {section === 'supplier' ? <td>{_.defaultTo(entity?.products?.length, 0)}</td> : null}

            <td>
              <button
                className='btn btn-sm btn-primary mr-3'
                type='button'
                onClick={() => {
                  setShowModal(true);
                  Object.keys(entity).forEach(key => {
                    methods.setValue(key as keyof Entity, entity[key as keyof Entity]);
                  });
                }}
              >
                <i className='fas fa-edit'></i>
              </button>

              {_.isEmpty(entity.list_clothing_projects) ? (
                <button
                  className='btn btn-sm btn-danger'
                  type='button'
                  onClick={() => {
                    deleteEntityMutation.mutate(entity.id);
                  }}
                  disabled={deleteEntityMutation.isLoading}
                >
                  {deleteEntityMutation.isLoading ? (
                    <i className='fas fa-spinner fa-spin'></i>
                  ) : (
                    <i className='fas fa-trash'></i>
                  )}
                </button>
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
