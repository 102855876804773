import * as yup from 'yup';

const taskList = yup.array().of(
  yup.object().shape({
    id: yup.number(),
    title: yup.string().required(),
    checked: yup.boolean().required()
  })
);

export const schemaCardModalForm = yup.object().shape({
  title: yup.string().required('O título é obrigatório'),
  description: yup.string(),
  quantity_piece: yup.number().required('A quantidade é obrigatória'),
  taskListGroupTitle: yup.string(),
  taskList,
  attachments: yup.array(),
  priority: yup.number().required('A prioridade é obrigatória')
});

export const schemaUpdateCardModalForm = yup.object().shape({
  title: yup.string().required('O título é obrigatório'),
  description: yup.string(),
  quantity_piece: yup.number(),
  taskListGroupTitle: yup.string(),
  taskList,
  attachments: yup.array(),
  date: yup.object().shape({
    start: yup.string().required('A data de início é obrigatória'),
    end: yup.string()
  }),
  priority: yup.number().required('A prioridade é obrigatória')
});
