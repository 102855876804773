import React from 'react';
import { Tooltip } from 'react-tooltip';
import { type SublistFinishingOption } from '../../../../../../../../../../types/ClothingFinishingOptions/SublistFinishingOption';

import { Container } from './styles';
import { useAppTranslation } from '../../../../../../../../../../contexts/TranslationContext';

type SelectedFinishingOptionsTooltipType = {
  sublistIndex: number;
  sublistFinishingOptions: SublistFinishingOption[];
}
export default function SelectedFinishingOptionsTooltip({ sublistIndex, sublistFinishingOptions }: SelectedFinishingOptionsTooltipType) {
  const { Translate } = useAppTranslation();

  return (
    <React.Fragment>
      <Tooltip
        id={`sublist-${sublistIndex}-collapsable-card-header-finishing-options`}
        opacity={1}
        style={{ backgroundColor: '#fff', padding: '0px 0px 0px 10px' }}
      >
        <Container>
          <ul>
            {
              sublistFinishingOptions.map((item, index) => (
                <li key={index}>{item.option.title}: {item.option_picked.item}</li>
              ))
            }
          </ul>
        </Container>
      </Tooltip>

      <span className='badge badge-primary badge-large align-self-center ml-2' data-tooltip-id={`sublist-${sublistIndex}-collapsable-card-header-finishing-options`}>
        {Translate('labels.contains-finishing-options')}
      </span>
    </React.Fragment>
  );
};
