import React from 'react';
import { type BasicTextInputProps } from '../BasicTextInput';
import { TimeInputContainer } from './styles';

export default function TimeInput({ disabled, value, onChange }: BasicTextInputProps) {
  return (
    <TimeInputContainer
      type='time'
      className='form-control'
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );
};
