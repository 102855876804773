import _ from 'lodash';
import { useCallback, useState } from 'react';

import { RawMaterialContent } from './Content';
import { Inventory } from './Inventory';

export function RawMaterial() {
  const [isInventory, setIsInventory] = useState(null);

  const handleInventory = useCallback((rawMaterial: any) => {
    setIsInventory(rawMaterial);
  }, []);

  if (!_.isEmpty(isInventory)) {
    return <Inventory isInventory={isInventory} handleInventory={handleInventory} />;
  }

  return <RawMaterialContent handleInventory={handleInventory} />;
}
