import { useMemo } from 'react';
import { type IconButtonType } from '../types/IconButtonType';

type SplitButtonType = Omit<IconButtonType, 'title'> & {
  title?: string;
};

export default function SplitButton({
  title,
  icon,
  color,
  size,
  marginLeft,
  tabIndex,
  simulateLabelMarginTop = false,
  disabled = false,
  outlined = false,
  handleClick,
  testId
}: SplitButtonType) {
  const buttonSize = useMemo(() => (size ? `btn-${size}` : ''), [size]);
  const useMarginLeft = useMemo(() => (marginLeft ? 'ml-2' : ''), [marginLeft]);
  const useSimulatedMarginTopLabel = useMemo(() => (simulateLabelMarginTop ? '32px' : '0'), [simulateLabelMarginTop]);
  const useOutlineStyle = useMemo(() => outlined ? '-outline' : '', [outlined]);

  return (
    <button
      style={{ marginTop: useSimulatedMarginTopLabel }}
      className={`btn btn${useOutlineStyle}-${color} btn-icon-split ${buttonSize} ${useMarginLeft}`}
      data-testid={testId}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={handleClick}
    >
      <span className={`icon ${title ? 'text-white-50' : ''}`}>
        <i className={`${icon} fa-fw`} data-testid='split-button-icon' />
      </span>

      {!!title && (
        <span className='text' data-testid='split-button-title'>
          {title}
        </span>
      )}
    </button>
  );
}
