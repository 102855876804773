import styled from 'styled-components';

type ColoredReportRowType = {
  color: string;
  showColor: boolean;
}

export const ColoredReportRow = styled.tr.withConfig({ shouldForwardProp: prop => !['color', 'showColor'].includes(prop) }) <ColoredReportRowType>`
  & > td {
    background-color: ${props => props.showColor ? props.color : 'transparent'} !important;
  }
`;
