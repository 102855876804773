import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHttpRequest } from './HttpRequestContext';
import { type UserPreferences } from '../types/UserPreferences';
import { areObjectsEquals } from '../pages/Authenticated/OrderList/ViewProject/services/objectComparation';
import { type AxiosResponse } from 'axios';

type SettingsContextType = {
  preferences: UserPreferences;
  setPreferences: React.Dispatch<React.SetStateAction<UserPreferences>>;
  isPreferencesSynched: boolean;
  isPreferencesSynching: boolean;
  backendResourcesReady: boolean;
  setBackendResourcesReady: React.Dispatch<React.SetStateAction<boolean>>;
  initSettings: (preferences: UserPreferences) => void;
  savePreferences: () => Promise<void>;
};

type SettingsContextProviderProps = {
  children: JSX.Element;
};

const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType);

export const SettingsProvider = ({ children }: SettingsContextProviderProps) => {
  const [backendResourcesReady, setBackendResourcesReady] = useState(false);
  const [preferences, setPreferences] = useState<UserPreferences>({} as UserPreferences);

  const [lastSynchedPreferences, setLastSynchedPreferences] = useState<UserPreferences | null>(null);
  const [isPreferencesSynched, setIsPreferencesSynched] = useState(false);
  const [isPreferencesSynching, setIsPreferencesSynching] = useState(false);

  const { httpConnection } = useHttpRequest();

  const savePreferences = useCallback(async (): Promise<void> => {
    await new Promise<void>((resolve, reject) => {
      if (isPreferencesSynched) resolve();
      setIsPreferencesSynching(true);

      httpConnection
        .put<UserPreferences>('/list/preferences', preferences)
        .then(() => {
          setLastSynchedPreferences(preferences);
          resolve();
        })
        .catch(err => {
          const error = err as AxiosResponse;
          reject(error);
        })
        .finally(() => {
          setIsPreferencesSynching(false);
        });
    });
  }, [httpConnection, isPreferencesSynched, preferences]);

  const initSettings = useCallback((preferences: UserPreferences) => {
    setPreferences(preferences);
    setLastSynchedPreferences(preferences);
    setIsPreferencesSynched(true);
    setBackendResourcesReady(true);
  }, []);

  useEffect(() => {
    const synched = areObjectsEquals(preferences, lastSynchedPreferences);
    setIsPreferencesSynched(synched);
  }, [preferences, lastSynchedPreferences]);

  const contextValues = useMemo(
    () => ({
      initSettings,
      preferences,
      setPreferences,
      isPreferencesSynched,
      isPreferencesSynching,
      backendResourcesReady,
      setBackendResourcesReady,
      savePreferences
    }),
    [
      initSettings,
      preferences,
      setPreferences,
      isPreferencesSynched,
      isPreferencesSynching,
      backendResourcesReady,
      setBackendResourcesReady,
      savePreferences
    ]
  );

  return <SettingsContext.Provider value={contextValues}>{children}</SettingsContext.Provider>;
};

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);

  if (!context) throw new Error('useSettings must be used witin a SettingsContextProvider');

  return context;
};
