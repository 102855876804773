import { createContext, useCallback, useContext, useMemo, useState } from 'react';

type FooterContextType = {
  isFooterVisible: boolean;
  showFooter: () => void;
  hideFooter: () => void;
}

type FooterProviderProps = {
  children: JSX.Element;
}

const FooterContext = createContext<FooterContextType>({} as FooterContextType);

export const FooterProvider = ({ children }: FooterProviderProps) => {
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  const showFooter = useCallback(() => {
    setIsFooterVisible(true);
  }, []);

  const hideFooter = useCallback(() => {
    setIsFooterVisible(false);
  }, []);

  const contextValues = useMemo(() => ({
    isFooterVisible,
    showFooter,
    hideFooter
  }), [isFooterVisible, showFooter, hideFooter]);

  return <FooterContext.Provider value={contextValues}>
    {children}
  </FooterContext.Provider>;
};

export const useFooter = (): FooterContextType => {
  const context = useContext(FooterContext);

  if (!context) throw new Error('useFooterContext must be use within a FooterProvider');

  return context;
};
