import React from 'react';
import QRCode from 'react-qr-code';
import { HeaderLeftSection, HeaderRightSection } from './styles';
import { useUser } from '../../../../../../contexts/UserContext';
import { type ProjectType } from '../../../../../../types/ProjectType';
import {
  displayCurrentTimeWithHourAndMinutes,
  displayFormattedDateWithAbbreviatedMonth
} from '../../../../../../helpers/dateTime';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { generateFinalClientEditorUrl } from '../../../ViewProject/components/ProjectHeaderInfo/ProjectSharedLinkInfo/services/sharedLinkInfoService';

type ProjectReportHeaderType = {
  project: ProjectType;
  hideOrderNumber: boolean;
};

export default function ProjectReportHeader({ project, hideOrderNumber }: ProjectReportHeaderType) {
  const { user } = useUser();
  const { Translate, dateFnsLocale } = useAppTranslation();

  return (
    <React.Fragment>
      <div className='row mt-2'>
        <div className='col pl-0'>
          <HeaderLeftSection>
            {user?.logo && <img width={100} height={100} src={user.logo} />}

            <section>
              {project.name && <h4>{project.name}</h4>}
              <small>{Translate('labels.report-generated-at')}:</small>
              <span>{displayCurrentTimeWithHourAndMinutes(dateFnsLocale)}</span>

              <h5 className='mt-2'>
                <b>{Translate('labels.client')}:</b>
                &nbsp;
                {project.client_name}
              </h5>

              <h5>
                <b>{Translate('labels.seller')}:</b>
                &nbsp;
                {project.author?.name}
              </h5>
            </section>
          </HeaderLeftSection>
        </div>

        <div className='col'>
          <QRCode value={generateFinalClientEditorUrl(project.id!, project.token!)} size={125} className='ml-5' />
        </div>

        <div className='col-auto pr-0 d-flex'>
          <HeaderRightSection>
            <div className={`row ${hideOrderNumber ? 'd-none' : ''}`}>
              <div className='col'>
                <div>
                  <span>{Translate('labels.order-number')}</span>
                  <h5># {project.order_number}</h5>
                </div>
              </div>
            </div>

            <div className={`row ${hideOrderNumber ? 'mt-3' : ''}`}>
              <div className='col d-flex'>
                <div className='mr-3'>
                  <span>{Translate('labels.order-date')}</span>
                  <h5>{displayFormattedDateWithAbbreviatedMonth(project.created_at!, dateFnsLocale)}</h5>
                </div>

                <section>
                  <span>{Translate('labels.delivery-date')}</span>
                  <h5>
                    {project.delivery_date
                      ? displayFormattedDateWithAbbreviatedMonth(project.delivery_date, dateFnsLocale)
                      : Translate('status.undefined')}
                  </h5>
                </section>
              </div>
            </div>
          </HeaderRightSection>
        </div>
      </div>

      {!!project.client_informations && (
        <div className='row mt-2'>
          <div className='col d-flex p-0 mb-0'>
            <table className='table table-bordered table-sm mb-1'>
              <tbody>
                <tr>
                  <td>
                    <strong>{Translate('labels.client-annotations')}: </strong>
                    <pre className='mb-0' style={{ fontFamily: 'inherit' }}>
                      {project.client_informations}
                    </pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
