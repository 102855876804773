import React, { useMemo, useState } from 'react';
import InputRange from '../../../../../../components/Forms/InputRange';
import { LayoutContainer, LayoutImage } from '../LayoutGallery/styles';
import SublistFinishingOptionsViewer from '../SublistFinishingOptionsViewer';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';

type LayoutWithFinishingOptionsType = {
  sublist: SublistType;
  importedModels: ClothingModelType[];
  showSublistTitle?: boolean;
}

export default function LayoutWithFinishingOptions({
  sublist,
  importedModels,
  showSublistTitle
}: LayoutWithFinishingOptionsType) {
  const { Translate } = useAppTranslation();

  const [layoutScaleFactor, setLayoutScaleFactor] = useState(1);
  const layoutScalePercent = useMemo(() => Math.round(layoutScaleFactor * 100), [layoutScaleFactor]);

  if (!sublist.layout) return <></>;

  return (
    <div>
      {showSublistTitle && <h3 className={'border-top-thick'}>{sublist.title}</h3>}

      <section className='d-print-none mb-2'>
        <InputRange
          min={50} max={100} initialValue={100}
          label={`${Translate('labels.scale')}: ${layoutScalePercent}%`}
          onChangeValue={(updatedValue => {
            setLayoutScaleFactor(updatedValue / 100);
          })} />
      </section>

      <div className='d-flex justify-content-between align-items-start'>
        <section className='mr-2'>
          <LayoutContainer scaleFactor={layoutScaleFactor}>
            <LayoutImage src={sublist.layout.file} className='img-fluid shadow-sm rounded my-2' alt={sublist.title} />
          </LayoutContainer>

          <h5 className='m-0 text-wrap text-center mt-3' style={{ fontFamily: 'inherit' }}>
            {sublist.details}
          </h5>
        </section>

        <SublistFinishingOptionsViewer sublist={sublist} importedModels={importedModels} />
      </div>
    </div>
  );
}
