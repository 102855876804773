import React, { type MouseEvent } from 'react';
import { Container, TextViewer } from './styles';
import BasicTextInput from '../../../../../../../../components/Forms/BasicTextInput';

type TextInputTableCellType = {
  text: string;
  isEditing: boolean;
  textCenter: boolean;
  onChange: (updatedText: string) => void;
  onDoubleClick: (event: MouseEvent<HTMLSpanElement>) => void;
  disableEditingMode: () => void;
}

export default function TextInputTableCell({ text, isEditing, textCenter, onChange, onDoubleClick, disableEditingMode }: TextInputTableCellType) {
  return (
    <Container>

      {
        !isEditing && <TextViewer
          onDoubleClick={onDoubleClick}
          className={textCenter ? 'text-center' : ''}>
          {text.length > 0 ? text : ''}
        </TextViewer>
      }

      {
        isEditing && <BasicTextInput autofocus
          value={text}
          onChange={({ target }) => {
            onChange(target.value);
          }}
          onKeyDown={(event) => {
            if (event.key.toUpperCase() === 'ENTER') {
              disableEditingMode();
              const inputFiredEvent = event.target as HTMLInputElement;
              onChange(inputFiredEvent.value.trim());
            }
          }}
          handleBlur={({ target }) => {
            disableEditingMode();
            onChange(target.value.trim());
          }}
        />
      }

    </Container>
  );
};
