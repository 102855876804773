import React, { useMemo } from 'react';
import { type ClothingSizeType, type ClothingModelType } from '../../../types/ClothingModelType';
import { type TableSizeConfigType } from '../../../types/TableSizeConfigType';
import { findCachedModelById } from '../../../pages/Authenticated/OrderList/ViewProject/services/modelService';
import { useAppTranslation } from '../../../contexts/TranslationContext';

type ClothingMetricsTableType = {
  importedModels: ClothingModelType[];
  clothingTableSizeConfig: TableSizeConfigType;
}

export default function ClothingMetricsTable({ importedModels, clothingTableSizeConfig }: ClothingMetricsTableType) {
  const { Translate } = useAppTranslation();

  const selectedModel = useMemo<ClothingModelType | null>(() => {
    return findCachedModelById(importedModels, clothingTableSizeConfig.selectedModelId);
  }, [clothingTableSizeConfig.selectedModelId, importedModels]);

  const selectedInformations = useMemo<ClothingSizeType[] | null>(() => {
    if (!selectedModel) return null;
    return selectedModel.informations[clothingTableSizeConfig.selectedGender].filter((model => model.size !== '' && !!model.dimensions));
  }, [clothingTableSizeConfig.selectedGender, selectedModel]);

  if (!selectedModel || !selectedInformations) return <>{Translate('error.model-needed-not-found')}</>;

  return (
    <table className='table table-sm table-bordered'>
      <thead>
        <tr>
          <th>{Translate('labels.size')}</th>
          <th>{Translate('labels.width-height')}</th>
        </tr>
      </thead>
      <tbody>
        {
          selectedInformations.length === 0 && (
            <tr>
              <td colSpan={999}>{Translate('status.nothing-to-show-for-now')}</td>
            </tr>
          )
        }

        {
          selectedInformations.map((model, index) => {
            return (
              <tr key={index}>
                <td className='table-column-fit text-center'>{model.size}</td>
                <td>{model.dimensions}</td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};
