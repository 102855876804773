import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Options = styled.div`
  position: absolute;
  top: 40px;
  right: -10px;
  background-color: var(--dark);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--dark);
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 5px;

  &:hover {
    background-color: var(--black);
  }
`;

export const OptionIcon = styled.i`
  color: var(--white);
  margin-right: 10px;
`;

export const OptionText = styled.span`
  color: var(--white);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
`;

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.span`
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;
