import styled from 'styled-components';

export const PrintOverlay = styled.div`
  font-size: 14pt !important;
  background-color: #fff !important;

  * {
    color: #000 !important;
  }

  .border-top-thick {
    border-top: 5px solid #555;
  }

  @media print {

    table:nth-child(1) td {
      background-color: #fff !important;
    }

    table:nth-child(2) thead th, table:nth-child(2) tbody tr:last-child > td {
      background-color: #fff !important;
    }

    .border {
      border-color: var(--dark) !important;
    }

    .table-bordered {
      border-color: var(--dark) !important;
    }
    .table-bordered td,
    .table-bordered th {
      border-color: var(--dark) !important;
    }
  }
`;
